import React, { useEffect, useMemo, useState } from 'react';

import { EmployeeRole, Enum, States } from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Organization } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, TextInput } from 'styleguide-v2';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import {
  CreatePodConfigParams,
  PodConfigEmployeeSlot,
} from 'dash/src/services/PodServices';

interface Props {
  data: {
    employees: SelectableInfo<Employee>[];
    languages: Selectable[];
    programExtensions: Selectable[];
    referrals: SelectableInfo<Organization>[];
  };
  loadingData: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (params: CreatePodConfigParams) => Promise<void>;
}

const PodConfigAddModal = ({
  data,
  loadingData,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [manager, setManager] = useState<SelectableInfo<Employee>>();
  const [state, setState] = useState<Selectable[]>([]);
  const [language, setLanguage] = useState<Selectable[]>([]);
  const [referralSource, setReferralSource] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [np, setNP] = useState<SelectableInfo<Employee>[]>([]);
  const [rn, setRN] = useState<SelectableInfo<Employee>[]>([]);
  const [healthCoach, setHealthCoach] = useState<SelectableInfo<Employee>[]>(
    [],
  );
  const [engagementOwner, setEngagementOwner] = useState<
    SelectableInfo<Employee>[]
  >([]);
  const [careCoordinator, setCareCoordinator] = useState<
    SelectableInfo<Employee>[]
  >([]);
  const [programExtension, setProgramExtension] = useState<Selectable[]>([]);

  const podManagers = useMemo(
    () => data.employees?.filter(({ info }) => info?.isPodManager),
    [data.employees],
  );

  const nps = useMemo(() => data.employees?.filter(({ info }) => info?.isNP), [
    data.employees,
  ]);

  const rns = useMemo(() => data.employees?.filter(({ info }) => info?.isRN), [
    data.employees,
  ]);

  const healthCoaches = useMemo(
    () => data.employees?.filter(({ info }) => info?.isInHealthCoaches),
    [data.employees],
  );

  const engagementOwners = useMemo(
    () => data.employees?.filter(({ info }) => info?.isInEngagement),
    [data.employees],
  );

  const clinicalMedicalAssistant = useMemo(
    () => data.employees?.filter(({ info }) => info?.isCareCoordinator),
    [data.employees],
  );

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSubmitted(false);
      setName('');
      setManager(undefined);
      setState([]);
      setLanguage([]);
      setReferralSource([]);
      setNP([]);
      setRN([]);
      setHealthCoach([]);
      setEngagementOwner([]);
      setCareCoordinator([]);
      setProgramExtension([]);
    }
  }, [open]);

  const validate = () =>
    Boolean(
      name &&
        manager &&
        state?.length &&
        language?.length &&
        programExtension?.length &&
        np?.length &&
        rn?.length &&
        healthCoach?.length &&
        engagementOwner?.length &&
        careCoordinator?.length,
    );

  const buildEmployeeSlots = (
    items?: SelectableInfo<Employee>[],
    role?: string,
  ) =>
    items?.map(
      ({ info }) =>
        ({
          employeeId: info?.id,
          role,
        } as PodConfigEmployeeSlot),
    ) || [];

  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);
      const employeeSlots = [
        ...buildEmployeeSlots(np, EmployeeRole.NP.value),
        ...buildEmployeeSlots(rn, EmployeeRole.RN.value),
        ...buildEmployeeSlots(healthCoach, EmployeeRole.HEALTH_COACH.value),
        ...buildEmployeeSlots(
          engagementOwner,
          EmployeeRole.ENGAGEMENT_COORDINATOR.value,
        ),
        ...buildEmployeeSlots(careCoordinator, EmployeeRole.MA.value),
      ];

      try {
        await onSubmit({
          name,
          employees: employeeSlots,
          languages: language?.map(({ value }) => value as string),
          managerId: manager?.value as number,
          programExtensionIds: programExtension?.map(
            ({ value }) => value as number,
          ),
          referralSourceIds: referralSource?.map(
            ({ value }) => value as number,
          ),
          states: state?.map(({ value }) => value as string),
        });
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('pods.config.modal.titleAdd')}</h2>}
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-12"
            error={submitted && !name}
            label={translate('pods.config.common.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            value={name}
            required
          />
          <Select
            className="grid-span-12"
            disableClearable
            error={submitted && !manager}
            items={podManagers}
            label={translate('pods.config.common.podManager')}
            loading={loadingData}
            onChange={setManager}
            value={manager}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !state?.length}
            items={Enum.toSelectable(States.asArray)}
            label={translate('pods.config.common.states')}
            limitTags={1}
            multiple
            onChange={setState}
            value={state}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !language?.length}
            grouped={({ preferred }) =>
              preferred
                ? translate('global.preferred')
                : translate('global.other')
            }
            items={data.languages}
            label={translate('pods.config.common.languages')}
            loading={loadingData}
            limitTags={1}
            multiple
            onChange={setLanguage}
            value={language}
            required
          />
          <ReferralDropdown
            className="grid-span-12"
            items={data.referrals}
            label={translate('pods.config.common.referralSources')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setReferralSource}
            value={referralSource}
          />
          <Select
            className="grid-span-12"
            error={submitted && !programExtension?.length}
            items={data.programExtensions}
            label={translate('pods.config.common.programExtensions')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setProgramExtension}
            value={programExtension}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !np?.length}
            items={nps}
            label={translate('pods.config.common.nps')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setNP}
            value={np}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !rn?.length}
            items={rns}
            label={translate('pods.config.common.rns')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setRN}
            value={rn}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !healthCoach?.length}
            items={healthCoaches}
            label={translate('pods.config.common.healthCoaches')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setHealthCoach}
            value={healthCoach}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !engagementOwner?.length}
            items={engagementOwners}
            label={translate('pods.config.common.engagementOwners')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setEngagementOwner}
            value={engagementOwner}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !careCoordinator?.length}
            items={clinicalMedicalAssistant}
            label={translate('pods.config.common.careCoordinators')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setCareCoordinator}
            value={careCoordinator}
            required
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="add-pod-config-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="add-pod-config-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default PodConfigAddModal;
