import { Moment } from 'moment';

import { EmployeeRole, Language, States } from '../enums';
import { Employee } from './Employee';
import { make, makeArray, makeEnums, makeUnix } from './Model';
import { Organization } from './Organization';
import { PodConfigEmployeeSlot } from './PodConfigEmployeeSlot';
import { ProgramExtension } from './ProgramExtension';

export class PodConfig {
  id = 0;

  active = true;

  name = '';

  careCoordinators: PodConfigEmployeeSlot[];

  engagementOwners: PodConfigEmployeeSlot[];

  healthCoaches: PodConfigEmployeeSlot[];

  languages: Language[];

  npOwners: PodConfigEmployeeSlot[];

  podManager: Employee;

  programExtensions: ProgramExtension[];

  referralSources?: Organization[];

  rnOwners: PodConfigEmployeeSlot[];

  states: States[];

  updatedAt?: Moment;

  updatedBy?: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.careCoordinators = makeArray(
      obj.employeeSlots?.filter(
        ({ role }: any) => role === EmployeeRole.MA.value,
      ) || [],
      PodConfigEmployeeSlot,
    );
    this.engagementOwners = makeArray(
      obj.employeeSlots?.filter(
        ({ role }: any) => role === EmployeeRole.ENGAGEMENT_COORDINATOR.value,
      ) || [],
      PodConfigEmployeeSlot,
    );
    this.healthCoaches = makeArray(
      obj.employeeSlots?.filter(
        ({ role }: any) => role === EmployeeRole.HEALTH_COACH.value,
      ) || [],
      PodConfigEmployeeSlot,
    );
    this.languages = makeEnums(obj.languages, Language);
    this.npOwners = makeArray(
      obj.employeeSlots?.filter(
        ({ role }: any) => role === EmployeeRole.NP.value,
      ) || [],
      PodConfigEmployeeSlot,
    );
    this.podManager = make(obj.manager, Employee);
    this.programExtensions = makeArray(obj.services, ProgramExtension);
    this.referralSources = makeArray(obj.referrals, Organization);
    this.rnOwners = makeArray(
      obj.employeeSlots?.filter(
        ({ role }: any) => role === EmployeeRole.RN.value,
      ) || [],
      PodConfigEmployeeSlot,
    );
    this.states = makeEnums(obj.states, States);
    this.updatedAt = makeUnix(obj.updatedAt);
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default PodConfig;
