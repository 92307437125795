import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useChannelStateContext } from 'stream-chat-react';

import {
  CaregiverRelationship,
  EncounterMethod,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, Patient } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import { BrandMemberIcon, Button } from 'styleguide-v2';
import { Link, Text } from 'styleguide/src/components/Text';

import { BrandBanner } from 'dash/src/components/BrandBanner';
import { AddEncounterButton } from 'dash/src/pages/MemberProfile/Encounters/AddEncounterButton';
import { CacheServices } from 'dash/src/services';
import { fetchById } from 'dash/src/services/PatientServices';
import { capitalize } from 'lodash';

import { Channel } from 'stream-chat';

type ChannelData = {
  caregiverType: string; // value of CaregiverType
  closed: boolean;
  relationship: string; // value of CaregiverRelationship
  memberId: number;
  name: string;
  rnOwnerId: number;
  created_by: {
    name: string;
    last_active: string;
    online: boolean;
  };
};

interface HeaderProps {
  memberFilter?: boolean;
  onCloseConversation: (channel: Channel) => void;
}

const ChannelHeader = ({ memberFilter, onCloseConversation }: HeaderProps) => {
  const { channel } = useChannelStateContext();
  const { showChatClosedFeature } = useFlags();
  const [member, setMember] = useState<Patient>();
  const [careTeamSubtypes, setCareTeamSubtypes] = useState<BaseEnum[]>([]);
  const { data } = channel;
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by,
    closed,
    relationship,
    memberId,
    name,
  } = data as ChannelData;

  const getInitialData = async () => {
    const [
      relationships,
      aideTypes,
      otherRelationships,
      specialities,
    ] = await Promise.all([
      CacheServices.getCareTeamMemberFamilyRelationShips(),
      CacheServices.getCareTeamMemberAideTypes(),
      CacheServices.getCareTeamMemberOtherRelationships(),
      CacheServices.getCareTeamMemberProviderSpecialities(),
    ]);
    setCareTeamSubtypes([
      ...relationships,
      ...aideTypes,
      ...otherRelationships,
      ...specialities,
    ]);
  };

  useEffect(() => {
    const fetchMember = async () => {
      setMember(await fetchById(memberId));
    };
    fetchMember();
    getInitialData();
  }, []);

  useEffect(() => {
    if (member?.id && member.isBrandCareAtHome()) {
      const brandName = member.brand.value.split('_').map(capitalize).join(' ');
      new Toast({
        title: translate('personalDetails.warningBrandTitle', {
          brand: brandName,
        }),
        body: translate('personalDetails.warningBrandBody', {
          brand: brandName,
        }),
        type: 'care-at-home',
        position: 'bottom-right',
      });
    }
  }, [member?.id]);

  const fullName = name?.split(' (')[0]; // split returns ['First Last']['Relationship)']
  const lastActive = created_by?.online
    ? 'Now'
    : moment(created_by.last_active).fromNow();
  const getRelationship = () => {
    if (
      !relationship ||
      relationship === CaregiverRelationship.NOT_RELATED.value
    ) {
      return 'Aide to ';
    }

    const ctr = careTeamSubtypes.find(({ id }) => id === relationship);
    if (ctr) {
      return `${ctr.description} of `;
    }
    if (
      relationship === CaregiverRelationship.MEMBER.value ||
      !CaregiverRelationship.byKey[relationship]
    ) {
      return '';
    }
    return `${CaregiverRelationship.byKey[relationship].toString()} of `;
  };
  const linkText = `${
    getRelationship() ? member?.fullName || EMPTY : 'member'
  } (view profile)`;
  const buttonHref = memberFilter
    ? '/#/chats'
    : `/#/chats?memberId=${memberId}`;

  return (
    <div className="message-header-container">
      <div className="message-header">
        <div className="info">
          <div className="patient-caregiver">
            <Text large bold className="flex gap">
              {fullName}
              {member?.isBrandCareAtHome() && (
                <BrandMemberIcon brand="careAtHome" size="s" />
              )}
            </Text>
            <Text small>
              {getRelationship()}
              <Link
                openNewTab
                className="small"
                href={`/#/patients/${memberId}`}
              >
                {linkText}
              </Link>
            </Text>
            <Text>{'| '}</Text>
            <Text small>{`RN Owner: ${
              member?.owner?.firstInitialLastName || EMPTY
            }`}</Text>
          </div>
          <Text small>{`Active: ${lastActive}`}</Text>
        </div>
        <div className="flex gap">
          {showChatClosedFeature && !closed && (
            <Button
              color="info"
              type="outlined"
              data-cy="close-conversation-chats"
              onClick={() => onCloseConversation(channel)}
            >
              <i className="fa fa-archive" />
              Close Conversation
            </Button>
          )}
          <AddEncounterButton
            method={EncounterMethod.CHAT}
            patientId={memberId}
          />
          <Button
            color="info"
            type="outlined"
            data-cy="toggle-member-chats"
            href={buttonHref}
          >
            <i className="fa fa-comments" />
            {memberFilter ? 'View All Chats' : "View Member's Chats"}
          </Button>
        </div>
      </div>
      <BrandBanner member={member} />
    </div>
  );
};

export default ChannelHeader;
