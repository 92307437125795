import moment from 'moment';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { Brand, Organization } from '@vestahealthcare/common/models';
import {
  EMPTY,
  NO_TIME_DATE_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  CollapseIcon,
  EmptyState,
  Fonts,
  IconButton,
  Table,
  UpdatedBy,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { sortBy } from 'lodash';

interface OrganizationExtended extends Organization {
  collapsed: boolean;
  detail: ReactNode;
}
interface Props {
  child?: boolean;
  referrals: Organization[];
  originals: Organization[];
  onEdit: (
    organization: Organization,
    child: boolean,
    grandchild: boolean,
  ) => void;
}

const COLUMNS = (
  onEdit: (
    organization: Organization,
    child: boolean,
    grandchild: boolean,
  ) => void,
  toggleDetail: (organization: Organization) => void,
  brandList: Brand[],
) => [
  { headerName: 'Name', field: 'name', sortable: true, width: '20%' },
  { headerName: 'Abbr', field: 'abbr' },
  { headerName: 'Type', field: 'type.name' },
  {
    headerName: 'Brand',
    component: ({ brandId }: Organization) =>
      brandList?.find(({ id }) => id === brandId)?.name || EMPTY,
  },
  { headerName: 'Status', field: 'status.name', width: 100 },
  {
    width: 120,
    headerName: 'Start Date',
    component: (row: Organization) =>
      row.startDate ? moment(row.startDate).format(NO_TIME_DATE_FORMAT) : EMPTY,
  },
  {
    width: 120,
    headerName: 'End Date',
    component: (row: Organization) =>
      row.endDate ? moment(row.endDate).format(NO_TIME_DATE_FORMAT) : EMPTY,
  },
  { headerName: 'Medicare', field: 'medicareDiscovery' },
  {
    headerName: 'Update At',
    width: 200,
    component: ({ updatedAt, updatedBy }: OrganizationExtended) =>
      updatedAt && <UpdatedBy date={updatedAt} user={updatedBy?.fullName} />,
  },
  {
    headerName: '',
    width: 75,
    component: (row: OrganizationExtended) => (
      <div className="flex min-gap">
        <IconButton
          onClick={(evt) => {
            evt?.stopPropagation();
            onEdit(row, true, false);
          }}
          size="small"
        >
          <EditIcon />
        </IconButton>
        {row.hasChildren && (
          <IconButton onClick={() => toggleDetail(row)} size="small">
            <CollapseIcon open={row.collapsed} />
          </IconButton>
        )}
      </div>
    ),
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const ReferralTable = (props: Props) => {
  const styles = useStyles();
  const { referrals, originals, onEdit } = props;
  const [sortedReferrals, setSortedReferrals] = useState(referrals);
  const [brandList, setBrandList] = useState<Brand[]>([]);

  const getInitialData = async () => {
    const brands = await CacheServices.getOrganizationBrands();
    setBrandList(brands);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setSortedReferrals([...referrals]);
  }, [referrals]);

  const sortDataByName = (direction?: string) => {
    setSortedReferrals(
      [...referrals].sort(
        (a: Organization, b: Organization) =>
          a.name.localeCompare(b.name) * (direction === 'desc' ? -1 : 1),
      ),
    );
  };

  const toggleDetail = (referral: Organization) => {
    const index = sortedReferrals.findIndex(({ id }) => id === referral.id);
    const item = sortedReferrals[index] as OrganizationExtended;
    const children: Organization[] = [];

    if (item.children) {
      item.children.forEach((ref) => {
        const objToPush = originals?.find((y) => y.id === ref.id);
        if (objToPush) children.push(objToPush);
      });
    }

    item.collapsed = !item.collapsed;
    if (item.collapsed) {
      const isChild = !!props.child;
      item.detail = (
        <ReferralTable
          originals={originals}
          referrals={sortBy(children, 'name')}
          onEdit={(o, child, grandChild) =>
            onEdit(o, child || !isChild, grandChild || !!isChild)
          }
          child
        />
      );
    } else {
      delete item.detail;
    }
    setSortedReferrals([...sortedReferrals]);
  };

  const columns = useMemo(() => COLUMNS(onEdit, toggleDetail, brandList), [
    brandList,
    onEdit,
    toggleDetail,
  ]);

  return referrals?.length ? (
    <Table
      className={styles.table}
      config={{
        columns,
        compact: true,
        data: sortedReferrals,
        detail: true,
        paginationOptions: [-1],
      }}
      data-cy="referral-table"
      onSort={(column) => sortDataByName(column.direction)}
    />
  ) : (
    <EmptyState />
  );
};

export default ReferralTable;
