import { Enum } from './Enum';

export class TaskStatus extends Enum {
  translationKey = 'taskStatus';

  static asArray: TaskStatus[] = [];

  static byKey: { [name: string]: TaskStatus } = {};

  static readonly NOT_STARTED = new TaskStatus('NOT_STARTED', false);

  static readonly IN_PROGRESS = new TaskStatus('IN_PROGRESS', false);

  static readonly PENDING_AUTHORIZATION = new TaskStatus(
    'PENDING_AUTHORIZATION',
    false,
  );

  static readonly DELAYED = new TaskStatus('DELAYED', false);

  static readonly ESCALATED = new TaskStatus('ESCALATED', false);

  static readonly ORDER_PLACED = new TaskStatus('ORDER_PLACED', false);

  static readonly SHIPPED = new TaskStatus('SHIPPED', false);

  static readonly DELIVERED = new TaskStatus('DELIVERED', false);

  static readonly VOID = new TaskStatus('VOID', true);

  static readonly COMPLETED = new TaskStatus('COMPLETED', true);

  constructor(public readonly value: string, public readonly closed: boolean) {
    super();
    TaskStatus.asArray.push(this);
    TaskStatus.byKey[value] = this;
  }

  static getNotClosedStatuses() {
    return TaskStatus.asArray.filter((s) => !s.closed).map((s) => s.value);
  }

  static getClosedStatuses() {
    return TaskStatus.asArray.filter((s) => s.closed).map((s) => s.value);
  }

  static COLOR_MAP = new Map<
    TaskStatus,
    {
      color: 'primary' | 'secondary' | 'warning' | 'error' | 'action' | 'info';
      type: 'contained' | 'outlined';
    }
  >([
    [TaskStatus.NOT_STARTED, { color: 'action', type: 'outlined' }],
    [TaskStatus.ESCALATED, { color: 'error', type: 'outlined' }],
    [TaskStatus.IN_PROGRESS, { color: 'info', type: 'contained' }],
    [TaskStatus.PENDING_AUTHORIZATION, { color: 'info', type: 'contained' }],
    [TaskStatus.ORDER_PLACED, { color: 'info', type: 'contained' }],
    [TaskStatus.SHIPPED, { color: 'info', type: 'contained' }],
    [TaskStatus.DELIVERED, { color: 'info', type: 'contained' }],
    [TaskStatus.COMPLETED, { color: 'secondary', type: 'contained' }],
    [TaskStatus.DELAYED, { color: 'error', type: 'outlined' }],
    [TaskStatus.VOID, { color: 'error', type: 'contained' }],
  ]);

  static getColor(status: TaskStatus | string) {
    const value = typeof status === 'string' ? status : status.value;
    switch (value) {
      case TaskStatus.NOT_STARTED.value:
        return 'info';
      case TaskStatus.DELAYED.value:
      case TaskStatus.ESCALATED.value:
      case TaskStatus.VOID.value:
        return 'error';
      case TaskStatus.COMPLETED.value:
        return 'success';
      case TaskStatus.IN_PROGRESS.value:
      case TaskStatus.PENDING_AUTHORIZATION.value:
      default:
        return 'warning';
    }
  }
}

export default TaskStatus;
