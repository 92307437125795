import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MemberStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberProgramExtension,
  MemberReferral,
  ProgramExtension,
} from '@vestahealthcare/common/models';

import { CustomTypeOptions, Spinner, Toast } from 'styleguide';

import { MemberProfileContext } from 'dash/src/contexts/MemberProfileContext';
import SMS from 'dash/src/pages/Chats/SMS';
import Conversation from 'dash/src/pages/Chats/SMS/Conversation';
import Biometrics from 'dash/src/pages/MemberProfile/Biometrics';
import ClinicalSummary from 'dash/src/pages/MemberProfile/ClinicalSummary';
import Documents from 'dash/src/pages/MemberProfile/Documents';
import Events from 'dash/src/pages/MemberProfile/Events';
import HealthReports from 'dash/src/pages/MemberProfile/HealthReports';
import MemberDetails from 'dash/src/pages/MemberProfile/MemberDetails';
import MemberNetwork from 'dash/src/pages/MemberProfile/Network';
import Observations from 'dash/src/pages/MemberProfile/Observations';
import PHIPProfile from 'dash/src/pages/MemberProfile/PHIPProfile';
import Tasks from 'dash/src/pages/MemberProfile/Tasks';
import {
  fetchPatientInfo,
  onPatientUpdated,
} from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import { fetchById } from 'dash/src/services/PatientServices';
import { capitalize } from 'lodash';

import { ClinicalLicensureModal } from './ClinicalLicensureModal';
import { MemberDashboard } from './Dashboard';
import Encounters from './Encounters';
import Header from './Header';
import Sidebar from './SideBar';
import { TOCDashboard } from './TOC';

type Params = {
  patientId: string;
};

const MemberProfile = (props: RouteComponentProps<Params>) => {
  const {
    match: {
      params: { patientId },
    },
  } = props;
  const { showClinicalLicensureWarning } = useFlags();
  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const loading = useSelector((state) => state.memberInfoSlice.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientInfo(+patientId));
    }
  }, [patientId]);

  let toast: Toast | undefined;

  useEffect(() => {
    if (!patient) return;
    if (toast) {
      toast.dismiss();
      toast = undefined;
    }
    const pe = patient.isVestaCoreLiteOrVPC();
    if (patient && patient?.id && patient.isBrandCareAtHome()) {
      const brandName = patient.brand.value
        .split('_')
        .map(capitalize)
        .join(' ');
      toast = new Toast({
        autoClose: false,
        title: translate('personalDetails.warningBrandTitle', {
          brand: brandName,
        }),
        body: translate('personalDetails.warningBrandBody', {
          brand: brandName,
        }),
        type: 'care-at-home',
        position: 'bottom-right',
      });
    } else if (patient && patient?.id && pe) {
      const id =
        pe instanceof MemberProgramExtension ? pe.programExtension.id : -1;
      let brand = '';
      let body = '';
      if (pe instanceof MemberReferral) {
        brand = pe.organization.isVPCVesta
          ? 'Shared Vesta & VPC'
          : 'Vesta Primary Care';
        body = pe.organization.isVPCVesta
          ? translate('personalDetails.warningVPCVestaBody')
          : translate('personalDetails.warningBrandBody', {
              brand,
            });
      } else {
        brand = pe.programExtension.name;
        body = translate('personalDetails.warningBrandBody', {
          brand,
        });
      }
      let type: CustomTypeOptions = 'vpc';
      if (pe instanceof MemberReferral && pe.organization.isVPCVesta) {
        type = 'vpc-shared';
      }
      if (pe instanceof MemberProgramExtension) {
        type = id === ProgramExtension.VESTA_CORE ? 'warning' : 'error';
      }
      toast = new Toast({
        autoClose: false,
        title: translate('personalDetails.warningBrandTitle', {
          brand,
        }),
        body,
        type,
        position: 'bottom-right',
      });
    }

    return () => {
      if (toast) {
        toast.dismiss();
        toast = undefined;
      }
    };
  }, [patient?.id]);

  const handleFetchPatient = async () => {
    const patient = await fetchById(patientId);
    dispatch(onPatientUpdated(patient));
    return patient;
  };

  if (!patient || loading || patient?.id !== +patientId) {
    return <Spinner width={84} style={{ marginTop: 100 }} />;
  }

  const redirectRoute =
    patient.status === MemberStatus.ACTIVE
      ? '/patients/:patientId/dashboard'
      : '/patients/:patientId/personal-details';

  return (
    <MemberProfileContext.Provider
      value={{
        patient,
        updatePatient: handleFetchPatient,
      }}
    >
      {showClinicalLicensureWarning && <ClinicalLicensureModal />}
      <Header />
      <div id="page-wrapper">
        <Sidebar patient={patient} />
        <Switch>
          <Route
            path="/patients/:patientId/health-reports"
            component={HealthReports}
          />
          <Route path="/patients/:memberId/tasks" component={Tasks} />
          <Route path="/patients/:patientId/events" component={Events} exact />
          <Route
            path="/patients/:patientId/events/:eventId"
            component={Events}
          />
          <Route
            path="/patients/:patientId/encounters"
            component={Encounters}
          />
          <Route
            path="/patients/:memberId/clinical-summary"
            component={ClinicalSummary}
          />
          <Route
            path="/patients/:patientId/personal-details"
            component={MemberDetails}
          />
          <Route
            path="/patients/:patientId/biometrics"
            component={Biometrics}
          />
          <Route
            path="/patients/:patientId/observations/:category"
            component={Observations}
          />
          <Route path="/patients/:patientId/documents" component={Documents} />
          <Route
            path="/patients/:patientId/phip-profile/:careTrack"
            component={PHIPProfile}
          />
          <Route
            path="/patients/:patientId/phip-profile"
            component={PHIPProfile}
          />
          <Route path="/patients/:patientId/chats/sms/:userPhone">
            <Conversation showHeader showPhone />
          </Route>
          <Route path="/patients/:patientId/chats/sms">
            <SMS detailPath="/patients/:patientId/chats/sms/:userPhone" />
          </Route>
          <Route
            path="/patients/:patientId/network"
            component={MemberNetwork}
          />
          <Route
            path="/patients/:patientId/dashboard"
            component={MemberDashboard}
          />
          <Route
            path="/patients/:patientId/toc-list"
            component={TOCDashboard}
          />
          <Redirect from="/patients/:patientId" to={redirectRoute} />
          <Redirect from="/patients/:patientId/**" to={redirectRoute} />
        </Switch>
      </div>
    </MemberProfileContext.Provider>
  );
};

export default MemberProfile;
