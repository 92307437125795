import moment from 'moment';

import {
  BaseEnum,
  SkilledDetail,
  SkilledItem,
  TOCAcuityLevel,
  TOCDetail,
  TOCItem,
  TOCStatus,
} from '@vestahealthcare/common/models';
import { EXPORT_DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, { PaginatedResponseWithTime, PaginationParams } from './Api';

const BASE_URL = '/toc';

export type GetTOCParams = PaginationParams & {
  acuityLevel?: string[];
  assigneeId?: number[];
  brandId?: string[];
  closed?: boolean;
  communityResourceId?: number[];
  dischargedInitial2BusinessDays?: boolean;
  handoff?: boolean;
  handoffDisposition?: string[];
  hasTOCOpen?: boolean;
  language?: string[];
  memberId?: number[];
  memberName?: string;
  needsInitialCarePlan?: boolean;
  npOwnerId?: number[];
  npVisitStatus?: string[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  referralSourceId?: number[];
  rnVisitStatus?: string[];
  state?: string[];
  status?: string[];
  trueTCMStatus?: boolean;
};

export const fetchSkilledList = async (
  params: GetTOCParams,
): Promise<PaginatedResponseWithTime<SkilledItem>> => {
  const {
    skilledRecords: { items, pagination, lastUpdatedAt },
  } = await Api.getv2(`${BASE_URL}/skilled`, params);
  return {
    items: items.map((x: any) => new SkilledItem(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const fetchTOCList = async (
  params: GetTOCParams,
): Promise<PaginatedResponseWithTime<TOCItem>> => {
  const {
    tocRecords: { items, pagination, lastUpdatedAt },
  } = await Api.getv2(BASE_URL, params);
  return {
    items: items.map((x: any) => new TOCItem(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const createTOCRecordFromEvent = async (
  memberId: number,
  eventId: number,
) => {
  const { tocRecord } = await Api.postv2JSON(`${BASE_URL}/from-event`, {
    memberId,
    sourceEventId: eventId,
  });
  return new TOCItem(tocRecord);
};

export const createSkilledRecordFromEvent = async (
  memberId: number,
  eventId: number,
) => {
  const { skilledRecord } = await Api.postv2JSON(
    `${BASE_URL}/skilled/from-event`,
    {
      memberId,
      sourceEventId: eventId,
    },
  );
  return new SkilledItem(skilledRecord);
};

export type UpdateTOCParams = {
  acuityLevelId?: string;
  acuityRationale?: string;
  admitDate?: string | null;
  assigneeId?: number | null;
  assigneeGroupId?: number | null;
  chiefComplaint?: string;
  dischargeDate?: string | null;
  handoffDispositionId?: string;
  handoffRecs?: string;
  facility?: string;
  medicalReqStatusId?: string;
  status?: string;
};

export type UpdateSkilledParams = {
  assigneeId?: number | null;
  chiefComplaint?: string;
  startDate?: string | null;
  endDate?: string | null;
  handoffNotes?: string;
};

export const updateTOCRecord = async (
  id: number,
  params: UpdateTOCParams,
): Promise<TOCItem> => {
  const { tocRecord } = await Api.patchv2JSON(`${BASE_URL}/${id}`, params);
  return new TOCItem(tocRecord);
};

export const updateSkilledRecord = async (
  id: number,
  params: UpdateSkilledParams,
): Promise<SkilledItem> => {
  const { skilledRecord } = await Api.patchv2JSON(
    `${BASE_URL}/skilled/${id}`,
    params,
  );
  return new SkilledItem(skilledRecord);
};

export const closeSkilledRecord = async (id: number): Promise<SkilledItem> => {
  const { skilledRecord } = await Api.patchv2JSON(
    `${BASE_URL}/skilled/${id}/close`,
  );
  return new SkilledItem(skilledRecord);
};

export const fetchTOCDetail = async (id: number): Promise<TOCDetail> => {
  const { tocRecordDetail } = await Api.getv2(`${BASE_URL}/${id}`);
  return new TOCDetail(tocRecordDetail);
};

export const fetchSkilledDetail = async (
  id: number,
): Promise<SkilledDetail> => {
  const { skilledRecordDetail } = await Api.getv2(`${BASE_URL}/skilled/${id}`);
  return new SkilledDetail(skilledRecordDetail);
};

export type TOCCounts = {
  all?: number;
  closed?: number;
  dischargedInitial?: number;
  dischargedPost?: number;
  inpatient?: number;
  rehab?: number;
  handoff?: number;
  toc?: number;
};

export const fetchSkilledCount = async (
  params: GetTOCParams,
): Promise<TOCCounts> => {
  const { skilledCounts } = await Api.getv2(
    `${BASE_URL}/skilled/counts`,
    params,
  );
  return skilledCounts;
};

export const fetchTOCCount = async (
  params: GetTOCParams,
): Promise<TOCCounts> => {
  const { tocCounts } = await Api.getv2(`${BASE_URL}/counts`, params);
  return tocCounts;
};

export const downloadTOCList = async (params: GetTOCParams) => {
  const blob: Blob = await Api.getv2Blob('/toc/export', params);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute(
    'download',
    `TOC-Export_${moment().format(EXPORT_DATE_FORMAT)}.csv`,
  );
  tempLink.click();
};

export const downloadSkilledList = async (params: GetTOCParams) => {
  const blob: Blob = await Api.getv2Blob('/toc/skilled/export', params);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute(
    'download',
    `Skilled-Export_${moment().format(EXPORT_DATE_FORMAT)}.csv`,
  );
  tempLink.click();
};

export const fecthTOCActuityLevels = async (): Promise<TOCAcuityLevel[]> => {
  const {
    acuityLevels: { items },
  } = await Api.getv2(`${BASE_URL}/acuity-levels`);
  return items.map((x: any) => new TOCAcuityLevel(x));
};

export const fecthTOCStatus = async (): Promise<TOCStatus[]> => {
  const {
    statuses: { items },
  } = await Api.getv2(`${BASE_URL}/statuses`);
  return items.map((x: any) => new TOCStatus(x));
};

export const fecthTOCVisitStatus = async (): Promise<BaseEnum[]> => {
  // const { visitStatuses: {items} } = await Api.getv2(`${BASE_URL}/visit-status`);
  // return items.map((x: any) => new BaseEnum(x));
  return [
    {
      id: 'COMP',
      description: 'Completed',
    },
    {
      id: 'PEN',
      description: 'Pending',
    },
    {
      id: 'KEPT',
      description: 'Kept',
    },
    {
      id: 'N/S',
      description: 'No show',
    },
    {
      id: 'R/S',
      description: 'Rescheduled',
    },
  ].map((x: any) => new BaseEnum(x));
};

export const fecthTOCEligibilityStatus = async (): Promise<BaseEnum[]> => {
  const {
    trueTcmStatuses: { items },
  } = await Api.getv2(`${BASE_URL}/true-tcm-statuses`);
  return items.map((x: any) => new BaseEnum(x));
};

export const fecthTOCHandoffDispositions = async (): Promise<BaseEnum[]> => {
  const {
    handoffDispositions: { items },
  } = await Api.getv2(`${BASE_URL}/handoff-dispositions`);
  return items.map((x: any) => new BaseEnum(x));
};

export const fecthTOCMedicalReqStatus = async (): Promise<BaseEnum[]> => {
  const {
    medicalReqStatuses: { items },
  } = await Api.getv2(`${BASE_URL}/medical-req-statuses`);
  return items.map((x: any) => new BaseEnum(x));
};
