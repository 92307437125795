import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ClockIcon from '@mui/icons-material/AccessTime';
import GoalIcon from '@mui/icons-material/Flag';
import HelpIcon from '@mui/icons-material/HelpOutline';
import DoctorIcon from '@mui/icons-material/LocalHospital';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import VideoIcon from '@mui/icons-material/VideocamOutlined';
import { makeStyles } from '@mui/styles';

import {
  Enum,
  Language,
  MemberStatus,
  RPMAdherenceLevel,
} from '@vestahealthcare/common/enums';
import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PodReportingData,
  PodReportingGroup,
  PodReportingSummary,
  ProgramExtension,
  ProgramExtensionStatus,
  StoredFilter,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  EMPTY,
  LS_POD_REPORTING_FILTERS,
  LS_POD_REPORTING_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Chip,
  Colors,
  FilterItem,
  Fonts,
  LastUpdated,
  Panel,
  Select,
  Spinner,
  Summary,
  ToggleButton,
  Tooltip,
} from 'styleguide-v2';

import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import {
  GetPodReportDataParams,
  fetchCCMPodReportingBianualVisit,
  fetchCCMPodReportingGroups,
  fetchCCMPodReportingMemberStatus,
  fetchCCMPodReportingMinutes,
  fetchCCMPodReportingProvider,
  fetchCCMPodReportingRPMAdherence,
  fetchCCMPodReportingRPMStatus,
  fetchCCMPodReportingSummary,
} from 'dash/src/services/CCMServices';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import {
  LoadFilterOption,
  getAsQuery,
  getFilterItem,
  getServerFilters,
  getStoredFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { PodReportingDataTable } from './PodReportingDataTable';
import { PodReportingFiltersBar } from './PodReportingFiltersBar';
import { PodReportingFiltersModal } from './PodReportingFiltersModal';
import { PodReportingWorklistTable } from './PodReportingWorklistTable';

const UNASSIGNED = {
  label: translate('global.unassigned'),
  value: 0,
  info: undefined,
} as SelectableInfo<Employee>;
const NUMBER_OF_MONTHS = 12;
const ALL_PODS = new Employee({
  id: -1,
  fullName: translate('podReporting.allPods'),
});
const ALL_GROUPS = new EmployeeGroup({
  id: -1,
  name: translate('podReporting.allGroups'),
});
const UNASSIGNED_GROUP = new EmployeeGroup({
  name: translate('global.unassigned'),
});

const POD_REPORTING_TAB_ALL = 'all';
const POD_REPORTING_TAB_COMPLEX_CCM = 'complex-ccm';

const POD_REPORTING_TAB_ITEMS = [
  {
    label: translate('podReporting.tabs.all'),
    value: POD_REPORTING_TAB_ALL,
  },
  {
    label: translate('podReporting.tabs.complexCCM'),
    value: POD_REPORTING_TAB_COMPLEX_CCM,
  },
];

type KeyGetPodReportParams = keyof GetPodReportDataParams;

const useStyles = makeStyles({
  borderContainer: {
    gap: 0,
    '& > div': {
      padding: '2rem 3.2rem',
      borderBottom: `1px solid ${BackgroundColors.lightGray}`,
      borderRight: `1px solid ${BackgroundColors.lightGray}`,
    },
  },
  chip: {
    '&&': {
      alignItems: 'center',
      border: `1px solid ${Colors.gray}AA`,
      display: 'flex',
      justifyContent: 'center',
      height: '3rem',
      marginRight: '1rem',
      padding: '0.25rem',
      width: '3rem',
    },
  },
  date: {
    width: '15rem',
  },
  exportButton: {
    fontSize: '0.75em',
    marginLeft: 'auto',
  },
  paddingContainer: {
    padding: '2rem 3.2rem',
  },
  panelWhiteBackground: {
    '&& .heading .tabs': {
      borderColor: BackgroundColors.lightGray,
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${BackgroundColors.lightGray}`,
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 400,
      gap: '0.5rem',
      flexWrap: 'wrap',

      '&& > *': {
        minWidth: 'max-content',
      },
    },
  },
  whiteBackground: {
    backgroundColor: Colors.white,
    transition: 'none!important',
  },
});

const initFilters = (
  query: URLSearchParams,
): { tab: string } & GetPodReportDataParams => {
  const filters = getStoredFilters(LS_POD_REPORTING_FILTERS);

  const tab = query.get('tab') || filters.tab || POD_REPORTING_TAB_ALL;

  return {
    tab,
  };
};

let PodReportingStringifyFilters = '';
let PodReportingProviderStringifyFilters = '';

const POD_PAGE_FILTER = 'pod-reporting-page';

export const PodReportingDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const query = useQueryParams();

  const storedFilters = useSelector((state) =>
    state.employeeSlice.storedFilters?.filter(
      StoredFilter.filter(POD_PAGE_FILTER),
    ),
  );

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProvider, setLoadingProvider] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [pods, setPods] = useState<Employee[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [programExtensions, setProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    ProgramExtensionStatus[]
  >([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const months = [] as Moment[];

  months.push(moment());
  // Last day of month for previous months
  for (let i = NUMBER_OF_MONTHS - 1; i > 0; i -= 1) {
    months.push(
      moment()
        .subtract(NUMBER_OF_MONTHS - i, 'month')
        .endOf('month'),
    );
  }

  const [summary, setSummary] = useState<PodReportingSummary>();
  const [summaryGroups, setSummaryGroups] = useState<PodReportingGroup[]>([]);
  const [summaryMembers, setSummaryMembers] = useState<PodReportingData[]>([]);
  const [summaryMinutes, setSummaryMinutes] = useState<PodReportingData[]>([]);
  const [summaryVirtualVisit, setSummaryVirtualVisit] = useState<
    PodReportingData[]
  >([]);
  const [summaryRPMAdherence, setSummaryRPMAdherence] = useState<
    PodReportingData[]
  >([]);
  const [summaryRPMStatus, setSummaryRPMStatus] = useState<PodReportingData[]>(
    [],
  );
  const [summaryProvider, setSummaryProvider] = useState<PodReportingData[]>(
    [],
  );

  const { tab: initialTab } = initFilters(query);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetPodReportParams]?: FilterItem;
    }
  >();
  const [month, setMonth] = useState<Moment | undefined>(moment());

  const [tab, setTab] = useState<string>(initialTab);
  const [filtersString, setFiltersString] = useState<string>('');
  const [filtersStringWithoutGroups, setFiltersStringWithoutGroups] = useState<
    string
  >('');

  const filtersData = useMemo(
    () => ({
      employees: [UNASSIGNED, ...Employee.toSelectable(employees)],
      languages: Language.toSelectable(languages),
      programExtensions: ProgramExtension.toSelectable(programExtensions),
      worklistGroups: EmployeeGroup.toSelectable(
        groups.filter(({ id }) => id !== -1),
      ),
    }),
    [languages, pods, programExtensions, groups],
  );

  const getFiltersData = async () => {
    setLoadingFilters(true);
    const [groups, employees, pes, pess, languages] = await Promise.all([
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getEmployees(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      fetchSupportedLanguages(),
    ]);

    setGroups([ALL_GROUPS, UNASSIGNED_GROUP, ...groups]);
    setEmployees(employees);
    setPods([ALL_PODS, ...employees.filter((e) => e.isNP)]);
    setProgramExtensions(pes);
    setProgramExtensionStatuses(pess);
    setLanguages(languages);
    setLoadingFilters(false);
  };

  const getData = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters),
      month,
    };

    PodReportingStringifyFilters = JSON.stringify(currentFilters);
    setLoading(true);

    const [
      summary,
      groups,
      members,
      minutes,
      virtualVisit,
      rpmAdherence,
      rpmStatus,
    ] = await Promise.all([
      fetchCCMPodReportingSummary(currentFilters),
      fetchCCMPodReportingGroups(currentFilters),
      fetchCCMPodReportingMemberStatus(currentFilters),
      fetchCCMPodReportingMinutes(currentFilters),
      fetchCCMPodReportingBianualVisit(currentFilters),
      fetchCCMPodReportingRPMAdherence(currentFilters),
      fetchCCMPodReportingRPMStatus(currentFilters),
    ]);

    if (PodReportingStringifyFilters === JSON.stringify(currentFilters)) {
      setSummary(summary);
      setSummaryGroups(
        groups?.length > 1
          ? [
              new PodReportingGroup({
                worklistName: translate('global.total'),
                summary,
              }),
              ...groups,
            ]
          : groups,
      );
      setSummaryMembers(members);
      setSummaryMinutes(minutes);
      setSummaryVirtualVisit(virtualVisit);
      setSummaryRPMAdherence(rpmAdherence);
      setSummaryRPMStatus(rpmStatus);

      setLoading(false);
    }
  };

  const getProviderData = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters),
      month,
    };

    setLoadingProvider(true);
    const completeFilters = {
      ...currentFilters,
      isComplex: tab === POD_REPORTING_TAB_COMPLEX_CCM,
    };

    PodReportingProviderStringifyFilters = JSON.stringify(completeFilters);

    const provider = await fetchCCMPodReportingProvider(completeFilters);

    if (
      PodReportingProviderStringifyFilters === JSON.stringify(completeFilters)
    ) {
      setSummaryProvider(provider);
      setLoadingProvider(false);
    }
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getData();
    getProviderData();
  }, [newFilters, month]);

  useEffect(() => {
    getProviderData();
  }, [tab]);

  useEffect(() => {
    if (!newFilters) return;

    const unifiedFilters: GetPodReportDataParams = getServerFilters(newFilters);

    const currentFilters = [
      unifiedFilters.isUnreachable ? 'isUnreachable=true' : '',
      unifiedFilters.careCoordinatorId?.length
        ? unifiedFilters.careCoordinatorId
            .map((id) => `careCoordinatorId=${id}`)
            .join('&')
        : '',
      unifiedFilters?.healthCoachId?.length
        ? unifiedFilters.healthCoachId
            .map((id) => `healthCoachId=${id}`)
            .join('&')
        : '',
      unifiedFilters.npOwnerId?.length
        ? unifiedFilters.npOwnerId.map((id) => `npOwnerId=${id}`).join('&')
        : '',
      unifiedFilters.podManagerId?.length
        ? unifiedFilters.podManagerId
            .map((id) => `podManagerId=${id}`)
            .join('&')
        : '',
      unifiedFilters.rnOwnerId?.length
        ? unifiedFilters.rnOwnerId.map((id) => `rnOwnerId=${id}`).join('&')
        : '',
      unifiedFilters.excludedProgramExtensionId?.length
        ? unifiedFilters.excludedProgramExtensionId
            .map((id) => `excludedProgramExtensionId=${id}`)
            .join('&')
        : '',
      unifiedFilters.language?.length
        ? unifiedFilters.language.map((id) => `language=${id}`).join('&')
        : '',
      unifiedFilters.language?.length ? 'hasLanguage=true' : '',
    ];
    setFiltersString(
      [
        ...currentFilters,
        unifiedFilters.worklistGroupId?.length
          ? unifiedFilters.worklistGroupId
              .map((id) => `worklistGroupId=${id}`)
              .join('&')
          : '',
      ]
        .filter(Boolean)
        .join('&'),
    );
    setFiltersStringWithoutGroups(currentFilters.filter(Boolean).join('&'));
  }, [newFilters]);

  useEffect(() => {
    const flatFilters = {
      tab,
      month: month?.format(DATE_FORMAT),
    };
    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);
    saveStoredFilters(LS_POD_REPORTING_FILTERS, flatFilters);
  }, [tab, month]);

  const storedFiltersConfiguration = useMemo(() => {
    if (
      filtersData.employees?.length &&
      filtersData.languages?.length &&
      filtersData.programExtensions?.length &&
      filtersData.worklistGroups?.length
    ) {
      return {
        careCoordinatorId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.clinicalAssistant'),
          multiple: true,
        },
        excludedProgramExtensionId: {
          data: filtersData.programExtensions,
          default: [
            ProgramExtension.MEDICAID,
            ProgramExtension.TCM,
            ProgramExtension.VESTA_LITE,
          ],
          label: translate('podReporting.filters.excludedProgramExtensions'),
          multiple: true,
        },
        healthCoachId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.healthCoach'),
          multiple: true,
        },
        isComplex: {},
        isUnreachable: {
          default: false,
          label: translate('podReporting.filters.unreachable'),
        },
        language: {
          data: filtersData.languages,
          label: translate('podReporting.filters.language'),
          multiple: true,
        },
        npOwnerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.pods'),
          multiple: true,
        },
        podManagerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.podManager'),
          multiple: true,
        },
        rnOwnerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.rnOwner'),
          multiple: true,
        },
        status: {
          data: Enum.toSelectable(MemberStatus.asArray),
          label: translate('podReporting.filters.memberStatus'),
          multiple: true,
        },
        worklistGroupId: {
          data: filtersData.worklistGroups,
          label: translate('podReporting.filters.groups'),
          multiple: true,
        },
      } as { [x in KeyGetPodReportParams]: LoadFilterOption };
    }
  }, [filtersData]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const storedFilters = loadNewFiltersFromStorage(
        LS_POD_REPORTING_FILTERS_V2,
        storedFiltersConfiguration,
      );
      setNewFilters(storedFilters);
    }
  }, [storedFiltersConfiguration]);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_POD_REPORTING_FILTERS_V2, {
        ...newFilters,
        tab: {
          label: '',
          value: { label: '', value: tab },
        },
      });
    }
  }, [newFilters, tab]);

  return (
    <Panel className={styles.panelWhiteBackground}>
      <Panel.Heading
        className={styles.borderBottom}
        title={translate('podReporting.title')}
        filtersV2
      >
        <Panel.Actions>
          {summary && (
            <LastUpdated
              lastUpdatedAt={summary.lastUpdatedAt}
              tooltip={translate('podReporting.lastUpdatedTooltip')}
            />
          )}
          <Select
            className={styles.date}
            data-cy="pod-reporting-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={months}
            onChange={(month?: Moment) => {
              setMonth(month);
            }}
            placeholder={translate('podReporting.filters.month')}
            value={month}
          />
        </Panel.Actions>
        <Panel.FilterBar
          onClearFilters={() => {
            setNewFilters({
              isUnreachable: getFilterItem(
                translate('podReporting.filters.unreachable'),
                false,
              ),
            });
          }}
          onDeleteFilter={(key: string) => {
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpenFilters(!openFilters)}
          onSelectFilter={(filter) =>
            storedFiltersConfiguration &&
            setNewFilters(
              loadNewFilters(filter.filters, storedFiltersConfiguration),
            )
          }
          chips={{
            ...newFilters,
            isUnreachable: newFilters?.isUnreachable
              ? ({
                  ...newFilters.isUnreachable,
                  readonly: true,
                } as FilterItem & { readonly: boolean })
              : undefined,
          }}
          inputs={
            <PodReportingFiltersBar
              data={filtersData}
              filters={newFilters}
              loading={loadingFilters}
              onChange={setNewFilters}
            />
          }
          storedFilters={storedFilters}
        />
      </Panel.Heading>
      <Panel.Body
        className={classNames('no-padding', styles.whiteBackground)}
        loading={loading}
      >
        <PodReportingFiltersModal
          data={filtersData}
          filters={newFilters}
          loadingFilters={loadingFilters}
          open={openFilters}
          onChange={setNewFilters}
          onClose={() => setOpenFilters(false)}
          onDeleteFilter={async (filter) => {
            await deleteSelfStoredFilter(filter.id);
            dispatch(onStoredFilterDeleted(filter));
          }}
          onEditFilter={async (filter) => {
            await updateSelfStoredFilter(filter.id, filter);
            dispatch(onStoredFilterChange(filter));
          }}
          onSaveFilter={async (filter) => {
            if (filter.id) {
              await updateSelfStoredFilter(filter.id, {
                filters: saveNewFilters(newFilters || {}),
              });
              dispatch(onStoredFilterChange(filter));
            } else if (newFilters) {
              const updated = await createSelfStoredFilter({
                description: filter.description,
                filters: saveNewFilters(newFilters || {}),
                page: POD_PAGE_FILTER,
                preferred: false,
              });
              dispatch(onStoredFilterAdd(updated));
            }
          }}
          storedFilters={storedFilters}
        />
        <div
          className={classNames(
            'flex spaced',
            styles.borderBottom,
            styles.paddingContainer,
          )}
        >
          <Summary
            color={Colors.textGreen}
            title={translate('podReporting.summary.total')}
            body={summary?.total !== undefined ? summary.total : EMPTY}
            footer={
              <>
                <span className="bold">{summary ? 100 : EMPTY}%</span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            tooltip={translate('podReporting.tooltips.total')}
          />
          <Summary
            color={Colors.mint}
            title={translate('podReporting.summary.completed')}
            body={summary?.completed !== undefined ? summary.completed : EMPTY}
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.completed / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=COMPLETED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.completed')}
          />
          <Summary
            color={Colors.gold}
            title={translate('podReporting.summary.attempted')}
            body={summary?.attempted !== undefined ? summary.attempted : EMPTY}
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.attempted / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=ATTEMPTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.attempted')}
          />
          <Summary
            color={Colors.darkOrange}
            title={translate('podReporting.summary.notStarted')}
            body={
              summary?.notStarted !== undefined ? summary.notStarted : EMPTY
            }
            footer={
              <>
                <span className="bold">
                  {summary?.total
                    ? Math.round((summary.notStarted / summary.total) * 1000) /
                      10
                    : EMPTY}
                  %
                </span>{' '}
                {translate('podReporting.summary.ofPatients')}
              </>
            }
            onClick={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&ccmCallStatus=NOT_STARTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            tooltip={translate('podReporting.tooltips.notStarted')}
          />
          <Summary
            color={Colors.gray}
            title={translate('podReporting.summary.businessDays')}
            body={
              summary
                ? `${summary.businessDaysElapsed} / ${summary.businessDaysRemaining}`
                : EMPTY
            }
            footer={
              <i>{translate('podReporting.summary.businessDaysFooter')}</i>
            }
            size="s"
            tooltip={translate('podReporting.tooltips.businessDays')}
          />
          <Summary
            color={Colors.lightGray}
            title={translate('podReporting.summary.patientsPerDay')}
            body={summary ? `${summary.perDay} / ${summary.perDayGoal}` : EMPTY}
            footer={
              <i>{translate('podReporting.summary.patientsPerDayFooter')}</i>
            }
            size="s"
            tooltip={translate('podReporting.tooltips.patientsPerDay')}
          />
        </div>
        <div className={classNames('grid-wrapper', styles.borderContainer)}>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <StarIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleMemberStatus')}
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryMembers}
              header={translate('podReporting.table.status')}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <ClockIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleMinutes')}
              </h2>
            </div>
            <PodReportingDataTable
              data={summaryMinutes}
              header={translate('podReporting.table.minutes')}
              onClick={(data) => {
                let minutesFrom = '';
                let minutesTo = '';
                if (data.name.includes('-')) {
                  minutesFrom = data.name.split('-')[0].trim();
                  minutesTo = data.name.split('-')[1].trim();
                } else {
                  minutesFrom = '90';
                }
                window.open(
                  `#/dashboard/ccm?clearFilters=true&totalMinutesFrom=${minutesFrom}&totalMinutesTo=${minutesTo}&${filtersString}`,
                  '_blank',
                  'noreferrer',
                );
              }}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <VideoIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleBianualVisit')}
                </span>
                <Tooltip
                  text={translate('podReporting.tooltips.biannualVisit')}
                >
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              data={summaryVirtualVisit}
              header={translate('podReporting.table.visitStatus')}
              onClick={(data) =>
                window.open(
                  `#/dashboard/ccm?clearFilters=true&isBiannualComplete=${
                    data.name === 'Completed'
                  }&${filtersString}`,
                  '_blank',
                  'noreferrer',
                )
              }
            />
          </div>
          <div className="grid-span-4">
            <div className="flex spaced wrap">
              <div className="flex gap">
                <Chip className={styles.chip} color="action">
                  <DoctorIcon />
                </Chip>
                <h2 className={styles.subtitle}>
                  <span className={styles.subtitle}>
                    {translate('podReporting.titleProviderTime')}
                  </span>
                </h2>
              </div>
              <ToggleButton
                color="info"
                items={POD_REPORTING_TAB_ITEMS}
                onChange={setTab}
                size="xs"
                value={tab}
              />
            </div>
            {loadingProvider && !loading ? (
              <div className="flex center" style={{ marginTop: '1rem' }}>
                <Spinner width={32} />
              </div>
            ) : (
              <PodReportingDataTable
                data={summaryProvider}
                header={translate('podReporting.table.providerTime')}
                onClick={(data) =>
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&from&totalMinutesFrom=${
                      tab === POD_REPORTING_TAB_COMPLEX_CCM ? '90' : ''
                    }&hasProfessionalTimeOrTouchedByNp=${
                      tab === POD_REPORTING_TAB_COMPLEX_CCM
                        ? data.name === 'Yes'
                        : ''
                    }&hasProfessionalTime=${
                      tab === POD_REPORTING_TAB_ALL ? data.name === 'Yes' : ''
                    }&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  )
                }
              />
            )}
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <GoalIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleRPMAdherence')}
                </span>
                <Tooltip text={translate('podReporting.tooltips.rpmAdherence')}>
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryRPMAdherence}
              header={translate('podReporting.table.opportunityLevel')}
              onClick={(data) => {
                const item = Enum.toSelectable(RPMAdherenceLevel.asArray).find(
                  ({ label }) => label === data.name,
                );
                if (item) {
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&programExtensionId=${ProgramExtension.RPM}&hasProgramExtensions=true&programExtensionStatus=${ProgramExtensionStatus.ACTIVE}&rpmAdherenceId=${item.value}&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  );
                }
              }}
            />
          </div>
          <div className="grid-span-4">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <StarIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span className={styles.subtitle}>
                  {translate('podReporting.titleRPMStatus')}
                </span>
                <Tooltip text={translate('podReporting.tooltips.rpmStatus')}>
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingDataTable
              boldLastRow
              data={summaryRPMStatus.map((item) => ({
                ...item,
                name:
                  programExtensionStatuses?.find(({ id }) => id === item.name)
                    ?.description || item.name,
              }))}
              header={translate('podReporting.table.status')}
              onClick={(data) => {
                const item = programExtensionStatuses.find(
                  ({ description }) => description === data.name,
                );
                if (item) {
                  window.open(
                    `#/dashboard/ccm?clearFilters=true&programExtensionId=${ProgramExtension.RPM}&programExtensionStatus=${item.id}&${filtersString}`,
                    '_blank',
                    'noreferrer',
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={classNames('grid-wrapper', styles.paddingContainer)}>
          <div className="grid-span-12">
            <div className="flex gap">
              <Chip className={styles.chip} color="action">
                <PeopleIcon />
              </Chip>
              <h2 className={styles.subtitle}>
                <span>{translate('podReporting.titleWorklist')}</span>
                <Tooltip
                  text={translate('podReporting.tooltips.worklistGroups')}
                >
                  <HelpIcon color="action" />
                </Tooltip>
              </h2>
            </div>
            <PodReportingWorklistTable
              data={summaryGroups}
              onClick={(data) =>
                window.open(
                  `#/dashboard/ccm?clearFilters=true&worklistGroupId=${data.worklistId}&${filtersStringWithoutGroups}`,
                  '_blank',
                  'noreferrer',
                )
              }
            />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default PodReportingDashboard;
