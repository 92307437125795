import { Enum } from '@vestahealthcare/common/enums';
import { Employee, EventDetail, Patient } from '@vestahealthcare/common/models';

export class EventActions extends Enum {
  translationKey = 'eventActions';

  static asArray: EventActions[] = [];

  static byKey: { [name: string]: EventActions } = {};

  static readonly ADD_ADDENDUM = new EventActions('ADD_ADDENDUM');

  static readonly ADD_NOTE = new EventActions('ADD_NOTE');

  static readonly ADD_ENCOUNTER = new EventActions('ADD_ENCOUNTER');

  static readonly ADD_TASK = new EventActions('ADD_TASK');

  static readonly ADD_ISSUE = new EventActions('ADD_ISSUE');

  static readonly MODIFY_URGENCY = new EventActions('MODIFY_URGENCY');

  static readonly UNLINK_ISSUE = new EventActions('UNLINK_ISSUE');

  static readonly LINK_EVENT = new EventActions('LINK_EVENT');

  static readonly MODIFY_FOLLOW_UP = new EventActions('MODIFY_FOLLOW_UP');

  static readonly CLOSE_EVENT = new EventActions('CLOSE_EVENT');

  static readonly INVALIDATE_EVENT = new EventActions('INVALIDATE_EVENT');

  static readonly CLOSE_DOCUMENTED = new EventActions('CLOSE_DOCUMENTED');

  static readonly SHOW_HISTORY = new EventActions('SHOW_HISTORY');

  static readonly SUBMIT_INCIDENT = new EventActions('SUBMIT_INCIDENT');

  static readonly VIEW_INCIDENT = new EventActions('VIEW_INCIDENT');

  static readonly SUBMIT_TOC = new EventActions('SUBMIT_TOC');

  static readonly VIEW_TOC = new EventActions('VIEW_TOC');

  static readonly SUBMIT_SKILLED = new EventActions('SUBMIT_SKILLED');

  static readonly VIEW_SKILLED = new EventActions('VIEW_TOC');

  static readonly CHECK_PENDING_ACTIONS = new EventActions(
    'CHECK_PENDING_ACTIONS',
  );

  static asActionsArray(
    event?: EventDetail,
    actingUser?: Employee,
    member?: Patient,
    isClinical?: boolean,
    forceSubmitIncident?: boolean,
  ) {
    if (event?.isClosed) {
      return [
        EventActions.ADD_ADDENDUM,
        EventActions.SHOW_HISTORY,
        ...(isClinical ? [] : [EventActions.LINK_EVENT]),
        ...(event.hasIncident && actingUser?.isInIncidentsManagement
          ? [EventActions.VIEW_INCIDENT]
          : []),
        ...(forceSubmitIncident || (!event.hasIncident && event.allowIncident)
          ? [EventActions.SUBMIT_INCIDENT]
          : []),
      ].sort((a, b) => a.toString().localeCompare(b.toString()));
    }
    const userCanForceClose = event?.canForceCloseEvent(actingUser, member);
    const userCanInvalidate =
      actingUser?.isInAdmin || actingUser?.isInClinicalLeadership;

    const actions = [
      EventActions.ADD_ENCOUNTER,
      EventActions.ADD_ISSUE,
      EventActions.ADD_NOTE,
      EventActions.ADD_TASK,
      EventActions.SHOW_HISTORY,
    ];

    if (event?.isReadyToClose || userCanForceClose) {
      actions.push(EventActions.CLOSE_EVENT);
    }

    if (userCanInvalidate) {
      actions.push(EventActions.INVALIDATE_EVENT);
    }

    if (!isClinical) {
      actions.push(EventActions.LINK_EVENT);
    }

    actions.push(EventActions.MODIFY_FOLLOW_UP);
    actions.push(EventActions.MODIFY_URGENCY);

    if (event?.numberOfIssues && event.numberOfIssues > 1) {
      actions.push(EventActions.UNLINK_ISSUE);
    }

    if (forceSubmitIncident || event?.allowIncident) {
      if (event?.hasIncident) {
        if (actingUser?.isInIncidentsManagement) {
          actions.push(EventActions.VIEW_INCIDENT);
        }
      } else {
        actions.push(EventActions.SUBMIT_INCIDENT);
      }
    }

    if (event?.allowToc) {
      if (event?.hasToc) {
        actions.push(EventActions.VIEW_TOC);
      } else {
        actions.push(EventActions.SUBMIT_TOC);
      }
    } else if (event?.allowSkilled) {
      if (event?.hasSkilled) {
        actions.push(EventActions.VIEW_SKILLED);
      } else {
        actions.push(EventActions.SUBMIT_SKILLED);
      }
    }

    return actions.sort((a, b) => a.toString().localeCompare(b.toString()));
  }

  constructor(public readonly value: string) {
    super();
    EventActions.asArray.push(this);
    EventActions.byKey[value] = this;
  }
}

export default EventActions;
