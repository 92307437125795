import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, EventDetail } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select, TextInput } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { CloseEventParams } from 'dash/src/services/EventServices';

interface Props {
  event?: EventDetail;
  open: boolean;
  onClose: () => void;
  onSubmit: (event: EventDetail, params: CloseEventParams) => Promise<boolean>;
}

export const MemberEventCloseModal = ({
  event,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [resolutionOptions, setResolutionOptions] = useState<BaseEnum[]>([]);
  const [divertedFromOptions, setDivertedFromOptions] = useState<BaseEnum[]>(
    [],
  );

  const [resolution, setResolution] = useState<BaseEnum>();
  const [resolutionOther, setResolutionOther] = useState<string>();

  const [wouldVisitER, setWouldVisitER] = useState<boolean>();
  const [divertedFrom, setDivertedFrom] = useState<BaseEnum>();
  const [interventionPreventedER, setInterventionPreventedER] = useState<
    boolean
  >();
  const [npEscalationRequired, setNPEscalationRequired] = useState<boolean>();

  const reset = () => {
    setLoading(false);
    setSubmitted(false);
    if (event) {
      const {
        isClosed,
        resolution,
        resolutionOther,
        wouldVisitER,
        divertedFrom,
        interventionPreventedER,
        npEscalationRequired,
      } = event;
      setResolution(isClosed ? resolution : undefined);
      setResolutionOther(isClosed ? resolutionOther : undefined);
      setWouldVisitER(isClosed ? wouldVisitER : undefined);
      setDivertedFrom(isClosed ? divertedFrom : undefined);
      setInterventionPreventedER(
        isClosed ? interventionPreventedER : undefined,
      );
      setNPEscalationRequired(isClosed ? npEscalationRequired : undefined);
    }
  };

  const getInitialData = async () => {
    setLoadingData(true);
    const [r, d] = await Promise.all([
      CacheServices.getEventResolutionOptions(),
      CacheServices.getEventDivertedFromOptions(),
    ]);
    setResolutionOptions(r);
    setDivertedFromOptions(d);
    setLoadingData(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const showDeprecatedFields = event?.isClosed && !event?.resolution;

  const validate = () =>
    showDeprecatedFields
      ? wouldVisitER || divertedFrom !== undefined
      : !!resolution && (BaseEnum.OTHER !== resolution.id || resolutionOther);

  const getModalTitle = () => {
    let key = 'closeEventTitle';
    if (event?.isClosed && showDeprecatedFields) key = 'edDiversionTitle';
    if (event?.isClosed && !showDeprecatedFields) key = 'resolution';

    return translate(`memberEvents.modals.${key}`);
  };

  const doSubmit = async () => {
    setSubmitted(true);

    if (event && validate()) {
      setLoading(true);
      try {
        const result = await onSubmit(
          event,
          showDeprecatedFields
            ? {
                wouldVisitER,
                divertedFrom: wouldVisitER ? undefined : divertedFrom,
                interventionPreventedER,
                npEscalationRequired,
              }
            : {
                resolution,
                resolutionOther:
                  BaseEnum.OTHER === resolution?.id
                    ? resolutionOther
                    : undefined,
              },
        );
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{getModalTitle()}</h2>}
      size={400}
    >
      <CollapsableSidebar.Body>
        {showDeprecatedFields ? (
          <>
            <Select.Choice
              error={submitted && wouldVisitER === undefined}
              label={translate('memberEvents.modals.wouldVisitER')}
              onChange={(value?: boolean) => setWouldVisitER(!!value)}
              required
              value={wouldVisitER}
            />
            {wouldVisitER === false && (
              <Select
                error={submitted && !divertedFrom}
                label={translate('memberEvents.modals.divertedFrom')}
                loading={loadingData}
                onChange={setDivertedFrom}
                getItemLabel={({ description }: BaseEnum) => description}
                items={divertedFromOptions}
                required={wouldVisitER === false}
                value={divertedFrom}
              />
            )}
            <Select.Choice
              error={submitted && interventionPreventedER === undefined}
              label={translate('memberEvents.modals.interventionPreventedER')}
              onChange={(value?: boolean) =>
                setInterventionPreventedER(!!value)
              }
              required
              value={interventionPreventedER}
            />
            <Select.Choice
              error={submitted && npEscalationRequired === undefined}
              label={translate('memberEvents.modals.npEscalationRequired')}
              onChange={(value?: boolean) => setNPEscalationRequired(!!value)}
              required
              value={npEscalationRequired}
            />
          </>
        ) : (
          <>
            <Select
              error={submitted && !resolution}
              items={resolutionOptions}
              label={translate('memberEvents.modals.resolution')}
              loading={loadingData}
              onChange={setResolution}
              required
              value={resolution}
            />
            {BaseEnum.OTHER === resolution?.id && (
              <TextInput
                error={submitted && !resolutionOther}
                loading={loadingData}
                onChange={setResolutionOther}
                required
                value={resolutionOther}
              />
            )}
          </>
        )}
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="close-event-close" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="close-event-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {event?.isClosed
            ? translate('global.save')
            : translate('memberEvents.modals.closeEvent')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default MemberEventCloseModal;
