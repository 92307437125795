import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Modal } from 'styleguide-v2';

import { startCase } from 'lodash';

type Message = {
  message: any;
  detail?: any;
  field?: string;
};

interface Props {
  open: boolean;
  title: any;
  messages: Message[];
  dismissText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onDismiss: () => void;
  type?: 'default' | 'warning' | 'error';
  hideContactUs?: boolean;
}

const useStyles = makeStyles({
  container: {
    padding: '1rem 2rem 0',
  },
});

const MessageComponent = (props: Props) => {
  const styles = useStyles();
  const {
    open = false,
    title = translate('global.genericError'),
    messages = [
      {
        message: translate('global.weAreSorry'),
      },
    ],
    dismissText = translate('global.ok'),
    onDismiss,
    onCancel,
    onConfirm,
    type,
    hideContactUs = false,
  } = props;

  const isWarning = !!onConfirm;

  return (
    <Modal
      color={type}
      open={open}
      onClose={
        isWarning
          ? () => {
              onDismiss();
              if (isWarning && onCancel) {
                onCancel();
              }
            }
          : undefined
      }
      title={
        <span>
          {(type === 'error' || type === 'warning') && (
            <i className="fa fa-warning" />
          )}{' '}
          {title}
        </span>
      }
      submitText={dismissText}
      onSubmit={async () => onDismiss()}
    >
      <div className={styles.container}>
        {messages.map(({ message, field, detail }, i) => (
          <p className="large" key={i}>
            {field && `${startCase(field)}: `}
            {message}
            {detail && (
              <span>
                <br />
                <em>{detail}</em>
              </span>
            )}
          </p>
        ))}
        {(type === 'error' || type === 'warning') && !hideContactUs && (
          <p className="large">{translate('global.contactUs')}</p>
        )}
      </div>
    </Modal>
  );
};

export default MessageComponent;
