import classnames from 'classnames';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import IncidentIcon from '@mui/icons-material/CrisisAlert';
import ReportIcon from '@mui/icons-material/Report';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { Enum } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  EventDetail,
  EventModelExtended,
  PaginationType,
  Patient,
} from '@vestahealthcare/common/models';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Actions,
  Chip,
  Colors,
  Fonts,
  MemberExtraInfo,
  MemberInfo,
  Select,
  SelectAssignee,
  StatusIndicator,
  Table,
  TableItem,
  Tooltip,
  UNASSIGNED,
  UpdatedBy,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';

import EventActions from '../../MemberProfile/Events/types/EventActions';

const getUrgencyColor = (urgencyLevel?: number) => {
  if (urgencyLevel === 1) {
    return Colors.textRed;
  }
  if (urgencyLevel === 2) {
    return Colors.gold;
  }
  if (urgencyLevel === 3) {
    return Colors.green;
  }
  return Colors.gray;
};

type TableProps = {
  employees: Employee[];
  eventStatuses: EventStatus[];
  groups: EmployeeGroup[];
};

export type EventTableModel = EventModelExtended &
  TableItem & {
    actionLoading?: boolean;
  };

type Props = {
  forceShowIncidentAction?: boolean;
  events: EventModelExtended[];
  loading?: boolean;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeAssignee: (id: number, employee: Employee | EmployeeGroup) => void;
  onChangeStatus: (id: number, status: EventStatus) => void;
  onClickRow?: (evt: EventTableModel, idx: number) => void;
  onEventAction: (
    action: Selectable,
    event: EventModelExtended,
    index: number,
  ) => void;
  pagination?: PaginationType;
};

const OTHER = 'OTHER';

const isOverdue = (date: Moment, hasTime?: boolean) => {
  let data = date;
  if (hasTime === false) {
    data = data.endOf('day');
  }

  return data.isBefore();
};

const DASHBOARD_EVENTS_COLUMNS = (
  styles: ClassNameMap<
    | 'urgentContainer'
    | 'greenSelect'
    | 'small'
    | 'bold'
    | 'redText'
    | 'lineThrough'
    | 'incidentChip'
  >,
  { employees, eventStatuses, groups }: TableProps,
  {
    forceShowIncidentAction,
    onChangeAssignee,
    onChangeStatus,
    onEventAction,
  }: Props,
) => [
  {
    headerName: translate('dashboardEvents.table.event'),
    component: (event: EventTableModel) => {
      const { datetime, hasTime, open, type, urgencyLevel, urgent } = event;
      return (
        <div className={styles.urgentContainer}>
          {urgent && (
            <Tooltip
              text={
                urgencyLevel
                  ? translate('memberEvents.table.urgencyLevel', {
                      count: urgencyLevel,
                    })
                  : translate('memberEvents.table.urgent')
              }
            >
              <ReportIcon
                fontSize="large"
                style={{ color: getUrgencyColor(urgencyLevel) }}
              />
            </Tooltip>
          )}
          <div>
            <p className={classnames(styles.bold, styles.small, 'flex gap')}>
              <span>{type.name}</span>
              {(forceShowIncidentAction ||
                (open &&
                  event.detailData instanceof EventDetail &&
                  event.detailData.allowIncident &&
                  !event.detailData.hasIncident)) && (
                <Chip
                  className={styles.incidentChip}
                  color="error"
                  type="contained-2"
                  title={translate('events.incidentTooltip')}
                >
                  <IncidentIcon sx={{ fontSize: 14, display: 'flex' }} />
                </Chip>
              )}
            </p>
            <p className={styles.small}>
              {datetime.format(
                hasTime ? DATE_FORMAT_SHORT_WITH_TIME : DATE_FORMAT_SHORT,
              )}
            </p>
          </div>
        </div>
      );
    },
    width: 150,
  },
  {
    headerName: translate('dashboardEvents.table.member'),
    component: ({ id, member }: EventModelExtended) =>
      member ? (
        <MemberInfo
          key={`event-row-${id}-member-info`}
          link={`#/patients/${member?.id}/events`}
          member={member}
          showBirthDate
          showLanguage
          showState
        />
      ) : (
        ''
      ),
    width: 200,
  },
  {
    headerName: ' ',
    component: ({ id, member }: EventModelExtended) => (
      <MemberExtraInfo
        activeReferrals={member?.activeReferrals}
        hideTooltips
        key={`event-row-${id}-member-extra-info`}
        memberId={member?.id}
        programExtensions={member?.programExtensions}
      />
    ),
    width: 175,
  },
  {
    headerName: translate('dashboardEvents.table.status'),
    component: ({
      id,
      status,
      isClosed,
      invalidatedReason,
      invalidNote,
      open,
    }: EventTableModel) =>
      !open || isClosed ? (
        <StatusIndicator
          color={EventStatus.getColor(status)}
          status={status.description}
          subtitle={
            invalidatedReason
              ? `${
                  invalidatedReason.id === OTHER
                    ? invalidNote || ''
                    : invalidatedReason.description
                }`
              : ''
          }
        />
      ) : (
        <Select
          className={
            status.id === EventStatus.NOT_STARTED ? styles.greenSelect : ''
          }
          disableClearable
          getItemLabel={({ description }: EventStatus) => description}
          items={eventStatuses}
          key={`event-${id}-status-${Date.now()}`}
          onChange={(val: EventStatus) => onChangeStatus(id, val)}
          size="xs"
          value={status}
        />
      ),
    width: 210,
  },
  {
    headerName: translate('dashboardEvents.table.followUpInfo'),
    component: ({
      followUpDatetime,
      followUpDatetimeHasTime,
      isClosed,
    }: EventModelExtended) =>
      followUpDatetime ? (
        <div
          className={classnames(
            !isClosed &&
              isOverdue(followUpDatetime, followUpDatetimeHasTime) &&
              styles.redText,
            styles.small,
          )}
        >
          <span className={isClosed ? styles.lineThrough : ''}>
            {followUpDatetime.format(
              followUpDatetimeHasTime
                ? DATE_FORMAT_SHORT_WITH_TIME
                : DATE_FORMAT_SHORT,
            )}
          </span>
        </div>
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('dashboardEvents.table.created'),
    component: ({ createdAt, createdBy }: EventModelExtended) => (
      <UpdatedBy
        className={styles.small}
        date={createdAt}
        user={createdBy?.fullName}
      />
    ),
    width: 150,
  },
  {
    headerName: translate('dashboardEvents.table.assignee'),
    component: ({
      id,
      assignee,
      assigneeGroup,
      isClosed,
      open,
    }: EventTableModel) =>
      !open || isClosed ? (
        assignee?.fullName || assigneeGroup?.name
      ) : (
        <SelectAssignee
          assignToMe
          allowUnassigned
          assignee={assignee || assigneeGroup || UNASSIGNED}
          currentUser={Session.actingUser}
          key={`event-${id}-assignee`}
          items={[...groups, ...employees]}
          onChange={(val?: Employee | EmployeeGroup) => {
            if (val) {
              onChangeAssignee(id, val);
            }
          }}
          size="xs"
        />
      ),
    width: 225,
  },
  {
    component: (event: EventTableModel, index: number) => {
      const { actionLoading } = event;
      const { actingUser } = Session;
      return (
        <Actions
          actions={Enum.toSelectable(
            EventActions.asActionsArray(
              (event?.detailData || event) as EventDetail,
              actingUser,
              event.member as Patient,
              true,
              forceShowIncidentAction,
            ),
          )}
          data-cy={`event-item-${index}-settings`}
          loading={actionLoading}
          onActionSelected={(action) => onEventAction(action, event, index)}
        />
      );
    },
    width: 40,
  },
];

const useStyles = makeStyles({
  urgentContainer: {
    display: 'flex',
    '& > svg': {
      left: '-4.125rem',
      margin: 'auto -2.5rem auto 0',
      position: 'relative',
    },
  },
  greenSelect: {
    '& .MuiOutlinedInput-root': {
      background: Colors.green,
    },
    '& input': {
      color: Colors.white,
    },
    '& svg': {
      color: Colors.white,
    },
  },
  small: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  bold: {
    fontWeight: 500,
  },
  incidentChip: {
    '&&': {
      padding: '0.1rem',
    },
  },
  urgentRowUnknown: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.gray}`,
    },
  },
  urgentRow1: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.textOrange}`,
    },
  },
  urgentRow2: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.gold}`,
    },
  },
  urgentRow3: {
    '&:first-child': {
      borderLeft: `3px solid ${Colors.green}`,
    },
  },
  redText: {
    color: Colors.textRed,
    fontWeight: 500,
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
});

export const EventsTable = (props: Props) => {
  const { events, loading, onChangePage, onChangePageSize, pagination } = props;
  const styles = useStyles();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [eventStatuses, setEventStatuses] = useState<EventStatus[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);

  const getRowClass = ({ urgent, urgencyLevel }: EventModelExtended) =>
    classnames(
      urgent && !urgencyLevel && styles.urgentRowUnknown,
      urgent && urgencyLevel === 1 && styles.urgentRow1,
      urgent && urgencyLevel === 2 && styles.urgentRow2,
      urgent && urgencyLevel === 3 && styles.urgentRow3,
    );

  const getInitialData = async () => {
    const employees = await CacheServices.getEmployees();
    setEmployees([...employees]);

    const eventStatuses = await CacheServices.getEventStatuses();
    setEventStatuses(
      eventStatuses.filter(({ isClosedStatus }) => !isClosedStatus),
    );

    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(groups);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Table
      bottomLoading={loading}
      config={{
        data: events,
        defaultPageSize: 50,
        columns: DASHBOARD_EVENTS_COLUMNS(
          styles,
          { employees, eventStatuses, groups },
          props,
        ),
        detail: true,
        getRowClass,
        getRowBrand: ({ member }: EventTableModel) => member?.brand,
        pagination,
        paginationOptions: [10, 25, 50],
      }}
      fontSize="small"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      onClickRow={props.onClickRow}
    />
  );
};

export default EventsTable;
