import { Moment } from 'moment';

import {
  BaseEnum,
  Employee,
  EmployeeGroup,
  EventMember,
  MemberDashboardEncounter,
  TOCAcuityLevel,
  TOCStatus,
} from '.';
import { make, makeMoment, makeUnix } from './Model';

export class TOCItem {
  id = 0;

  acuityLevel: BaseEnum;

  acuityRationale = '';

  admitDate?: Moment;

  assignee?: Employee;

  assigneeGroup?: EmployeeGroup;

  businessDaysSinceDischargeDate?: number;

  chiefComplaint = '';

  daysInpatient?: number;

  dischargeDate?: Moment;

  facility = '';

  handoffDate?: Moment;

  handoffDisposition?: BaseEnum;

  handoffRecs = '';

  lastTcmEncounter?: MemberDashboardEncounter;

  medicalReqStatus?: BaseEnum;

  member: EventMember;

  npVisit?: MemberDashboardEncounter;

  rnCarePlan?: MemberDashboardEncounter;

  sourceEventId = 0;

  status: TOCStatus;

  statusUpdatedAt?: Moment;

  trueTcmStatus?: BaseEnum;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.acuityLevel = make(obj.acuityLevel, TOCAcuityLevel);
    this.admitDate = makeMoment(obj.admitDate);
    this.assignee = make(obj.assignee, Employee);
    this.assigneeGroup = make(obj.assigneeGroup, EmployeeGroup);
    this.dischargeDate = makeMoment(obj.dischargeDate);
    this.handoffDate = makeUnix(obj.handoffDate);
    this.handoffDisposition = make(obj.handoffDisposition, BaseEnum);
    this.lastTcmEncounter = make(
      obj.lastTcmEncounter,
      MemberDashboardEncounter,
    );
    this.member = make(obj.member, EventMember);
    this.medicalReqStatus = make(obj.medicalReqStatus, BaseEnum);
    this.npVisit = make(obj.npVisit, MemberDashboardEncounter);
    this.rnCarePlan = make(obj.rnCarePlan, MemberDashboardEncounter);
    this.status = make(obj.status, TOCStatus);
    this.statusUpdatedAt = makeUnix(obj.statusUpdatedAt);
    this.trueTcmStatus = make(obj.trueTcmStatus, BaseEnum);
  }
}

export default TOCItem;
