import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Notification } from '@vestahealthcare/common/models';

interface Props {
  data: Notification;
  onEdit: (notification: Notification) => void;
}

const useStyles = makeStyles({
  titleHeader: {
    display: 'flex',
  },
  title: {
    width: '100%',
  },
  date: {
    marginLeft: 'auto',
    textAlign: 'right',
    width: 90,
  },
  unreadIcon: {
    marginLeft: 'auto',
    backgroundColor: '#fa600f',
    width: 8,
    height: 8,
    borderRadius: '50%',
    content: ' ',
  },
  bodyWrapper: {
    display: 'flex',
  },
  body: {
    width: '98%',
    color: '#aaa',
  },
  divider: {
    borderBottom: '1px solid #ccc',
  },
  marginWrapper: {
    padding: '10px 20px',
  },
  backgroundWrapper: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
  },
});
const NotificationItem = (props: Props & RouteComponentProps) => {
  const { data, onEdit } = props;
  const styles = useStyles();

  const markAsReadAndRedirect = () => {
    if (!data.seen) onEdit(data);
    props.history.push(`/${data.relativeUrl}`);
  };
  const { member } = data;
  return (
    <div
      className={styles.backgroundWrapper}
      onClick={() => markAsReadAndRedirect()}
      id={`notification-menu-${data.id}`}
      data-cy={`notification-menu-${data.id}`}
    >
      <div className={styles.marginWrapper}>
        <div className={styles.titleHeader}>
          <p className={classNames('semi-bold no-margin', styles.title)}>
            {data.title}
          </p>
          <p className={classNames('small no-margin', styles.date)}>
            {moment.unix(data.createdAt).fromNow()}
          </p>
        </div>
        <div className={styles.bodyWrapper}>
          <div className={styles.body}>
            <span className="small">{data.body}</span>
            {member && (
              <p className={classNames('small no-margin', styles.body)}>
                {translate('notification.member')} {member?.nameWithId}
              </p>
            )}
          </div>
          {!data.seen && <span className={styles.unreadIcon} />}
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default withRouter(NotificationItem);
