import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { EmployeeRole, States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
} from '@vestahealthcare/common/models';
import { DashPermission } from '@vestahealthcare/common/models/DashPermission';

import { FilterItem, IconButton, Panel } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  EmployeeParams,
  createEmployee,
  fetchPaginatedEmployees,
  updateEmployee,
} from 'dash/src/services/EmployeeServices';
import Session from 'dash/src/services/SessionServices';
import { getServerFilters } from 'dash/src/utils/filterUtils';

import { EmployeeEditModal } from './EmployeeEdit';
import { EmployeeFiltersBar } from './EmployeeFiltersBar';
import { EmployeeFiltersModal } from './EmployeeFiltersModal';
import { EmployeesTable } from './EmployeesTable';

let employeeFiltersString = '';

type KeyGetEmployeeParams = keyof EmployeeParams;

export const EmployeesDashboard = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [leadEmployees, setLeadEmployees] = useState<Employee[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [permissions, setPermissions] = useState<DashPermission[]>([]);
  const [nlcStates, setNLCStates] = useState<States[]>([]);

  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetEmployeeParams]?: FilterItem;
    }
  >({});

  const [showEditEmployee, setShowEditEmployee] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Employee>();

  const getLeadEmployees = async () => {
    const { items } = await fetchPaginatedEmployees({
      enabled: true,
      isLead: true,
      limit: 100,
    });
    setLeadEmployees(items);
  };

  const getInitialData = async () => {
    setLoadingData(true);
    const [groups, permissions, nlcStates] = await Promise.all([
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getDashPermissions(),
      CacheServices.getEmployeeNLCStates(),
    ]);
    setGroups(groups);
    setPermissions(permissions);
    setNLCStates(nlcStates);
    setLoadingData(false);
  };

  const getEmployees = async () => {
    if (!newFilters) return;

    setLoading(true);
    const currentFilters = getServerFilters(newFilters);

    try {
      employeeFiltersString = JSON.stringify(currentFilters);

      const { items, pagination } = await fetchPaginatedEmployees({
        ...currentFilters,
        limit: pageSize,
        offset: page * pageSize,
        sort: 'fullName asc',
      });

      if (employeeFiltersString === JSON.stringify(currentFilters)) {
        if (page === 0) {
          setEmployees(items);
        } else {
          setEmployees([...employees, ...items]);
        }
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    if (employeeFiltersString === JSON.stringify(currentFilters)) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
    getLeadEmployees();
  }, []);

  useEffect(() => {
    getEmployees();
  }, [newFilters, page, pageSize]);

  const addEmployee = () => {
    setModalData(new Employee({}));
    setShowEditEmployee(true);
  };

  const isAdmin = Session.actingUser.role === EmployeeRole.ADMIN;

  return (
    <Panel>
      <Panel.Heading title={translate('employees.title')} filtersV2>
        {isAdmin && (
          <Panel.Actions>
            <IconButton
              onClick={addEmployee}
              tooltip={translate('employees.add')}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Panel.Actions>
        )}
        <Panel.FilterBar
          onClearFilters={() => {
            setPage(0);
            setNewFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setPage(0);
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpen(!isOpen)}
          chips={newFilters}
          inputs={
            <EmployeeFiltersBar
              data={{
                employeeGroups: EmployeeGroup.toSelectable(groups),
                permissions: DashPermission.toSelectable(permissions),
              }}
              loading={loadingData}
              onChange={setNewFilters}
              filters={newFilters}
            />
          }
        />
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <EmployeeFiltersModal
          data={{
            employeeGroups: EmployeeGroup.toSelectable(groups),
            permissions: DashPermission.toSelectable(permissions),
          }}
          filters={newFilters}
          loadingFilters={loadingData}
          onChange={setNewFilters}
          onClose={() => setOpen(false)}
          open={isOpen}
        />
        <EmployeesTable
          employees={employees}
          nlcStates={nlcStates}
          onChangePage={setPage}
          onChangeRowsPerPage={(size) => {
            setPage(0);
            setPageSize(size);
          }}
          onEdit={(employee) => {
            setModalData(employee);
            setShowEditEmployee(true);
          }}
          pagination={pagination}
        />
        <EmployeeEditModal
          employee={modalData}
          employees={leadEmployees}
          open={showEditEmployee}
          onClose={() => setShowEditEmployee(false)}
          onCreate={async (params) => {
            await createEmployee(params);
            CacheServices.invalidateEmployees();
            if (page !== 0) {
              setPage(0);
            } else {
              await getEmployees();
              await getLeadEmployees();
            }
          }}
          onEdit={async (id, params) => {
            await updateEmployee(id, params);
            CacheServices.invalidateEmployees();
            if (Session.actingUser.id === id) {
              await Session.fetchSessionInformation();
            }
            if (page !== 0) {
              setPage(0);
            } else {
              await getEmployees();
              await getLeadEmployees();
            }
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default EmployeesDashboard;
