export default {
  appointmentStatus: {
    CANC: 'Cancelled',
    COMP: 'Completed',
    KEPT: 'Kept',
    PEN: 'Pending',
    VOID: 'Void',
    CANCSMS: 'Cancelled via SMS',
    CONFNOTST: 'Confirmed - No Connection Test',
    CONFTESTED: 'Confirmed - Connection Tested',
    'N/S': 'No-Show',
    'R/S': 'Rescheduled',
    'UNC-LVM': 'Confirmation Attempted - LVM',
    'UNC-SMS': 'Confirmation Attempted - SMS',
    'UNCLVM+SMS': 'Confirmation Attempted - LVM/SMS',
  },
  appUser: {
    AGENCY: 'Agency aide',
    AIDE_UNKNOWN: 'Family/friend (unpaid)',
    PCA: 'Non-agency aide',
    SELF: 'Self',
  },
  assessmentSource: {
    UAS: 'UAS',
    DS: 'Discharge summary',
    PAYER: 'Payer',
    PATIENT: 'Member',
    POC: 'Point of contact',
    RNA: 'RN Assessment',
    SALESFORCE: 'Salesforce',
    CLAIMS: 'Claims',
    CLIENT_EMR: 'Client EMR',
    HIE_VENDOR: 'HIE Vendor',
    CARE_MANAGER: 'Care manager',
    AIDE: 'Aide',
    MDS: 'MDS',
    UNKNOWN: 'Unknown',
    HEALTH_ALERT: 'Health Alert',
    abbr: {
      UAS: 'UAS',
      DS: 'Discharge summary',
      PAYER: 'Payer',
      PATIENT: 'Member',
      POC: 'Point of contact',
      CARE_MANAGER: 'Care manager',
      CLIENT_EMR: 'Client EMR',
      HIE_VENDOR: 'HIE Vendor',
      AIDE: 'Aide',
      RNA: 'RNA',
      SALESFORCE: 'SF',
      CLAIMS: 'Claims',
      MDS: 'MDS',
      HEALTH_ALERT: 'HA',
      UNKNOWN: 'Unknown',
    },
  },
  asyncTaskLeadStatus: {
    DEFAULT: 'Default',
    ON_HOLD: 'On Hold',
    RELEASED: 'Released to SF',
  },
  audienceTarget: {
    CAREGIVERS: 'Caregivers',
    EVERYONE: 'Everyone',
    MEMBERS: 'Members',
  },
  biometricsLocation: {
    HOME: 'Home',
    DOCTOR_OFFICE: 'Dr. Office',
  },
  biometricsTimeOption: {
    BEFORE_MEAL: 'Before Meal',
    AFTER_MEAL: 'After Meal',
  },
  biometricsType: {
    BP_DIASTOLIC: 'Diastolic blood pressure',
    BP_SYSTOLIC: 'Systolic blood pressure',
    BLOOD_SUGAR: 'Blood Glucose',
    TEMPERATURE: 'Temperature',
    WEIGHT: 'Weight',
    OXYGEN: 'Oxygen',
    PULSE: 'Pulse',
  },
  bodyPart: {
    abdomen: 'Abdomen',
    back: 'Back',
    buttocks: 'Buttocks',
    centralChest: 'Central Chest',
    chest: 'Chest',
    face: 'Face',
    groin: 'Groin',
    headBack: 'Head Back',
    headFront: 'Head Front',
    hips: 'Hips',
    lAnkleBack: 'L Ankle Back',
    lAnkleFront: 'L Ankle Front',
    lBreast: 'L Breast',
    lButtock: 'L Buttock',
    lElbow: 'L Elbow',
    lFingersBack: 'L Fingers Back',
    lFingersFront: 'L Fingers Front',
    lFoot: 'L Foot',
    lForearmBack: 'L Forearm Back',
    lForearmFront: 'L Forearm Front',
    lHandBack: 'L Hand Back',
    lHandFront: 'L Hand Front',
    lHeel: 'L Heel',
    lKnee: 'L Knee',
    lLowerLegBack: 'L Lower Leg Back',
    lLowerLegFront: 'L Lower Leg Front',
    lowerBack: 'Lower Back',
    lShoulderBack: 'L Shoulder Back',
    lShoulderFront: 'L Shoulder Front',
    lToes: 'L Toes',
    lUpperArmBack: 'L Upper Arm Back',
    lUpperArmFront: 'L Upper Arm Front',
    lUpperLegBack: 'L Upper Leg Back',
    lUpperLegFront: 'L Upper Leg Front',
    lWristBack: 'L Wrist Back',
    lWristFront: 'L Wrist Front',
    neckBack: 'Neck Back',
    neckFront: 'Neck Front',
    rAnkleBack: 'R Ankle Back',
    rAnkleFront: 'R Ankle Front',
    rBreast: 'R Breast',
    rButtock: 'R Buttock',
    rElbow: 'R Elbow',
    rFingersBack: 'R Fingers Back',
    rFingersFront: 'R Fingers Front',
    rFoot: 'R Foot',
    rForearmBack: 'R Forearm Back',
    rForearmFront: 'R Forearm Front',
    rHandBack: 'R Hand Back',
    rHandFront: 'R Hand Front',
    rHeel: 'R Heel',
    rKnee: 'R Knee',
    rLowerLegBack: 'R Lower Leg Back',
    rLowerLegFront: 'R Lower Leg Front',
    rShoulderBack: 'R Shoulder Back',
    rShoulderFront: 'R Shoulder Front',
    rToes: 'R Toes',
    rUpperArmBack: 'R Upper Arm Back',
    rUpperArmFront: 'R Upper Arm Front',
    rUpperLegBack: 'R Upper Leg Back',
    rUpperLegFront: 'R Upper Leg Front',
    rWristBack: 'R Wrist Back',
    rWristFront: 'R Wrist Front',
    sacrum: 'Sacrum',
    upperBack: 'Upper Back',
    HEAD_FRONT: 'Head Front',
    HEAD_BACK: 'Head Back',
    FACE: 'Face',
    NECK_FRONT: 'Neck Front',
    NECK_BACK: 'Neck Back',
    CHEST: 'Chest',
    CENTRAL_CHEST: 'Central Chest',
    L_SHOULDER_FRONT: 'L Shoulder Front',
    R_SHOULDER_FRONT: 'R Shoulder Front',
    L_BREAST: 'L Breast',
    R_BREAST: 'R Breast',
    L_UPPER_ARM_FRONT: 'L Upper Arm Front',
    L_UPPER_ARM_BACK: 'L Upper Arm Back',
    L_FOREARM_FRONT: 'L Forearm Front',
    L_FOREARM_BACK: 'L Forearm Back',
    R_UPPER_ARM_FRONT: 'R Upper Arm Front',
    R_UPPER_ARM_BACK: 'R Upper Arm Back',
    R_FOREARM_FRONT: 'R Forearm Front',
    R_FOREARM_BACK: 'R Forearm Back',
    L_HAND_FRONT: 'L Hand Front',
    L_HAND_BACK: 'L Hand Back',
    L_FINGERS_FRONT: 'L Fingers Front',
    L_FINGERS_BACK: 'L Fingers Back',
    L_WRIST_FRONT: 'L Wrist Front',
    L_WRIST_BACK: 'L Wrist Back',
    R_HAND_FRONT: 'R Hand Front',
    R_HAND_BACK: 'R Hand Back',
    R_FINGERS_FRONT: 'R Fingers Front',
    R_FINGERS_BACK: 'R Fingers Back',
    R_WRIST_FRONT: 'R Wrist Front',
    R_WRIST_BACK: 'R Wrist Back',
    ABDOMEN: 'Abdomen',
    HIPS: 'Hips',
    GROIN: 'Groin',
    L_UPPER_LEG_FRONT: 'L Upper Leg Front',
    L_UPPER_LEG_BACK: 'L Upper Leg Back',
    L_KNEE: 'L Knee',
    L_LOWER_LEG_FRONT: 'L Lower Leg Front',
    L_LOWER_LEG_BACK: 'L Lower Leg Back',
    R_UPPER_LEG_FRONT: 'R Upper Leg Front',
    R_UPPER_LEG_BACK: 'R Upper Leg Back',
    R_KNEE: 'R Knee',
    R_LOWER_LEG_FRONT: 'R Lower Leg Front',
    R_LOWER_LEG_BACK: 'R Lower Leg Back',
    L_FOOT: 'L Foot',
    L_ANKLE_BACK: 'L Ankle Back',
    L_ANKLE_FRONT: 'L Ankle Front',
    L_TOES: 'L Toes',
    R_FOOT: 'R Foot',
    R_ANKLE_BACK: 'R Ankle Back',
    R_ANKLE_FRONT: 'R Ankle Front',
    R_TOES: 'R Toes',
    BACK: 'Back',
    UPPER_BACK: 'Upper Back',
    LOWER_BACK: 'Lower Back',
    L_SHOULDER_BACK: 'L Shoulder Back',
    R_SHOULDER_BACK: 'R Shoulder Back',
    L_ELBOW: 'L Elbow',
    R_ELBOW: 'R Elbow',
    BUTTOCKS: 'Buttocks',
    L_BUTTOCK: 'L Buttock',
    R_BUTTOCK: 'R Buttock',
    SACRUM: 'Sacrum',
    L_HEEL: 'L Heel',
    R_HEEL: 'R Heel',
  },
  bodyPartSide: {
    FRONT: 'Front',
    BACK: 'Back',
  },
  brand: {
    CARE_AT_HOME: 'Care at Home',
    VESTA: 'Vesta',
  },
  campaignTiming: {
    IMMEDIATE: 'Next Available',
    DELAYED: 'Delayed',
  },
  campaignTimingDelay: {
    UP_TO_3_HOURS: 'From 1 to 3 hours',
    UP_TO_6_HOURS: 'From 4 to 6 hours',
    UP_TO_12_HOURS: 'From 7 to 12 hours',
    UP_TO_24_HOURS: 'From 13 to 24 hours',
    UP_TO_2_DAYS: 'From 1 to 2 days',
    UP_TO_5_DAYS: 'From 3 to 5 days',
    UP_TO_15_DAYS: 'From 6 to 15 days',
    UP_TO_30_DAYS: 'From 16 to 30 days',
    UP_TO_ANY_DAYS: '31 days or greater',
  },
  caregiverRelationship: {
    DAUGHTER: 'Daughter',
    FRIEND_FAMILY_UNKNOWN: 'Friend/Family - Unknown',
    FRIEND: 'Friend',
    GRANDDAUGHTER: 'Granddaughter',
    GRANDSON: 'Grandson',
    HUSBAND: 'Husband',
    MEMBER: 'Member',
    NEIGHBOR: 'Neighbor',
    NEPHEW: 'Nephew',
    NIECE: 'Niece',
    OTHER: 'Other',
    PARENT: 'Parent',
    SIBLING: 'Sibling',
    SON: 'Son',
    WIFE: 'Wife',
    NOT_RELATED: 'Not Related',
  },
  careTeamRosterTaskStatus: {
    IN_PROGRESS: 'In progress',
    COMPLETED: 'Completed',
    ERROR: 'Error',
  },
  choice: {
    YES: 'Yes',
    NO: 'No',
  },
  collectionMethod: {
    CLEAR_ARCH: 'Clear Arch',
    CONTACT_HEALTH: 'Contact Health',
    HEALTH_REPORT: 'Health Check-in',
    LIVECARE: 'LiveCare',
    MAGPIE: 'Magpie',
    MANUAL: 'Manual',
    SMART_METER: 'Smart Meter',
    UNKNOWN: 'Unknown',
  },
  consumerRole: {
    SMARTPHONE_APP: 'New App - Phone',
    PLATFORM_APP: 'Old App - iPad',
  },
  conversationStatus: {
    OPEN: 'Open',
    CLOSED: 'Closed',
  },
  dayOfWeek: {
    FRIDAY: 'Friday',
    MONDAY: 'Monday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    THURSDAY: 'Thursday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
  },
  deviceOS: {
    ANDROID: 'Android',
    IOS: 'iOS',
  },
  employeeGroupType: {
    ASSIGNEE: 'Assignee',
    WORKLIST: 'Worklist',
  },
  employeeRole: {
    admin: 'Administrator',
    abbr: {
      admin: 'Admin',
      care_manager: 'CM',
      cna: 'CNA',
      lpn: 'LPN',
      lvn: 'LVN',
      ma: 'MA',
      md: 'MD',
      np: 'NP',
      operations: 'Ops',
      rn: 'RN',
    },
    care_manager: 'Care Manager',
    care_navigator: 'Care Navigator',
    city_manager: 'City Manager',
    cna: 'Certified Nursing Assistant',
    compliance: 'Compliance Team',
    cst_limited: 'Care Support Team (Limited)',
    external: 'External',
    lpn: 'Licensed Practical Nurse',
    lvn: 'Licensed Vocational Nurse',
    ma: 'Clinical Medical Assistant (CMA)',
    matcher: 'Matcher',
    md: 'Medical Doctor',
    np: 'Nurse Practitioner',
    operations: 'Operations',
    people_ops: 'People Ops Team',
    recruiter: 'Recruiter',
    reliability_team: 'Care Support Team',
    rn: 'Registered Nurse',
    sales: 'Care Advisor',
    unknown: 'Unknown',
    engagement_coordinator: 'Engagement Coordinator',
    health_coach: 'Health Coach',
    oa: 'OA',
    mcd: 'Manager of Clinical Delivery (MCD)',
    pharmacist: 'Pharmacist',
    pharmacist_ma: 'Pharmacy MA',
    social_worker: 'Social Worker',
  },
  encounterType: {
    ACP: 'ACP',
    CCM: 'CCM',
    CLINICAL: 'Clinical',
    ENGAGEMENT: 'Engagement',
    OPS: 'Ops',
    PHARM_VISIT: 'Pharm Visit',
    RPM: 'RPM',
    TCM: 'TOC / Skilled',
    BHI: 'BHI',
  },
  encounterChannel: {
    APPOINTMENT_SCHEDULING: 'Appointment scheduling',
    BLOOD_OXYGEN: 'Blood Oxygen',
    BLOOD_PRESSURE: 'Blood Pressure',
    BLOOD_SUGAR: 'Blood Glucose',
    CARE_COORDINATION: 'Care Coordination',
    CAREGIVER_SUPPORT_NEED: 'Caregiver Support Need',
    CCM_VISIT: 'CCM Visit',
    CHANGE_MEMBER_CONDITION: 'Change in Member Condition',
    COMMUNITY_PARAMEDICINE: ' Community Paramedicine',
    DME_NEED: 'DME Need',
    ENGAGEMENT: 'Engagement',
    ENGAGEMENT_CALL: 'Engagement Call',
    EVENT_FOLLOW_UP: 'Event follow-up',
    EVENT: 'Event',
    FALL: 'Fall',
    HCI: 'HCI',
    FOOD_INSECURITY: 'Food Insecurity',
    HEART_RATE: 'Heart Rate',
    IMMUNIZATIONS: 'Immunizations',
    INSURANCE_BILLING: 'Insurance/Billing',
    INSURANCE_BILLING_CALL: 'Insurance/Billing call',
    MEDICATION_NEEDS: 'Medication needs',
    MEMBER_CG_ONBOARDING: 'Member/CG Onboarding',
    NURSE_HOME_VISIT: 'Nurse home visit',
    OTHER: 'Other',
    RPM_ADHERENCE: 'RPM Adherence',
    RPM_TRAINING: 'RPM Training',
    RPM_TECH_ISSUE: 'RPM Tech Issue',
    SUPPLIES: 'Supplies',
    TECH_ISSUE: 'Tech Issue',
    TEMPERATURE: 'Temperature',
    TRANSPORTATION: 'Transportation',
    WEIGHT: 'Weight',
    WOUND_CARE_RN: 'Wound care RN',
  },
  encounterDirection: {
    INBOUND: 'Inbound',
    OUTBOUND: 'Outbound',
  },
  encounterMethod: {
    CHAT: 'Chat',
    SMS: 'SMS',
    EMAIL: 'Email',
    CALL: 'Call',
    FAX: 'Fax',
    VIDEO: 'Video',
    MAILING: 'USPS Mail',
  },
  encounterMethodOutcome: {
    SUCCESSFUL: 'Completed',
    LEFT_MESSAGE: 'Left Voicemail',
    UNABLE_TO_LEAVE_MESSAGE: 'Unavailable - Call Back',
    NO_ANSWER_NO_VOICEMAIL: 'No Answer / No VoiceMail',
    PHONE_NUMBER_INVALID: 'Disconnected / Wrong Number',
    SENT: 'Sent',
    CHAT_SUCCESSFUL: 'Chat successful',
    NO_RESPONSE: 'No response',
    UNABLE_TO_CONNECT: 'Unable to connect',
    INVALID_EMAIL: 'Invalid mail',
    INVALID_FAX_NUMBER: 'Invalid fax number',
    NO_SHOW: 'No show',
    RETURNED_UNDELIVERABLE: 'Returned undeliverable',
  },
  eventActions: {
    ADD_ADDENDUM: 'Add Addendum',
    ADD_ENCOUNTER: 'Add Encounter',
    ADD_ISSUE: 'Add Issue',
    ADD_NOTE: 'Add Note',
    ADD_TASK: 'Add Task',
    CLOSE_EVENT: 'Close Event',
    INVALIDATE_EVENT: 'Invalidate Event',
    LINK_EVENT: 'Link Event',
    MODIFY_FOLLOW_UP: 'Modify F/U Info',
    MODIFY_URGENCY: 'Modify Urgency',
    SHOW_HISTORY: 'Audit History',
    UNLINK_ISSUE: 'Unlink Issue(s)',
    SUBMIT_INCIDENT: 'Submit Incident',
    SUBMIT_TOC: 'Submit TOC',
    SUBMIT_SKILLED: 'Submit Skilled',
    VIEW_INCIDENT: 'View Incident',
    VIEW_TOC: 'View TOC',
    VIEW_SKILLED: 'View Skilled',
  },
  eventEvaluationActions: {
    ADD_ADDENDUM: 'Add Addendum',
    ADD_INTERVENTION: 'Add Intervention',
    ADD_NOTE: 'Add Note',
    ADD_TRIAGE: 'Triage',
  },
  eventReporterType: {
    MEMBER: 'Member',
    CARE_TEAM: 'Care Team',
    COMMUNITY_RESOURCE: 'Com. Resource',
  },
  facilityType: {
    EXTERNAL: 'External',
    VESTA: 'Vesta',
  },
  formValidationError: {
    POSITIVE_INTEGER: 'Whole numbers only',
  },
  fluSeason: {
    SEASON_2019_2020: '2019/2020',
    SEASON_2020_2021: '2020/2021',
    SEASON_2021_2022: '2021/2022',
  },
  frequency: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    EVERY_OTHER_WEEK: 'Every Other Week',
    MONTHLY: 'Monthly',
  },
  goalStatus: {
    GOAL_MET: 'Goal Met',
    GOAL_NOT_MET: 'Goal Not Met',
    GOAL_PARTIALLY_MET: 'Goal Partially Met',
    IN_PROGRESS: 'In Progress',
    ON_HOLD: 'On Hold',
    READY: 'Ready to Start',
  },
  goalType: {
    ACCESS_TO_CARE: 'Access to Care',
    CHF_MANAGEMENT: 'CHF Management',
    COPD_MANAGEMENT: 'COPD Management',
    CVA_MANAGEMENT: 'CVA Management',
    DEMENTIA_MANAGEMENT: 'Dementia Management',
    DMII_MANAGEMENT: 'DMII Management',
    FALL_AVOIDANCE: 'Fall Avoidance',
    GENERAL_CONDITION_MANAGEMENT: 'General Condition Management',
    GI_CONDITION_MANAGEMENT: 'GI Condition Management',
    LIFESTYLE: 'Lifestyle',
    MEDICATION_MANAGEMENT: 'Medication Management',
    OTHER: 'Other',
    PALLIATIVE_NEEDS: 'Palliative Needs',
    SKIN_CONDITION_MANAGEMENT: 'Skin Condition Management',
    SOCIAL: 'Social',
    TRANSITION_OF_CARE: 'Transition of Care',
    UTI_MANAGEMENT: 'UTI Management',
  },
  interventionEvaluationAction: {
    ACTIVATE: 'Activate',
    ALREADY_IN_PLACE: 'Already in Place',
    COMPLETED_TELEHEALTH: 'Completed in Telehealth Visit',
    EXCLUDE: 'Exclude',
  },
  interventionExclusionReason: {
    ALREADY_DONE: 'Already Done',
    INTERVENTION_DEPRECATED: 'Intervention Deprecated',
    NOT_CLINICALLY_APPROPRIATE: 'Not Appropriate',
    NOT_COVERED: 'Not Covered',
    PARTNER_REFUSED: 'Partner Refused',
    PATIENT_REFUSED: 'Member Refused',
    PROGRAM_UNIMPLEMENTED: 'Unavailable',
  },
  interventionType: {
    ANTIBIOTIC: 'Ensure member uses antibiotic for UTI as prescribed',
    CARE_UPDATE_QUALITY: 'Care Update quality',
    CG_ACTION: 'Caregiver action',
    CG_COACHING: 'Ensure caregiver coaching',
    MEMBER_COACHING: 'Ensure member coaching',
    CG_PRAISE: 'Caregiver praise',
    CHEST_XRAY: 'Arrange for member to receive in home chest X-ray',
    CHF_COMMUNITY_PARAMEDICINE_VISIT:
      'Arrange for member to receive community paramedicine visit for IM/IV injection',
    CHF_IN_HOME_LABS: 'Arrange for member to receive in home labs',
    CLINICAL_TRAINING: 'Clinical training',
    COMMUNICATION_PROFESSIONALISM: 'Communication or professionalism',
    COPD_CHEST_XRAY: 'Arrange for member to receive in home chest X-ray',
    COPD_COMMUNITY_PARAMEDICINE_VISIT:
      'Arrange for member to receive community paramedicine visit for IM/IV injection',
    COPD_IN_HOME_LABS: 'Arrange for member to receive in home labs',
    COPD_RESCUE_PACK:
      'Ensure member uses COPD Rescue Pack as ordered in case of exacerbation',
    EMERGENCY_ESCALATION: 'Ensure escalation via 911',
    ENVIRONMENT_CHANGE_PARTNER: 'Request environmental change',
    ENVIRONMENT_CHANGE: 'Advise environmental change',
    ER_VISIT: 'Ensure emergency department visit',
    EXTRA_INCREASED_OXYGEN:
      'If no order or no reduction in symptoms, request urgent verbal PRN MD order for O2 LPM increase',
    EXTRA_INJECTABLE_DIURETIC:
      'If no order or no reduction in symptoms, request urgent verbal PRN MD order for extra dose of injectable diuretic',
    EXTRA_ORAL_DIURETIC:
      'If no order or no reduction in symptoms, request urgent verbal PRN MD order for extra dose of oral diuretic',
    FAN: 'Ensure member uses in home fan for air hunger',
    FURTHER_CLINICAL_RA: 'Arrange for further Clinical Risk Assessment',
    FURTHER_SOCIAL_RA: 'Arrange for further Social Risk Assessment',
    GET_MD_ORDER: 'Get MD Order',
    GLUCOSE_SUPPLEMENT:
      'Ensure member takes glucose supplement for hypoglycemic event as prescribed',
    HYDROCOLLOID:
      'Ensure member utilizes hydrocolloid dressing for skin breakdown',
    IMPROPER_REPORTING_CHANNEL: 'Improper reporting channel',
    INCREASED_OXYGEN:
      'Ensure member has increased oxygen for air hunger as ordered',
    INJECTABLE_DIURETIC:
      'Ensure member has taken injectable diuretic dose as ordered',
    MAKE_REFERRAL: 'Make referral',
    MD_VISIT: 'Arrange for MD home visit',
    NURSE_VISIT_PARTNER: 'Request partner nurse visit',
    NURSE_VISIT: 'Arrange for RN home visit',
    OPIOID: 'Ensure member takes opioid for air hunger as ordered',
    ORAL_DIURETIC: 'Ensure member has taken oral diuretic dose as ordered',
    OTHER_PARTNER: 'Other',
    OTHER: 'Other',
    PARAMEDICINE_VISIT: 'Arrange for paramedicine visit',
    PCP_VISIT: 'Arrange for PCP visit',
    POLICY_ERROR: 'Policy error',
    REQUEST_AUTHORIZATION: 'Request authorization',
    RESCUE_INHALER:
      'Ensure member uses rescue inhaler for air hunger as ordered',
    RN_WOUND_CARE: 'Arrange for RN wound care visit for skin breakdown',
    SPECIALIST_VISIT: 'Arrange for specialist visit',
    TECH_TRAINING: 'Technology training',
    UNFIT_FOR_CASE: 'Caregiver unfit for case',
    URGENT_CARE: 'Ensure urgent care visit',
  },
  invalidationSource: {
    PAYER: 'Payer',
    MEMBER: 'Member',
    CLAIMS: 'Claims',
    MDS: 'MDS',
    DISCHARGE_SUMMARY: 'Discharge Summary',
    CARE_MANAGER: 'Care Manager',
    AIDE: 'Aide',
    POINT_OF_CONTACT: 'Point of Contact',
    DS: 'DS',
    HEALTH_ALERT: 'Health Alert',
    PATIENT: 'Member',
    RNA: 'RN Assessment',
    SALESFORCE: 'Salesforce',
    UAS: 'UAS',
    UNKNOWN: 'Unknown',
  },
  insuranceQualification: {
    DUAL_ELIGIBLE: 'Dual Eligible',
    INACTIVE_PART_B: 'Inactive Part B Medicare (Ineligible)',
    INELIGIBLE_ERROR: 'Eligibility returned error',
    INN_MISSING_MEDICAID:
      'INN - Missing Medicaid/Supplemental Plan Information',
    INN_RENDER: 'INN - Rendr (UHC NY)',
    MISSING_MEDICARE_AND_MEDICAID:
      'Missing Medicare & Medicaid/Supplemental Plan Information',
    MISSING_MEDICARE: 'Missing Medicare Information',
    OON_INELIGIBLE: 'Out of Network (Ineligible)',
    PPO_OON_ELIGIBLE: 'PPO - Out of Network (Eligible)',
    PPO_OON_MISSING:
      'PPO - Out of Network - Missing Medicaid/Supplemental Plan Information',
    QMB: 'QMB',
    UHC_NE: 'UHC NE',
    CARE_AT_HOME_CINQ: 'Care At Home - CINQ Care',
    MEDICAID_PROGRAM: 'Medicaid Program',
    NO_MEDICARE_INELIGIBLE: 'No Medicare/Inactive Medicare (Ineligible)',
    INN_MEDICARE_ACTIVE_SECONDARY_NON_MEDICAID:
      'INN Medicare + Active Secondary (Non-Medicaid)',
  },
  insuranceLeadQualification: {
    PRE_QUALIFIED: 'Prequalified',
    UNQUALIFIED_OVER_65: 'Unqualified - over 65',
    UNQUALIFIED_UNDER_65: 'Unqualified - under 65',
  },
  issueTypeRuleOperation: {
    GREATER: '>',
    GREATER_OR_EQUAL: '>=',
    LESS: '<',
    LESS_OR_EQUAL: '<=',
    EQUAL: '=',
    BETWEEN: 'Range',
    GAIN: 'Gain',
    LOSS: 'Loss',
  },
  livingArrangement: {
    ALONE: 'Alone',
    CHILD: 'Child',
    GRANDDAUGHTER: 'Granddaughter',
    GRANDSON: 'Grandson',
    NEPHEW: 'Nephew',
    NIECE: 'Niece',
    PARENT: 'Parent',
    SPOUSE: 'Spouse',
    LIVE_IN_AIDE: 'Live-in Aide',
    OTHER: 'Other',
  },
  language: {
    AFRIKAANS: 'Afrikaans',
    AKAN: 'Akan',
    ALBANIAN: 'Albanian',
    AMHARIC: 'Amharic',
    ANLO_EWE: 'Ewe',
    ARABIC: 'Arabic',
    ARAMAIC: 'Aramaic',
    ARMENIAN: 'Armenian',
    ASSYRIAN: 'Assyrian',
    AZERBAIJANI: 'Azerbaijani',
    BALINESE: 'Balinese',
    BALOCHI: 'Balochi',
    BAMBARA: 'Bambara',
    BANGALI: 'Bengali',
    BELARUSIAN: 'Belarusian',
    BOSNIAN: 'Bosnian',
    BULGARIAN: 'Bulgarian',
    BURMESE: 'Burmese',
    CANTONESE: 'Cantonese',
    CARIBEAN: 'Caribean',
    CATALAN: 'Catalan',
    CEBUANO: 'Cebuano',
    CHAMORRO: 'Chamorro',
    CHEROKEE: 'Cherokee',
    CHICHEWA: 'Chichewa',
    CHINESE: 'Chinese',
    CHINESE_TRADITIONAL: 'Chinese (Traditional)',
    CORSA: 'Corsa',
    CREE: 'Cree',
    CREOLE: 'Creole',
    CROATIAN: 'Croatian',
    CZECH: 'Czech',
    DANISH: 'Danish',
    DARI: 'Dari',
    DARI_PERSIAN: 'Dari-Persian',
    DIDA: 'Dida',
    DOLGAN: 'Dolgan',
    DUTCH: 'Dutch',
    DZONGKHA: 'Dzongkha',
    EGYPTIAN_ARABIC: 'Egyptian-Arabic',
    ENGLISH: 'English',
    ERZYA: 'Erzya',
    ESTONIAN: 'Estonian',
    EWE: 'Ewe',
    FARSI: 'Farsi',
    FIJIAN: 'Fijian',
    FILIPINO: 'Filipino',
    FINNISH: 'Finnish',
    FRENCH: 'French',
    FULA: 'Fula',
    FULAH: 'Fulah',
    FUR: 'Fur',
    GA: 'Ga',
    GARIFUNA: 'Garifuna',
    GEORGIAN: 'Georgian',
    GERMAN: 'German',
    GIKUYU: 'Gikuyu',
    GREEK: 'Greek',
    GUJARATI: 'Gujarati',
    HAITIAN_CREOLE: 'Haitian Creole',
    HAKKA: 'Hakka',
    HEBREW: 'Hebrew',
    HINDI: 'Hindi',
    HMONG: 'Hmong',
    HO: 'Ho',
    HUNGARIAN: 'Hungarian',
    IBAN: 'Iban',
    IBANAG: 'Ibanag',
    IBIBIO: 'Ibibio',
    IBO: 'Ibo',
    ICELANDIC: 'Icelandic',
    IGBO: 'Igbo',
    IKALANGA: 'Ikalanga',
    ILLOCANO: 'Illocano',
    INDONESIAN: 'Indonesian',
    ITALIAN: 'Italian',
    JAPANESE: 'Japanese',
    KALASHA: 'Kalasha',
    KANNANDA: 'Kannanda',
    KARELIAN: 'Karelian',
    KAYBYLE: 'Kaybyle',
    KAZAKH: 'Kazakh',
    KHALAJ: 'Khalaj',
    KHAM: 'Kham',
    KHMER: 'Khmer',
    KHMU: 'Khmu',
    KINYARWANDA: 'Kinyarwanda',
    KIRUNDI: 'Kirundi',
    KOREAN: 'Korean',
    KURDISH: 'Kurdish',
    LAO: 'Lao',
    LATVIAN: 'Latvian',
    LINGALA: 'Lingala',
    LITHUANIAN: 'Lithuanian',
    MACEDONIAN: 'Macedonian',
    MALAY: 'Malay',
    MALAYALAM: 'Malayalam',
    MALDIVIAN: 'Maldivian',
    MALTESE: 'Maltese',
    MANDARIN: 'Mandarin',
    MANDINKA: 'Mandinka',
    MAORI: 'Maori',
    MARI: 'Mari',
    MARSHALLESE: 'Marshallese',
    MONGOLIAN: 'Mongolian',
    NAVAJO: 'Navajo',
    NEPAL_BHASA: 'Nepal Bhasa',
    NEPALI: 'Nepali',
    NORWEGIAN: 'Norwegian',
    OROMO: 'Oromo',
    OTHER: 'Other',
    PAHARI: 'Pahari',
    PANGASINAN: 'Pangasinan',
    PASHTO: 'Pashto',
    PATOIS: 'Patois',
    PERSIAN: 'Persian',
    PHOENICIAN: 'Phoenician',
    POLISH: 'Polish',
    PORTUGUESE: 'Portuguese',
    PANJABI: 'Panjabi',
    PUNJABI: 'Punjabi',
    ROMANIAN: 'Romanian',
    RUSSIAN: 'Russian',
    SAMOAN: 'Samoan',
    SERBIAN: 'Serbian',
    SERBO_CROATIAN: 'Serbo-Croatian',
    SICIALIAN: 'Sicialian',
    SIGN_LANGUAGE: 'Sign Language',
    SINDHI: 'Sindhi',
    SINHALA: 'Sinhala',
    SLOVAK: 'Slovak',
    SLOVENIAN: 'Slovenian',
    SOMALI: 'Somali',
    SPANISH: 'Spanish',
    SUDANESE: 'Sudanese',
    SWAHILI: 'Swahili',
    SWEDISH: 'Swedish',
    TAGALOG: 'Tagalog',
    TAJIK: 'Tajik',
    TAMIL: 'Tamil',
    TATAR: 'Tatar',
    TELUGU: 'Telugu',
    THAI: 'Thai',
    TIBETAN: 'Tibetan',
    TIGRE: 'Tigre',
    TIGRINYA: 'Tigrinya',
    TONGA: 'Tonga',
    TONGAN: 'Tongan',
    TURKISH: 'Turkish',
    TUROYO: 'Turoyo',
    TUVULUAN: 'Tuvuluan',
    UKRAINIAN: 'Ukrainian',
    URDU: 'Urdu',
    UZBECK: 'Uzbeck',
    VIETNAMESE: 'Vietnamese',
    WELSH: 'Welsh',
    WOLOF: 'Wolof',
    WU: 'Wu',
    YIDDISH: 'Yiddish',
    YORUBA: 'Yoruba',
    ZULU: 'Zulu',
  },
  residenceType: {
    PRIVATE_HOME: 'Private Home',
    APARTMENT: 'Apartment',
    REHAB: 'Rehab',
    NURSING_HOME: 'Nursing Home',
    SENIOR_LIVING_COMMUNITY: 'Senior Living Community',
    HOSPICE: 'Hospice',
    OTHER: 'Other',
  },
  measurementFrequency: {
    WEEKS: 'Once a Week or More',
    DAYS: 'Daily',
    ONCE_PER_DAY: 'Once a Day',
    TWICE_PER_DAY: 'Twice a Day',
    THREE_PER_DAY: 'Three Times a Day',
  },
  medicalEquipment: {
    airMattress: 'Air mattress',
    bedAlarm: 'Bed alarm',
    bedbound: 'Bedbound',
    bedpan: 'Bedpan',
    bedsideCommode: 'Bedside commode',
    bipap: 'BiPAP',
    cane: 'Cane',
    catheterCare: 'Catheter care',
    chux: 'Chux (Disposable underpads)',
    commode: 'Commode',
    condomCatheter: 'Condom Catheter',
    compressionStockings: 'Compression Stockings',
    dentures: 'Dentures',
    dialysisMachine: 'Dialysis Machine',
    enteralPump: 'Enteral pump',
    eyeglasses: 'Eyeglasses',
    feedingTubes: 'Feeding tubes',
    fixedWalker: 'Fixed walker',
    floorMats: 'Floor mats',
    foleyCatheter: 'Foley Catheter',
    gaitBelt: 'Gait belt',
    geriChair: 'Geri chair',
    glasses: 'Glasses',
    grabBars: 'Grab Bars',
    handheldShower: 'Handheld shower',
    hearingAid: 'Hearing aid',
    hospitalBed: 'Hospital bed',
    hoyerLift: 'Hoyer lift',
    incontinence: 'Incontinence',
    ivPump: 'IV Pump',
    lifeAlert: 'Life Alert',
    liftChair: 'Lift chair',
    merryWalker: 'Merry walker',
    motorizedWheelchair: 'Motorized wheelchair',
    nebulizer: 'Nebulizer',
    obt: 'OBT',
    ostomyBag: 'Ostomy bag',
    other: 'Other',
    overbedTable: 'Overbed table',
    oxygen: 'Oxygen',
    pers: 'Personal Emergency Response System (PERS)',
    picLineCatheter: 'Piccline Catheter',
    powerRecliner: 'Power recliner',
    pressurePad: 'Pressure pad',
    quadCane: 'Quad cane',
    raisedToiletSeat: 'Raised toilet seat',
    rollator: 'Rollator',
    rollingWalker: 'Rolling walker',
    scooter: 'Scooter',
    showerChair: 'Shower Chair',
    sitToStandLift: 'Sit to stand lift',
    slideBoard: 'Slide board',
    specializedMattress: 'Specialized mattress',
    stairlift: 'Stairlift',
    standPivot: 'Stand/Pivot',
    transportChair: 'Transport chair',
    unanswered: 'UNANSWERED',
    unknown: 'Unknown',
    urinal: 'Urinal',
    ventilator: 'Ventilator',
    walker: 'Walker',
    wc: 'WC',
    wheelchair: 'Wheelchair',
    woundVac: 'Wound vac',
    AIR_MATTRESS: 'Air mattress',
    BATHTUB_SAFETY_RAIL: 'Bathtub Safety Rail',
    BED_ALARM: 'Bed alarm',
    BED_RAILS: 'Bed rails',
    BEDPAN: 'Bedpan',
    BEDSIDE_COMMODE: 'Bedside commode',
    BIPAP: 'BiPAB',
    CANE: 'Cane',
    CHUX: 'Chux (Disposable Underpads)',
    CPAP: 'Continuous Positive Airway Pressure (CPAP) Machine',
    CONDOM_CATHETER: 'Condom catheter',
    COMMODE: 'Commode',
    COMPRESSION_STOCKINGS: 'Compression Stockings',
    DENTURES: 'Dentures',
    DIALYSIS_MACHINE: 'Dialysis machine',
    ENTERAL_PUMP: 'Enteral pump',
    EYEGLASSES: 'Eyeglasses',
    FEEDING_TUBES: 'Feeding tubes',
    FIXED_WALKER: 'Fixed walker',
    FOLEY_CATHETER: 'Foley catheter',
    GERI_CHAIR: 'Geri chair',
    GRAB_BARS: 'Grab bars',
    HANDHELD_SHOWER: 'Handheld shower',
    HEARING_AID: 'Hearing aid',
    HOSPITAL_BED: 'Hospital bed',
    HOYER_LIFT: 'Hoyer lift',
    IV_PUMP: 'IV Pump',
    LEG_BRACES: 'Leg braces',
    LIFE_ALERT: 'Life alert',
    LIFT_CHAIR: 'Lift chair',
    MERRY_WALKER: 'Merry walker',
    MOTORIZED_WHEELCHAIR: 'Motorized wheelchair',
    NEBULIZER: 'Nebulizer',
    ORTHOTICS: 'Orthotics',
    OSTOMY_BAG: 'Ostomy bag',
    OVERBED_TABLE: 'Overbed table',
    OXYGEN: 'Oxygen',
    PIC_LINE_CATHETER: 'PIC line catheter',
    POWER_RECLINER: 'Power recliner',
    PRESSURE_PAD: 'Pressure pad',
    PERS: 'Personal Emergency Response System (PERS)',
    PROSTHESES: 'Prostheses',
    QUAD_CANE: 'Quad cane',
    RAISED_TOILET_SEAT: 'Raised toilet seat',
    ROLLING_WALKER: 'Rolling walker',
    ROLLATOR: 'Rollator',
    SHOWER_CHAIR: 'Shower chair',
    SIT_TO_STAND_LIFT: 'Sit to stand lift',
    SPECIALIZED_MATTRESS: 'Specialized mattress',
    STAIRLIFT: 'Stairlift',
    TRANSPORT_CHAIR: 'Transport chair',
    VENTILATOR: 'Ventilator',
    WALKER: 'Walker',
    WOUND_VAC: 'Wound vac',
    WHEELCHAIR: 'Wheelchair',
    URINAL: 'Urinal',
    UNKNOWN: 'Unknown',
    OTHER: 'Other',
    UNANSWERED: 'UNANSWERED',
    BEDBOUND: 'BedBound',
    CATHETER_CARE: 'Catheter care',
    GLASSES: 'Glasses',
    INCONTINENCE: 'Incontinence',
    WC: 'WC',
    OBT: 'OBT',
    FLOOR_MATS: 'Floor Mats',
    STAND_PIVOT: 'Stand/Pivot',
    SLIDE_BOARD: 'Slide Board',
    GAIT_BELT: 'Gait Belt',
  },
  memberConsentType: {
    WRITTEN: 'Written',
    VERBAL: 'Verbal',
  },
  memberContactMethodType: {
    PHONE_CALL: 'Phone Call',
    TEXT: 'Text',
    MOBILE_APP_CHAT: 'Mobile App - Chat',
    EMAIL: 'Email',
    OTHER: 'Other',
  },
  memberContactTimeType: {
    ANY_TIME: 'Anytime',
    MORNING: 'Morning',
    AFTERNOON: 'Afternoon',
    CUSTOM: 'Custom',
  },
  memberDashboardActionStatus: {
    NOT_STARTED: 'Not Started',
    IN_PROGRES: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  memberDashboardKeyContactType: {
    PCP: 'PCP',
    PHARMACY: 'Pharmacy',
  },
  memberDashboardPharmacyUnderstanding: {
    HIGH: 'High',
    MODERATE: 'Moderate',
    LOW: 'Low',

    sublabel: {
      HIGH: 'Understands 80-100%',
      MODERATE: 'Understands 50-80%',
      LOW: 'Understands less than 50%',
    },
  },
  memberEligibilityReason: {
    NOT_IN_NETWORK: 'Not in network',
    INACTIVE_REFERRAL: 'Inactive Referral',
    REFERRAL_IN_IMPLEMENTATION: 'Referral in Implementation',
    ELIGIBILITY_ERROR: 'Eligibility Returned Error',
    INACTIVE_MEDICARE: 'Inactive Medicare',
    NOT_ENOUGH_INFORMATION: 'Not Enough Information',
    NOT_RESPONDED: 'No Response',
    SYSTEM_ERROR: 'System Error',
    NO_PRIMARY_INSURANCE_IN_RESPONSE: 'No primary insurance in 271',
    UNEXPECTED_ERROR_CODE: 'Unexpected error code',
    NO_ERROR_CODE_FOUND: 'No error code found in 271',
  },
  memberEligibilityError: {
    C04: '04 - Multiple Beneficiaries sent in request',
    C15: '15 - Required application data missing',
    C33: '33 - Input Errors',
    C35: '35 - Out of Network',
    C41: '41 - NPI Not enrolled with HETIS with the Submitter ID provided',
    C42: '42 - System Unavailable or Unable to create Response',
    C43: '43 - Invalid Submitter ID due to Medicare provider status',
    C44: '44 - Invalid/Missing Provider Name',
    C45: '45 - Invalid/Missing Provider Specialty',
    C46: '46 - Invalid/Missing Provider Phone Number',
    C47: '47 - Invalid/Missing Provider State',
    C48: '48 - Invalid/Missing Referring Provider Identification Number',
    C49: '49 - Provider is Not Primary Care Physician',
    C50: '50 - NPI not Eligible to verify Eligibility Status in HETS',
    C51: '51 - NPI is not on file with HETS',
    C52: '52 - Service Dates Not Within Provider Plan Enrollment',
    C53: '53 - Inquired Benefit Inconsistent with Provider Type',
    C54: '54 - Inappropriate Product/Service ID Qualifier',
    C55: '55 - Inappropriate Product/Service ID',
    C56: '56 - Inappropriate Date',
    C57: '57 - Invalid/Missing Date(s) of Service',
    C58: '58 - Missing Member First Name and Date of Birth',
    C60: '60 - Date of Birth Follows Date(s) of Service',
    C61: '61 - Date of Death Precedes Date(s) of Service',
    C62:
      '62 - Requested Date for Eligibility is more than 12 months in the past or 4 months in future',
    C63: '63 - Date of Service in Future',
    C64: '64 - Invalid/Missing Patient ID',
    C65: '65 - Invalid/Missing Patient Name',
    C66: '66 - Invalid/Missing Patient Gender Code',
    C67: '67 - Patient Not Found',
    C68: '68 - Duplicate Patient ID Number',
    C69: '69 - Inconsistent with Patient Age',
    C70: '70 - Inconsistent with Patient Gender',
    C71:
      '71 - Date of birth of the Member does not Match Date of Birth on File',
    C72: '72 - Invalid/Missing Subscriber ID',
    C73: '73 - No matching first/last name on File',
    C74: '74 - Invalid/Missing Subscriber/Insured Gender Code',
    C75: '75 - Subscriber/Insured Not Found',
    C76: '76 - Duplicate Subscriber/Insured ID Number',
    C77: '77 - Subscriber Found, Patient Not Found',
    C78: '78 - Subscriber/Insured Not in Group/Plan Identified',
    C79: '79 - Subscriber ID is not MBI',
    C80: '80 - No Response received - Transaction Terminated',
    C97: '97 - Invalid or Missing Provider Address',
    C98: '98 - Experimental Service or Procedure',
    CAA: 'AA - Authorization Number Not Found',
    CAE: 'AE - Requires Primary Care Physician Authorization',
    CAF: 'AF - Invalid/Missing Diagnosis Code(s)',
    CAG: 'AG - Invalid/Missing Procedure Code(s)',
    CAO: 'AO - Additional Patient Condition Information Required',
    CCI: 'CI - Certification Information Does Not Match Patient',
    CE8: 'E8 - Requires Medical Review',
    CIA: 'IA - Invalid Authorization Number Format',
    CMA: 'MA - Missing Authorization Number',
    CT4:
      'T4 - Insurance Plan Name, Member Last Name or Subscriber ID missing from Request',
  },
  memberEligibilityPushError: {
    NO_VALID_INSURANCES: 'No valid Primary or Secondary insurances found.',
    NO_INSURANCES_IN_NETWORK: 'Insurance associated is not In Network.',
    NO_SUBSCRIBER_IDS: 'Subscriber ID missing.',
    NO_PLAN_PRACTICESUITE_ID: 'Insurance associated has no PS ID',
    PENDING_ELIGIBILITY: 'The member has an Eligibility Check in progress.',
  },
  memberEligibilityStatus: {
    YES: 'Eligible',
    NO: 'Ineligible',
    NOT_RAN: 'Not ran',
    PENDING: 'Pending',
    QUEUED: 'Queued',
    UNDETERMINED: 'Undetermined',
    ERROR: 'Error',
  },
  memberRisk: {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
  },
  memberStatus: {
    ACCEPTED: 'Accepted',
    ACTIVE: 'Active',
    DECEASED_ONBOARDED: 'Deceased - Onboarded',
    DECEASED_NON_ONBOARDED: 'Deceased - Non Onboarded',
    DECLINED: 'Declined',
    DISCHARGED: 'Discharged',
    DISENROLLED: 'Disenrolled',
    DISENROLLED_HOSPICE: 'Disenrolled - Hospice',
    DISENROLLED_INSTITUTIONALIZED: 'Disenrolled - Institutionalized',
    DISENROLLED_OON_INSURANCE: 'Disenrolled - OON Insurance',
    DISENROLLED_UNRESPONSIVE:
      'Disenrolled - Unresponsive to Engagement Efforts',
    DISENROLLED_UNSUPPORTED_STATE: 'Disenrolled - Moves to Unsupported State',
    DISENROLLED_WITHDRAW: 'Disenrolled - Chose to Withdraw',
    DISQUALIFIED: 'Disqualified',
    HOSPICE: 'Hospice',
    INSTITUTION: 'Institution',
    INVALID: 'Invalid',
    PALLIATIVE: 'Palliative',
    PASSIVE: 'Passive',
    UNREACHABLE: 'Unreachable',
    ON_HOLD: 'On hold',
  },
  messageType: {
    SMS_APP_INVITE: 'App invite',
    SMS_INBOUND: 'Inbound',
    SMS_OUTBOUND: 'Outbound',
  },
  messaging: {
    MEMBER: 'Member',
    CAREGIVER: 'Caregiver',
    CARE_TEAM: 'Care Team',
  },
  observationCategory: {
    'uti-dehydration': 'UTI/Dehydration',
    cardiopulmonary: 'Cardiopulmonary',
    diabetes: 'Diabetes',
    fall: 'Fall',
    gastrointestinal: 'Gastrointestinal',
    palliative: 'Palliative',
    skin: 'Skin',
    social: 'Social',
  },
  organizationAbbr: {
    AHC: 'AHC',
    CCA: 'CCA',
    CCX: 'CCX',
    CCI: 'CCI',
    CL: 'CL',
    EC_PACE: 'EC PACE',
    JASA: 'JASA',
    PH: 'Perfect Health',
    VESTA: 'Vesta',
  },
  patientGender: {
    FEMALE: 'Female',
    MALE: 'Male',
    UNKNOWN: 'Unknown',
  },
  phoneType: {
    HOME: 'Home',
    CELL: 'Cell',
    FAX: 'FAX',
    BUSINESS: 'Business',
  },
  programExtensionStatus: {
    UNASSIGNED: 'Unassigned',
    NOT_STARTED: 'Not started',
    IN_PROGRESS: 'In progress',
    ACTIVE: 'Active',
    DELAYED_ESCALATED: 'Delayed - Escalated',
    DELAYED_UNREACHABLE: 'Delayed - Unreachable',
    DELAYED_TECH_ISSUE: 'Delayed - Tech issue',
    HOLD: 'Hold',
    PENDING_AUTH: 'Pending auth',
    DISENROLLED: 'Disenrolled',
    DENIED_DECLINED: 'Denied/Declined',
  },
  patientContactType: {
    AIDE: 'Aide',
    CARDIOLOGIST: 'Cardiologist',
    CARE_MANAGER: 'Care Manager',
    CHILD: 'Child',
    DME: 'DME',
    ENDOCRINOLOGIST: 'Endocrinologist',
    GRANDDAUGHTER_GRANDSON: 'Granddaughter / Grandson',
    HOSPICE: 'Hospice',
    IDT: 'IDT',
    MSW: 'MSW',
    NEUROLOGIST: 'Neurologist',
    NIECE_NEPHEW: 'Niece / Nephew',
    OTHER: 'Other',
    OTHER_PHYSICIAN: 'Other Physician',
    OXYGEN: 'Oxygen',
    PALLIATIVE_CARE: 'Palliative Care',
    PCP: 'PCP',
    PHARMACY: 'Pharmacy',
    PSYCHIATRIST: 'Psychiatrist',
    PSYCHOLOGIST: 'Psychologist',
    PT: 'PT',
    RHEUMATOLOGIST: 'Rheumatologist',
    RN: 'RN',
    SELF: 'Self',
    SIBLING: 'Sibling',
    SPOUSE: 'Spouse',
    ST: 'ST',
    TRANSPORTATION: 'Transportation',
    UROLOGIST: 'Urologist',
  },
  reasonWithdraw: {
    CHOOSE_TO_WIDRAW_DO_NOT_CALL: 'Chose to Withdraw - Do Not Call',
    CONTENT_WITH_SUPPORT: 'Content with Support',
    DUPLICATIVE_SERVICES: 'Duplicative Services',
    HIGH_CALL_VOLUME: 'High Call Volume',
  },
  riskProgramSource: {
    PAYER: 'Payer',
    MEMBER: 'Member',
    CLAIMS: 'Claims',
    MDS: 'MDS',
    DISCHARGE_SUMMARY: 'Discharge Summary',
    CARE_MANAGER: 'Care Manager',
    AIDE: 'Aide',
    POC: 'Point of Contact',
  },
  careTrackType: {
    abbr: {
      CEREBROVASCULAR: 'CVA',
      CHF_EXACERBATION: 'CHF',
      COPD_EXACERBATION: 'COPD',
      DIABETES_TYPE_II: 'DMII',
      DEMENTIA: 'Dementia',
      FALL: 'Fall',
      GENERAL: 'General',
      GI_COMPLICATION: 'GI',
      PRESSURE_ULCER: 'Skin',
      SOCIAL: 'Social',
      UTI_DEHYDRATION: 'UTI',
      TRANSITIONS_OF_CARE: 'ToC',
      ELEMENT_CARE: 'Custom',
    },
    CEREBROVASCULAR: 'Cerebrovascular',
    CHF_EXACERBATION: 'CHF Exacerbation',
    COPD_EXACERBATION: 'COPD Exacerbation',
    DIABETES_TYPE_II: 'Diabetes type II',
    DEMENTIA: 'Dementia',
    FALL: 'Fall',
    GENERAL: 'General',
    GI_COMPLICATION: 'GI complication',
    SOCIAL: 'Social',
    PRESSURE_ULCER: 'Pressure ulcer',
    UTI_DEHYDRATION: 'UTI Dehydration',
    TRANSITIONS_OF_CARE: 'Transitions of Care',
    ELEMENT_CARE: 'Element Care custom track',
  },
  riskLevel: {
    HIGH: 'High Risk',
    MODERATE: 'Moderate Risk',
    LOW: 'Low Risk',
    NONE: 'No Risk',
    UNSPECIFIED: 'Unspecified',
  },
  rnStatus: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  RPMAdherenceLevel: {
    MET: 'Met',
    ON_TRACK: 'On Track',
    MISSED: 'Missed',
    AT_RISK: 'At Risk',
    Missed: 'Missed',
  },
  RPMVendorDeviceSize: {
    SMALL: 'Small',
    LARGE: 'Large',
    X_LARGE: 'X-Large',
  },
  RPMVendorDeviceType: {
    BLOOD_PRESSURE: 'Blood Pressure',
    GLUCOMETER: 'Glucometer',
    PULSE_OX: 'Pulse Oximeter',
    SCALE: 'Scale',
  },
  serviceLevel: {
    CO: 'CO (Companion Care)',
    HHA: 'HHA (Home Health Aide)',
    HM: 'HM (Homemaker)',
    PC: 'PC (Personal Care)',
    PCA: 'PCA (Personal Care Aide)',
    LPN: 'LPN (Licensed Practical Nurse)',
  },
  states: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  virtualVisitPlatform: {
    DOXY: 'Doxy',
    ZOOM: 'Zoom',
    MEET: 'Meet',
  },
  virtualVisitStatus: {
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    NO_SHOW: 'No show',
    RESCHEDULED: 'Rescheduled',
    SCHEDULED: 'Scheduled',
  },
  taskStatus: {
    COMPLETED: 'Completed',
    DELAYED: 'Delayed',
    DELIVERED: 'Delivered',
    ESCALATED: 'Escalated',
    IN_PROGRESS: 'In progress',
    NOT_STARTED: 'Not Started',
    ORDER_PLACED: 'Order Placed',
    PENDING_AUTHORIZATION: 'Pending Authorization',
    SHIPPED: 'Shipped',
    VOID: 'Void',
  },
  taskDefinitionStatus: {
    DRAFT: 'Draft',
    INACTIVE: 'Inactive',
    PUBLISHED: 'Published',
  },
  taskFields: {
    STATUS: 'Status',
    DUE_DATE: 'Due Date',
    ASSIGNEE: 'Assignee',
    TASK_DEFINITION_OUTCOME: 'Task Definition Outcome',
    MEMBER: 'Member',
    EMPLOYEE_GROUP: 'Employee Group',
    HEALTH_ALERT: 'Alert',
  },
  timeUnit: {
    DAYS: 'Days',
    HOURS: 'Hours',
  },
  documentType: {
    CARE_PLAN: 'Care Plan',
    HIE: 'HIE',
  },
};
