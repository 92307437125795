import { Employee } from './Employee';
import { make } from './Model';

export class PodConfigEmployeeSlot {
  id = 0;

  employee?: Employee;

  role?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.employee = make(obj.employee, Employee);
  }
}

export default PodConfigEmployeeSlot;
