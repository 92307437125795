import moment from 'moment';
import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  MemberContactTimeType,
  PhoneType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CommunityResource,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EMPTY, TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import { Fonts, IconButton, PhoneLink, Table, Tooltip } from 'styleguide-v2';

interface Props {
  communityResources: CommunityResource[];
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onEdit: (communityResource: CommunityResource) => void;
  pagination?: PaginationType;
}

const COMMUNITY_RESOURCES_COLUMNS = ({ onEdit }: Props) => [
  {
    headerName: translate('communityResources.table.type'),
    component: ({ type, specialities }: CommunityResource) => (
      <>
        <p className="no-margin">{type.description}</p>
        {specialities?.length && (
          <p className="no-margin gray">
            {specialities?.map((s) => s.description).join(', ')}
          </p>
        )}
      </>
    ),
  },
  {
    headerName: translate('communityResources.table.name'),
    field: 'name',
  },
  {
    headerName: translate('communityResources.table.address'),
    component: ({ address }: CommunityResource) =>
      address ? (
        <>
          <p className="no-margin">
            {[address?.address1, address.address2].filter(Boolean).join(', ')}
          </p>
          <p className="no-margin">
            {[address.city, address.state?.value, address.zipCode]
              .filter(Boolean)
              .join(', ')}
          </p>
        </>
      ) : (
        ''
      ),
  },
  {
    headerName: translate('communityResources.table.phone'),
    component: ({ phone, fax }: CommunityResource) => (
      <>
        {phone && (
          <PhoneLink phone={phone} type={PhoneType.BUSINESS.toString()} />
        )}
        {fax && <PhoneLink phone={fax} type={PhoneType.FAX.toString()} />}
        {!fax && !phone && EMPTY}
      </>
    ),
    width: 160,
  },
  {
    headerName: translate('communityResources.table.mltc'),
    component: ({ mltcPlan }: CommunityResource) => {
      return mltcPlan ? (
        <Tooltip
          text={
            <>
              <p className="white no-margin">
                <span className="semi-bold">
                  {translate('communityResources.table.mltcPlanName')}
                  {': '}
                </span>
                <span>{mltcPlan?.name || EMPTY}</span>
              </p>
              <p className="white no-margin">
                <span className="semi-bold">
                  {translate('communityResources.table.mltcPlanId')}
                  {': '}
                </span>
                <span>{mltcPlan?.mltcId || EMPTY}</span>
              </p>
              <p className="white no-margin">
                <span className="semi-bold">
                  {translate('communityResources.table.mltcPlanCode')}
                  {': '}
                </span>
                <span>{mltcPlan?.mltcCode || EMPTY}</span>
              </p>
            </>
          }
        >
          <div className="flex gap">
            <span style={{ marginTop: '0.25rem' }}>
              {[mltcPlan?.mltcCode, mltcPlan?.mltcId]
                .filter(Boolean)
                .join(' - ') || EMPTY}
            </span>
            <InfoIcon color="info" />
          </div>
        </Tooltip>
      ) : (
        EMPTY
      );
    },
    width: 150,
  },
  {
    headerName: translate('communityResources.table.hoursOfOperation'),
    component: (cr: CommunityResource) =>
      cr
        .getHoursOfOperation()
        .map(({ dates, startTime, endTime, type }, index) => (
          <p
            className="no-margin"
            key={`community-resource-${cr.id}-time-${index}`}
          >
            <span>{DayOfWeek.stringify(dates)}</span>
            {type !== MemberContactTimeType.CUSTOM && `: ${type?.toString()}`}
            {type === MemberContactTimeType.CUSTOM && (
              <>
                {': '}
                {startTime
                  ? moment(startTime.getTime()).format(TIME_FORMAT)
                  : '*'}
                {' - '}
                {endTime ? moment(endTime.getTime()).format(TIME_FORMAT) : '*'}
              </>
            )}
          </p>
        )),
  },
  {
    headerName: translate('communityResources.table.website'),
    component: ({ website }: CommunityResource) =>
      website ? (
        <Tooltip text={website}>
          <a
            className="flex middle gap"
            href={website}
            target="_blank"
            rel="noreferrer"
          >
            Link
            <OpenInNewIcon fontSize="small" color="inherit" />
          </a>
        </Tooltip>
      ) : (
        ''
      ),
  },
  {
    headerName: translate('communityResources.table.coverageArea'),
    component: ({ states }: CommunityResource) =>
      states.map((s) => {
        return (
          <p
            className="no-margin"
            key={`community-resource-state-${s.state.value}`}
          >
            <span className="bold">
              <Tooltip text={s.state.toString()}>
                <span>
                  {s.state.value}
                  {s.cities.length ? ': ' : ''}
                </span>
              </Tooltip>
            </span>
            <span>
              {s.cities
                .map(
                  ({ city, zip }) =>
                    `${city}${city && zip ? ' ' : ''}${zip ? `(${zip})` : ''}`,
                )
                .join(', ')}
            </span>
          </p>
        );
      }),
  },
  {
    headerName: translate('communityResources.table.referrals'),
    component: ({ referrals }: CommunityResource) =>
      referrals?.length ? (
        <Tooltip
          text={referrals.map((r) => (
            <p
              className="no-margin white"
              key={`community-resource-tooltip-referral-${r.id}`}
            >
              {r.name}
            </p>
          ))}
        >
          <CheckCircleIcon color="primary" />
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('communityResources.table.interventions'),
    component: ({ interventions }: CommunityResource) =>
      interventions?.length ? (
        <Tooltip
          text={interventions.map((i) => (
            <p
              className="no-margin white"
              key={`community-resource-tooltip-referral-${i.id}`}
            >
              {i.description}
            </p>
          ))}
        >
          <CheckCircleIcon color="primary" />
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('communityResources.table.insurances'),
    component: ({ insurances }: CommunityResource) =>
      insurances?.length ? (
        <Tooltip
          text={insurances.map((i) => (
            <p
              className="no-margin white"
              key={`community-resource-tooltip-insurance-${i.id}`}
            >
              {i.name}
            </p>
          ))}
        >
          <CheckCircleIcon color="primary" />
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('communityResources.table.status'),
    component: ({ status }: CommunityResource) => (
      <Tooltip text={status ? 'Active' : 'Inactive'}>
        {status ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </Tooltip>
    ),
  },
  {
    headerName: '',
    component: (cr: CommunityResource) => (
      <IconButton size="small" onClick={() => onEdit(cr)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

export const CommunityResourcesTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: COMMUNITY_RESOURCES_COLUMNS(props),
        compact: true,
        data: props.communityResources,
        pagination: props.pagination,
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default CommunityResourcesTable;
