import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import BoltIcon from '@mui/icons-material/Bolt';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import HandIcon from '@mui/icons-material/PanTool';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { MemberEligibilityStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  ErrorModel,
  MemberInsurance,
  Patient,
} from '@vestahealthcare/common/models';
import moment, { Moment } from '@vestahealthcare/common/moment';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Button,
  CollapseIcon,
  Colors,
  Fonts,
  IconButton,
  Modal,
  Table,
  Tooltip,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  callPushEligibilityCheck,
  createMemberInsurance,
  fetchMemberInsuranceQualification,
  getMemberInsurances,
  updateMemberInsurance,
  updateMemberInsuranceQualification,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';
import { startCase, toLower } from 'lodash';

import InsuranceEditModal, { InsuranceData } from './InsuranceEditModal';
import InsuranceViewQualificationsModal from './InsuranceViewQualificationsModal';

const useStyles = makeStyles({
  eligibilityCell: {
    '&&': {
      verticalAlign: 'middle',
    },
  },
  eligibilityContainer: {
    display: 'grid',
    fontSize: `calc(${Fonts.fontSize} * .75)`,
  },
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.5)`,
      fontWeight: 500,
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: '5px',

    '& > *': {
      flex: '1',
      minWidth: '20rem',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const getEligibilityStatusIcon = (
  status?: MemberEligibilityStatus,
  date?: Moment,
  error?: ErrorModel,
  eligibilityContainerClass?: string,
) => {
  let icon: ReactNode = <CancelIcon color="action" />;
  let text: string = 'notRun';
  const tooltip = error?.description;

  if (status === MemberEligibilityStatus.YES) {
    icon = <CheckIcon color="primary" />;
    text = 'eligible';
  }
  if (status === MemberEligibilityStatus.ERROR) {
    icon = <ErrorIcon color="error" />;
    text = 'errors';
  }
  if (status === MemberEligibilityStatus.NO) {
    icon = <CancelIcon color="error" />;
    text = 'ineligible';
  }
  if (status === MemberEligibilityStatus.CHECK) {
    icon = <HandIcon color="warning" />;
    text = 'review';
  }

  const result = (
    <div className="flex extra-gap">
      {icon}
      <div className={eligibilityContainerClass}>
        <span className="bold">
          {translate(`memberInsurances.eligibility.${text}`)}
        </span>
        {date && <span>{date?.format(DATE_FORMAT_SHORT)}</span>}
      </div>
    </div>
  );
  return tooltip ? <Tooltip text={tooltip}>{result}</Tooltip> : result;
};

interface Props {
  member: Patient;
  onLoading: (loading: boolean) => void;
}
interface TableProps {
  insurances: MemberInsurance[];
  editInsurance?: (item: MemberInsurance) => void;
  deleteInsurance?: (item: MemberInsurance) => void;
  emptyState?: string;
  isInactive?: boolean;
}

const MEMBER_INSURANCE_COLUMNS = (
  { isInactive, editInsurance, deleteInsurance }: TableProps,
  styles: ClassNameMap<'eligibilityContainer'>,
) => {
  const columns: Column[] = [
    {
      headerName: translate('memberInsurances.eligibilityStatus'),
      component: ({
        eligibilityStatus,
        eligibilityDatetime,
        eligibilityError,
      }: MemberInsurance) =>
        getEligibilityStatusIcon(
          eligibilityStatus,
          eligibilityDatetime,
          eligibilityError,
          styles.eligibilityContainer,
        ),
      width: 165,
    },
    {
      headerName: translate('memberInsurances.insurancePlan'),
      component: ({ insurancePlan, insurancePriority }: MemberInsurance) => (
        <>
          <p className="no-margin size-xs">{insurancePlan.name}</p>
          <p className="no-margin bold size-xxs">
            {startCase(toLower(insurancePriority.name))}
          </p>
        </>
      ),
    },
    {
      headerName: translate('memberInsurances.insuranceType'),
      component: ({ insuranceType, insuranceTypeName }: MemberInsurance) =>
        insuranceType ? (
          <Tooltip text={insuranceTypeName}>
            <span>{insuranceType}</span>
          </Tooltip>
        ) : (
          EMPTY
        ),
      width: 50,
    },
    {
      headerName: translate('memberInsurances.subscriberId'),
      component: ({ subscriberId }: MemberInsurance) => (
        <span className="size-xs" style={{ overflowWrap: 'anywhere' }}>
          {subscriberId || EMPTY}
        </span>
      ),
    },
    {
      headerName: translate('memberInsurances.mltc'),
      component: ({ mltcPlan }: MemberInsurance) =>
        mltcPlan ? (
          <Tooltip
            text={
              <>
                <p style={{ color: 'white', margin: 0 }}>
                  {translate('memberInsurances.mltcPlanCode')}:{' '}
                  {mltcPlan?.mltcCode}
                </p>
                <p style={{ color: 'white', margin: 0 }}>
                  {translate('memberInsurances.mltcPlanId')}: {mltcPlan?.mltcId}
                </p>
                <p style={{ color: 'white', margin: 0 }}>
                  {translate('memberInsurances.mltcPlanType')}:{' '}
                  {mltcPlan?.mltcType}
                </p>
              </>
            }
          >
            <span>{mltcPlan?.name}</span>
          </Tooltip>
        ) : (
          <span>{EMPTY}</span>
        ),
      width: 200,
    },
    {
      headerName: translate('memberInsurances.enrollmentStart'),
      component: ({ enrollmentStart }: MemberInsurance) =>
        enrollmentStart
          ? moment(enrollmentStart).format(DATE_FORMAT_SHORT)
          : EMPTY,
      width: 140,
    },
    {
      headerName: translate('memberInsurances.enrollmentEnd'),
      component: ({ enrollmentEnd }: MemberInsurance) =>
        enrollmentEnd ? moment(enrollmentEnd).format(DATE_FORMAT_SHORT) : EMPTY,
      width: 140,
    },
    {
      headerName: translate('memberInsurances.updated'),
      component: ({ updatedAt, updatedBy }: MemberInsurance) => (
        <UpdatedBy
          user={updatedBy?.fullName}
          date={updatedAt}
          contrast={isInactive}
          size="s"
        />
      ),
      width: 150,
    },
  ];

  if (isInactive) {
    columns.push({
      headerName: translate('memberInsurances.discontinuedAt'),
      component: (item: MemberInsurance) =>
        item.discontinuedAt
          ? moment.unix(item.discontinuedAt).format(DATE_FORMAT_SHORT)
          : EMPTY,
    });
  } else {
    columns.push({
      headerName: '',
      component: (item: MemberInsurance) => (
        <div className="flex gap">
          {editInsurance && (
            <IconButton
              className="no-padding"
              data-cy="insurance-edit-button"
              onClick={() => editInsurance(item)}
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          {deleteInsurance && (
            <IconButton
              className="no-padding"
              data-cy="insurance-delete-button"
              onClick={() => deleteInsurance(item)}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      ),
      width: 50,
    });
  }

  return columns;
};

const tableStyles = makeStyles({
  eligibilityContainer: {
    display: 'grid',
    fontSize: `calc(${Fonts.fontSize} * .75)`,
  },
  inactive: {
    '&&': {
      backgroundColor: `${BackgroundColors.lightGray}!important`,
    },
  },
  row: {
    '&&': {
      padding: '6px',
    },
  },
  table: {
    marginBottom: '2rem',
  },
});

const MemberInsurancesTable = (props: TableProps) => {
  const styles = tableStyles();
  return (
    <Table
      className={styles.table}
      config={{
        columns: MEMBER_INSURANCE_COLUMNS(props, styles),
        compact: true,
        data: props.insurances,
        getRowClass: () => {
          return classNames(styles.row, props.isInactive && styles.inactive);
        },
        paginationOptions: [-1],
        size: 'm',
      }}
      data-cy="referral-list"
      empty={props.emptyState}
    />
  );
};

const MemberInsurances = ({ member, onLoading }: Props) => {
  const styles = useStyles();
  const { enableInsurancesProtection } = useFlags();

  const [insurances, setInsurances] = useState<MemberInsurance[]>();
  const [pastInsurances, setPastInsurances] = useState<MemberInsurance[]>();
  const [refresh, setRefresh] = useState(0);
  const [editing, setEditing] = useState<MemberInsurance>();
  const [showDisabledRows, setShowDisabledRows] = useState(false);
  const [loadingPushEligibility, setLoadingPushEligibility] = useState<
    boolean
  >();
  const [disableViewQualifications, setDisableViewQualifications] = useState<
    boolean
  >(false);
  const [openViewQualifications, setOpenViewQualifications] = useState<boolean>(
    false,
  );
  const [deleteInsuranceModal, setDeleteInsuranceModal] = useState<
    MemberInsurance
  >();

  const canEditInsurance = useMemo(
    () =>
      (Session.actingUser.isInBilling || Session.actingUser.isInEligibility) &&
      member.isEditable() &&
      (!enableInsurancesProtection || member.isInsuranceEditable()),
    [Session.actingUser, member.status, enableInsurancesProtection],
  );

  const canRunEligibility = useMemo(
    () =>
      (Session.actingUser.isInBilling ||
        Session.actingUser.isInEligibility ||
        Session.actingUser.isInEnrollment) &&
      member.isEditable() &&
      (!enableInsurancesProtection || member.isInsuranceEditable()),
    [Session.actingUser, member.status, enableInsurancesProtection],
  );

  const addInsurance = () => {
    setEditing(new MemberInsurance({}));
  };

  const editInsurance = (insurance: MemberInsurance) => {
    setEditing(insurance);
  };

  const removeInsurance = async (insurance: MemberInsurance) => {
    insurance.discontinuedAt = moment().unix();
    const data = {
      ...insurance,
      insurancePlanId: insurance.insurancePlan.id,
      insurancePriorityId: insurance.insurancePriority.id,
    };
    await updateMemberInsurance(member.id, insurance.id, data);
    doRefresh();
  };

  const viewQualifications = () => {
    setOpenViewQualifications(true);
  };

  const pushMemberEligibility = async () => {
    setLoadingPushEligibility(true);
    try {
      const insurances: MemberInsurance[] = await callPushEligibilityCheck(
        member.id,
      );
      setInsurances(
        insurances
          .filter((item) => !item.discontinuedAt)
          .sort((a, b) => a.insurancePriority.id - b.insurancePriority.id),
      );
      setPastInsurances(
        insurances
          .filter((item) => !!item.discontinuedAt)
          .sort(
            (a, b) =>
              moment(b.discontinuedAt).unix() - moment(a.discontinuedAt).unix(),
          ),
      );

      const qualification = await fetchMemberInsuranceQualification(member.id);
      setDisableViewQualifications(!qualification);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingPushEligibility(false);
  };

  const doEdit = async (data: InsuranceData) => {
    let result = true;

    try {
      if (!data.id) {
        await createMemberInsurance(member.id, data);

        doRefresh();
      } else {
        await updateMemberInsurance(member.id, data.id, data);
      }

      setEditing(undefined);
      doRefresh();
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    return result;
  };

  const doRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    onLoading(true);
    Promise.all([
      getMemberInsurances(member.id),
      fetchMemberInsuranceQualification(member.id),
    ]).then(([insurances, qualification]) => {
      setInsurances(
        insurances
          .filter((item) => !item.discontinuedAt)
          .sort((a, b) => a.insurancePriority.id - b.insurancePriority.id),
      );
      setPastInsurances(
        insurances
          .filter((item) => !!item.discontinuedAt)
          .sort(
            (a, b) =>
              moment(b.discontinuedAt).unix() - moment(a.discontinuedAt).unix(),
          ),
      );

      setDisableViewQualifications(!qualification);

      onLoading(false);
    });
  }, [member, refresh]);

  return (
    <>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{translate('memberInsurances.title')}</h2>
        <div className={styles.buttonContainer}>
          <Button
            className="btn-add profile-add"
            color="info"
            data-cy="view-qualifications-button"
            disabled={disableViewQualifications}
            icon={<RemoveRedEyeIcon />}
            onClick={viewQualifications}
            size="s"
            type="outlined"
          >
            {translate('memberInsurances.viewQualifications')}
          </Button>
          {canRunEligibility && (
            <Button
              data-cy="run-eligibility-button"
              className="btn-add profile-add"
              color="info"
              disabled={!insurances?.find(({ subscriberId }) => !!subscriberId)}
              icon={<BoltIcon />}
              onClick={pushMemberEligibility}
              loading={loadingPushEligibility}
              size="s"
              type="outlined"
            >
              {translate('memberInsurances.runEligibility')}
            </Button>
          )}
          {canEditInsurance && (
            <Button
              data-cy="add-insurance-button"
              className="btn-add profile-add"
              color="info"
              icon={<AddIcon />}
              onClick={addInsurance}
              size="s"
              type="outlined"
            >
              {translate('memberInsurances.add')}
            </Button>
          )}
        </div>
      </div>

      <MemberInsurancesTable
        insurances={insurances || []}
        editInsurance={canEditInsurance ? editInsurance : undefined}
        deleteInsurance={
          canEditInsurance
            ? (insurance) => setDeleteInsuranceModal(insurance)
            : undefined
        }
      />

      {!!pastInsurances?.length && (
        <footer>
          <div style={{ float: 'right' }}>
            <button
              className="h3 null"
              onClick={() => setShowDisabledRows(!showDisabledRows)}
              data-cy="toggle-disabled-insurances"
            >
              <CollapseIcon htmlColor={Colors.gray} open={showDisabledRows} />
              {translate('memberInsurances.history')}
            </button>
          </div>
        </footer>
      )}

      {showDisabledRows && pastInsurances && (
        <MemberInsurancesTable
          data-cy="disabled-insurances"
          insurances={pastInsurances}
          isInactive
        />
      )}

      {editing && (
        <InsuranceEditModal
          insurance={editing}
          onClose={() => setEditing(undefined)}
          onSubmit={doEdit}
        />
      )}
      <InsuranceViewQualificationsModal
        member={member}
        onClose={() => setOpenViewQualifications(false)}
        onSubmit={async (params) => {
          await updateMemberInsuranceQualification(member.id, params);
        }}
        open={openViewQualifications}
      />

      <Modal
        title={translate('memberInsurances.inactivateTitle')}
        body={translate('memberInsurances.confirmInactivateInsuranceBody', {
          name: deleteInsuranceModal?.insurancePlan?.name,
        })}
        submitText={translate('memberInsurances.inactivateConfirmButton')}
        onSubmit={async () => {
          if (deleteInsuranceModal) {
            await removeInsurance(deleteInsuranceModal);
            return true;
          }
        }}
        onClose={() => setDeleteInsuranceModal(undefined)}
        open={!!deleteInsuranceModal}
      />
    </>
  );
};

export default MemberInsurances;
