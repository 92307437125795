import { EmployeeRole } from '../enums';
import { BaseEnum } from './BaseEnum';
import { make, makeArray, makeEnums } from './Model';
import PodRuleInternalTrigger from './PodRuleInternalTrigger';
import { ProgramExtension } from './ProgramExtension';

export class PodRule {
  id = 0;

  active = true;

  name = '';

  delayDaysAfterTrigger = 0;

  externalTriggers?: BaseEnum[];

  frequency: BaseEnum;

  internalTriggers?: PodRuleInternalTrigger[];

  maxAttempts = 0;

  period?: BaseEnum;

  programExtensions: ProgramExtension[];

  roles: EmployeeRole[];

  taskSpacingDays = 0;

  createPeriodIfEmpty = false;

  closeAfterAttemps = false;

  closeAfterDue = false;

  selfTrigger = false;

  flexible = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.externalTriggers = makeArray(obj.externalTriggers, BaseEnum);
    this.frequency = make(obj.frequency, BaseEnum);
    this.internalTriggers = makeArray(
      obj.internalTriggers,
      PodRuleInternalTrigger,
    );
    this.period = make(obj.period, BaseEnum);
    this.programExtensions = makeArray(obj.programExtensions, ProgramExtension);
    this.roles = makeEnums(obj.roles, EmployeeRole);
  }
}

export default PodRule;
