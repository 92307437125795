import React, { useMemo } from 'react';

import { BiometricsType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberView, PaginationType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { MemberExtraInfo, MemberInfo, Table } from 'styleguide-v2';

interface Props {
  members: MemberView[];
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
  pagination?: PaginationType;
}

const MEMBERS_COLUMNS = () => [
  {
    headerName: translate('common.member'),
    component: (member: MemberView) => (
      <MemberInfo
        link={`#/patients/${member?.id}`}
        key={`members-row-${member?.id}-member-info`}
        member={member}
        showHouseHoldMembers
        showLanguage
        showRiskLevel
        showStatus
      />
    ),
    width: 200,
  },
  {
    headerName: ' ',
    component: ({ id, activeReferrals, programExtensions }: MemberView) => (
      <MemberExtraInfo
        activeReferrals={activeReferrals}
        key={`members-row-${id}-member-extra-info`}
        memberId={id}
        programExtensions={programExtensions}
      />
    ),
    width: 150,
  },
  {
    headerName: translate('members.carePlanGr'),
    field: 'carePlanGroup.carePlanGroup.description',
    width: 130,
  },
  {
    headerName: translate('members.vestaTeam'),
    component: ({
      id,
      careCoordinators,
      engagementOwners,
      healthCoaches,
      npOwner,
      podManager,
      owner,
    }: MemberView) => (
      <div key={`member-${id}-vesta-team`} className="grid-wrapper fit">
        <div className="grid-span-5">
          <p>
            <span className="bold">
              {translate('members.podManager')}:&nbsp;
            </span>
            <span>{podManager?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">{translate('members.npOwner')}:&nbsp;</span>
            <span>{npOwner?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">{translate('members.rnOwner')}:&nbsp;</span>
            <span>{owner?.fullName || EMPTY}</span>
          </p>
        </div>
        <div className="grid-span-7">
          <p>
            <span className="bold">
              {translate('members.careCoordinators')}:&nbsp;
            </span>
            <span>
              {careCoordinators?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('members.healthCoaches')}:&nbsp;
            </span>
            <span>
              {healthCoaches?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('members.engagementOwners')}:&nbsp;
            </span>
            <span>
              {engagementOwners?.map(({ fullName }) => fullName)?.join(', ') ||
                EMPTY}
            </span>
          </p>
        </div>
      </div>
    ),
  },
  {
    headerName: translate('members.vitals'),
    component: ({ vitalsConfigs }: MemberView, rowIndex: number) => {
      const methodSet: { [x: string]: string[] } = {};
      vitalsConfigs?.forEach(({ collectionMethod, type }) => {
        if (collectionMethod?.value) {
          if (!methodSet[collectionMethod?.value]) {
            methodSet[collectionMethod?.value] = [];
          }
          if (type === BiometricsType.BP_DIASTOLIC) {
            methodSet[collectionMethod?.value].push(translate('members.bp'));
          } else if (type !== BiometricsType.BP_SYSTOLIC) {
            methodSet[collectionMethod?.value].push(type.toString());
          }
        }
      });
      return (
        <>
          {Object.keys(methodSet).length !== 0
            ? Object.keys(methodSet).map((item) => (
                <p key={`collection-method-${rowIndex}-${item}`}>
                  <span className="medium">
                    {translate(`enums.collectionMethod.${item}`)}:&nbsp;
                  </span>
                  <span>{methodSet[item].join(', ')}</span>
                </p>
              ))
            : EMPTY}
        </>
      );
    },
  },
];

export const MembersDataTable = ({
  members,
  onChangePage,
  onChangePageSize,
  pagination,
}: Props) => {
  const columns = useMemo(() => MEMBERS_COLUMNS(), []);

  return (
    <Table
      config={{
        columns,
        data: members,
        getRowBrand: ({ brand }: MemberView) => brand,
        pagination,
      }}
      fontSize="small"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
    />
  );
};

export default MembersDataTable;
