import { startCase } from 'lodash';

import { Selectable } from '../enums/Enum';

export class DashPermission {
  id = 0;

  name = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get prettyName() {
    return startCase(this.name.toLowerCase().replace(/_/g, ' '));
  }

  static toSelectable(permissions: DashPermission[]): Selectable[] {
    return permissions.map((p) => ({
      value: p.id,
      label: p.name,
    }));
  }
}

export default DashPermission;
