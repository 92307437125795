import React, { useEffect, useState } from 'react';
import { List, arrayMove } from 'react-movable';

import { makeStyles } from '@material-ui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import {
  BackgroundColors,
  CollapseIcon,
  Colors,
  IconButton,
  Select,
} from 'styleguide-v2';
import ButtonModal from 'styleguide/src/components/Modal/ButtonModal';

type CheckType = boolean | ((item: TaskDefinition) => boolean);

type Props = {
  className?: string;
  color?: 'primary' | 'secondary';
  'data-cy'?: string;
  getLabel?: (item: TaskDefinition) => string;
  isEditable?: CheckType;
  items: TaskDefinition[];
  onChange: (items: TaskDefinition[]) => void;
  onEditItem?: (item: TaskDefinition) => void;
  onAddRule: (item: TaskDefinition) => void;
  onRemoveRule: (item: TaskDefinition) => void;
  onChangeRule: (item: TaskDefinition) => void;
  placeholder?: string;
  submitted?: boolean;
};

const useStyles = makeStyles({
  row: {
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : BackgroundColors.lighterGray,
    margin: '5px 0',
    padding: '5px',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1px 2px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rowWrapper: {
    display: 'flex',
  },
  icon: {
    color: Colors.lightGray,
    width: '20px',
    margin: '5px 5px',
    padding: '5px',
  },
  input: {
    alignItems: 'center',
    border: 0,
    background: ({ color }: Props) =>
      color === 'secondary' ? Colors.white : 'transparent',
    display: 'inline-flex',
    flex: 'auto',
    height: '34px',
  },
  inputError: {
    border: `${Colors.textRed2} 2px solid`,
    borderRadius: '5px',
  },
  collapsedWrapper: {
    width: '100%',
    display: 'flex',
  },
  collapsedContent: {
    padding: '5px',
  },
  collapsedRow: {
    padding: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputRow: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row',
    width: '100%',
  },
  collapsableSelect: {
    width: '100%',
  },
});

export const SubTaskSortableList = (props: Props) => {
  const styles = useStyles(props);
  const {
    className,
    'data-cy': dataCy,
    getLabel,
    items,
    isEditable,
    onAddRule,
    onRemoveRule,
    onChangeRule,
    onChange,
    onEditItem,
    placeholder,
    submitted,
  } = props;
  const [list, setList] = useState<TaskDefinition[]>(items || []);
  const [isCollapsed, setIsCollapsed] = useState<Boolean[]>([]);

  useEffect(() => {
    setList([...items]);
  }, [items]);

  useEffect(() => {
    setIsCollapsed(items.map(() => true));
  }, []);

  const removeItem = (index?: number) => {
    list.splice(index || 0, 1);
    setList([...list]);
    onChange([...list]);
  };

  const removeTaskPrecedent = (
    index: number,
    definition: TaskDefinition,
    collapsedIndex: number,
  ) => {
    const newDefinition = definition;
    newDefinition.taskPrecedentIds?.splice(index, 1);
    if (newDefinition.taskPrecedentIds?.length === 0)
      setIsCollapsed(
        isCollapsed.map((o, i) => (i === collapsedIndex ? true : o)),
      );
    onRemoveRule(newDefinition);
  };

  const changeRule = (
    definition: TaskDefinition,
    index: number,
    taskId: number,
  ) => {
    const newDefinition = definition;
    if (newDefinition.taskPrecedentIds)
      newDefinition.taskPrecedentIds[index] = taskId;
    onChangeRule(newDefinition);
  };

  const isCollapsable = (definition: TaskDefinition) => {
    return definition.taskPrecedentIds
      ? definition.taskPrecedentIds.length > 0
      : false;
  };

  const isRemovable = (definition: TaskDefinition) => {
    return definition.new;
  };

  const needConfirmation = (definition: TaskDefinition) => {
    return definition.taskPrecedentIds
      ? definition.taskPrecedentIds.length > 0
      : false;
  };

  const handleCollapsed = (index: number) => {
    setIsCollapsed(
      isCollapsed.map((o, i) => (i === index ? !isCollapsed[index] : o)),
    );
  };

  const addRule = (value: TaskDefinition, index: number) => {
    if (isCollapsed[index]) handleCollapsed(index);
    onAddRule(value);
  };
  return (
    <List
      lockVertically
      values={list}
      onChange={({ oldIndex, newIndex }) => {
        setList([...arrayMove(list, oldIndex, newIndex)]);
        onChange([...arrayMove(list, oldIndex, newIndex)]);
      }}
      renderList={({ children, props }) => (
        <div data-cy={dataCy} className={className} {...props}>
          {children}
        </div>
      )}
      renderItem={({ value: definition, props, isDragged, index = 0 }) => {
        const row = (
          <div {...props} className={styles.row} style={{ zIndex: 1 }}>
            <div className={styles.rowWrapper}>
              <i
                data-movable-handle
                className={`${styles.icon} fa fa-bars`}
                style={{
                  cursor: isDragged ? 'grabbing' : 'grab',
                }}
              />
              <div className={styles.collapsedWrapper} tabIndex={index}>
                {isEditable ? (
                  <input
                    autoComplete="disabled"
                    className={`${styles.input} ${
                      submitted && !definition.name ? styles.inputError : ''
                    }`}
                    placeholder={placeholder || translate('global.name')}
                    onChange={(e) => {
                      const name = e.target.value || '';
                      const newList = [...list];
                      newList[index] = { ...newList[index], name };
                      setList([...newList]);
                      onChange([...newList]);
                    }}
                    value={definition.name}
                    type="text"
                  />
                ) : (
                  <span className={styles.input}>
                    {getLabel
                      ? getLabel(definition)
                      : definition.name ||
                        placeholder ||
                        translate('global.name')}
                  </span>
                )}
                {items.length > 1 &&
                  definition.taskPrecedentIds &&
                  !definition.new && (
                    <IconButton
                      disabled={
                        definition.taskPrecedentIds.length === items.length - 1
                      }
                      onClick={() => addRule(definition, index)}
                      tooltip={translate('taskDefinitions.addRule')}
                      size="small"
                    >
                      <AddIcon color="info" />
                    </IconButton>
                  )}
                {onEditItem && (
                  <IconButton
                    className={`grid-span-1 ${styles.icon}`}
                    onClick={() => onEditItem(definition)}
                    size="small"
                  >
                    <EditIcon color="info" />
                  </IconButton>
                )}
                {isRemovable(definition) &&
                  (needConfirmation(definition) ? (
                    <ButtonModal
                      iconComponent={<DeleteIcon color="info" />}
                      modalTitle={translate('global.confirmRemove')}
                      onSubmit={() => removeItem(index)}
                    >
                      {translate(
                        'taskDefinition.subtask.inactiveWithPrecedent',
                      )}
                    </ButtonModal>
                  ) : (
                    <IconButton
                      className={`grid-span-1 ${styles.icon}`}
                      onClick={() => removeItem(index)}
                      size="small"
                    >
                      <DeleteIcon color="info" />
                    </IconButton>
                  ))}
                {isCollapsable(definition) && (
                  <IconButton
                    onClick={() => handleCollapsed(index)}
                    size="small"
                  >
                    <CollapseIcon open={!isCollapsed[index]} color="info" />
                  </IconButton>
                )}
              </div>
            </div>
            {!isCollapsed[index] && (
              <div className={styles.collapsedContent}>
                {definition.taskPrecedentIds?.map(
                  (precedent, precedentIndex) => {
                    const itemsMapped = items
                      .filter(
                        (current) =>
                          current.id !== definition.id && !current.new,
                      )
                      .map((o) => ({
                        label:
                          o.name || translate('taskDefinitions.subtask.name'),
                        value: o.id,
                      }));
                    return (
                      <div
                        className={styles.collapsedRow}
                        key={`precedent_${precedentIndex}`}
                      >
                        <div className={styles.inputRow}>
                          <Select
                            className={styles.collapsableSelect}
                            onChange={(task: Selectable) => {
                              changeRule(
                                definition,
                                precedentIndex,
                                task?.value as number,
                              );
                            }}
                            items={itemsMapped}
                            label={translate(
                              'taskDefinitions.subtask.dependsOn',
                            )}
                            required
                            value={itemsMapped?.find(
                              ({ value }) => value === precedent,
                            )}
                          />
                          <IconButton
                            className={`grid-span-1 ${styles.icon}`}
                            onClick={() =>
                              removeTaskPrecedent(
                                precedentIndex,
                                definition,
                                index,
                              )
                            }
                            size="small"
                          >
                            <DeleteIcon
                              color="info"
                              sx={{ marginTop: '2rem' }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
        );
        return isDragged ? (
          <div
            // eslint-disable-next-line react/prop-types
            style={{ ...props.style, borderSpacing: 5 }}
          >
            {row}
          </div>
        ) : (
          row
        );
      }}
    />
  );
};

export default SubTaskSortableList;
