import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Enum,
  Language,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Organization,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import {
  LS_DASHBOARD_TASK_FILTERS,
  LS_DASHBOARD_TASK_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CheckboxWithLabel,
  FilterButton,
  FilterItem,
  Panel,
  SearchInput,
  Select,
  Spinner,
  Tabs,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';

import AddTaskButton from 'dash/src/pages/Tasks/AddTaskButton';
import TaskCompleteDetails from 'dash/src/pages/Tasks/TaskCompleteDetails';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';
import {
  TaskFetchParams,
  fetchAllTasks,
  fetchTask,
} from 'dash/src/services/TaskServices';
import {
  transformDateToDaysRange,
  transformDaysRangeToDates,
} from 'dash/src/utils/dateUtils';
import {
  getAsQuery,
  getBoolean,
  getCustom,
  getDateRange,
  getFiltersEnabled,
  getSafeValues,
  getServerFilters,
  getStoredFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { EVENTS_PAGE_TAB_OPEN } from '../Events/constants';
import ClinicalTaskTable, { TaskTable } from './ClinicalTaskTable';
import { TaskFiltersBar } from './TaskFiltersBar';
import { TaskFiltersModal } from './TaskFiltersModal';

const INITIAL_PAGE_SIZE = 50;
const DEFAULT_SORTING = 'dueDate asc';

const UNASSIGNED = new Employee({ fullName: translate('global.unassigned') });

const TASKS_PAGE_TAB_ASSIGNED_ME = 'tab-assigned';
const TASKS_PAGE_TAB_OPEN = 'tab-open';
const TASKS_PAGE_TAB_CLOSED = 'tab-closed';
const TASKS_PAGE_TAB_ALL = 'tab-all';

const TASK_PAGE_TABS = [
  {
    label: translate('clinicalDashboard.tasks.tabs.all'),
    value: TASKS_PAGE_TAB_ALL,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.open'),
    value: TASKS_PAGE_TAB_OPEN,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.closed'),
    value: TASKS_PAGE_TAB_CLOSED,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.assigned'),
    value: TASKS_PAGE_TAB_ASSIGNED_ME,
  },
];

const PROGRAM_EXTENSION_UNASSIGNED = {
  label: translate('global.unassigned'),
  value: -1,
};

const useStyles = makeStyles({
  check: {
    alignSelf: 'center',
  },
  dateRange: {
    marginTop: '-2.5rem',
  },
  filterButton: {
    marginTop: '.25rem',
  },
  filters: {
    alignItems: 'flex-start',
  },
  search: {
    alignSelf: 'center',
    minWidth: '45rem',
  },
  staticFilters: {
    alignItems: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
    marginLeft: '10.55rem',
  },
});

type TaskFilters = {
  assignee?: number[];
  brand?: string[];
  completedAtFrom?: number;
  completedAtTo?: number;
  createdAtFrom?: number;
  createdAtTo?: number;
  createdBy?: number[];
  dueDateFrom?: number;
  dueDateTo?: number;
  group?: number[];
  hasProgramExtensions?: boolean;
  language?: Language[];
  organization?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  queryString?: string;
  selectedTab?: string;
  status?: TaskStatus[];
  subTasksOnly: boolean;
  topLevelOnly: boolean;
  type?: number[];
  vitalCollectionMethod?: CollectionMethod[];
};

type KeyGetTaskParams = keyof TaskFetchParams;

const getFilters = (query: URLSearchParams) => {
  const filters = getStoredFilters(LS_DASHBOARD_TASK_FILTERS);
  const defaultCompletedDateRange =
    getDateRange(query, filters, 'completedDateRange') || -1;

  const defaultCreatedDateRange =
    getDateRange(query, filters, 'createdDateRange') || -1;
  const defaultDueDateRange =
    getDateRange(query, filters, 'dueDateRange') || -1;
  const subTasksOnly = getBoolean(query, filters, 'subTasksOnly') || false;
  const topLevelOnly = getBoolean(query, filters, 'topLevelOnly') || true;
  const hasProgramExtensions = getBoolean(
    query,
    filters,
    'hasProgramExtensions',
  );

  const completedAt = transformDaysRangeToDates(defaultCompletedDateRange);
  const createdAt = transformDaysRangeToDates(defaultCreatedDateRange);
  const dueDate = transformDaysRangeToDates(defaultDueDateRange);

  return {
    assignee: getCustom(query.getAll('assignee'), filters.assignee, (item) =>
      Number(item),
    ) as number[],
    brand: getCustom(query.getAll('brandId'), filters.brand),
    createdBy: getCustom(query.getAll('createdBy'), filters.createdBy, (item) =>
      Number(item),
    ) as number[],
    completedAtFrom: completedAt[0]?.getTime(),
    completedAtTo: completedAt[1]?.getTime(),
    createdAtFrom: createdAt[0]?.getTime(),
    createdAtTo: createdAt[1]?.getTime(),
    dueDateFrom: dueDate[0]?.getTime(),
    dueDateTo: dueDate[1]?.getTime(),
    defaultCompletedDateRange,
    defaultCreatedDateRange,
    defaultDueDateRange,
    group: getCustom(query.getAll('group'), filters.group, (item) =>
      Number(item),
    ) as number[],
    hasProgramExtensions,
    language: getCustom(
      query.getAll('language'),
      filters.language,
      (item) => Language.byKey[item],
    ) as Language[],
    organization: getCustom(
      query.getAll('organization'),
      filters.organization,
      (item) => Number(item),
    ) as number[],
    programExtensionId: getCustom(
      query.getAll('programExtensionId'),
      filters.programExtensionId,
      (item) => Number(item),
    ) as number[],
    programExtensionStatus: getCustom(
      query.getAll('programExtensionStatus'),
      filters.programExtensionStatus,
    ) as string[],
    queryString: query.get('queryString') || filters.queryString,
    selectedTab:
      query.get('selectedTab') || filters.selectedTab || TASKS_PAGE_TAB_ALL,
    sort: query.get('sort') || filters.sort || DEFAULT_SORTING,
    status: getCustom(
      query.getAll('status'),
      filters.status,
      (item) => TaskStatus.byKey[item],
    ) as TaskStatus[],
    subTasksOnly,
    topLevelOnly,
    type: getCustom(query.getAll('type'), filters.type, (item) =>
      Number(item),
    ) as number[],
    vitalCollectionMethod: getCustom(
      query.getAll('vitalCollectionMethod'),
      filters.vitalCollectionMethod,
      (item) => CollectionMethod.byKey[item],
    ) as CollectionMethod[],
  };
};

type Props = {
  taskId?: string;
};

export const ClinicalTasks = ({
  match: {
    params: { taskId },
  },
}: RouteComponentProps<Props>) => {
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();
  const { showFiltersRefactorTasks } = useFlags();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [isOpenFilters, setOpenFilters] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const refCreated = useRef<ToggleDateRangeHandle>(null);
  const refCompleted = useRef<ToggleDateRangeHandle>(null);
  const refDue = useRef<ToggleDateRangeHandle>(null);

  const [types, setTypes] = useState<TaskDefinition[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [supportedLanguages, setLanguages] = useState<Language[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    Selectable[]
  >([]);

  const {
    defaultCreatedDateRange,
    defaultCompletedDateRange,
    defaultDueDateRange,
    selectedTab: st,
    sort: defaultSort,
    ...rest
  } = getFilters(query);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [pagination, setPagination] = useState<PaginationType>();
  const [sort, setSort] = useState<string>(defaultSort);

  const [selectedTab, setSelectedTab] = useState<string>(st);
  const [filters, setFilters] = useState<TaskFilters>(rest as TaskFilters);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetTaskParams]?: FilterItem;
    }
  >();
  const [disabledFilters, setDisabledFilters] = useState<KeyGetTaskParams[]>(
    [],
  );

  const {
    topLevelOnly,
    subTasksOnly,
    createdAtFrom,
    createdAtTo,
    completedAtFrom,
    completedAtTo,
    dueDateFrom,
    dueDateTo,
    hasProgramExtensions,
    queryString,
    ...countFilters
  } = filters;
  const [tasks, setTasks] = useState<TaskTable[]>([]);

  const getTabFilters = (tab: string) => {
    const dateFilters = {
      completedAtFrom: filters?.completedAtFrom
        ? moment(filters?.completedAtFrom).startOf('day').format()
        : undefined,
      completedAtTo: filters?.completedAtTo
        ? moment(filters?.completedAtTo).endOf('day').format()
        : undefined,
      createdAtFrom: filters?.createdAtFrom
        ? moment(filters?.createdAtFrom).startOf('day').format()
        : undefined,
      createdAtTo: filters?.createdAtTo
        ? moment(filters?.createdAtTo).endOf('day').format()
        : undefined,
      dueDateFrom: filters?.dueDateFrom
        ? moment(filters?.dueDateFrom).startOf('day').format()
        : undefined,
      dueDateTo: filters?.dueDateTo
        ? moment(filters?.dueDateTo).endOf('day').format()
        : undefined,
    };
    const commonFilters: TaskFetchParams = showFiltersRefactorTasks
      ? {}
      : {
          assigneeId: filters?.assignee?.length ? filters.assignee : [],
          brandId: filters?.brand?.length ? filters.brand : [],
          createdById: filters?.createdBy?.length ? filters.createdBy : [],
          employeeGroupId: filters?.group?.length ? filters.group : [],
          language: filters?.language?.length
            ? filters.language.map(({ value }) => value)
            : [],
          memberId: [],
          queryString: filters.queryString || undefined,
          referralSourceId: filters?.organization?.length
            ? filters.organization
            : [],
          status: filters?.status?.length
            ? filters.status.map(({ value }) => value)
            : [],
          subTasksOnly:
            filters.topLevelOnly && filters.subTasksOnly
              ? false
              : filters.subTasksOnly,
          taskDefinitionId: filters?.type?.length ? filters.type : [],
          taskDefinitionOutcomeId: [],
          topLevelOnly:
            filters.topLevelOnly && filters.subTasksOnly
              ? false
              : filters.topLevelOnly,
          hasProgramExtensions: filters.hasProgramExtensions,
          programExtensionId: filters.programExtensionId,
          programExtensionStatus: filters.programExtensionStatus,
          vitalCollectionMethod: filters.vitalCollectionMethod?.map(
            ({ value }) => value,
          ),
        };
    if (tab === TASKS_PAGE_TAB_OPEN) {
      const {
        completedAtFrom,
        completedAtTo,
        ...restDateFilters
      } = dateFilters;
      return {
        ...commonFilters,
        ...restDateFilters,
        status: TaskStatus.getNotClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_CLOSED) {
      return {
        ...commonFilters,
        ...dateFilters,
        status: TaskStatus.getClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_ASSIGNED_ME) {
      return {
        ...commonFilters,
        ...dateFilters,
        status: TaskStatus.getNotClosedStatuses(),
        assigneeId: [Session.actingUser.id],
      };
    }
    return {
      ...commonFilters,
      ...dateFilters,
    };
  };

  const getInitialData = async () => {
    setLoadingFilters(true);
    const [
      types,
      groups,
      employees,
      organizations,
      languages,
      brands,
      programExtensions,
      programExtensionStatuses,
    ] = await Promise.all([
      CacheServices.getTaskDefinitions(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getEmployees(),
      CacheServices.getOrganizations(),
      fetchSupportedLanguages(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
    ]);
    setTypes(types || []);
    setGroups(groups || []);
    setEmployees(employees || []);
    setOrganizations(
      organizations.filter(({ hasValidDate }) => hasValidDate) || [],
    );
    setLanguages(languages);
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setProgramExtensions([
      PROGRAM_EXTENSION_UNASSIGNED as Selectable,
      ...ProgramExtension.toSelectable(programExtensions),
    ]);
    setProgramExtensionStatuses(
      ProgramExtensionStatus.toSelectable(programExtensionStatuses),
    );
    setLoadingFilters(false);
  };

  const getTasks = async () => {
    setLoading(true);
    let currentFilters: TaskFetchParams;
    if (showFiltersRefactorTasks) {
      if (!newFilters) return;
      const { queryString, ...otherFilters } = getServerFilters(newFilters);
      currentFilters = {
        ...otherFilters,
        ...getTabFilters(selectedTab),
        queryString,
      };

      if (currentFilters.programExtensionId?.length) {
        const filteredPEs = currentFilters.programExtensionId?.filter(
          (id) => id !== -1,
        );
        currentFilters.hasProgramExtensions =
          filteredPEs.length === currentFilters.programExtensionId.length;
        currentFilters.programExtensionId = filteredPEs;
      }
      currentFilters.subTasksOnly =
        filters.topLevelOnly && filters.subTasksOnly
          ? false
          : filters.subTasksOnly;
      currentFilters.topLevelOnly =
        filters.topLevelOnly && filters.subTasksOnly
          ? false
          : filters.topLevelOnly;
    } else {
      currentFilters = getTabFilters(selectedTab);
    }
    const { items, pagination } = await fetchAllTasks({
      ...currentFilters,
      offset: page * pageSize,
      limit: pageSize,
      sort,
    });
    if (page === 0) {
      setTasks(items);
    } else {
      setTasks([...tasks, ...items]);
    }
    setPagination(pagination);
    setLoading(false);
  };

  const getTaskDetails = async (task: TaskTable, index: number) => {
    const taskItem = tasks[index] || {};
    if (taskItem.open) {
      delete taskItem.detail;
      taskItem.open = false;
    } else {
      taskItem.open = true;
      taskItem.detail = (
        <div className="flex center">
          <Spinner />
        </div>
      );
      setTasks([...tasks]);
      const taskDetail = await fetchTask(task.id);
      tasks[index] = {
        ...taskDetail,
        open: true,
        detail: (
          <TaskCompleteDetails
            task={taskDetail}
            onUpdate={(newInfo) => {
              tasks[index] = { ...tasks[index], ...newInfo } as TaskTable;
              setTasks([...tasks]);
            }}
            showSubtask={!!taskId}
          />
        ),
      } as TaskTable;
    }
    setTasks([...tasks]);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (taskId) {
      getTaskDetails({ id: Number(taskId) } as TaskTable, 0);
    } else {
      getTasks();
    }
  }, [newFilters, filters, page, pageSize, selectedTab, sort]);

  useEffect(() => {
    const {
      completedAtFrom,
      completedAtTo,
      createdAtFrom,
      createdAtTo,
      dueDateFrom,
      dueDateTo,
      language,
      programExtensionStatus,
      status,
      vitalCollectionMethod,
      ...storedFilters
    } = filters;

    const flatFilters = {
      createdDateRange:
        createdAtFrom && createdAtTo
          ? transformDateToDaysRange(
              new Date(createdAtFrom),
              new Date(createdAtTo),
              [7, 30],
            )
          : -1,
      completedDateRange:
        completedAtFrom && completedAtTo
          ? transformDateToDaysRange(
              new Date(completedAtFrom),
              new Date(completedAtTo),
              [7, 30],
            )
          : -1,
      dueDateRange:
        dueDateFrom && dueDateTo
          ? transformDateToDaysRange(
              new Date(dueDateFrom),
              new Date(dueDateTo),
              [7, 30],
            )
          : -1,
      language: language?.map(({ value }) => value),
      status: status?.map(({ value }) => value),
      selectedTab,
      sort: sort !== DEFAULT_SORTING ? sort : undefined,
      vitalCollectionMethod: vitalCollectionMethod?.map(({ value }) => value),
      ...storedFilters,
    };

    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);
    saveStoredFilters(LS_DASHBOARD_TASK_FILTERS, flatFilters);
  }, [selectedTab, sort, filters]);

  const filtersData = {
    brands,
    employees: [
      ...Employee.toSelectable([UNASSIGNED]),
      ...Employee.toSelectable(employees),
    ],
    employeeGroups: [...EmployeeGroup.toSelectable(groups)],
    languages: Language.toSelectable(supportedLanguages),
    organizations: Organization.toSelectable(organizations),
    programExtensions,
    programExtensionStatuses,
    status: TaskStatus.toSelectable(TaskStatus.asArray),
    taskDefinitionId: TaskDefinition.toSelectable(types),
  };

  useEffect(() => {
    if (newFilters && showFiltersRefactorTasks) {
      saveNewFiltersToStorage(LS_DASHBOARD_TASK_FILTERS_V2, newFilters);
    }
  }, [newFilters]);

  useEffect(() => {
    if (
      brands?.length &&
      employees?.length &&
      groups?.length &&
      organizations?.length &&
      programExtensions?.length &&
      programExtensionStatuses?.length &&
      supportedLanguages?.length &&
      types?.length
    ) {
      const storedFilters = loadNewFiltersFromStorage(
        LS_DASHBOARD_TASK_FILTERS_V2,
        {
          assigneeId: {
            data: filtersData.employees,
            label: translate('clinicalDashboard.tasks.filters.assignee'),
            multiple: true,
          },
          brandId: {
            data: filtersData.brands,
            label: translate('clinicalDashboard.tasks.filters.brand'),
            multiple: true,
          },
          createdById: {
            data: filtersData.employees,
            label: translate('clinicalDashboard.tasks.filters.createdBy'),
            multiple: true,
          },
          employeeGroupId: {
            data: filtersData.employeeGroups,
            label: translate('clinicalDashboard.tasks.filters.assignee'),
            multiple: true,
          },
          language: {
            data: filtersData.languages,
            label: translate('clinicalDashboard.tasks.filters.language'),
            multiple: true,
          },
          programExtensionId: {
            data: filtersData.programExtensions,
            label: translate(
              'clinicalDashboard.tasks.filters.programExtensions',
            ),
            multiple: true,
          },
          programExtensionStatus: {
            data: filtersData.programExtensionStatuses,
            label: translate(
              'clinicalDashboard.tasks.filters.programExtensionStatus',
            ),
            multiple: true,
          },
          queryString: {
            label: translate('tasks.searchTasks'),
          },
          referralSourceId: {
            data: filtersData.organizations,
            label: translate('clinicalDashboard.tasks.filters.organization'),
            multiple: true,
          },
          subTasksOnly: {
            label: translate('tasks.showAllSubTasks'),
          },
          status: {
            data: filtersData.status,
            label: translate('clinicalDashboard.tasks.filters.status'),
            multiple: true,
          },
          taskDefinitionId: {
            data: filtersData.taskDefinitionId,
            label: translate('clinicalDashboard.tasks.filters.type'),
            multiple: true,
          },
          topLevelOnly: {
            default: true,
            label: translate('tasks.showAllParents'),
          },
          vitalCollectionMethod: {
            data: Enum.toSelectable(CollectionMethod.asArray),
            label: translate(
              'clinicalDashboard.tasks.filters.vitalCollectionMethod',
            ),
            multiple: true,
          },
        } as { [x in KeyGetTaskParams]: any },
      );
      setNewFilters(storedFilters);
    }
  }, [
    brands,
    employees,
    groups,
    organizations,
    programExtensions,
    programExtensionStatuses,
    supportedLanguages,
    types,
  ]);

  useEffect(() => {
    if (filters.organization && filters.organization.length > 1) {
      const lastAddedId = filters.organization[filters.organization.length - 1];
      const lastAdded = organizations.find(({ id }) => id === lastAddedId);
      const newOrganizations = filters.organization.filter(
        (child) => !lastAdded?.hasDescendant(child),
      );
      if (newOrganizations.length < filters.organization.length) {
        setFilters({ ...filters, organization: newOrganizations });
      }
    }
  }, [filters.organization]);

  useEffect(() => {
    const result = [] as KeyGetTaskParams[];
    if ([TASKS_PAGE_TAB_ASSIGNED_ME].includes(selectedTab)) {
      result.push('assigneeId');
      result.push('employeeGroupId');
    }
    if ([TASKS_PAGE_TAB_OPEN, EVENTS_PAGE_TAB_OPEN].includes(selectedTab)) {
      result.push('status');
    }
    setDisabledFilters(result);
  }, [selectedTab]);

  const getAssigneeFilterValue = () => {
    if (selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME) return [Session.actingUser];
    return (
      [UNASSIGNED, ...employees]?.filter(({ id }) =>
        filters.assignee?.includes(id),
      ) || []
    );
  };

  const getStatusFilterValue = () => {
    if (
      selectedTab === TASKS_PAGE_TAB_OPEN ||
      selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME
    )
      return TaskStatus.getNotClosedStatuses().map((x) => TaskStatus.byKey[x]);
    if (selectedTab === TASKS_PAGE_TAB_CLOSED)
      return TaskStatus.getClosedStatuses().map((x) => TaskStatus.byKey[x]);
    return filters.status || [];
  };

  return (
    <Panel>
      <Panel.Heading
        title={translate(
          `clinicalDashboard.tasks.${taskId ? 'detail' : 'title'}`,
        )}
        filtersV2={showFiltersRefactorTasks}
      >
        {!taskId && (
          <>
            <Panel.Filters
              className={classNames('grid-span-12 flex gap', styles.filters)}
            >
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultCreatedDateRange}
                items={[-1, 7, 30, 'custom']}
                label={translate('clinicalDashboard.tasks.filters.createdAt')}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.createdAtFrom ||
                    to?.getTime() !== filters.createdAtTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        createdAtFrom: from.getTime(),
                        createdAtTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        createdAtFrom: undefined,
                        createdAtTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refCreated}
              />
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultDueDateRange}
                items={[-1, 7, 30, 'custom']}
                label={translate('clinicalDashboard.tasks.filters.dueDate')}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.dueDateFrom ||
                    to?.getTime() !== filters.dueDateTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        dueDateFrom: from.getTime(),
                        dueDateTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        dueDateFrom: undefined,
                        dueDateTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refDue}
              />
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultCompletedDateRange}
                disabled={
                  [TASKS_PAGE_TAB_OPEN, TASKS_PAGE_TAB_ASSIGNED_ME].indexOf(
                    selectedTab,
                  ) !== -1
                }
                items={[-1, 7, 30, 'custom']}
                label={translate(
                  'clinicalDashboard.tasks.filters.completedDate',
                )}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.completedAtFrom ||
                    to?.getTime() !== filters.completedAtTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        completedAtFrom: from.getTime(),
                        completedAtTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        completedAtFrom: undefined,
                        completedAtTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refCompleted}
              />
              {!showFiltersRefactorTasks && (
                <FilterButton
                  className={styles.filterButton}
                  data-cy="tasks-filters"
                  filters={{
                    ...countFilters,
                    hasProgramExtensions:
                      hasProgramExtensions === false &&
                      !filters.programExtensionId?.length
                        ? false
                        : undefined,
                  }}
                  onClick={() => setOpenFilters(!isOpenFilters)}
                />
              )}
            </Panel.Filters>
            <Panel.Actions>
              <AddTaskButton
                onSubmit={(memberId, taskId) =>
                  history.push({
                    pathname: memberId
                      ? `/patients/${memberId}/tasks`
                      : `/dashboard/tasks/${taskId}`,
                    state: { taskId },
                  })
                }
                v2
              />
            </Panel.Actions>
            {showFiltersRefactorTasks && (
              <Panel.FilterBar
                onClearFilters={() => {
                  setPage(0);
                  setNewFilters({});
                }}
                onDeleteFilter={(key: string) => {
                  setPage(0);
                  setNewFilters({
                    ...newFilters,
                    [key]: undefined,
                  });
                }}
                onOpenFilters={() => setOpenFilters(!isOpenFilters)}
                chips={getFiltersEnabled(newFilters, filtersData, [
                  ...disabledFilters,
                  'dueDate',
                  'queryString',
                  'subTasksOnly',
                  'topLevelOnly',
                ] as KeyGetTaskParams[])}
                inputs={
                  <TaskFiltersBar
                    filters={newFilters}
                    onChange={(filters) => {
                      setNewFilters(filters);
                      setPage(0);
                    }}
                  />
                }
              />
            )}
            {!showFiltersRefactorTasks && (
              <Panel.Collapse
                static={
                  <div className={classNames('flex gap', styles.staticFilters)}>
                    <SearchInput
                      className={styles.search}
                      label=""
                      onChange={(queryString) => {
                        if (timer) {
                          clearTimeout(timer);
                        }
                        setTimer(
                          setTimeout(() => {
                            if (!queryString) {
                              setFilters({
                                ...filters,
                                queryString: undefined,
                              });
                              setPage(0);
                            } else if (queryString.length > 2) {
                              setFilters({ ...filters, queryString });
                              setPage(0);
                            }
                          }, 300),
                        );
                      }}
                      placeholder={translate('tasks.searchTasks')}
                      value={filters.queryString}
                      size="small"
                    />
                    <CheckboxWithLabel
                      className={styles.check}
                      checked={filters.topLevelOnly}
                      label={translate('tasks.showAllParents')}
                      onChange={() => {
                        setFilters({
                          ...filters,
                          topLevelOnly: !filters.topLevelOnly,
                        });
                        setPage(0);
                      }}
                    />
                    <CheckboxWithLabel
                      className={styles.check}
                      checked={filters.subTasksOnly}
                      label={translate('tasks.showAllSubTasks')}
                      onChange={() => {
                        setFilters({
                          ...filters,
                          subTasksOnly: !filters.subTasksOnly,
                        });
                        setPage(0);
                      }}
                    />
                  </div>
                }
                open={isOpenFilters}
              >
                <div className="grid-wrapper fit">
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-status"
                    disabled={
                      [
                        TASKS_PAGE_TAB_OPEN,
                        TASKS_PAGE_TAB_CLOSED,
                        TASKS_PAGE_TAB_ASSIGNED_ME,
                      ].indexOf(selectedTab) !== -1
                    }
                    items={Enum.toSelectable(TaskStatus.asArray)}
                    limitTags={1}
                    multiple
                    onChange={(items?: Selectable[]) => {
                      setFilters({
                        ...filters,
                        status: items?.length
                          ? items.map(({ value }) => TaskStatus.byKey[value])
                          : undefined,
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.status',
                    )}
                    value={Enum.toSelectable(getStatusFilterValue())}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-type"
                    getItemLabel={({ name }: TaskDefinition) => name}
                    items={types}
                    limitTags={1}
                    multiple
                    onChange={(type?: TaskDefinition[]) => {
                      setFilters({
                        ...filters,
                        type: type?.map(({ id }) => id),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.type',
                    )}
                    value={types?.filter(({ id }) =>
                      filters.type?.includes(id),
                    )}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-group"
                    getItemLabel={({ name }: EmployeeGroup) => name}
                    items={groups}
                    limitTags={1}
                    multiple
                    onChange={(group?: EmployeeGroup[]) => {
                      setFilters({
                        ...filters,
                        group: group?.map(({ id }) => id),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.group',
                    )}
                    value={groups?.filter(({ id }) =>
                      filters.group?.includes(id),
                    )}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-assignee"
                    disabled={selectedTab === TASKS_PAGE_TAB_ASSIGNED_ME}
                    getItemLabel={({ fullName }: Employee) => fullName}
                    items={[UNASSIGNED, ...employees]}
                    limitTags={1}
                    multiple
                    onChange={(assignee?: Employee[]) => {
                      setFilters({
                        ...filters,
                        assignee: assignee?.map(({ id }) => id),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.assignee',
                    )}
                    value={getAssigneeFilterValue()}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-organization"
                    items={organizations}
                    getItemLabel={({ name }: Organization) => name}
                    getItemDisabled={(child) =>
                      !!organizations
                        .filter(({ id }) => filters.organization?.includes(id))
                        ?.reduce(
                          (acc, item) => acc || item.hasDescendant(child.id),
                          false,
                        )
                    }
                    limitTags={1}
                    multiple
                    onChange={(organization?: Organization[]) => {
                      setFilters({
                        ...filters,
                        organization: organization?.map(({ id }) => id),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.organization',
                    )}
                    value={organizations?.filter(({ id }) =>
                      filters.organization?.includes(id),
                    )}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-language"
                    items={Language.toSelectable(supportedLanguages).filter(
                      ({ disabled }) => !disabled,
                    )}
                    grouped={({ preferred }) =>
                      translate(`global.${preferred ? 'preferred' : 'other'}`)
                    }
                    limitTags={1}
                    multiple
                    onChange={(items?: Selectable[]) => {
                      setFilters({
                        ...filters,
                        language: items?.length
                          ? items.map(({ value }) => Language.byKey[value])
                          : undefined,
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.language',
                    )}
                    value={Enum.toSelectable(filters.language || [])}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-createdBy"
                    getItemLabel={({ fullName }: Employee) => fullName}
                    items={employees}
                    limitTags={1}
                    multiple
                    onChange={(createdBy?: Employee[]) => {
                      setFilters({
                        ...filters,
                        createdBy: createdBy?.map(({ id }) => id),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.createdBy',
                    )}
                    value={employees?.filter(({ id }) =>
                      filters.createdBy?.includes(id),
                    )}
                    size="small"
                  />
                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-brand"
                    items={brands}
                    limitTags={1}
                    multiple
                    onChange={(brand?: Selectable[]) => {
                      setFilters({
                        ...filters,
                        brand: brand?.map(({ value }) => value as string),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.brand',
                    )}
                    size="small"
                    value={brands.filter(({ value }) =>
                      filters.brand?.includes(value as string),
                    )}
                  />

                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-program-extensions"
                    items={programExtensions}
                    limitTags={1}
                    loading={loading}
                    multiple
                    onChange={(values: Selectable[]) => {
                      setFilters({
                        ...filters,
                        hasProgramExtensions: values?.length
                          ? !values.find((v) => v.value === -1)
                          : undefined,
                        programExtensionId: values
                          .map((v) => v.value as number)
                          .filter((id) => id !== -1),
                      });
                      setPage(0);
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.programExtensions',
                    )}
                    size="small"
                    value={[
                      ...(filters.hasProgramExtensions === false
                        ? [PROGRAM_EXTENSION_UNASSIGNED]
                        : []),
                      ...getSafeValues(
                        programExtensions,
                        filters.programExtensionId,
                      ),
                    ]}
                  />

                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-program-extension-status"
                    items={filtersData.programExtensionStatuses}
                    limitTags={1}
                    multiple
                    onChange={(status: Selectable[]) => {
                      setFilters({
                        ...filters,
                        programExtensionStatus: status?.map(
                          ({ value }) => value as string,
                        ),
                      });
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.programExtensionStatus',
                    )}
                    size="small"
                    value={filtersData.programExtensionStatuses?.filter(
                      ({ value }) =>
                        filters?.programExtensionStatus?.includes(
                          value as string,
                        ),
                    )}
                  />

                  <Select
                    className="grid-span-3"
                    data-cy="tasks-filter-vital-collection-method"
                    items={CollectionMethod.toVendorSelectable().sort(
                      Enum.sort,
                    )}
                    limitTags={1}
                    multiple
                    onChange={(values: Selectable[]) => {
                      const status = values.map(
                        (v) => CollectionMethod.byKey[v.value],
                      );
                      setFilters({
                        ...filters,
                        vitalCollectionMethod: status,
                      });
                    }}
                    placeholder={translate(
                      'clinicalDashboard.tasks.filters.vitalCollectionMethod',
                    )}
                    size="small"
                    value={Enum.toSelectable(
                      filters.vitalCollectionMethod || [],
                    )}
                  />

                  <Button
                    className="grid-span-3"
                    data-cy="tasks-clear-filters"
                    color="secondary"
                    type="outlined"
                    onClick={() => {
                      setPage(0);
                      setFilters({
                        topLevelOnly: filters.topLevelOnly,
                        subTasksOnly: filters.subTasksOnly,
                      });
                      setTimeout(() => {
                        refCreated.current?.init();
                        refCompleted.current?.init();
                        refDue.current?.init();
                      }, 0);
                    }}
                    size="s"
                  >
                    {translate('global.clearFilters')}
                  </Button>
                </div>
              </Panel.Collapse>
            )}
            <Panel.Tabs>
              <Tabs
                items={TASK_PAGE_TABS}
                disabled={loading}
                onChange={(tab) => {
                  setSelectedTab(tab);
                  setTasks([]);
                  setPage(0);
                }}
                value={selectedTab}
              />
            </Panel.Tabs>
          </>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {showFiltersRefactorTasks && (
          <TaskFiltersModal
            data={filtersData}
            disabledFilters={disabledFilters}
            filters={newFilters}
            loadingFilters={loadingFilters}
            open={isOpenFilters}
            onChange={(filters) => {
              setPage(0);
              setNewFilters(filters);
            }}
            onClose={() => setOpenFilters(false)}
          />
        )}
        <ClinicalTaskTable
          defaultSort={
            defaultSort !== DEFAULT_SORTING ? defaultSort : undefined
          }
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onClickRow={getTaskDetails}
          onDefaultSort={() => setSort(DEFAULT_SORTING)}
          onSort={(field, direction) => setSort(`${field} ${direction}`)}
          pagination={pagination}
          tasks={tasks}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ClinicalTasks;
