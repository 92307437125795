import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee } from '@vestahealthcare/common/models';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';

import { Colors, IconButton, Label, Select } from 'styleguide-v2';

type Props = {
  className?: string;
  error?: boolean;
  items: SelectableInfo<Employee>[];
  onChange: (items: SelectableInfo<PodConfigEmployeeSlot>[]) => void;
  label: string;
  loading?: boolean;
  role: string;
  value: SelectableInfo<PodConfigEmployeeSlot>[];
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  icon: {
    margin: '1rem auto auto',
  },
  addEmployeeIcon: {
    marginTop: '-0.25rem',
  },
  addEmployeeLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
});

export const PodEmployeeSlotField = ({
  className,
  items,
  label,
  loading,
  onChange,
  role,
  value,
}: Props) => {
  const styles = useStyles();

  const UNASSIGNED = {
    label: translate('global.unassigned'),
    value: 0,
    info: new PodConfigEmployeeSlot({ role }),
  };

  const [currentValue, setValue] = useState<
    SelectableInfo<PodConfigEmployeeSlot>[]
  >(value?.length ? value : [UNASSIGNED]);

  const mapSelectableEmployee = (
    items: SelectableInfo<Employee>[],
    role: string,
  ): SelectableInfo<PodConfigEmployeeSlot>[] => [
    UNASSIGNED,
    ...items.map((item) => ({
      label: item.label,
      value: item.value,
      info: new PodConfigEmployeeSlot({ employee: item, role }),
    })),
  ];

  const excludeOtherValues = (
    items: SelectableInfo<PodConfigEmployeeSlot>[],
    values: SelectableInfo<PodConfigEmployeeSlot>[],
    currenValue: SelectableInfo<PodConfigEmployeeSlot>,
  ) =>
    items.filter(
      (item) =>
        !item.value ||
        item.value === currenValue.value ||
        !values?.find((value) => value.value === item.value),
    );

  useEffect(() => setValue(value?.length ? value : [UNASSIGNED]), [value]);

  return (
    <div className={classNames(className, styles.container)}>
      <Label className="flex middle">
        {label}
        <a
          className={classNames('flex middle', styles.addEmployeeLink)}
          onClick={(evt) => {
            evt.preventDefault();
            onChange([
              ...(currentValue || []),
              {
                label: translate('global.unassigned'),
                value: 0,
                info: new PodConfigEmployeeSlot({ role }),
              },
            ]);
          }}
        >
          <AddIcon className={styles.addEmployeeIcon} fontSize="small" />
          {translate('pods.config.modal.addAnotherEmployee')}
        </a>
      </Label>
      <div className="grid-wrapper fit">
        {currentValue.map((item, idx) => (
          <Fragment key={`employee-slot-${label}-${item.value}-${idx}`}>
            <Select
              className="grid-span-11"
              disableClearable
              items={excludeOtherValues(
                mapSelectableEmployee(items, role),
                currentValue,
                item,
              )}
              loading={loading}
              onChange={(newValue: SelectableInfo<PodConfigEmployeeSlot>) => {
                currentValue[idx] = newValue;
                onChange([...currentValue]);
              }}
              value={item}
              required
            />
            <IconButton
              className={classNames(styles.icon, 'grid-span-1')}
              disabled={value && value.length < 2 && !value[0]?.value}
              size="small"
              onClick={() => {
                if (value && value.length < 2) {
                  onChange([UNASSIGNED]);
                } else {
                  currentValue?.splice(idx, 1);
                  onChange([...currentValue]);
                }
              }}
            >
              <DeleteOutlineIcon fontSize="large" />
            </IconButton>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
