import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { TaskCustomFieldType } from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { translate } from '@vestahealthcare/common/i18n';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';

import {
  BackgroundColors,
  Button,
  CheckboxWithLabel,
  Colors,
  TextInput,
} from 'styleguide-v2';

type Props = {
  field: TaskCustomField;
  onDelete?: (field: TaskCustomField) => void;
  onEdit: (field: TaskCustomField) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.lighterGray,
    padding: '1rem 2rem',
    marginTop: '2rem',
    width: '100%',
  },
  header: {
    color: Colors.textGreen,
  },
});

export const EditDateCustomField = (props: Props) => {
  const styles = useStyles();
  const { field, onDelete, onEdit, submitted } = props;
  const [editableField, setEditableField] = useState<TaskCustomField>(
    {} as TaskCustomField,
  );

  useEffect(() => {
    setEditableField({ ...field });
  }, [field]);

  return (
    <article className={`grid-wrapper grid-span-12 ${styles.container}`}>
      <header className="grid-wrapper grid-span-12">
        <span className={`h7 grid-span-11 ${styles.header}`}>
          {translate(
            `global.${
              field.type === TaskCustomFieldType.DATE ? 'date' : 'time'
            }`,
          )}
        </span>
        {onDelete && (
          <Button
            color="info"
            icon={
              <i className="fa fa-trash" style={{ marginTop: '-0.125rem' }} />
            }
            type="outlined"
            className="grid-span-1"
            data-cy="delete-custom-field"
            onClick={() => onDelete && onDelete(field)}
            size="s"
          >
            {translate('global.delete')}
          </Button>
        )}
      </header>
      <TextInput
        className="grid-span-4"
        data-cy="custom-field-label-input"
        error={
          submitted &&
          !editableField.label &&
          translate('global.missingRequiredField')
        }
        label={translate(
          `taskDefinitions.subtask.${
            field.type === TaskCustomFieldType.DATE ? 'date' : 'time'
          }Label`,
        )}
        onChange={(label = '') => {
          setEditableField({ ...editableField, label });
          onEdit({ ...editableField, label });
        }}
        value={editableField.label}
        required
      />

      <CheckboxWithLabel
        checked={editableField.required}
        className="grid-span-12"
        data-cy="custom-field-required-input"
        label={translate('taskDefinitions.subtask.requiredField')}
        onChange={(required = false) => {
          setEditableField({ ...editableField, required });
          onEdit({ ...editableField, required });
        }}
      />
    </article>
  );
};

export default EditDateCustomField;
