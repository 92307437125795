import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { PodRule } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Chip, IconButton, Table } from 'styleguide-v2';

interface Props {
  rules: PodRule[];
  onAdd: () => void;
  onEdit: (group: PodRule) => void;
}

const renderBooleanWithLabel = (label: string, checked: boolean) => (
  <p className="flex gap">
    <span>{label}:</span>
    <span className="flex">
      {checked ? (
        <CheckCircleIcon color="primary" />
      ) : (
        <CancelIcon color="error" />
      )}
    </span>
  </p>
);

const POD_RULES_COLUMNS = ({ onEdit }: Props) => [
  { headerName: translate('pods.rules.common.name'), field: 'name' },
  {
    headerName: translate('pods.rules.common.programExtensions'),
    component: ({ programExtensions }: PodRule) =>
      programExtensions?.length
        ? programExtensions?.map((r) => r.abbr)?.join(', ')
        : EMPTY,
  },
  {
    headerName: translate('pods.rules.common.roles'),
    component: ({ roles }: PodRule) =>
      roles?.length ? roles?.map((r) => r.toString())?.join(', ') : EMPTY,
  },
  {
    headerName: translate('pods.rules.common.triggers'),
    component: ({ id, externalTriggers, internalTriggers }: PodRule) => (
      <div
        className="flex gap column start"
        key={`pod-rule-${id}-triggers`}
        style={{ alignItems: 'flex-start' }}
      >
        {!externalTriggers?.length && !internalTriggers?.length && EMPTY}
        {externalTriggers?.map(({ id: triggerId, description }) => (
          <Chip
            color="secondary"
            type="contained"
            key={`pod-rule-${id}-trigger-${triggerId}`}
            size="medium"
          >
            {description}
          </Chip>
        ))}
        {internalTriggers?.map(({ id: triggerId, description, status }) => (
          <Chip
            color="secondary"
            type="outlined"
            key={`pod-rule-${id}-trigger-${triggerId}`}
            size="medium"
          >
            {description} [{status.description}]
          </Chip>
        ))}
      </div>
    ),
  },
  {
    headerName: translate('pods.rules.common.configuration'),
    component: (rule: PodRule) => (
      <div className="grid-wrapper fit">
        <div className="grid-span-3">
          <p className="flex gap">
            <span>{translate('pods.rules.common.frequency')}:</span>
            <span className="flex">{rule.frequency?.description || EMPTY}</span>
          </p>
          <p className="flex gap">
            <span>{translate('pods.rules.common.period')}:</span>
            <span className="flex">{rule.period?.description || EMPTY}</span>
          </p>
        </div>
        <div className="grid-span-3">
          <p className="flex gap">
            <span>{translate('pods.rules.common.maxAttempts')}:</span>
            <span className="flex">{rule.maxAttempts || EMPTY}</span>
          </p>
          <p className="flex gap">
            <span>{translate('pods.rules.common.delayDaysAfterTrigger')}:</span>
            <span className="flex">{rule.delayDaysAfterTrigger || EMPTY}</span>
          </p>
          <p className="flex gap">
            <span>{translate('pods.rules.common.taskSpacingDays')}:</span>
            <span className="flex">{rule.taskSpacingDays || EMPTY}</span>
          </p>
        </div>
        <div className="grid-span-3">
          {renderBooleanWithLabel(
            translate('pods.rules.common.createPeriodIfEmpty'),
            rule.createPeriodIfEmpty,
          )}
          {renderBooleanWithLabel(
            translate('pods.rules.common.closeAfterAttemps'),
            rule.closeAfterAttemps,
          )}
          {renderBooleanWithLabel(
            translate('pods.rules.common.closeAfterDue'),
            rule.closeAfterDue,
          )}
        </div>
        <div className="grid-span-3">
          {renderBooleanWithLabel(
            translate('pods.rules.common.selfTrigger'),
            rule.selfTrigger,
          )}
          {renderBooleanWithLabel(
            translate('pods.rules.common.flexible'),
            rule.flexible,
          )}
        </div>
      </div>
    ),
  },
  {
    headerName: '',
    component: (config: PodRule) => (
      <IconButton size="small" onClick={() => onEdit(config)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const PodRulesTable = (props: Props) => (
  <Table
    config={{
      columns: POD_RULES_COLUMNS(props),
      compact: true,
      data: props.rules,
      paginationOptions: [-1],
    }}
    empty={translate('pods.rules.table.noResults')}
    emptyLink={translate('pods.rules.table.add')}
    fontSize="small"
    onClickEmptyLink={props.onAdd}
  />
);

export default PodRulesTable;
