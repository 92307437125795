// @ts-ignore
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { TaskDefinitionStatus } from '@vestahealthcare/common/enums/TaskDefinitionStatus';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, EmployeeGroup } from '@vestahealthcare/common/models';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  TaskDefinitionFilterParams,
  fetchTaskDefinitions,
} from 'dash/src/services/TaskServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { TaskDefinitionsTable } from './TaskDefinitionsTable';

interface TaskDefinitionExtended extends TaskDefinition {
  employeeGroup?: EmployeeGroup;
}

export const TaskDefinitions = () => {
  const history = useHistory();

  const initFilters = () => {
    const query = useQueryParams();
    const createdByIdString = query.get('createdById') || undefined;
    const createdById = createdByIdString
      ? parseFloat(createdByIdString)
      : undefined;
    const employeeGroupIdString = query.get('employeeGroupId') || undefined;
    const employeeGroupId = employeeGroupIdString
      ? parseFloat(employeeGroupIdString)
      : undefined;
    const name = query.get('name') || undefined;
    const status = query.get('status') || undefined;

    return {
      createdById,
      employeeGroupId,
      name,
      status,
    };
  };

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<TaskDefinitionFilterParams>(
    initFilters(),
  );
  const [groups, setGroups] = useState<Selectable[]>([]);
  const [employees, setEmployees] = useState<Selectable[]>([]);
  const [definitions, setDefinitions] = useState<TaskDefinitionExtended[]>([]);

  const mapDefinitions = (
    definitions: TaskDefinition[],
    groups: EmployeeGroup[],
  ) =>
    definitions.map((definition) => {
      let employeeGroup: EmployeeGroup | undefined;
      const { employeeGroupId } = definition;
      if (employeeGroupId) {
        employeeGroup = groups.find(
          (group: EmployeeGroup) => group.id === employeeGroupId,
        );
      }
      return { ...definition, employeeGroup };
    });

  const fetchDefinitionsAndGroups = async () => {
    const [groups, employees] = await Promise.all([
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getEmployees(),
    ]);
    return [
      groups.map(({ name, id }: EmployeeGroup) => ({ label: name, value: id })),
      employees.map(({ id, fullName }: Employee) => ({
        label: fullName,
        value: id,
      })),
    ];
  };

  const fetchData = async () => {
    const [groups, employees] = await fetchDefinitionsAndGroups();
    setGroups(groups);
    setEmployees(employees);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const clearFilters = () => setFilters({});

  const filter = async (filters: TaskDefinitionFilterParams) => {
    setLoading(true);
    const definitions = await fetchTaskDefinitions({
      ...filters,
      topLevelOnly: true,
    });
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setDefinitions(mapDefinitions(definitions, groups));
    setLoading(false);
  };

  useEffect(() => {
    filter(filters);
    const { createdById, employeeGroupId, name, status } = filters;
    const query = qs.stringify(
      {
        createdById,
        employeeGroupId,
        name,
        status,
      },
      { arrayFormat: 'repeat' },
    );
    history.push(`?${query}`);
  }, [filters]);

  return (
    <Panel data-cy="task-definitions-page">
      <Panel.Heading title={translate('taskDefinitions.title')}>
        <FilterButton
          className="filters"
          data-cy="task-templates-show-filters"
          filters={filters}
          onClick={() => setShowFilters(!showFilters)}
        />
        <Panel.Actions>
          <IconButton
            data-cy="task-template-add"
            onClick={() => history.push('/admin/task-templates/new')}
            tooltip={translate('taskDefinitions.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Collapse data-cy="task-definitions-filters" open={showFilters}>
          <div className="grid-wrapper">
            <TextInput
              className="grid-span-3"
              data-cy="task-definitions-name"
              value={filters.name}
              label={translate('taskDefinitions.name')}
              onChange={(value) => setFilters({ ...filters, name: value })}
            />
            <Select
              className="grid-span-3"
              data-cy="task-definitions-created-by"
              label={translate('taskDefinitions.createdBy')}
              items={employees}
              onChange={(val: Selectable) =>
                setFilters({ ...filters, createdById: val?.value as number })
              }
              value={
                filters.createdById
                  ? employees.find(({ value }) => filters.createdById === value)
                  : ''
              }
            />
            <Select
              className="grid-span-3"
              data-cy="task-definitions-group"
              label={translate('taskDefinitions.group')}
              items={groups}
              onChange={(val: Selectable) =>
                setFilters({
                  ...filters,
                  employeeGroupId: val?.value as number,
                })
              }
              value={
                filters.employeeGroupId
                  ? groups.find(
                      ({ value }) => filters.employeeGroupId === value,
                    )
                  : ''
              }
            />
            <Select
              className="grid-span-3"
              data-cy="task-definitions-status"
              label={translate('taskDefinitions.status')}
              getItemLabel={(item: TaskDefinitionStatus) => item.toString()}
              items={TaskDefinitionStatus.asArray}
              onChange={(val: TaskDefinitionStatus) =>
                setFilters({ ...filters, status: val?.value })
              }
              value={
                filters.status
                  ? TaskDefinitionStatus.byKey[filters.status]
                  : undefined
              }
            />
            <div className="grid-span-9" />
            <Button
              data-cy="clear-filters"
              className="grid-span-3"
              color="secondary"
              type="outlined"
              onClick={clearFilters}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <TaskDefinitionsTable
          definitions={definitions}
          onEdit={({ id }) => history.push(`/admin/task-templates/${id}`)}
        />
      </Panel.Body>
    </Panel>
  );
};

export default TaskDefinitions;
