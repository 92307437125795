import moment, { Moment } from 'moment';

import {
  CollectionMethod,
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterMethodOutcome,
  EncounterType,
  Language,
  MemberRisk,
  States,
} from '@vestahealthcare/common/enums';
import {
  BaseEnum,
  Employee,
  EmployeeGroup,
  EventDetail,
  EventInterventionType,
  EventModel,
  EventModelExtended,
  EventNote,
  EventSummaryCount,
  EventTriage,
  EventType,
  EventsConfiguration,
  IssueDetailFieldGroup,
  IssueType,
  MemberEventsSummary,
  SkilledEvent,
  SourceChannel,
  TOCEvent,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import EventLink from '@vestahealthcare/common/models/EventLink';
import EventLinkReason from '@vestahealthcare/common/models/EventLinkReason';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import IssueTypeRule from '@vestahealthcare/common/models/IssueTypeRule';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, { PaginatedResponse, PaginationParams } from './Api';

export type UpdateEventTypeParams = {
  name: string;
  active: boolean;
  issueTypes: IssueType[];
};

export const createEventType = async ({
  active,
  issueTypes,
  name,
}: UpdateEventTypeParams) =>
  await Api.postv2JSON('events/types', {
    active,
    issueTypes: issueTypes?.map(({ id }) => ({ id })),
    name,
  });

export const updateEventType = async (
  id: number,
  { active, issueTypes, name }: UpdateEventTypeParams,
) =>
  await Api.putv2JSON(`events/types/${id}`, {
    active,
    issueTypes: issueTypes?.map(({ id }) => id),
    name,
  });

export type UpdateEventTypeConfig = {
  timeGrouping?: boolean;
  timeGroupingHours?: number;
};

export const updateEventsConfig = async (params: UpdateEventTypeConfig) =>
  await Api.putv2JSON('events/configuration', params);

export const fetchEventsConfig = async () => {
  const { configuration } = await Api.getv2('events/configuration');
  return new EventsConfiguration(configuration);
};

export type GetEventTypeParams = {
  sort?: string;
};
export const fetchEventTypes = async (
  params: GetEventTypeParams = {},
): Promise<EventType[]> => {
  const p = {
    sort: 'active desc, name asc',
    ...params,
  };
  const {
    eventTypes: { items },
  } = await Api.getv2('events/types', p);
  return items?.map((et: any) => new EventType(et));
};

export const fetchEventType = async (id: number): Promise<EventType> => {
  const { eventType } = await Api.getv2(`events/types/${id}`);
  return new EventType(eventType);
};

export type GetIssueTypeParams = {
  description?: string;
  eventTypeId?: EventType[];
  sort?: string;
};

export const fetchIssueTypes = async (
  params: GetIssueTypeParams = {},
): Promise<IssueType[]> => {
  const p = {
    sort: 'id asc',
    ...params,
    eventTypeId: params.eventTypeId
      ? params.eventTypeId.map(({ id }) => id)
      : undefined,
  };
  const {
    issueTypes: { items },
  } = await Api.getv2('events/issues/types', p);
  return items?.map((et: any) => new IssueType(et)).sort(IssueType.sort);
};

export type UpdateIssueTypeParams = {
  description: string;
  urgent: boolean;
  urgencyLevel?: number;
  urgencyRules: IssueTypeRule[];
  eventTypeId: EventType;
};

export const updateIssueType = async (
  issueType: string,
  { eventTypeId, urgencyRules, ...rest }: UpdateIssueTypeParams,
) =>
  await Api.putv2JSON(`events/issues/types/${issueType}`, {
    ...rest,
    eventTypeId: eventTypeId.id,
    urgencyRules: urgencyRules?.map(({ operationType, ...other }) => ({
      operationType: operationType?.value,
      ...other,
    })),
  });

export const fetchEventStatuses = async (): Promise<EventStatus[]> => {
  const {
    statuses: { items },
  } = await Api.getv2('events/statuses', {
    sort: 'id asc',
  });
  return items?.map((et: any) => new EventStatus(et));
};

export type GetInterventionTypParams = {
  active?: boolean;
  categoryId?: BaseEnum[];
  sort?: string;
};

export const fecthEventInterventionTypes = async (
  params?: GetInterventionTypParams,
): Promise<EventInterventionType[]> => {
  const {
    types: { items },
  } = await Api.getv2('events/interventions/types', {
    active: params?.active,
    categoryId: params?.categoryId?.map(({ id }) => id),
    limit: -1,
    sort: params?.sort || 'description asc',
  });
  return items?.map((et: any) => new EventInterventionType(et));
};

export type UpdateInterventionTypeParams = {
  active: boolean;
  categoryId: BaseEnum;
  description: string;
};

export const createEventInterventionType = async ({
  categoryId,
  ...rest
}: UpdateInterventionTypeParams) =>
  await Api.postv2JSON('events/interventions/types', {
    ...rest,
    categoryId: categoryId.id,
  });

export const updateEventInterventionType = async (
  interventionType: number,
  { categoryId, ...rest }: UpdateInterventionTypeParams,
) =>
  await Api.putv2JSON(`events/interventions/types/${interventionType}`, {
    ...rest,
    categoryId: categoryId.id,
  });

export const fecthEventInterventionCategories = async (): Promise<
  BaseEnum[]
> => {
  const {
    categories: { items },
  } = await Api.getv2('events/interventions/categories', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};

export const fecthEventInterventionStatuses = async () => {
  const {
    'outcome-statuses': { items },
  } = await Api.getv2('events/interventions/outcome-statuses', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};

export const fecthEventInterventionResults = async () => {
  const {
    'outcome-results': { items },
  } = await Api.getv2('events/interventions/outcome-results', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};

export const fecthEventDivertedOptions = async () => {
  const {
    divertedFrom: { items },
  } = await Api.getv2('events/diverted-from', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};

export const fecthEventResolutionOptions = async () => {
  const {
    resolutions: { items },
  } = await Api.getv2('events/resolutions', {
    sort: 'description asc',
  });
  return items?.map((et: any) => new BaseEnum(et));
};

export const fecthEventLinkReasons = async () => {
  const {
    reasons: { items },
  } = await Api.getv2('/events/links/reasons', {
    sort: 'name asc',
  });
  return items?.map((et: any) => new EventLinkReason(et));
};

export const fecthIssueDetailFields = async () => {
  const {
    detailFieldGroups: { items },
  } = await Api.getv2('events/issues/types/detail-field-groups', {
    sort: 'position asc',
  });
  return items?.map((et: any) => new IssueDetailFieldGroup(et));
};

export type GetParams = {
  assigneeGroupId?: number[];
  assigneeId?: number[];
  brandId?: string[];
  careCoordinatorId?: number[];
  everEscalated?: boolean;
  from?: Moment;
  language?: Language[] | string[];
  limit?: number;
  npOwnerId?: number[];
  offset?: number;
  pendingIncident?: boolean;
  podManagerId?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  referralSourceId?: number[];
  riskLevel?: MemberRisk[] | string[];
  rnOwnerId?: number[];
  sort?: string;
  stateId?: States[] | string[];
  statusId?: string[];
  statusGroupId?: string[];
  testMember?: boolean;
  to?: Moment;
  typeId?: number[];
  vitalCollectionMethod?: CollectionMethod[] | string[];
};

const prepareEventParams = ({
  from,
  language,
  programExtensionStatus,
  riskLevel,
  sort,
  stateId,
  to,
  vitalCollectionMethod,
  ...others
}: GetParams) => ({
  ...others,
  from: from?.toISOString(),
  language: language?.map((lang) => lang.valueOf()),
  programExtensionStatus: programExtensionStatus?.map((pe) => pe.valueOf()),
  riskLevel: riskLevel?.map((level) => level.valueOf()),
  sort: sort || 'createdAt desc',
  stateId: stateId?.map((state) => state.valueOf()),
  to: to?.toISOString(),
  vitalCollectionMethod: vitalCollectionMethod?.map((item) => item.valueOf()),
});

export const fetchEvents = async (
  params: GetParams,
): Promise<PaginatedResponse<EventModelExtended>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2('events', prepareEventParams(params));
  return {
    items: items?.map((evt: any) => new EventModelExtended(evt)),
    pagination,
  };
};

export const fetchEventsDashboard = async (
  params: GetParams,
): Promise<PaginatedResponse<EventModelExtended>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2('events/dashboard', prepareEventParams(params));
  return {
    items: items?.map((evt: any) => new EventModelExtended(evt)),
    pagination,
  };
};

export const fetchEventCounts = async (
  params: GetParams,
): Promise<EventSummaryCount> => {
  const { eventCounts } = await Api.getv2('events/counts', {
    ...prepareEventParams(params),
    count: true,
  });
  return new EventSummaryCount(eventCounts);
};

export type GetMemberParams = {
  assigneeId?: number;
  count?: boolean;
  from?: Moment;
  limit?: number;
  offset?: number;
  sort?: string;
  status?: string;
  statusGroup?: string[];
  to?: Moment;
  typeId?: number[];
};

export const fetchMemberEvents = async (
  patientId: number,
  { from, to, sort, ...others }: GetMemberParams,
): Promise<PaginatedResponse<EventModel>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2(`patients/${patientId}/events`, {
    ...others,
    from: from?.toISOString(),
    to: to?.toISOString(),
    sort: sort || 'createdAt desc',
  });
  return { items: items?.map((evt: any) => new EventModel(evt)), pagination };
};

export const fetchEventDetail = async (eventId: number) => {
  const { event } = await Api.getv2(`events/${eventId}`);
  return new EventDetail(event);
};

export type IssueField = {
  issueDetailFieldId: string;
  value: string | number | boolean | undefined | null;
};

export type IssueDetail = {
  [x: string]: IssueField[];
};

export type CreateEventParams = {
  chiefComplaint: string;
  dateTime: string;
  direction?: BaseEnum;
  documentationOnly: boolean;
  hasTime: boolean;
  issues: IssueDetail;
  memberId: number;
  reporterMember?: number;
  reporterCareTeamMember?: number;
  reporterMemberCommunityResource?: number;
  reporterPatientCaregiver?: number;
  reporterPatientContact?: number;
  source?: SourceChannel;
  sourceOther?: string;
  typeId: EventType;
};

export const createEvent = async ({
  direction,
  issues,
  source,
  typeId,
  ...rest
}: CreateEventParams) => {
  const { event } = await Api.postv2JSON('events', {
    ...rest,
    direction: direction?.id,
    // Filter details without value
    issues: Object.entries(issues).reduce(
      (acc: IssueDetail, [key, values]: [string, IssueField[]]) => {
        acc[key] = values.filter(
          ({ value }) => value !== undefined && value !== null && value !== '',
        );
        return acc;
      },
      {} as IssueDetail,
    ),
    source: source?.id,
    typeId: typeId?.id,
  });
  return new EventDetail(event);
};

export type UnlinkIssuesParams = {
  chiefComplaint: string;
  dateTime: string;
  hasTime: boolean;
  issueIds: EventIssue[];
  memberId: number;
  typeId: EventType;
};

export const unlinkIssues = async ({
  issueIds,
  typeId,
  ...rest
}: UnlinkIssuesParams) => {
  const { event } = await Api.postv2JSON('events/from-unlinked-issues', {
    ...rest,
    issueIds: issueIds.map(({ id }) => id),
    typeId: typeId?.id,
  });

  return new EventDetail(event);
};

export type UpdateEventParams = {
  assigneeId?: Employee;
  assigneeGroupId?: EmployeeGroup;
  chiefComplaint?: string;
  followUpDatetime?: string;
  followUpDatetimeHasTime?: boolean;
  resolution?: BaseEnum;
  statusId?: EventStatus;
  urgencyLevel?: number;
  urgent?: boolean;

  // Deprecated fields
  divertedFrom?: BaseEnum;
  interventionPreventedER?: boolean;
  npEscalationRequired?: boolean;
  wouldVisitER?: boolean;
};

export const updateEvent = async (
  eventId: number,
  {
    assigneeId,
    assigneeGroupId,
    divertedFrom,
    resolution,
    statusId,
    ...rest
  }: UpdateEventParams,
) => {
  const { event } = await Api.patchv2JSON(`events/${eventId}`, {
    ...rest,
    assigneeId: assigneeId?.id,
    assigneeGroupId: assigneeGroupId?.id,
    divertedFrom: divertedFrom?.id,
    resolution: resolution?.id,
    statusId: statusId?.id,
  });
  return new EventDetail(event);
};

export type CloseEventParams = {
  resolution?: BaseEnum;
  resolutionOther?: string;

  // Deprecated fields
  wouldVisitER?: boolean;
  divertedFrom?: BaseEnum;
  interventionPreventedER?: boolean;
  npEscalationRequired?: boolean;
};

export const closeEvent = async (
  eventId: number,
  { resolution, ...rest }: CloseEventParams,
) => {
  const { event } = await Api.patchv2JSON(`events/${eventId}/close`, {
    ...rest,
    resolution: resolution?.id,
  });
  return new EventDetail(event);
};

export const forceCloseEvent = async (eventId: number) => {
  const { event } = await Api.patchv2JSON(`events/${eventId}/close`, {
    force: true,
  });
  return new EventDetail(event);
};

export const closeDocumentedEvent = async (eventId: number) => {
  const { event } = await Api.patchv2JSON(
    `events/${eventId}/documentation-only`,
  );
  return new EventDetail(event);
};

export type InvalidateEventParams = {
  invalidatedReasonId: string;
  invalidNote?: string;
};

export const getInvalidateReasons = async () => {
  const { invalidatedReasons } = await Api.getv2(`events/invalidated-reasons`, {
    active: true,
    sort: 'description asc',
  });
  return invalidatedReasons.items.map((item: any) => new BaseEnum(item));
};

export const invalidateEvent = async (
  eventId: number,
  params: InvalidateEventParams,
) => {
  const { event } = await Api.patchv2JSON(
    `events/${eventId}/invalidate`,
    params,
  );
  return new EventDetail(event);
};

export type GetMemberEventsSummary = {
  from?: Moment;
  to?: Moment;
};

export const fetchMemberEventsSummary = async (
  id: number,
  { from, to }: GetMemberEventsSummary,
) => {
  const { eventsSummary } = await Api.getv2(`patients/${id}/events/summary`, {
    from: from?.toISOString(),
    to: to?.toISOString(),
  });
  return new MemberEventsSummary(eventsSummary);
};

export const createEventEvaluation = async (eventId: number) =>
  await Api.postv2JSON(`events/${eventId}/evaluations`, {});

export const completeEventEvaluation = async (
  eventId: number,
  evaluationId: number,
) =>
  await Api.patchv2JSON(`events/${eventId}/evaluations/${evaluationId}/close`);

export type CreateEventEncounterParams = {
  careTeamMemberId?: number;
  communityResourceId?: number;
  channel?: EncounterChannel;
  direction?: EncounterDirection;
  duration: number;
  encounterDate: Moment;
  memberId: number;
  method: EncounterMethod;
  methodOutcome?: EncounterMethodOutcome;
  note?: string;
  otherChannel?: string;
  otherContact?: string;
  otherContactReferralId?: number;
  patientCaregiverId?: number;
  patientContactId?: number;
  reporterMember?: number;
  type: EncounterType;
};

export const createEventEncounter = async (
  patientId: number,
  eventId: number,
  {
    channel,
    encounterDate,
    direction,
    method,
    methodOutcome,
    type,
    ...others
  }: CreateEventEncounterParams,
) =>
  await Api.postv2JSON(`patients/${patientId}/encounter-time-trackings`, {
    channel: channel?.value,
    encounterDate: encounterDate.format(DATE_FORMAT),
    encounterDateTime: encounterDate.format(),
    direction: direction?.value,
    eventId,
    method: method.value,
    methodOutcome: methodOutcome?.value,
    type: type.value,
    ...others,
  });

export type CreateEventIssueParams = {
  details?: IssueField[];
  direction?: BaseEnum;
  issueTypeId?: IssueType;
  note?: string;
  reporterPatientCaregiver?: number;
  reporterPatientContact?: number;
  reporterMember?: number;
  reporterCareTeamMember?: number;
  reporterMemberCommunityResource?: number;
  source?: SourceChannel;
  sourceOther?: string;
};

export const createEventIssue = async (
  eventId: number,
  {
    details,
    direction,
    issueTypeId,
    source,
    ...others
  }: CreateEventIssueParams,
) => {
  const { issue } = await Api.postv2JSON(`events/${eventId}/issues`, {
    dateTime: moment().format(),
    details: details?.filter(
      ({ value }) => value !== undefined && value !== null && value !== '',
    ),
    direction: direction?.id,
    hasTime: true,
    issueTypeId: issueTypeId?.id,
    source: source?.id,
    ...others,
  });
  return new EventIssue(issue);
};

export type CreateEventInterventionParams = {
  typeIds: EventInterventionType[];
};

export const createEventIntervention = async (
  eventId: number,
  evaluationId: number,
  { typeIds }: CreateEventInterventionParams,
) =>
  await Api.postv2JSON(
    `events/${eventId}/evaluations/${evaluationId}/interventions`,
    {
      typeIds: typeIds?.map(({ id }) => id),
    },
  );

export type UpdateEventInterventionParams = {
  outcomeResultId?: BaseEnum;
  outcomeStatusId?: BaseEnum;
};

export const updateEventIntervention = async (
  eventId: number,
  evaluationId: number,
  interventionId: number,
  { outcomeResultId, outcomeStatusId }: UpdateEventInterventionParams,
) =>
  await Api.putv2JSON(
    `events/${eventId}/evaluations/${evaluationId}/interventions/${interventionId}`,
    {
      outcomeResultId: outcomeResultId?.id,
      outcomeStatusId: outcomeStatusId?.id,
    },
  );

export type UpdateEventIssueParams = {
  valid: boolean;
  details?: IssueField[];
};

export const updateEventIssue = async (
  eventId: number,
  issueId: number,
  param: UpdateEventIssueParams,
) => await Api.putv2JSON(`events/${eventId}/issues/${issueId}`, param);

export const createEventEvaluationNote = async (
  eventId: number,
  evaluationId: number,
  { addendum, note }: EventNote,
) =>
  await Api.postv2JSON(`events/${eventId}/evaluations/${evaluationId}/notes`, {
    note,
    isAddendum: addendum,
  });

export const createEventNote = async (
  eventId: number,
  { addendum, note }: EventNote,
) =>
  await Api.postv2JSON(`events/${eventId}/notes`, {
    note,
    isAddendum: addendum,
  });

export const updateEventEvaluationNote = async (
  eventId: number,
  evaluationId: number,
  { id, note }: EventNote,
) =>
  await Api.putv2JSON(
    `events/${eventId}/evaluations/${evaluationId}/notes/${id}`,
    {
      note,
    },
  );

export const updateEventNote = async (
  eventId: number,
  { id, note }: EventNote,
) =>
  await Api.putv2JSON(`events/${eventId}/notes/${id}`, {
    note,
  });

export const createEventTriage = async (
  evaluationId: number,
  triage: EventTriage,
) => await Api.postv2JSON(`evaluations/${evaluationId}/triages`, triage);

export const fetchEventTriages = async (evaluationId: number) => {
  const {
    triages: { items },
  } = await Api.getv2(`evaluations/${evaluationId}/triages`);
  return items?.map((obj: any) => new EventTriage(obj));
};

export type LinkEventParams = {
  reasonId: EventLinkReason;
  sourceEventId: EventModel;
  targetEventId: EventModel;
};

export const linkEvent = async ({
  reasonId,
  sourceEventId,
  targetEventId,
}: LinkEventParams) =>
  await Api.postv2JSON('events/links', {
    reasonId: reasonId.id,
    sourceEventId: sourceEventId.id,
    targetEventId: targetEventId.id,
  });

export const unlinkEvent = async (link: EventLink) =>
  await Api.deletev2JSON(`events/links/${link.id}`);

export const acceptEventIssuePrompt = async (
  eventId: number,
  issueId: number,
  promtpId: number,
) =>
  await Api.putv2JSON(
    `events/${eventId}/issues/${issueId}/prompts/${promtpId}`,
    { ack: true },
  );

export const fetchTOCEvents = async (
  memberId: number,
  params: PaginationParams,
): Promise<PaginatedResponse<TOCEvent>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2(`/patients/${memberId}/events/toc`, {
    ...params,
    sort: 'issueDatetime desc',
  });
  return {
    items: items.map((x: any) => new TOCEvent(x)),
    pagination,
  };
};

export const fetchSkilledEvents = async (
  memberId: number,
  params: PaginationParams,
): Promise<PaginatedResponse<SkilledEvent>> => {
  const {
    events: { items, pagination },
  } = await Api.getv2(`/patients/${memberId}/events/skilled`, {
    ...params,
    sort: 'issueDatetime desc',
  });
  return {
    items: items.map((x: any) => new SkilledEvent(x)),
    pagination,
  };
};
