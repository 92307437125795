import React, { Component } from 'react';
import { connect } from 'react-redux';

import { translate } from '@vestahealthcare/common/i18n';

import { Modal } from 'styleguide-v2';

import {
  closeConflictingDataWarning,
  getConflictingDataWarningMessage,
} from 'dash/src/redux/actions/GlobalActions';

interface StateProps {
  conflictingDataWarningMessage: string | null;
}

interface DispatchProps {
  cancel: () => void;
}

const mapStateToProps = (state: any): StateProps => ({
  conflictingDataWarningMessage: getConflictingDataWarningMessage(state),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  cancel: () => dispatch(closeConflictingDataWarning()),
});

class ConflictingDataWarning extends Component<StateProps & DispatchProps> {
  doCancel() {
    const { cancel } = this.props;
    cancel && cancel();
  }

  doReload() {
    // Todo: improve this
    window.location.reload();
  }

  render() {
    const { conflictingDataWarningMessage } = this.props;

    return (
      <Modal
        open={!!conflictingDataWarningMessage}
        color="warning"
        onClose={() => this.doCancel()}
        onSubmit={async () => this.doReload()}
        title={
          <>
            <i className="fa fa-warning" />{' '}
            {translate('global.changesCannotBeSubmitted')}
          </>
        }
        submitText={translate('global.refresh')}
      >
        <p>
          {conflictingDataWarningMessage}{' '}
          {translate('global.refreshToSeeChanges')}
        </p>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConflictingDataWarning);
