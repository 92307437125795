import moment from 'moment';

import {
  Address,
  BaseEnum,
  CommunityResource,
  CommunityResourceSpeciality,
  EventInterventionType,
  InsurancePlan,
  MemberCommunityResource,
  MemberContactInfoTimes,
  Organization,
  StateCities,
} from '@vestahealthcare/common/models';
import {
  LOCAL_TIME_FORMAT,
  SERVER_DATE_TIME_FORMAT_ZONED,
} from '@vestahealthcare/common/utils/constants';

import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

export interface CommunityResourceParams extends PaginationParams {
  id?: string | number;
  name?: string;
  phone?: string;
  queryString?: string;
  specialities?: string[];
  state?: string;
  status?: 'ACTIVE' | 'INACTIVE';
}

export interface CommunityResourceCreateParams extends PaginationParams {
  address?: Address;
  fax?: string;
  insurances?: InsurancePlan[];
  interventions?: EventInterventionType[];
  mltcPlanId?: number | null;
  name: string;
  notes?: string;
  phone?: string;
  pocEmail?: string;
  pocExtension?: string;
  pocName?: string;
  pocNumber?: string;
  referrals?: Organization[];
  specialities: BaseEnum[];
  specialityOther?: string;
  states: StateCities[];
  status: 'ACTIVE' | 'INACTIVE';
  times?: MemberContactInfoTimes[];
  type: BaseEnum;
  website?: string;
}

export const fetchCommunityResources = async (
  params: CommunityResourceParams,
): Promise<PaginatedResponse<CommunityResource>> => {
  const {
    communityResources: { items, pagination },
  } = await Api.getv2('/community-resource/', params);
  return {
    items: items.map((x: any) => new CommunityResource(x)),
    pagination,
  };
};

export const fetchCommunityResourceTypes = async (): Promise<BaseEnum[]> => {
  const {
    communityResourceTypes: { items },
  } = await Api.getv2('/community-resource/types');
  return items
    ?.map((x: any) => new BaseEnum(x))
    .sort((a: BaseEnum, b: BaseEnum) =>
      a.description.localeCompare(b.description),
    );
};

export const fetchCommunityResourceSpecialities = async (
  type?: BaseEnum,
): Promise<CommunityResourceSpeciality[]> => {
  const {
    communityResourceSpecialityTypes: { items },
  } = await Api.getv2(
    `/community-resource/specialities${type ? '/' : ''}${type?.id || ''}`,
  );
  return items
    ?.map((x: any) => new CommunityResourceSpeciality(x))
    .sort((a: CommunityResourceSpeciality, b: CommunityResourceSpeciality) =>
      a.description.localeCompare(b.description),
    );
};

const prepareUpdateParams = ({
  insurances,
  interventions,
  referrals,
  specialities,
  states,
  times,
  type,
  ...params
}: CommunityResourceCreateParams) => ({
  insurances: insurances?.map(({ id }) => id),
  interventions: interventions?.map(({ id }) => id),
  referrals: referrals?.map(({ id }) => id),
  specialities: specialities?.map(({ id }) => ({ type: id })),
  states: states?.map(({ state, cities }) => ({
    state: state?.value,
    cities,
  })),
  times: (times || [])?.map(
    ({ id, type, dayOfWeek, startTime, endTime }) =>
      ({
        id,
        type: type?.value,
        dayOfWeek: dayOfWeek?.value,
        startTime: startTime?.isValid()
          ? startTime.format(LOCAL_TIME_FORMAT)
          : undefined,
        endTime: endTime?.isValid()
          ? endTime.format(LOCAL_TIME_FORMAT)
          : undefined,
      } || []),
  ),
  type: type.id,
  ...params,
});

export const createCommunityResource = async (
  params: CommunityResourceCreateParams,
) => {
  const { communityResource } = await Api.postv2JSON(
    '/community-resource/',
    prepareUpdateParams(params),
    { showToast: true },
  );
  return new CommunityResource(communityResource);
};

export const updateCommunityResource = async (
  id: number,
  params: CommunityResourceCreateParams,
) => {
  await Api.patchv2JSON(
    `/community-resource/${id}`,
    prepareUpdateParams(params),
    { showToast: true },
  );
};

export const fetchMemberCommunityResources = async (
  memberId: number,
): Promise<PaginatedResponse<MemberCommunityResource>> => {
  const {
    memberCommunityResources: { items, pagination },
  } = await Api.getv2(
    `/community-resource/member-community-resources/${memberId}`,
  );
  return {
    items: items.map((x: any) => new MemberCommunityResource(x)),
    pagination,
  };
};

export const fetchCommunityResourceMembers = async (
  communityResourceId: number,
): Promise<PaginatedResponse<MemberCommunityResource>> => {
  const {
    memberCommunityResources: { items, pagination },
  } = await Api.getv2(`/community-resource/${communityResourceId}/members`);
  return {
    items: items.map((x: any) => new MemberCommunityResource(x)),
    pagination,
  };
};

export interface MemberCommunityResourceUpdateParams {
  externalId?: string | null;
  notes?: string;
  pocEmail?: string;
  pocExtension?: string;
  pocName?: string;
  pocNumber?: string;
}

export interface MemberCommunityResourceAddParams
  extends MemberCommunityResourceUpdateParams {
  communityResourceId: number;
  memberId: number;
}

export const addMemberCommunityResource = async (
  params: MemberCommunityResourceAddParams,
) =>
  await Api.postv2JSON(
    '/community-resource/member-community-resources',
    params,
    { showToast: true },
  );

export const updateMemberCommunityResource = async (
  id: number,
  {
    pocEmail,
    pocExtension,
    pocName,
    pocNumber,
    notes,
    ...rest
  }: MemberCommunityResourceUpdateParams,
) =>
  await Api.patchv2JSON(
    `/community-resource/member-community-resources/${id}`,
    {
      pocEmail: pocEmail || null,
      pocExtension: pocExtension || null,
      pocName: pocName || null,
      pocNumber: pocNumber || null,
      notes: notes || null,
      ...rest,
    },
    { showToast: true },
  );

export const deleteMemberCommunityResource = async (id: number) =>
  await Api.patchv2JSON(
    `/community-resource/member-community-resources/${id}`,
    { discontinuedAt: moment().format(SERVER_DATE_TIME_FORMAT_ZONED) },
  );
