import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CompleteIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import {
  EncounterDirection,
  EncounterType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PaginationType,
  SkilledDetail,
  SkilledEvent,
  TOCDetail,
  TOCEvent,
  TOCHandoffStatus,
  TOCStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  BrandMemberIcon,
  Button,
  Colors,
  Fonts,
  Icon,
  MemberProgramExtensions,
  Modal,
  Panel,
  Select,
  SelectAssignee,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { AddEncounterModal } from 'dash/src/pages/MemberProfile/Encounters/AddEncounterModal';
import { CacheServices } from 'dash/src/services';
import {
  fetchSkilledEvents,
  fetchTOCEvents,
} from 'dash/src/services/EventServices';
import Session from 'dash/src/services/SessionServices';
import {
  UpdateSkilledParams,
  UpdateTOCParams,
  closeSkilledRecord,
  fetchSkilledDetail,
  fetchTOCDetail,
  updateSkilledRecord,
  updateTOCRecord,
} from 'dash/src/services/TOCServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { BrandBanner } from '../../../components/BrandBanner';
import { VestaCoreLiteTag } from '../../MemberProfile/Header/VestaCoreLiteTag';
import { SkilledEpisodeCard } from './SkilledEpisodeCard';
import { TOCDetailsCard } from './TOCDetailsCard';
import { TOCEncountersCard } from './TOCEncountersCard';
import { TOCEventsHistoryCard } from './TOCEventsCard';
import { TOCHospitalizationCard } from './TOCHospitalizationCard';

type Props = {
  type: 'TOC' | 'SKILLED';
};

type Params = {
  id: string;
  fullName?: string;
  status?: string;
  assignee?: string;
};

const MAX_EVENTS = 5;

const useStyles = makeStyles({
  back: {
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  bannerTabs: {
    '&&': {
      height: 'auto',
    },
  },
  banner: {
    position: 'relative',
    left: '-4rem',
    width: ' calc(100% + 8rem)',
  },
  brandIcon: {
    marginLeft: '.8rem',
  },
  buttonContainer: { flexFlow: 'row-reverse', justifyContent: 'space-between' },
  buttonEdit: {
    borderRadius: '5px',
    height: '3.6rem',
    minWidth: '7.5rem',
    padding: '0.25rem 0 0',

    '& svg': {
      marginTop: '-0.25rem',
    },
  },
  buttonsFooter: {
    height: 'fit-content',
    minWidth: '15rem',
  },
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
  ellipsis: {
    maxWidth: '54rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    '&&': {
      color: Colors.link,
      '&:hover': {
        color: Colors.textGray,
      },
    },
  },
  select: {
    marginTop: '-2rem',
    minWidth: '20rem',
  },
  subtitle: {
    alignItems: 'center',
    color: Colors.textGray,
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: Fonts.fontProxima,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
    gap: '0 1rem',
    letterSpacing: 0,
    maxWidth: '600px',
  },
  title: {
    fontFamily: Fonts.fontWorkSans,
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: '1.8px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  verticalLine: {
    backgroundColor: Colors.lightGray,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    height: 25,
    margin: '0 1rem',
    width: 1,
  },
});

export const TOCDetailPage = ({ type }: Props) => {
  const { id } = useParams<Params>();
  const queryParams = useQueryParams();
  const queryAssignee = queryParams.get('assignee');
  const memberId = queryParams.get('memberId');
  const queryName = queryParams.get('fullName');
  const queryStatus = queryParams.get('status');

  const history = useHistory();
  const styles = useStyles();

  const [loadingAssignee, setLoadingAssignee] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openEncounterModal, setOpenEncounterModal] = useState<boolean>(false);

  const [statuses, setStatuses] = useState<TOCStatus[]>([]);
  const [assignees, setAssignees] = useState<Employee[]>([]);
  const [assigneeGroups, setAssigneeGroups] = useState<EmployeeGroup[]>([]);
  const [status, setStatus] = useState<TOCStatus>();
  const [assignee, setAssignee] = useState<Employee | EmployeeGroup>();

  const [detail, setDetail] = useState<TOCDetail | SkilledDetail>();
  const [tocEvents, setTOCEvents] = useState<TOCEvent[]>([]);
  const [skilledEvents, setSkilledEvents] = useState<SkilledEvent[]>([]);
  const [tocEventsPagination, setTOCEventsPagination] = useState<
    PaginationType
  >();
  const [skilledEventsPagination, setSkilledEventsPagination] = useState<
    PaginationType
  >();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const [params, setParams] = useState<UpdateTOCParams | UpdateSkilledParams>(
    {},
  );

  const editable =
    detail instanceof TOCDetail
      ? !detail.tocRecord?.status.isClosed
      : !detail?.skilledRecord?.isClosed;

  const actingUserIsAllowed =
    assignee &&
    (assignee instanceof Employee
      ? Session.actingUser.id === assignee.id
      : Session.actingUser.activeGroups?.find(({ id }) => id === assignee.id));

  const member = detail?.memberDetail;

  const canEditTOC =
    Session.actingUser.canEditTOC &&
    !(detail instanceof SkilledDetail && !editable);

  const getInitialData = async () => {
    setLoadingData(true);
    try {
      const [e, eg, s] = await Promise.all([
        CacheServices.getEmployees(),
        CacheServices.getEmployeeGroupsAssignee(),
        CacheServices.getTOCStatus(),
      ]);

      setAssignees(e);
      setAssigneeGroups(eg);
      setStatuses(s);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingData(false);
    }
  };

  const getTOCEvents = async (memberId: number) => {
    const { items, pagination } = await fetchTOCEvents(memberId, {
      offset: 0,
      limit: MAX_EVENTS,
    });
    setTOCEvents(items);
    setTOCEventsPagination(pagination);
  };

  const getSkilledEvents = async (memberId: number) => {
    const {
      items: skilledItems,
      pagination: skilledPagination,
    } = await fetchSkilledEvents(memberId, {
      offset: 0,
      limit: MAX_EVENTS,
    });
    setSkilledEvents(skilledItems);
    setSkilledEventsPagination(skilledPagination);
  };

  const getTOCDetail = async () => {
    setLoadingDetail(true);
    try {
      const detail = await fetchTOCDetail(+id);
      setDetail(detail);

      await Promise.all([
        getSkilledEvents(detail.memberDetail.id),
        getTOCEvents(detail.memberDetail.id),
      ]);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingDetail(false);
    }
  };

  const getSkilledDetail = async () => {
    setLoadingDetail(true);
    try {
      const detail = await fetchSkilledDetail(+id);
      setDetail(detail);

      await Promise.all([
        getSkilledEvents(detail.memberDetail.id),
        getTOCEvents(detail.memberDetail.id),
      ]);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingDetail(false);
    }
  };

  const getMoreTOCEvents = async () => {
    if (detail) {
      try {
        const { items } = await fetchTOCEvents(detail.memberDetail.id, {
          offset: MAX_EVENTS,
          limit: 100,
        });
        setTOCEvents([...(tocEvents as TOCEvent[]), ...items]);
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getMoreSkilledEvents = async () => {
    if (detail) {
      try {
        const { items } = await fetchSkilledEvents(detail.memberDetail.id, {
          offset: MAX_EVENTS,
          limit: 100,
        });
        setSkilledEvents([...(skilledEvents as SkilledEvent[]), ...items]);
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const onChangeParams = (
    partial: Partial<UpdateTOCParams | UpdateSkilledParams>,
  ) => {
    setParams({
      ...params,
      ...partial,
    });
  };

  const reset = () => {
    if (type === 'TOC') {
      getTOCDetail();
    } else {
      getSkilledDetail();
    }
  };

  const validate = () => true;

  const saveEdit = async () => {
    setSubmitted(true);

    if (!validate()) return;

    setLoadingEdit(true);
    setLoadingDetail(true);

    try {
      if (type === 'TOC') {
        await updateTOCRecord(+id, params);
        await getTOCDetail();
      } else {
        await updateSkilledRecord(+id, params);
        await getSkilledDetail();
      }
      setSubmitted(false);
      setEditMode(false);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingEdit(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (type === 'TOC') {
      getTOCDetail();
    } else {
      getSkilledDetail();
    }
  }, [id]);

  useEffect(() => {
    if (detail instanceof TOCDetail) {
      setAssignee(
        detail?.tocRecord?.assignee ||
          detail?.tocRecord?.assigneeGroup ||
          UNASSIGNED,
      );
      setStatus(detail?.tocRecord?.status);
    } else {
      setAssignee(detail?.skilledRecord?.assignee || UNASSIGNED);
    }
    setParams({});
  }, [detail]);

  return (
    <Panel>
      <Panel.Heading
        title={
          <div className="block" style={{ marginTop: '-2.25rem' }}>
            <p className="no-margin">
              <Button
                href="blank"
                className={styles.back}
                data-cy="sms-back-button"
                onClick={() => history.goBack()}
              >
                <Icon className="fa fa-arrow-left" color="green" size="sm" />
                &nbsp;{translate('global.back')}
              </Button>
            </p>
            <p className={classNames(styles.title, 'no-margin')}>
              {member?.fullName || queryName} (
              <a className={styles.link} href={`#/patients/${member?.id}`}>
                {member?.id || memberId}
              </a>
              ){member?.isVPC() && <VestaCoreLiteTag patient={member} />}
              {member?.isBrandCareAtHome() && (
                <BrandMemberIcon
                  brand="careAtHome"
                  className={styles.brandIcon}
                  size="m"
                />
              )}
            </p>
            <p className={classNames(styles.subtitle, 'no-margin')}>
              {member?.birthDateFormatted && (
                <span>
                  {member.birthDateFormatted}{' '}
                  {`(${translate('global.yearsOld', {
                    age: moment().diff(moment(member?.birthDate), 'years'),
                  })})`}
                </span>
              )}
              <span>{member?.gender?.toString() || EMPTY}</span>
              <span>{member?.state}</span>
              {member?.npOwner && (
                <span>
                  {translate('common.npOwner')}:{' '}
                  {member.npOwner.fullName || EMPTY}
                </span>
              )}
              <MemberProgramExtensions
                programExtensions={member?.programExtensions}
              />
            </p>
          </div>
        }
      >
        <Panel.Actions>
          <div className="flex gap">
            {type === 'TOC' && detail instanceof TOCDetail && (
              <Select
                className={styles.select}
                getItemLabel={(item: TOCStatus) => item?.description}
                disabled={!actingUserIsAllowed}
                disableClearable
                items={statuses}
                label={translate('toc.common.status')}
                loading={loadingStatus || loadingData || loadingDetail}
                onChange={async (status?: TOCStatus) => {
                  if (status) {
                    setLoadingStatus(true);
                    // FIXME Set handoff disposition by default
                    const defatulHandoffDisposition =
                      status?.id === TOCStatus.HANDOFF &&
                      !(detail as TOCDetail).tocRecord?.handoffDisposition;

                    const { status: newStatus } = await updateTOCRecord(+id, {
                      handoffDispositionId: defatulHandoffDisposition
                        ? TOCHandoffStatus.WITHDREW
                        : undefined,
                      status: status?.id,
                    });

                    if (defatulHandoffDisposition) {
                      new Toast({
                        autoClose: 5000,
                        position: 'bottom-right',
                        title: translate('global.info'),
                        body: translate(
                          'toc.detail.messageDefaultHandoffDisposition',
                        ),
                        type: 'info',
                      });
                    }

                    if (
                      [detail.tocRecord.status.id, status.id].includes(
                        TOCStatus.HANDOFF,
                      )
                    ) {
                      await getTOCDetail();
                    }

                    setStatus(newStatus);
                    setStatus(status);
                    setLoadingStatus(false);
                  }
                }}
                readOnly={!canEditTOC}
                renderOption={(item: TOCStatus) => {
                  if (item.id === TOCStatus.HANDOFF)
                    return (
                      <Tooltip
                        text={
                          <div style={{ maxWidth: '26rem' }}>
                            {translate('toc.detail.infoTooltipHandoff')}
                          </div>
                        }
                      >
                        <span className="flex gap">
                          {item?.description}
                          <InfoIcon color="action" />
                        </span>
                      </Tooltip>
                    );
                  if (
                    item.id === TOCStatus.DISCHARGED &&
                    !detail.tocRecord.dischargeDate
                  )
                    return (
                      <Tooltip
                        enterDelay={50}
                        followCursor
                        text={translate('toc.detail.disabledTooltipDischarged')}
                      >
                        <span
                          className={styles.disabledOption}
                          onClick={(evt) => evt.stopPropagation()}
                        >
                          {item?.description}
                        </span>
                      </Tooltip>
                    );

                  return item?.description;
                }}
                size="small"
                value={
                  loadingStatus || loadingData || loadingDetail
                    ? { description: queryStatus || '' }
                    : status
                }
              />
            )}

            <SelectAssignee
              allowUnassigned
              assignToMe
              assignee={
                loadingAssignee || loadingData || loadingDetail
                  ? (({ label: queryAssignee || '' } as unknown) as Employee)
                  : assignee || UNASSIGNED
              }
              className={styles.select}
              currentUser={Session.actingUser}
              items={[
                ...(type === 'TOC' && detail instanceof TOCDetail
                  ? assigneeGroups
                  : []),
                ...assignees,
              ]}
              label={translate('toc.common.assignee')}
              loading={loadingAssignee || loadingData || loadingDetail}
              onChange={async (assignee?: Employee | EmployeeGroup) => {
                if (assignee) {
                  setLoadingAssignee(true);
                  if (type === 'TOC') {
                    const {
                      assignee: newAssignee,
                      assigneeGroup,
                    } = await updateTOCRecord(+id, {
                      assigneeId:
                        assignee instanceof Employee ? assignee?.id : null,
                      assigneeGroupId:
                        assignee instanceof EmployeeGroup ? assignee?.id : null,
                    });
                    setAssignee(
                      assignee instanceof Employee
                        ? newAssignee
                        : assigneeGroup,
                    );
                  } else {
                    const { assignee: newAssignee } = await updateSkilledRecord(
                      +id,
                      {
                        assigneeId: assignee?.id,
                      },
                    );
                    setAssignee(newAssignee);
                  }
                  setLoadingAssignee(false);
                }
              }}
              readOnly={!canEditTOC}
              size="small"
            />
            {canEditTOC && (
              <>
                <div className={styles.verticalLine} />
                <Button
                  className={styles.buttonEdit}
                  color="secondary"
                  disabled={
                    editable
                      ? editMode ||
                        (!actingUserIsAllowed &&
                          translate('toc.detail.disabledEditAssignee'))
                      : translate('toc.detail.disabledEditStatus')
                  }
                  loading={loadingEdit}
                  icon={<EditIcon />}
                  onClick={() => setEditMode(!editMode)}
                >
                  {translate('global.edit')}
                </Button>
                {type === 'SKILLED' && (
                  <Button
                    className={styles.buttonEdit}
                    color="secondary"
                    disabled={
                      editable
                        ? editMode ||
                          (!actingUserIsAllowed &&
                            translate('toc.detail.disabledEditAssignee'))
                        : translate('toc.detail.disabledEditStatus')
                    }
                    icon={<CompleteIcon />}
                    onClick={async () => {
                      setOpenCloseModal(true);
                    }}
                  >
                    {translate('global.close')}
                  </Button>
                )}
              </>
            )}
          </div>
        </Panel.Actions>
        {(member?.isBrandCareAtHome() || member?.isVPC()) && (
          <Panel.Tabs className={styles.bannerTabs}>
            <BrandBanner className={styles.banner} member={member} />
          </Panel.Tabs>
        )}
      </Panel.Heading>
      <Panel.Body loading={loadingDetail}>
        {!loadingDetail && (
          <div className="grid-wrapper">
            <div className="grid-span-6">
              {type === 'TOC' ? (
                <TOCHospitalizationCard
                  detail={detail as TOCDetail}
                  onChange={onChangeParams}
                  readonly={!editMode}
                  submitted={submitted}
                />
              ) : (
                <SkilledEpisodeCard
                  detail={detail as SkilledDetail}
                  onChange={onChangeParams}
                  readonly={!editMode}
                  submitted={submitted}
                />
              )}
              <br />
              <TOCEncountersCard
                data={detail?.encounters}
                onLogEncounter={() => setOpenEncounterModal(true)}
                type={type}
              />
            </div>
            <TOCDetailsCard
              className="grid-span-6"
              detail={detail}
              onChange={onChangeParams}
              readonly={!editMode}
              submitted={submitted}
              type={type}
            />
            <TOCEventsHistoryCard
              className="grid-span-12"
              data={tocEvents}
              member={detail?.memberDetail}
              onSeeMore={getMoreTOCEvents}
              pagination={tocEventsPagination}
              type="TOC"
            />
            <TOCEventsHistoryCard
              className="grid-span-12"
              data={skilledEvents}
              member={detail?.memberDetail}
              onSeeMore={getMoreSkilledEvents}
              pagination={skilledEventsPagination}
              type="SKILLED"
            />
          </div>
        )}
      </Panel.Body>
      {editMode && (
        <Panel.Footer white>
          <div className={classNames('flex extra-gap', styles.buttonContainer)}>
            <Button
              className={styles.buttonsFooter}
              color="secondary"
              data-cy="edit-incident-submit"
              loading={loadingEdit}
              onClick={saveEdit}
            >
              {translate('global.saveChanges')}
            </Button>
            <Button
              className={styles.buttonsFooter}
              color="tertiary"
              data-cy="edit-incident-cancel"
              onClick={() => {
                reset();
                setEditMode(false);
              }}
            >
              {translate('global.cancel')}
            </Button>
          </div>
          <br />
        </Panel.Footer>
      )}
      <AddEncounterModal
        autofillTime
        avoidRequiredMinutes
        direction={EncounterDirection.OUTBOUND}
        type={EncounterType.TCM}
        onClose={() => setOpenEncounterModal(false)}
        onSubmit={async () => {
          if (type === 'TOC') {
            await getTOCDetail();
          } else {
            await getSkilledDetail();
          }
          setOpenEncounterModal(false);
        }}
        open={openEncounterModal}
        patient={member?.id}
      />
      <Modal
        title={translate('global.warning')}
        body={translate('toc.detail.wargningCloseSkilled')}
        onClose={() => setOpenCloseModal(false)}
        onSubmit={async () => {
          const skilledRecord = await closeSkilledRecord(+id);
          setDetail({
            ...(detail as SkilledDetail),
            skilledRecord,
          });
        }}
        open={openCloseModal}
      />
    </Panel>
  );
};
