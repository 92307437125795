import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';
import { TaskCustomFieldOption } from '@vestahealthcare/common/models/TaskCustomFieldOption';

import { SortableList } from 'styleguide';
import {
  BackgroundColors,
  Button,
  CheckboxWithLabel,
  Colors,
  TextInput,
} from 'styleguide-v2';

type Props = {
  field: TaskCustomField;
  onDelete?: (field: TaskCustomField) => void;
  onEdit: (field: TaskCustomField) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.lighterGray,
    padding: '1rem 2rem',
    marginTop: '2rem',
    width: '100%',
  },
  choicesHeader: {
    alignItems: 'baseline',
    display: 'flex',
    flexFlow: 'column',
  },
  header: {
    color: Colors.textGreen,
  },
  button: {
    width: 'max-content',
  },
});

export const EditDropdownCustomField = (props: Props) => {
  const styles = useStyles();
  const { field, onDelete, onEdit, submitted } = props;
  const [editableField, setEditableField] = useState<TaskCustomField>(
    {} as TaskCustomField,
  );

  useEffect(() => {
    setEditableField({ ...field });
  }, [field]);

  const addChoice = () => {
    if (editableField.options) {
      setEditableField({
        ...editableField,
        options: [...editableField.options, {} as TaskCustomFieldOption],
      });
    } else {
      setEditableField({
        ...editableField,
        options: [{} as TaskCustomFieldOption],
      });
    }
  };

  return (
    <article
      className={`grid-wrapper grid-span-12 form-group ${styles.container}`}
    >
      <header className="grid-wrapper grid-span-12">
        <span className={`h7 grid-span-11 ${styles.header}`}>
          {translate('taskDefinitions.subtask.multipleSelect')}
        </span>
        {onDelete && (
          <Button
            color="info"
            icon={
              <i className="fa fa-trash" style={{ marginTop: '-0.125rem' }} />
            }
            type="outlined"
            className="grid-span-1"
            data-cy="delete-custom-field"
            onClick={() => onDelete && onDelete(field)}
            size="s"
          >
            {translate('global.delete')}
          </Button>
        )}
      </header>
      <TextInput
        className="grid-span-4"
        data-cy="custom-field-label-input"
        error={
          submitted &&
          !editableField.label &&
          translate('global.missingRequiredField')
        }
        label={translate(`taskDefinitions.subtask.dropdownLabel`)}
        value={editableField.label}
        onChange={(label = '') => {
          setEditableField({ ...editableField, label });
          onEdit({ ...editableField, label });
        }}
        required
      />

      <CheckboxWithLabel
        checked={editableField.required}
        className="grid-span-12"
        data-cy="custom-field-required-input"
        label={translate('taskDefinitions.subtask.requiredField')}
        onChange={(required = false) => {
          setEditableField({ ...editableField, required });
          onEdit({ ...editableField, required });
        }}
      />

      <div className={`grid-span-11 ${styles.choicesHeader}`}>
        <span className="h7">
          {translate('taskDefinitions.subtask.choices')}
        </span>
        {submitted && (editableField.options?.length || 0) < 2 ? (
          <span className="error-red hint">
            {translate('taskDefinitions.subtask.noChoicesError')}
          </span>
        ) : (
          <span className="hint">
            {translate('taskDefinitions.subtask.dragdropOutcome')}
          </span>
        )}
      </div>
      <Button
        className={`grid-span-1 ${styles.button}`}
        size="s"
        color="info"
        type="outlined"
        onClick={addChoice}
      >
        +&nbsp;{translate('taskDefinitions.subtask.addChoice')}
      </Button>

      {editableField?.options?.length ? (
        <SortableList
          className="grid-span-12"
          color="secondary"
          items={editableField.options || []}
          isEditable
          onChange={(newOptions) => {
            const options = (newOptions as TaskCustomFieldOption[]).map(
              (item, position) => ({ ...item, position }),
            );
            setEditableField({
              ...editableField,
              options,
            });
            onEdit({
              ...editableField,
              options,
            });
          }}
          submitted={submitted}
        />
      ) : undefined}
    </article>
  );
};

export default EditDropdownCustomField;
