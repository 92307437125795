import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { Modal } from 'styleguide-v2';

interface Props {
  loading?: boolean;
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<boolean>;
}

export const ClearConsentConfirmModal = ({
  open,
  onCancel,
  onSubmit,
}: Props) => (
  <Modal
    onClose={onCancel}
    onSubmit={onSubmit}
    open={open}
    submitText={translate('global.confirm')}
    title={translate('memberConsents.clearConsentTitle')}
  >
    {translate('memberConsents.clearConsentBody')}
  </Modal>
);

export default ClearConsentConfirmModal;
