import moment, { Moment } from 'moment';

import { EventReporterType } from '../enums';
import { BaseEnum } from './BaseEnum';
import { Employee } from './Employee';
import { EventIssueNote } from './EventIssueNote';
import { IssueDetailValue } from './IssueDetailValue';
import { IssuePrompt } from './IssuePrompt';
import { IssueType } from './IssueType';
import { Measurement } from './Measurement';
import { make, makeArray } from './Model';
import { Patient } from './Patient';
import { SourceChannel } from './SourceChannel';
import { UserBase } from './UserBase';

export class EventIssue {
  id = 0;

  issueType: IssueType;

  valid = false;

  source: SourceChannel;

  sourceOther = '';

  direction: BaseEnum;

  issueDatetime: Moment;

  hasTime = false;

  reporterMember?: Patient;

  reporterCareTeamMember?: UserBase;

  reporterMemberCommunityResource?: UserBase;

  createdBy: Employee;

  createdAt: Moment;

  urgent?: boolean;

  urgencyLevel?: number;

  updatedBy: Employee;

  updatedAt: Moment;

  notes?: EventIssueNote[];

  readings?: Measurement[];

  details: IssueDetailValue[];

  pendingPrompts?: IssuePrompt[];

  missingDetails = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.issueType = make(obj.issueType, IssueType);
    this.source = make(obj.source, SourceChannel);
    this.direction = make(obj.direction, BaseEnum);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.issueDatetime = moment.unix(obj.issueDatetime);
    this.reporterMember = make(obj.reporterMember, UserBase);
    this.reporterCareTeamMember = make(obj.reporterCareTeamMember, UserBase);
    this.reporterMemberCommunityResource = make(
      obj.reporterMemberCommunityResource,
      UserBase,
    );
    this.createdAt = moment.unix(obj.createdAt);
    this.updatedAt = moment.unix(obj.updatedAt);
    this.notes = makeArray(obj.notes, EventIssueNote);
    this.readings = obj.readings?.map(
      ({ reading }: { reading: object }) => new Measurement(reading),
    );
    this.details = makeArray(obj.details, IssueDetailValue);
    this.pendingPrompts = makeArray(obj.pendingPrompts, IssuePrompt);
  }

  get reporter() {
    if (this.reporterMember) {
      const { id, fullName } = this.reporterMember;
      return { id, fullName, type: EventReporterType.MEMBER };
    }
    if (this.reporterCareTeamMember) {
      const { id, fullName } = this.reporterCareTeamMember;
      return { id, fullName, type: EventReporterType.CARE_TEAM };
    }
    if (this.reporterMemberCommunityResource) {
      const { id, fullName } = this.reporterMemberCommunityResource;
      return { id, fullName, type: EventReporterType.COMMUNITY_RESOURCE };
    }
    return null;
  }
}
