import moment from 'moment';
// @ts-ignore
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import makeStyles from '@mui/styles/makeStyles';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Encounter,
  EventDetail,
  EventIntervention,
  EventModel,
  EventNote,
  EventType,
  IssuePrompt,
  IssueType,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EventIssue } from '@vestahealthcare/common/models/EventIssue';
import { EventStatus } from '@vestahealthcare/common/models/EventStatus';
import { EventStatusGroup } from '@vestahealthcare/common/models/EventStatusGroup';
import { LS_MEMBER_EVENTS_FILTERS } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  Chip,
  IconButton,
  Modal,
  Panel,
  Select,
  Spinner,
  Tabs,
  ToggleDateRange,
} from 'styleguide-v2';
import { ChipColor } from 'styleguide-v2/src/components/Chip';

import {
  showGlobalError,
  showGlobalWarning,
} from 'dash/src/components/GlobalMessage';
import { CreateIncidentModal } from 'dash/src/pages/Incidents/CreateIncidentModal';
import { AddTaskModal } from 'dash/src/pages/Tasks/AddTaskModal';
import {
  onAddEvent,
  onEventChangeStatus,
} from 'dash/src/redux/slices/EventsSlice';
import {
  fetchPatientById,
  fetchPatientWarnings,
} from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import {
  UpdateEventParams,
  acceptEventIssuePrompt,
  closeDocumentedEvent,
  closeEvent,
  createEvent,
  createEventEncounter,
  createEventIssue,
  createEventNote,
  fetchEventDetail,
  fetchMemberEvents,
  fetchMemberEventsSummary,
  forceCloseEvent,
  invalidateEvent,
  linkEvent,
  unlinkIssues,
  updateEvent,
  updateEventNote,
} from 'dash/src/services/EventServices';
import { createIncident } from 'dash/src/services/IncidentServices';
import Session from 'dash/src/services/SessionServices';
import {
  createSkilledRecordFromEvent,
  createTOCRecordFromEvent,
} from 'dash/src/services/TOCServices';
import { transformDateToDaysRange } from 'dash/src/utils/dateUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { MemberEventsDetail, RefreshHandle } from './MemberEventsDetail';
import { EventTableModel, MemberEventsTable } from './MemberEventsTable';
import { EventsInterventionsSummary } from './components/EventsInterventionsSummary';
import { EventsLastEncounter } from './components/EventsLastEncounter';
import { MemberEventAddModal } from './modals/MemberEventAdd';
import { MemberEventAddEncounter } from './modals/MemberEventAddEncounter';
import { MemberEventAddIssueModal } from './modals/MemberEventAddIssue';
import { MemberEventAddNote } from './modals/MemberEventAddNote';
import { MemberEventCloseModal } from './modals/MemberEventClose';
import { MemberEventFollowUpInfo } from './modals/MemberEventFollowUpInfo';
import { MemberEventHistoryModal } from './modals/MemberEventHistory';
import { MemberEventInvalidate } from './modals/MemberEventInvalidate';
import { MemberEventLinkModal } from './modals/MemberEventLink';
import { MemberModifyUrgencyModal } from './modals/MemberEventModifyUrgency';
import { EventActions } from './types/EventActions';

const useStyles = makeStyles({
  actions: {
    paddingTop: '1.125rem',
  },
  chips: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
  dateRange: {
    marginTop: '-2.5rem',
  },
  filtersContainer: {
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem 2rem',
    maxWidth: 'calc(100vw - 55rem)',
  },
  sort: {
    minWidth: '22.5rem',
  },
  subtitleContainer: {
    display: 'flex',
    gap: '2rem',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: 'fit-content',
    },
  },
  table: {
    marginBottom: '20rem',
  },
  toggleButtonReduced: {
    '&&': {
      margin: 'auto 0',
    },
  },
});

const ALL_EVENT_VALUE = 'ALL';
const OPEN_EVENT_VALUE = 'OPEN';
const CLOSED_EVENT_VALUE = 'CLOSED';
const OPEN_CLOSE_EVENT_ITEMS = [
  { label: translate('memberEvents.allEvents'), value: ALL_EVENT_VALUE },
  { label: translate('memberEvents.openEvents'), value: OPEN_EVENT_VALUE },
  { label: translate('memberEvents.closedEvents'), value: CLOSED_EVENT_VALUE },
];

const EVENTS_TABLE_LIMIT = 25;
const FOLLOW_UP_STATUSES = [
  EventStatus.NON_URGENT_FOLLOW_UP,
  EventStatus.URGENT_FOLLOW_UP,
];

interface SortOption extends Selectable {
  sort: string;
}

const SORT_OPTIONS: SortOption[] = [
  {
    label: translate('dashboardEvents.sort.createdNewest'),
    value: 'created-newest',
    sort: 'createdAt desc, followUpDatetime asc,  urgencyLevel asc',
  },
  {
    label: translate('dashboardEvents.sort.createdOldest'),
    value: 'created-oldest',
    sort: 'createdAt asc, followUpDatetime desc,  urgencyLevel desc',
  },
  {
    label: translate('dashboardEvents.sort.eventNewest'),
    value: 'event-newest',
    sort: 'eventDatetime desc, followUpDatetime asc,  urgencyLevel asc',
  },
  {
    label: translate('dashboardEvents.sort.eventOldest'),
    value: 'event-oldest',
    sort: 'eventDatetime asc, followUpDatetime desc,  urgencyLevel desc',
  },
  {
    label: translate('dashboardEvents.sort.urgency'),
    value: 'urgencyLevel',
    sort: 'urgencyLevel asc, followUpDatetime asc, eventDatetime desc',
  },
  {
    label: translate('dashboardEvents.sort.followUp'),
    value: 'follow-up-desc',
    sort: 'followUpDatetime asc, urgencyLevel desc eventDatetime desc',
  },
];

type Props = {
  patientId: string;
  eventId?: string;
};

type EventsCount = {
  count: number;
  value: number;
  active: boolean;
  label: string;
};

const initFilters = (query: URLSearchParams) => {
  const filtersString = localStorage.getItem(LS_MEMBER_EVENTS_FILTERS) || '';
  const f = qs.parse(filtersString) || {};

  const dateRange =
    query.get('defaultToggleRange') || f.defaultToggleRange || -1;
  const defaultToggleRange = isNaN(Number(dateRange))
    ? dateRange
    : Number(dateRange);
  const sort = query.get('sort') || f.sort;

  return {
    defaultToggleRange,
    sort,
  };
};

let pendingAction:
  | {
      action: Selectable;
      data: any;
    }
  | undefined;

export const MemberEventDashboard = ({
  match: {
    params: { patientId, eventId },
  },
}: RouteComponentProps<Props>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();
  const { actingUser } = Session;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

  const eventsRef = useRef<RefreshHandle[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const [showOpenEvents, setShowOpenEvents] = useState<string>(
    OPEN_CLOSE_EVENT_ITEMS[0].value,
  );

  const { defaultToggleRange: dTR, sort: dSort } = initFilters(query);
  const [defaultToggleRange, setDefaultToggleRange] = useState<number | string>(
    dTR,
  );
  const [sort, setSort] = useState<SortOption>(
    SORT_OPTIONS.find(({ value }) => value === dSort) || SORT_OPTIONS[2],
  );
  const [interval, setDateInterval] = useState<
    [Date | undefined, Date | undefined]
  >();

  const [openAddEvent, setOpenAddEvent] = useState<boolean>(false);
  const [openAddEventIssue, setOpenAddEventIssue] = useState<boolean>(false);
  const [openAddEncounter, setOpenAddEncounter] = useState<boolean>(false);
  const [openAddNote, setOpenAddNote] = useState<boolean>(false);
  const [openAddTask, setOpenAddTask] = useState<boolean>(false);
  const [openCloseEvent, setOpenCloseEvent] = useState<boolean>(false);
  const [openInvalidateEvent, setOpenInvalidateEvent] = useState<boolean>(
    false,
  );
  const [openLinkEvent, setOpenLinkEvent] = useState<boolean>(false);
  const [openModifyFollowUp, setOpenModifyFollowUp] = useState<boolean>(false);
  const [followUpStatus, setFollowUpStatus] = useState<EventStatus>();
  const [openModifyUrgency, setOpenModifyUrgency] = useState<boolean>(false);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [openAddIncident, setOpenAddIncident] = useState<boolean>(false);
  const [eventIncidentMultiStep, setEventIncidentMultiStep] = useState<boolean>(
    false,
  );
  const [eventPromptDetail, setEventPromptDetail] = useState<EventDetail>();
  const [eventIncidentDetail, setEventIncidentDetail] = useState<EventDetail>();
  const [eventModalDetail, setEventModalDetail] = useState<EventModel>();
  const [eventModalData, setEventModalData] = useState<any>();

  const [eventsCount, setEventsCount] = useState<EventsCount[]>([]);
  const [lastEncounter, setLastEncounter] = useState<Encounter>();
  const [interventions, setInterventions] = useState<EventIntervention[]>([]);
  const patient = useSelector((state) => state.memberInfoSlice.patient);

  const [events, setEvents] = useState<EventModel[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const getEventsSummary = async (avoidRefreshList?: boolean) => {
    if (patientId && interval) {
      let timer: NodeJS.Timeout | null = null;

      if (!lastEncounter) {
        setLoadingSummary(true);
      } else {
        timer = setTimeout(() => setLoadingSummary(true), 200);
      }

      setLoading(true);
      try {
        const {
          events,
          interventions,
          lastEncounter: le,
        } = await fetchMemberEventsSummary(Number(patientId), {
          from: interval[0] ? moment(interval[0].getTime()) : undefined,
          to: interval[1] ? moment(interval[1].getTime()) : undefined,
        });
        if (!avoidRefreshList) {
          setEvents([]);
          setEventsCount(
            events
              .map(({ count, type }) => ({
                count,
                label: `${count} ${type.name}`,
                value: type.id,
                active: false,
              }))
              .sort(({ value: a }, { value: b }) => a - b),
          );
        } else {
          setLoading(false);
        }
        setInterventions(interventions);
        setLastEncounter(le);
        if (timer) {
          clearTimeout(timer);
        }
      } catch (e) {
        showGlobalError(e as string);
      }
      setLoadingSummary(false);
    }
  };

  const getEventDetail = async () => {
    let detail: EventTableModel | undefined;
    setLoading(true);
    try {
      detail = await fetchEventDetail(Number(eventId));
      detail.open = true;
      detail.detail = (
        <MemberEventsDetail
          eventId={Number(eventId)}
          onEventAction={onEventAction}
          onCreateIntervention={async () => await getEventsSummary(true)}
          onUnlinkIssue={onUnlinkIssue}
          patient={patient}
          ref={(ref: RefreshHandle) => (eventsRef.current[0] = ref)}
        />
      );
      setEvents([detail]);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
    return detail;
  };

  useEffect(() => {
    if (eventId) {
      getEventDetail();
    } else {
      getEventsSummary();
    }
  }, [patientId, interval, eventId]);

  const getEvents = async (pagination?: PaginationType) => {
    if (!interval) return;
    if (
      pagination &&
      pagination.offset > pagination.total - EVENTS_TABLE_LIMIT
    ) {
      return;
    }

    setLoading(true);
    if (!pagination) {
      setEvents([]);
    }

    try {
      let statusGroup: string[] = [];
      if (showOpenEvents === OPEN_EVENT_VALUE) {
        statusGroup = [EventStatusGroup.OPEN, EventStatusGroup.IN_PROGRESS];
      } else if (showOpenEvents === CLOSED_EVENT_VALUE) {
        statusGroup = [CLOSED_EVENT_VALUE];
      }

      const { items, pagination: newPagination } = await fetchMemberEvents(
        Number(patientId),
        {
          from: interval[0] ? moment(interval[0].getTime()) : undefined,
          to: interval[1] ? moment(interval[1].getTime()) : undefined,
          typeId: eventsCount
            .filter(({ active }) => active)
            .map(({ value }) => value),
          statusGroup,
          offset: pagination ? pagination?.offset + EVENTS_TABLE_LIMIT : 0,
          limit: EVENTS_TABLE_LIMIT,
          sort: sort.sort,
        },
      );
      if (pagination) {
        setEvents([...events, ...items]);
      } else {
        setEvents(items);

        if (ref?.current?.scrollIntoView) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      setPagination(newPagination);
    } catch (e) {
      showGlobalError(e as string);
    }

    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, [eventsCount, showOpenEvents, sort]);

  useEffect(() => {
    eventsRef.current = eventsRef.current.slice(0, events.length);
  }, [events]);

  const storeFilters = () => {
    const flatFilters = {
      defaultToggleRange,
      sort: sort.value,
    };
    const query = qs.stringify(flatFilters);
    history.replace(`?${query}`);

    localStorage.setItem(LS_MEMBER_EVENTS_FILTERS, qs.stringify(flatFilters));
  };

  useEffect(() => {
    storeFilters();
  }, [defaultToggleRange, sort]);

  const onScrollBottom = () => {
    getEvents(pagination);
  };

  const onUnlinkIssue = (event: EventDetail, issues: EventIssue[]) => {
    setEventModalDetail(event);
    setEventModalData(issues);
    setOpenAddEvent(true);
  };

  const addDetail = (evt: EventTableModel, index: number) => {
    if (!evt.open) {
      evt.open = true;
      evt.detail = (
        <MemberEventsDetail
          eventId={evt.id}
          ref={(ref: RefreshHandle) => (eventsRef.current[index] = ref)}
          onCreateIntervention={async () => await getEventsSummary(true)}
          onEventAction={onEventAction}
          onRetrieveDetail={(detail) => {
            setTimeout(() => {
              evt.detailData = detail;
              setEvents([...events]);
            }, 50);
          }}
          onUnlinkIssue={onUnlinkIssue}
          patient={patient}
        />
      );
      setEvents([...events]);
    }
  };

  const setActionLoading = (index: number, value: boolean) => {
    if (events[index]) {
      (events[index] as EventTableModel).actionLoading = value;
      setEvents([...events]);
    }
  };

  const getCompleteEvent = async (event: EventModel | EventDetail) => {
    let detail = event as EventDetail;
    if (!(event instanceof EventDetail)) {
      detail = await fetchEventDetail(event.id);
    }
    return detail;
  };

  const doAddNoteEvent = async (
    event: EventModel,
    eventIndex: number,
    modalData?: any,
  ) => {
    setActionLoading(eventIndex, true);
    const detail = await fetchEventDetail(event.id);
    if (!detail.hasEvaluationOpen() || modalData?.id) {
      setOpenAddNote(true);
      setEventModalData(modalData);
    } else {
      showGlobalWarning({
        title: translate('memberEvents.warningAddNoteTitle'),
        messages: [{ message: translate('memberEvents.warningAddNoteBody') }],
        hideContactUs: true,
      });
    }
    setActionLoading(eventIndex, false);
  };

  const doShowHistoryEvent = async (event: EventModel, eventIndex: number) => {
    setActionLoading(eventIndex, true);
    const detail = await getCompleteEvent(event);
    setEventModalDetail(detail);
    setOpenHistory(true);
    setActionLoading(eventIndex, false);
  };

  const checkMissingDetails = async (
    event: EventModel,
    eventIndex: number,
    currentPendingAction: EventActions,
  ) => {
    const detail = await fetchEventDetail(event.id);

    if (detail.hasMissingDetails) {
      addDetail(events[eventIndex] as EventTableModel, eventIndex);
      const timer = setInterval(() => {
        if (eventsRef.current[eventIndex]) {
          eventsRef.current[eventIndex].missingDetails();
          clearInterval(timer);
        }
      }, 100);

      const issues = detail.getMissingDetails();
      if (issues?.length > 1) {
        showGlobalWarning({
          title: translate('memberEvents.warningMissingFieldsTitle'),
          messages: [
            { message: translate('memberEvents.warningMissingFieldsBody') },
          ],
          hideContactUs: true,
        });
        eventsRef.current[eventIndex].missingDetails();
      } else {
        new Toast({
          title: translate('memberEvents.warningMissingFieldsTitle'),
          body: translate('memberEvents.warningMissingFieldsBody'),
          type: 'warning',
          position: 'bottom-right',
        });
        eventsRef.current[eventIndex].editIssue(issues[0], true);
        pendingAction = {
          action: {
            value: currentPendingAction.value,
            label: currentPendingAction.toString(),
          } as Selectable,
          data: detail,
        };
      }
    }
    return {
      hasMissingDetails: detail.hasMissingDetails,
      detail,
    };
  };

  const doCloseEvent = async (event: EventModel, eventIndex: number) => {
    const detail = await getCompleteEvent(event);
    setActionLoading(eventIndex, true);
    if (detail.canCloseEvent()) {
      const { hasMissingDetails } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.CLOSE_EVENT,
      );
      if (!hasMissingDetails) {
        setOpenCloseEvent(true);
      }
    } else if (detail.isClosed) {
      setOpenCloseEvent(true);
    } else if (detail.canForceCloseEvent(actingUser, patient)) {
      setActionLoading(eventIndex, true);
      await forceCloseEvent(event.id);
      await getEventsSummary();
      updateWarnings();
      setActionLoading(eventIndex, false);
    } else {
      showGlobalWarning({
        title: translate('memberEvents.warningCloseEventTitle'),
        messages: [
          { message: translate('memberEvents.warningCloseEventBody') },
        ],
        hideContactUs: true,
      });
    }
    setActionLoading(eventIndex, false);
  };

  const doSubmitIncident = async (event: EventModel, eventIndex: number) => {
    if (
      [EventType.FALL, EventType.ER_VISIT, EventType.FACILITY].includes(
        event.type.id,
      )
    ) {
      setActionLoading(eventIndex, true);
      const { hasMissingDetails, detail } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.SUBMIT_INCIDENT,
      );
      if (!hasMissingDetails) {
        setEventIncidentDetail(detail);
        setOpenAddIncident(true);
      }
    } else {
      setOpenAddIncident(true);
    }
    setActionLoading(eventIndex, false);
  };

  const doSubmitToc = async (event: EventModel, eventIndex: number) => {
    const detail = await getCompleteEvent(event);
    if (!detail?.member?.id) return;

    setActionLoading(eventIndex, true);
    const { id } = await createTOCRecordFromEvent(detail.member.id, event.id);
    setActionLoading(eventIndex, false);
    window.open(`#/dashboard/toc/${id}`, '_blank', 'noreferrer');
  };

  const doSubmitSkilled = async (event: EventModel, eventIndex: number) => {
    const detail = await getCompleteEvent(event);
    if (!detail?.member?.id) return;

    setActionLoading(eventIndex, true);
    const { id } = await createSkilledRecordFromEvent(
      detail.member.id,
      event.id,
    );
    setActionLoading(eventIndex, false);
    window.open(`#/dashboard/skilled/${id}`, '_blank', 'noreferrer');
  };

  const doCloseDocumented = async (event: EventModel, eventIndex: number) => {
    try {
      const { hasMissingDetails } = await checkMissingDetails(
        event,
        eventIndex,
        EventActions.CLOSE_DOCUMENTED,
      );
      if (!hasMissingDetails) {
        await closeDocumentedEvent(event.id);
        await getEventsSummary();
      }
      updateWarnings();
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const onEventAction = async (
    { value }: Selectable,
    event: EventModel | EventDetail,
    index?: number,
    modalData?: any,
  ) => {
    const eventIndex = index || events?.findIndex(({ id }) => id === event.id);
    setEventModalDetail(event);
    if (value === EventActions.ADD_ADDENDUM.value) {
      setOpenAddNote(true);
      setEventModalData(new EventNote({ addendum: true }));
    } else if (value === EventActions.ADD_ISSUE.value) {
      setOpenAddEventIssue(true);
    } else if (value === EventActions.ADD_ENCOUNTER.value) {
      setOpenAddEncounter(true);
    } else if (value === EventActions.ADD_NOTE.value) {
      await doAddNoteEvent(event, eventIndex, modalData);
    } else if (value === EventActions.ADD_TASK.value) {
      setOpenAddTask(true);
    } else if (value === EventActions.SHOW_HISTORY.value) {
      doShowHistoryEvent(event, eventIndex);
    } else if (value === EventActions.CLOSE_EVENT.value) {
      await doCloseEvent(event, eventIndex);
    } else if (value === EventActions.LINK_EVENT.value) {
      setOpenLinkEvent(true);
    } else if (value === EventActions.MODIFY_FOLLOW_UP.value) {
      setOpenModifyFollowUp(true);
      setEventModalData(modalData);
    } else if (value === EventActions.INVALIDATE_EVENT.value) {
      setOpenInvalidateEvent(true);
    } else if (value === EventActions.CLOSE_DOCUMENTED.value) {
      await doCloseDocumented(event, eventIndex);
    } else if (value === EventActions.MODIFY_URGENCY.value) {
      setOpenModifyUrgency(true);
    } else if (value === EventActions.UNLINK_ISSUE.value) {
      addDetail(events[eventIndex] as EventTableModel, eventIndex);
      const timer = setInterval(() => {
        if (eventsRef.current[eventIndex]) {
          eventsRef.current[eventIndex].unlinkIssues();
          clearInterval(timer);
        }
      }, 100);
    } else if (value === EventActions.SUBMIT_INCIDENT.value) {
      doSubmitIncident(event, eventIndex);
    } else if (value === EventActions.VIEW_INCIDENT.value) {
      window.open(
        `#/admin/incidents/${event.incidentId}`,
        '_blank',
        'noreferrer',
      );
    } else if (value === EventActions.SUBMIT_TOC.value) {
      doSubmitToc(event, eventIndex);
    } else if (value === EventActions.VIEW_TOC.value) {
      const detail = await getCompleteEvent(event);
      window.open(
        `#/dashboard/toc/${detail.tocRecordId}`,
        '_blank',
        'noreferrer',
      );
    } else if (value === EventActions.SUBMIT_SKILLED.value) {
      doSubmitSkilled(event, eventIndex);
    } else if (value === EventActions.VIEW_SKILLED.value) {
      const detail = await getCompleteEvent(event);
      window.open(
        `#/dashboard/skilled/${detail.skilledRecordId}`,
        '_blank',
        'noreferrer',
      );
    } else if (
      value === EventActions.CHECK_PENDING_ACTIONS.value &&
      pendingAction?.action
    ) {
      onEventAction(pendingAction.action, pendingAction.data);
      pendingAction = undefined;
    }
  };

  const refreshDetail = (id: number) => {
    const eventIdx = events.findIndex(({ id: evtId }) => evtId === id);
    if (eventIdx !== -1) {
      eventsRef.current[eventIdx]?.refresh();
    }
  };

  const updateWarnings = () => {
    setTimeout(() => {
      if (patient) {
        dispatch(fetchPatientWarnings(patient.id));
      }
    }, 250);
  };

  return (
    <Panel id="member-event-section">
      {!eventId && (
        <Panel.Heading title={translate('memberEvents.title')}>
          <Panel.Filters className={styles.filtersContainer}>
            <ToggleDateRange
              className={styles.dateRange}
              items={[-1, 1, 7, 30, 90, 'custom']}
              onChange={(from, to) => {
                if (from && to) {
                  // eslint-disable-next-line @typescript-eslint/no-implied-eval
                  setDateInterval([from, to]);
                  setDefaultToggleRange(
                    transformDateToDaysRange(from, to, [1, 7, 30, 90]),
                  );
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-implied-eval
                  setDateInterval([undefined, undefined]);
                  setDefaultToggleRange(-1);
                }
              }}
              defaultValue={defaultToggleRange}
            />
            <EventsLastEncounter
              lastEncounter={lastEncounter}
              loading={!lastEncounter && loadingSummary}
            />
            <EventsInterventionsSummary
              interventions={interventions}
              loading={loadingSummary}
            />
          </Panel.Filters>
          <Panel.Actions className={styles.actions}>
            <IconButton
              tooltip={translate('memberEvents.addEvent')}
              onClick={() => {
                setEventModalDetail(undefined);
                setOpenAddEvent(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Panel.Actions>
          <Panel.Tabs>
            <div className={styles.tabsContainer}>
              <Tabs
                disabled={loading || loadingSummary}
                items={OPEN_CLOSE_EVENT_ITEMS}
                onChange={setShowOpenEvents}
                value={showOpenEvents}
              />
              <div className={styles.chips}>
                {loadingSummary ? (
                  <Spinner color="primary" />
                ) : (
                  <>
                    {eventsCount.map(
                      ({ label, active, value, ...others }, index) => (
                        <Chip
                          color={
                            ['primary', 'secondary', 'info'][
                              index % 3
                            ] as ChipColor
                          }
                          key={label}
                          label={label}
                          type={active ? 'contained' : 'outlined'}
                          onClick={() => {
                            const current = {
                              label,
                              active: !active,
                              value,
                              ...others,
                            };
                            eventsCount[index] = current;
                            setEventsCount([...eventsCount]);
                          }}
                          size="large"
                        />
                      ),
                    )}
                    {eventsCount?.length > 1 && (
                      <>
                        <ArrowForwardIcon color="action" />
                        <Chip
                          color="action"
                          label={`${eventsCount.reduce(
                            (acc, { count }) => acc + count,
                            0,
                          )} total`}
                          type="outlined"
                          size="large"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              <Select
                className={styles.sort}
                disabled={loading || loadingSummary}
                disableClearable
                flow="horizontal"
                items={SORT_OPTIONS}
                label={translate('dashboardEvents.sortBy')}
                onChange={(sort: SortOption) => {
                  setPagination(undefined);
                  setSort(sort);
                }}
                size="xs"
                value={sort}
              />
            </div>
          </Panel.Tabs>
        </Panel.Heading>
      )}
      <Panel.Body
        loading={loading && !events?.length}
        onScrollBottom={eventId ? undefined : () => onScrollBottom()}
      >
        <div ref={ref} style={{ position: 'relative', top: '-2rem' }} />
        <MemberEventAddModal
          open={openAddEvent}
          event={eventModalDetail as EventDetail}
          issues={eventModalData as EventIssue[]}
          onSubmit={async (params, event, issues) => {
            let result = true;
            try {
              let detail: EventDetail;
              if (event && issues) {
                detail = await unlinkIssues({
                  ...params,
                  issueIds: issues,
                });
              } else {
                detail = await createEvent(params);
                const prompts = detail.getPendingPrompts();
                if (prompts.length) {
                  setOpenPrompt(true);
                  setEventModalData(prompts[0]);
                  setEventPromptDetail(detail);
                }
              }
              if (
                detail.issues?.find(({ issueType }) =>
                  [
                    IssueType.ER_VISIT,
                    IssueType.FALL,
                    IssueType.HOSPITALIZATION,
                  ].includes(issueType.id),
                )
              ) {
                setEventIncidentDetail(detail);
                setEventIncidentMultiStep(true);
                setOpenAddIncident(true);
                setOpenAddEvent(false);
              }

              if (
                detail.issues?.find(({ issueType }) =>
                  [
                    IssueType.HOSPITALIZATION,
                    IssueType.SKILLED,
                    IssueType.SNF,
                  ].includes(issueType.id),
                )
              ) {
                dispatch(fetchPatientById(+patientId));
              }

              await getEventsSummary();
              await getEvents();

              updateWarnings();
              dispatch(onAddEvent());
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenAddEvent(false)}
          patient={patient}
        />
        <MemberEventCloseModal
          open={openCloseEvent}
          event={eventModalDetail as EventDetail}
          onSubmit={async (event, params) => {
            let result = true;
            try {
              let status: EventStatus;
              const { id } = event;
              if (event.isClosed) {
                status = (await updateEvent(id, params as UpdateEventParams))
                  .status;
                const index = events.findIndex(
                  ({ id: listId }) => id === listId,
                );
                if (index !== -1) {
                  events[index].status = status;
                  setEvents([...events]);
                  refreshDetail(id);
                }
              } else {
                const newEvent = await closeEvent(id, params);
                dispatch(onEventChangeStatus(newEvent));
                updateWarnings();
                getEventsSummary();
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenCloseEvent(false)}
        />
        <MemberEventInvalidate
          open={openInvalidateEvent}
          event={eventModalDetail as EventDetail}
          onSubmit={async (event, params) => {
            let result = true;
            try {
              const { id } = event;
              const newEvent = await invalidateEvent(id, params);
              dispatch(onEventChangeStatus(newEvent));
              updateWarnings();
              getEventsSummary();
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenInvalidateEvent(false)}
        />
        <MemberEventAddEncounter
          open={openAddEncounter}
          event={eventModalDetail as EventDetail}
          onSubmit={async ({ id }, params) => {
            let result = true;
            try {
              await createEventEncounter(Number(patientId), id, params);
              await getEventsSummary(true);
              refreshDetail(id);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenAddEncounter(false)}
          patient={patient}
        />
        <MemberEventAddNote
          note={eventModalData as EventNote}
          open={!!openAddNote}
          onClose={() => setOpenAddNote(false)}
          onSubmit={async (note) => {
            let result = true;
            try {
              if (eventModalDetail?.id) {
                if (note.id) {
                  await updateEventNote(eventModalDetail?.id, note);
                } else {
                  await createEventNote(eventModalDetail?.id, note);
                }
                refreshDetail(eventModalDetail?.id);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
        />
        <AddTaskModal
          event={eventModalDetail as EventModel}
          member={patient}
          open={openAddTask}
          onSubmit={async () => {
            if (eventModalDetail?.id) {
              refreshDetail(eventModalDetail.id);
            }
          }}
          onClose={() => setOpenAddTask(false)}
        />
        <MemberEventAddIssueModal
          event={eventModalDetail}
          open={openAddEventIssue}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              const issue = await createEventIssue(id, params);
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === id,
              );
              if (eventIdx !== -1) {
                const event = await fetchEventDetail(id);
                events[eventIdx] = event;
                setEvents([...events]);
              }
              refreshDetail(id);
              if (issue?.pendingPrompts?.length) {
                setOpenPrompt(true);
                setEventModalData(issue.pendingPrompts[0]);
                const event = await fetchEventDetail(id);
                setEventPromptDetail(event);
              }
              if (
                [
                  IssueType.HOSPITALIZATION,
                  IssueType.SKILLED,
                  IssueType.SNF,
                ].includes(issue.issueType.id)
              ) {
                dispatch(fetchPatientById(+patientId));
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }

            return result;
          }}
          onClose={() => setOpenAddEventIssue(false)}
          patient={patient}
        />
        <MemberEventLinkModal
          event={eventModalDetail}
          open={openLinkEvent}
          onSubmit={async (params) => {
            let result = true;
            try {
              await linkEvent(params);
              if (eventModalDetail) {
                refreshDetail(eventModalDetail.id);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenLinkEvent(false)}
          patient={patient}
        />
        <MemberEventFollowUpInfo
          event={eventModalDetail}
          open={openModifyFollowUp}
          requireFieldsToClose={eventModalData}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              const event = await updateEvent(id, {
                ...params,
                statusId: followUpStatus,
              });
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === id,
              );
              if (eventIdx !== -1 && event) {
                events[eventIdx] = Object.assign(new EventModel({}), event);
                setEvents([...events]);
              }
              setOpenModifyFollowUp(false);
              setFollowUpStatus(undefined);
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onCancel={() => {
            if (eventModalDetail) {
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === eventModalDetail.id,
              );
              if (events[eventIdx]) {
                events[eventIdx] = Object.assign(
                  new EventModel({}),
                  eventModalDetail,
                );
              }
              setEvents([...events]);
            }
            setOpenModifyFollowUp(false);
            setFollowUpStatus(undefined);
          }}
        />
        <MemberModifyUrgencyModal
          event={eventModalDetail}
          open={openModifyUrgency}
          onSubmit={async (id, params) => {
            let result = true;
            try {
              await updateEvent(id, params);
              const eventIdx = events.findIndex(
                ({ id: evtId }) => evtId === id,
              );
              if (eventIdx !== -1) {
                events[eventIdx].urgent = !!params.urgent;
                events[eventIdx].urgencyLevel = params.urgencyLevel;
                setEvents([...events]);
              }
            } catch (e) {
              showGlobalError(e as string);
              result = false;
            }
            return result;
          }}
          onClose={() => setOpenModifyUrgency(false)}
        />
        <MemberEventHistoryModal
          event={eventModalDetail as EventDetail}
          onClose={() => setOpenHistory(false)}
          open={openHistory}
        />
        {patient && (
          <CreateIncidentModal
            defaultType={eventIncidentDetail?.getIncidentTypeAssociated()}
            defaultFields={eventIncidentDetail?.getIncidentData()}
            isEventWorkflow
            member={patient}
            multiStep={eventIncidentMultiStep}
            open={openAddIncident}
            onClose={() => {
              setOpenAddIncident(false);
              setTimeout(() => {
                setEventIncidentMultiStep(false);
                setEventIncidentDetail(undefined);
              }, 1000);
            }}
            onCreate={async (params) => {
              await createIncident({
                ...params,
                eventId: eventIncidentDetail?.id,
              });
              await getEvents();
            }}
          />
        )}
        {(events?.length || !loading) && (
          <MemberEventsTable
            className={styles.table}
            events={events}
            loading={loading}
            member={patient}
            onClickRow={(evt, idx) => {
              evt.open = !evt.open;
              if (evt.open) {
                evt.detail = (
                  <MemberEventsDetail
                    eventId={evt.id}
                    ref={(ref: RefreshHandle) => (eventsRef.current[idx] = ref)}
                    onCreateIntervention={async () =>
                      await getEventsSummary(true)
                    }
                    onEventAction={onEventAction}
                    onRetrieveDetail={(detail) => {
                      setTimeout(() => {
                        evt.detailData = detail;
                        setEvents([...events]);
                      }, 50);
                    }}
                    onUnlinkIssue={onUnlinkIssue}
                    patient={patient}
                  />
                );
              } else {
                delete evt.detail;
                delete evt.detailData;
              }
              setEvents([...events]);
            }}
            onChangeAssignee={async (
              evtId: number,
              assignee: Employee | Selectable | EmployeeGroup,
            ) => {
              try {
                if (assignee instanceof Employee) {
                  await updateEvent(evtId, {
                    assigneeId: assignee,
                    assigneeGroupId: new EmployeeGroup({}),
                  });
                } else if (assignee instanceof EmployeeGroup) {
                  await updateEvent(evtId, {
                    assigneeId: new Employee({}),
                    assigneeGroupId: assignee,
                  });
                } else {
                  await updateEvent(evtId, {
                    assigneeId: new Employee({}),
                    assigneeGroupId: new EmployeeGroup({}),
                  });
                }
                const event = events.find(({ id }) => evtId === id);
                if (event) {
                  if (assignee instanceof Employee) {
                    event.assignee = assignee;
                    event.assigneeGroup = undefined;
                  } else if (assignee instanceof EmployeeGroup) {
                    event.assignee = undefined;
                    event.assigneeGroup = assignee;
                  } else {
                    event.assignee = undefined;
                  }
                  refreshDetail(event.id);
                }
                setEvents([...events]);
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
            onChangeStatus={async (evtId: number, status: EventStatus) => {
              const eventIdx = events.findIndex(({ id }) => evtId === id);
              const event = events[eventIdx];
              if (
                FOLLOW_UP_STATUSES.includes(status.id) &&
                !FOLLOW_UP_STATUSES.includes(event.status.id)
              ) {
                setFollowUpStatus(status);
                onEventAction(
                  { value: EventActions.MODIFY_FOLLOW_UP.value } as Selectable,
                  Object.assign(new EventModel({}), { ...event }),
                  eventIdx,
                  true,
                );
                if (event) {
                  event.status = status;
                }
                setEvents([...events]);
              } else {
                if (event) {
                  event.status = status;
                }
                try {
                  await updateEvent(evtId, { statusId: status });
                  if (event) {
                    refreshDetail(event.id);
                  }
                  setEvents([...events]);
                } catch (e) {
                  showGlobalError(e as string);
                }
              }
            }}
            onEventAction={onEventAction}
          />
        )}
        <Modal
          open={openPrompt}
          body={
            eventModalData && <p>{(eventModalData as IssuePrompt).message}</p>
          }
          maxWidth="sm"
          onSubmit={async () => {
            try {
              if (eventPromptDetail) {
                const issue = eventPromptDetail.issues?.find(
                  ({ pendingPrompts }) =>
                    !!pendingPrompts?.find(
                      ({ id }) => id === (eventModalData as IssuePrompt).id,
                    ),
                );
                if (issue) {
                  setOpenPrompt(false);
                  await acceptEventIssuePrompt(
                    eventPromptDetail?.id,
                    issue.id,
                    eventModalData.id,
                  );
                  issue.pendingPrompts?.shift();
                  const prompts = eventPromptDetail.getPendingPrompts();
                  if (prompts.length) {
                    setEventModalData(prompts[0]);
                    setOpenPrompt(true);
                  }
                }
              }
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          title={translate('global.warning')}
        />
      </Panel.Body>
    </Panel>
  );
};

export default MemberEventDashboard;
