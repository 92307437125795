import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { EmployeeRole } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  PodRule,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import PodRuleTrigger from '@vestahealthcare/common/models/PodRuleInternalTrigger';
import { getDiff } from '@vestahealthcare/common/utils/api';

import {
  Button,
  CollapsableSidebar,
  Colors,
  IconButton,
  Label,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import {
  CreatePodRuleParams,
  UpdatePodRuleParams,
} from 'dash/src/services/PodServices';

interface Props {
  data: {
    externalTriggers: Selectable[];
    frequencies: Selectable[];
    programExtensions: Selectable[];
    taskTriggers: PodRuleTrigger[];
    triggerStatuses: BaseEnum[];
  };
  loadingData: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreatePodRuleParams | UpdatePodRuleParams,
    id?: number,
  ) => Promise<void>;
  rule?: PodRule;
}

const useStyles = makeStyles({
  taskTriggercontainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  taskTriggerIcon: {
    margin: '1rem auto auto',
  },
  taskTriggerAddIcon: {
    marginTop: '-0.25rem',
  },
  taskTriggerAddLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
});

const PodRuleEditModal = ({
  data,
  loadingData,
  open,
  onClose,
  onSubmit,
  rule,
}: Props) => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(false);
  const [closeAfterAttemps, setCloseAfterAttemps] = useState<boolean>(false);
  const [closeAfterDue, setCloseAfterDue] = useState<boolean>(false);
  const [createIfPeriodEmpty, setCreateIfPeriodEmpty] = useState<boolean>(
    false,
  );
  const [delayAfterTrigger, setDelayAfterTrigger] = useState<number>();
  const [externalTriggers, setExternalTriggers] = useState<Selectable[]>([]);
  const [flexible, setFlexible] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<Selectable>();
  const [maxAttempts, setMaxAttemps] = useState<number>();
  const [name, setName] = useState<string>('');
  const [, setPeriod] = useState<Selectable>();
  const [programExtension, setProgramExtension] = useState<Selectable[]>([]);
  const [roles, setRoles] = useState<Selectable[]>([]);
  const [selfTrigger, setSelfTrigger] = useState<boolean>(false);
  const [taskSpacingDays, setTaskSpacingDays] = useState<number>();
  const [taskTriggers, setTaskTriggers] = useState<PodRuleTrigger[]>([]);

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSubmitted(false);

      setActive(!!rule?.active);
      setCloseAfterAttemps(!!rule?.closeAfterAttemps);
      setCloseAfterDue(!!rule?.closeAfterDue);
      setCreateIfPeriodEmpty(!!rule?.createPeriodIfEmpty);
      setDelayAfterTrigger(rule?.delayDaysAfterTrigger);
      setExternalTriggers(BaseEnum.toSelectable(rule?.externalTriggers || []));
      setFlexible(!!rule?.flexible);
      setFrequency(
        rule?.frequency
          ? BaseEnum.toSelectable([rule.frequency])[0]
          : undefined,
      );
      setMaxAttemps(rule?.maxAttempts);
      setName(rule?.name || '');
      setPeriod(
        rule?.period ? BaseEnum.toSelectable([rule.period])[0] : undefined,
      );
      setRoles(Enum.toSelectable(rule?.roles || []));
      setSelfTrigger(!!rule?.selfTrigger);
      setTaskSpacingDays(rule?.taskSpacingDays);
      setTaskTriggers(
        rule?.internalTriggers?.length
          ? rule?.internalTriggers
          : [new PodRuleTrigger({})],
      );
      setProgramExtension(
        ProgramExtension.toSelectable(rule?.programExtensions || []),
      );
    }
  }, [open]);

  const validate = () =>
    name &&
    programExtension?.length &&
    frequency &&
    roles?.length &&
    maxAttempts !== undefined &&
    !taskTriggers?.find((item) => !!item?.id !== !!item?.status?.id);

  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);

      try {
        await onSubmit(
          getDiff<CreatePodRuleParams | UpdatePodRuleParams>(
            rule
              ? {
                  closeAfterAttemps: rule?.closeAfterAttemps,
                  closeAfterDue: rule?.closeAfterDue,
                  createIfPeriodEmpty: rule?.createPeriodIfEmpty,
                  delayAfterTrigger: rule?.delayDaysAfterTrigger,
                  flexible: rule?.flexible,
                  frequency: frequency?.value as string,
                  maxAttempts: rule?.maxAttempts,
                  name: rule?.name,
                  roles: rule?.roles?.map(({ value }) => value),
                  programExtensionIds: rule?.programExtensions?.map(
                    ({ id }) => id,
                  ),
                  selfTrigger: rule?.selfTrigger,
                  taskSpacing: rule?.taskSpacingDays,
                  taskTriggers: rule?.internalTriggers?.map(
                    ({ id, status }) => ({
                      trigger: id,
                      status: status.id,
                    }),
                  ),
                }
              : {},
            {
              closeAfterAttemps,
              closeAfterDue,
              createIfPeriodEmpty,
              delayAfterTrigger,
              flexible,
              frequency: frequency?.value as string,
              maxAttempts,
              name,
              roles: roles?.map(({ value }) => value as string),
              programExtensionIds: programExtension?.map(
                ({ value }) => value as number,
              ),
              selfTrigger,
              taskSpacing: taskSpacingDays,
              taskTriggers: taskTriggers
                ?.filter((item) => item.id)
                ?.map(({ id, status }) => ({
                  trigger: id,
                  status: status.id,
                })),
            },
          ),
          rule?.id,
        );
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const excludeOtherValues = (
    items: PodRuleTrigger[],
    values: PodRuleTrigger[],
    currenValue: PodRuleTrigger,
  ) =>
    items.filter(
      (item) =>
        !item.id ||
        item.id === currenValue.id ||
        !values?.find((value) => value.id === item.id),
    );

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(`pods.rules.modal.title${rule ? 'Edit' : 'Add'}`, {
            rule: rule?.name || 'POD Rule',
          })}
        </h2>
      }
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-12"
            error={submitted && !name}
            label={translate('pods.rules.common.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            value={name}
            required
          />
          <Select
            className="grid-span-12"
            error={submitted && !programExtension?.length}
            items={data.programExtensions}
            label={translate('pods.rules.common.programExtensions')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setProgramExtension}
            value={programExtension}
            required
          />
          <Select
            className="grid-span-12"
            disableClearable
            error={submitted && !frequency}
            items={data.frequencies}
            label={translate('pods.rules.common.frequency')}
            loading={loadingData}
            onChange={setFrequency}
            value={frequency}
            required
          />
          <Select
            className="grid-span-12"
            disableClearable
            error={submitted && !roles?.length}
            items={EmployeeRole.toSelectable()}
            label={translate('pods.rules.common.roles')}
            multiple
            onChange={setRoles}
            value={roles}
            required
          />
          <Select
            className="grid-span-12"
            disableClearable
            items={data.externalTriggers}
            label={translate('pods.rules.common.externalTriggers')}
            loading={loadingData}
            multiple
            onChange={setExternalTriggers}
            value={externalTriggers}
            required
          />

          <div
            className={classNames('grid-span-12', styles.taskTriggercontainer)}
          >
            <Label className="flex middle">
              {translate('pods.rules.common.taskTriggers')}
              <a
                className={classNames('flex middle', styles.taskTriggerAddLink)}
                onClick={(evt) => {
                  evt.preventDefault();
                  setTaskTriggers([
                    ...(taskTriggers || []),
                    new PodRuleTrigger({}),
                  ]);
                }}
              >
                <AddIcon
                  className={styles.taskTriggerAddIcon}
                  fontSize="small"
                />
                {translate('pods.rules.modal.addAnotherTrigger')}
              </a>
            </Label>
            <div className="grid-wrapper fit">
              {taskTriggers.map((item, idx) => (
                <Fragment key={`pod-rule-trigger-${idx}`}>
                  <Select
                    className="grid-span-5"
                    disableClearable
                    error={submitted && !item.id && !!item.status}
                    getItemLabel={(item: PodRuleTrigger) => item.description}
                    items={excludeOtherValues(
                      data.taskTriggers,
                      taskTriggers,
                      item,
                    )}
                    loading={loading}
                    onChange={(newValue: PodRuleTrigger) => {
                      taskTriggers[idx].id = newValue.id;
                      taskTriggers[idx].description = newValue.description;
                      setTaskTriggers([...taskTriggers]);
                    }}
                    value={item}
                  />
                  <Select
                    className="grid-span-5"
                    disableClearable
                    error={submitted && !!item.id && !item.status}
                    getItemLabel={(item: BaseEnum) => item.description}
                    items={data.triggerStatuses}
                    loading={loading}
                    onChange={(newValue: BaseEnum) => {
                      taskTriggers[idx].status = newValue;
                      setTaskTriggers([...taskTriggers]);
                    }}
                    value={item.status}
                  />
                  <IconButton
                    className={classNames(
                      styles.taskTriggerIcon,
                      'grid-span-2',
                    )}
                    disabled={
                      taskTriggers &&
                      taskTriggers.length < 2 &&
                      !(taskTriggers[0]?.id || taskTriggers[0]?.status)
                    }
                    size="small"
                    onClick={() => {
                      if (taskTriggers && taskTriggers.length < 2) {
                        setTaskTriggers([new PodRuleTrigger({})]);
                      } else {
                        taskTriggers?.splice(idx, 1);
                        setTaskTriggers([...taskTriggers]);
                      }
                    }}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </IconButton>
                </Fragment>
              ))}
            </div>
          </div>

          <TextInput
            className="grid-span-4"
            error={submitted && !maxAttempts}
            label={translate('pods.rules.common.maxAttempts')}
            onChange={(newValue) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setMaxAttemps(isNaN(number) ? undefined : number);
            }}
            value={maxAttempts}
            type="numeric"
            required
          />
          <TextInput
            className="grid-span-4"
            label={translate('pods.rules.common.delayDaysAfterTrigger')}
            onChange={(newValue) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setDelayAfterTrigger(isNaN(number) ? undefined : number);
            }}
            value={delayAfterTrigger}
            type="numeric"
          />
          <TextInput
            className="grid-span-4"
            label={translate('pods.rules.common.taskSpacingDays')}
            onChange={(newValue) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setTaskSpacingDays(isNaN(number) ? undefined : number);
            }}
            value={taskSpacingDays}
            type="numeric"
          />

          <SwitchGroup
            className="grid-span-6"
            items={[
              {
                label: translate('pods.rules.common.createPeriodIfEmpty'),
                checked: createIfPeriodEmpty,
              },
              {
                label: translate('pods.rules.common.closeAfterAttemps'),
                checked: closeAfterAttemps,
              },
              {
                label: translate('pods.rules.common.closeAfterDue'),
                checked: closeAfterDue,
              },
            ]}
            onChange={(items) => {
              setCreateIfPeriodEmpty(!!items[0].checked);
              setCloseAfterAttemps(!!items[1].checked);
              setCloseAfterDue(!!items[2].checked);
            }}
          />
          <SwitchGroup
            className="grid-span-6"
            items={[
              {
                label: translate('pods.rules.common.selfTrigger'),
                checked: selfTrigger,
              },
              {
                label: translate('pods.rules.common.flexible'),
                checked: flexible,
              },
            ]}
            onChange={(items) => {
              setSelfTrigger(!!items[0].checked);
              setFlexible(!!items[1].checked);
            }}
          />
          {rule && (
            <SwitchGroup
              className="grid-span-12"
              items={[
                {
                  label: translate('pods.rules.common.active'),
                  checked: active,
                },
              ]}
              onChange={(items) => setActive(!!items[0].checked)}
            />
          )}
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="add-pod-config-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="save-pod-config-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default PodRuleEditModal;
