import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  PodRule,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import PodRuleTrigger from '@vestahealthcare/common/models/PodRuleInternalTrigger';

import { IconButton, Panel } from 'styleguide-v2';

import {
  CreatePodRuleParams,
  createPodRule,
  fetchPodExternalTriggers,
  fetchPodFrequencies,
  fetchPodPrograms,
  fetchPodRules,
  fetchPodTaskTriggers,
  fetchPodTriggerStatuses,
  updatePodRule,
} from 'dash/src/services/PodServices';

import PodRuleEditModal from './PodRuleEditModal';
import { PodRulesTable } from './PodRulesTable';

export const PODsRulesDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [rules, setRules] = useState<PodRule[]>([]);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [editRule, setEditRule] = useState<PodRule>();

  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [frequencies, setFrequencies] = useState<Selectable[]>([]);
  const [externalTriggers, setExternalTriggers] = useState<Selectable[]>([]);
  const [taskTriggers, setTaskTriggers] = useState<PodRuleTrigger[]>([]);
  const [triggerStatuses, setTriggersStatuses] = useState<BaseEnum[]>([]);

  const getInitialData = async () => {
    setLoadingData(true);

    const [programs, freqs, et, it, statuses] = await Promise.all([
      fetchPodPrograms(),
      fetchPodFrequencies(),
      fetchPodExternalTriggers(),
      fetchPodTaskTriggers(),
      fetchPodTriggerStatuses(),
    ]);

    setProgramExtensions(ProgramExtension.toSelectable(programs));
    setFrequencies(BaseEnum.toSelectable(freqs));
    setExternalTriggers(BaseEnum.toSelectable(et));
    setTaskTriggers(it);
    setTriggersStatuses(statuses);

    setLoadingData(false);
  };

  const getPodRules = async () => {
    setLoading(true);
    const { items } = await fetchPodRules({
      limit: 100,
    });
    setRules(items);
    setLoading(false);
  };

  useEffect(() => {
    getPodRules();
  }, []);

  useEffect(() => {
    if (openEditModal && !programExtensions?.length) getInitialData();
  }, [openEditModal]);

  return (
    <Panel id="pods-config-section">
      <Panel.Heading title={translate('pods.rules.title')}>
        <Panel.Actions>
          <IconButton
            onClick={() => {
              setEditRule(undefined);
              setOpenEditModal(true);
            }}
            tooltip={translate('pods.rules.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <PodRulesTable
          rules={rules}
          onAdd={() => {
            setEditRule(undefined);
            setOpenEditModal(true);
          }}
          onEdit={(rule) => {
            setEditRule(rule);
            setOpenEditModal(true);
          }}
        />
        <PodRuleEditModal
          data={{
            externalTriggers,
            frequencies,
            programExtensions,
            taskTriggers,
            triggerStatuses,
          }}
          loadingData={loadingData}
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSubmit={async (params, id) => {
            if (id) {
              await updatePodRule(id, params);
            } else {
              await createPodRule(params as CreatePodRuleParams);
            }
            await getPodRules();
          }}
          rule={editRule}
        />
      </Panel.Body>
    </Panel>
  );
};

export default PODsRulesDashboard;
