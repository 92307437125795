import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
} from '@vestahealthcare/common/enums';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import {
  MemberDashboard as MemberDashboardData,
  MemberDashboardEvent,
  MemberDashboardTask,
  PaginationType,
  Task,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import { Panel } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { useSelector } from 'dash/src/redux/store';
import {
  fecthMemberDashboard,
  fecthMemberDashboardEvents,
  fecthMemberDashboardTasks,
} from 'dash/src/services/MemberDashboardServices';
import { fetchAllTasks } from 'dash/src/services/TaskServices';

import AddEncounterModal from '../Encounters/AddEncounterModal';
import { BHICard } from './BHICard';
import { CCMCard } from './CCMCard';
import { ClinicalSummaryCard } from './ClinicalSummaryCard';
import { KeyContactsCard } from './KeyContactsCard';
import { NPBiannualCard } from './NPBiannualCard';
import { OpenTasksCard } from './OpenTasksCard';
import { PharmacyCard } from './PharmacyCard';
import { RPMCard } from './RPMCard';
import { RecentActionsCard } from './RecentActionsCard';
import { RecentEncountersCard } from './RecentEncountersCard';
import { RecentEventsCard } from './RecentEventsCard';
import { MAX_DASHBOARD_TABLE_ITEMS } from './contansts';

const useStyles = makeStyles({
  cardFit: {
    height: 'fit-content',
  },
  container: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '2fr 1fr',
    gridAutoRows: 'auto',
    padding: '2rem',
  },
  subcontainer: {
    display: 'grid',
    gap: '20px',
  },
  leftSubContainer: {
    '&&': {
      gridTemplateColumns: 'auto auto',
    },
  },
});

export const MemberDashboard = () => {
  const styles = useStyles();
  const member = useSelector((state) => state.memberInfoSlice.patient);
  const warnings = useSelector((state) => state.memberInfoSlice.warnings);

  const [memberDashboard, setMemberDashboard] = useState<MemberDashboardData>();
  const [memberDashboardEvents, setMemberDashboardEvents] = useState<
    MemberDashboardEvent[]
  >([]);
  const [
    memberDashboardEventsPagination,
    setMemberDashboardEventsPagination,
  ] = useState<PaginationType>();
  const [memberDashboardTasks, setMemberDashboardTasks] = useState<
    MemberDashboardTask[]
  >([]);
  const [
    memberDashboardTasksPagination,
    setMemberDashboardTasksPagination,
  ] = useState<PaginationType>();
  const [rpmTasks, setRPMTasks] = useState<Task[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<EncounterType>();

  const getRPMTaskSummary = async () => {
    if (member) {
      try {
        const { items } = await fetchAllTasks({
          limit: 50,
          memberId: [member.id],
          status: [
            TaskStatus.DELAYED.value,
            TaskStatus.ESCALATED.value,
            TaskStatus.IN_PROGRESS.value,
            TaskStatus.NOT_STARTED.value,
            TaskStatus.PENDING_AUTHORIZATION.value,
            TaskStatus.ORDER_PLACED.value,
            TaskStatus.SHIPPED.value,
            TaskStatus.DELIVERED.value,
          ],
          taskDefinitionId: [
            TaskDefinition.RPM_NEW_ORDER_REQUEST,
            TaskDefinition.RPM_TRAINING_REQUEST,
            TaskDefinition.RPM_RETURN_DEVICE,
            TaskDefinition.RPM_TECH_ISSUE,
          ],
          topLevelOnly: true,
        });
        setRPMTasks(
          items.sort(({ status: a }, { status: b }) => {
            if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(a))
              return -1;
            if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(b))
              return 1;
            if (
              [
                TaskStatus.IN_PROGRESS,
                TaskStatus.PENDING_AUTHORIZATION,
              ].includes(a)
            )
              return -1;
            if (
              [
                TaskStatus.IN_PROGRESS,
                TaskStatus.PENDING_AUTHORIZATION,
              ].includes(b)
            )
              return 1;
            return 0;
          }),
        );
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setRPMTasks([]);
    }
  };

  const getMemberDashboard = async () => {
    if (member) {
      try {
        const data = await fecthMemberDashboard(member.id);
        setMemberDashboard(data);
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setMemberDashboard(undefined);
    }
  };

  const getMemberDashboardEvents = async () => {
    if (member) {
      try {
        const { items, pagination } = await fecthMemberDashboardEvents(
          member.id,
          {
            offset: 0,
            limit: MAX_DASHBOARD_TABLE_ITEMS,
          },
        );
        setMemberDashboardEvents(items);
        setMemberDashboardEventsPagination(pagination);
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setMemberDashboardEvents([]);
      setMemberDashboardEventsPagination(undefined);
    }
  };

  const getMoreDashboardEvents = async () => {
    if (member) {
      try {
        const { items } = await fecthMemberDashboardEvents(member.id, {
          offset: MAX_DASHBOARD_TABLE_ITEMS,
          limit: memberDashboardEventsPagination?.total || 100,
        });
        setMemberDashboardEvents([...memberDashboardEvents, ...items]);
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getMemberDashboardTasks = async () => {
    if (member) {
      try {
        const { items, pagination } = await fecthMemberDashboardTasks(
          member.id,
          {
            offset: 0,
            limit: MAX_DASHBOARD_TABLE_ITEMS,
          },
        );
        setMemberDashboardTasks(items);
        setMemberDashboardTasksPagination(pagination);
      } catch (e) {
        showGlobalError(e as string);
      }
    } else {
      setMemberDashboardTasks([]);
      setMemberDashboardTasksPagination(undefined);
    }
  };

  const getMoreDashboardTasks = async () => {
    if (member) {
      try {
        const { items } = await fecthMemberDashboardTasks(member.id, {
          offset: MAX_DASHBOARD_TABLE_ITEMS,
          limit: memberDashboardTasksPagination?.total || 100,
        });
        setMemberDashboardTasks([...memberDashboardTasks, ...items]);
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getData = async () => {
    if (member?.id) {
      setLoading(true);
      await Promise.all([
        getRPMTaskSummary(),
        getMemberDashboard(),
        getMemberDashboardEvents(),
        getMemberDashboardTasks(),
      ]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [member]);

  return (
    <Panel>
      <Panel.Body className={styles.container} loading={!member || loading}>
        {member && (
          <>
            <div
              className={classNames(
                styles.subcontainer,
                styles.leftSubContainer,
              )}
              style={{
                gridAutoRows: 'max-content',
                gridTemplateRows: 'max-content',
              }}
            >
              <CCMCard
                className="grid-span-12"
                data={memberDashboard?.ccmDetails}
                onLogCall={() => setOpenModal(EncounterType.CCM)}
                warnings={warnings}
              />
              <RPMCard
                className="grid-span-12"
                data={memberDashboard?.rpmDetails}
                member={member}
                tasks={rpmTasks}
                warnings={warnings}
              />
              <BHICard
                className="grid-span-12"
                data={memberDashboard?.bhiDetails}
                onLogCall={() => setOpenModal(EncounterType.BHI)}
                warnings={warnings}
              />
              <RecentEventsCard
                className={classNames('grid-span-6', styles.cardFit)}
                data={memberDashboardEvents}
                onSeeMore={getMoreDashboardEvents}
                openEvents={memberDashboard?.openEvents}
                pagination={memberDashboardEventsPagination}
                member={member}
              />
              <RecentActionsCard
                className={classNames('grid-span-6', styles.cardFit)}
                data={memberDashboard?.actions}
                lastUpdated={memberDashboard?.actionsLastUpdatedAt}
                openActions={memberDashboard?.openActions}
              />
              <RecentEncountersCard
                className="grid-span-12"
                data={memberDashboard?.encounters}
              />
            </div>
            <div className={styles.subcontainer}>
              <NPBiannualCard
                className="grid-span-12"
                data={memberDashboard?.biannualDetails}
                warnings={warnings}
              />
              <ClinicalSummaryCard className="grid-span-12" member={member} />
              <KeyContactsCard
                className="grid-span-12"
                member={member}
                data={memberDashboard?.keyContacts}
                warnings={warnings}
              />
              <PharmacyCard
                className="grid-span-12"
                data={memberDashboard?.pharmacy}
              />
              <OpenTasksCard
                className="grid-span-12"
                data={memberDashboardTasks}
                member={member}
                onSeeMore={getMoreDashboardTasks}
                openTasks={memberDashboard?.openTasks}
                pagination={memberDashboardTasksPagination}
              />
            </div>
            <br />
            <AddEncounterModal
              autofillTime
              avoidRequiredMinutes
              channel={
                openModal === EncounterType.CCM
                  ? EncounterChannel.CCM_VISIT
                  : undefined
              }
              direction={EncounterDirection.OUTBOUND}
              method={EncounterMethod.CALL}
              type={openModal}
              onClose={() => setOpenModal(undefined)}
              onSubmit={async () => {
                await getData();
                setOpenModal(undefined);
              }}
              open={!!openModal}
              patient={member}
            />
          </>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default MemberDashboard;
