import React, { Fragment, useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Incident,
  IncidentStatus,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Fonts,
  Icon,
  MemberInfo,
  SelectAssignee,
  StatusIndicator,
  Table,
  UNASSIGNED,
  UpdatedBy,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';

interface Props {
  incidents: Incident[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (pageSize: number) => void;
  onClickRow?: (incident: Incident) => void;
  onEditAssignee: (
    incidentId: number,
    assignee: Employee | EmployeeGroup,
  ) => Promise<void>;
  pagination?: PaginationType;
}

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const INCIDENTS_COLUMNS = (
  { onEditAssignee }: Props,
  employees: Employee[],
  employeeGroups: EmployeeGroup[],
) => [
  {
    headerName: translate('incidents.table.reported'),
    component: ({ externalReporter, reporter, reportedDate }: Incident) => (
      <UpdatedBy
        date={reportedDate}
        user={`${reporter?.fullName}${externalReporter ? ` (via eCW)` : ''}`}
      />
    ),
    width: 200,
  },
  {
    headerName: translate('incidents.table.member'),
    component: ({ member }: Incident) => (
      <MemberInfo
        member={member}
        link={`#/patients/${member?.id}`}
        showBirthDate
      />
    ),
    width: 225,
  },
  {
    headerName: translate('incidents.table.referral'),
    component: ({ member: { activeReferrals }, id }: Incident) =>
      activeReferrals?.length ? (
        <Fragment key={`incident-${id}-member-referrals`}>
          {activeReferrals
            .filter(({ discontinuedAt }) => !discontinuedAt)
            .map(({ organization: { name, incidentAuto, id: referralId } }) => (
              <div
                className="flex gap"
                key={`incident-${id}-referral-${referralId}`}
              >
                {incidentAuto ? (
                  <CheckCircleIcon color="primary" />
                ) : (
                  <CancelIcon color="action" />
                )}
                {name}
              </div>
            ))}
        </Fragment>
      ) : (
        EMPTY
      ),
    width: 225,
  },
  {
    headerName: translate('incidents.table.type'),
    component: ({ types }: Incident) =>
      types.map(({ description }) => description).join(', '),
  },
  {
    headerName: translate('incidents.table.status'),
    component: ({ status, statusUpdatedAt }: Incident) => (
      <StatusIndicator
        color={IncidentStatus.getColor(status)}
        status={status.description}
        subtitle={
          statusUpdatedAt ? <UpdatedBy date={statusUpdatedAt} /> : undefined
        }
      />
    ),
    width: 200,
  },
  {
    headerName: translate('incidents.table.assignee'),
    component: ({ assignee, assigneeGroup, id, status }: Incident) =>
      status.isClosed ? (
        assignee?.fullName
      ) : (
        <SelectAssignee
          assignToMe
          allowUnassigned
          assignee={assignee || assigneeGroup || UNASSIGNED}
          currentUser={Session.actingUser}
          key={`incident-${id}-assignee`}
          items={[...employeeGroups, ...employees]}
          onChange={async (val?: Employee | EmployeeGroup) => {
            if (val) {
              await onEditAssignee(id, val);
            }
          }}
          size="xs"
        />
      ),

    width: 250,
  },
  {
    header: ' ',
    component: () => (
      <Icon className="fa fa-chevron-right" size="xs" color="green" />
    ),
    width: 25,
  },
];

export const IncidentsTable = (props: Props) => {
  const { incidents, pagination, onChangePage, onChangeRowsPerPage } = props;

  const styles = useStyles();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);

  const getInitialData = async () => {
    const [employees, employeesGroups] = await Promise.all([
      CacheServices.getIncidentOwners(),
      CacheServices.getEmployeeGroupsAssignee(),
    ]);
    setEmployees(employees);
    setEmployeeGroups(
      employeesGroups.filter(
        ({ id }) => id === EmployeeGroup.CLINICAL_ACCOUNTS,
      ),
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Table
      className={styles.table}
      config={{
        columns: INCIDENTS_COLUMNS(props, employees, employeeGroups),
        compact: true,
        data: incidents,
        pagination,
        getRowBrand: ({ member }: Incident) => member.brand,
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onClickRow={props.onClickRow}
    />
  );
};

export default IncidentsTable;
