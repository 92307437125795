import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import { Organization } from '@vestahealthcare/common/models';

import { IconButton, Panel } from 'styleguide-v2';

import { fetchOrganizations } from 'dash/src/services/OrganizationServices';

import AddEditReferralModal from './AddEditReferralModal';
import ReferralTable from './ReferralTable';

const Referrals = () => {
  const [loading, setLoading] = useState(true);
  const [referralsParents, setReferralsParents] = useState<Organization[]>([]);
  const [editReferral, setEditReferral] = useState<Organization>();
  const [editReferralGrandChild, setEditReferralGrandChild] = useState<boolean>(
    false,
  );
  const [showModal, setShowModal] = useState(false);

  const fetchReferrals = async () => {
    setLoading(true);
    const items = await fetchOrganizations({
      sort: 'name asc',
    });
    setLoading(false);
    setReferralsParents(items);
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('referrals.title')}>
        <Panel.Actions>
          <IconButton
            data-cy="add-referral-button"
            onClick={() => {
              setEditReferralGrandChild(false);
              setEditReferral(undefined);
              setShowModal(true);
            }}
            tooltip={translate('referrals.addReferrals')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
          <AddEditReferralModal
            grandChild={editReferralGrandChild}
            referral={editReferral}
            onHide={() => {
              setShowModal(false);
              setEditReferral(undefined);
            }}
            onSubmit={async () => {
              await fetchReferrals();
              setShowModal(false);
              setEditReferral(undefined);
            }}
            open={showModal}
          />
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <ReferralTable
          originals={referralsParents}
          referrals={referralsParents.filter((item) => !item.parent)}
          onEdit={(referral, _, grandchild) => {
            setEditReferralGrandChild(grandchild);
            setEditReferral(referral);
            setShowModal(true);
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default Referrals;
