import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamCandidate,
  CareTeamMember,
  CareTeamPersonExtended,
  CommunityResource,
  HouseHoldMember,
  MemberCommunityResource,
  Patient,
} from '@vestahealthcare/common/models';

import { Fonts, IconButton, Modal, Panel } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamMemberDeleteModal } from 'dash/src/pages/CareTeam/modals/CareTeamMemberDelete';
import { CareTeamMemberEditModal } from 'dash/src/pages/CareTeam/modals/CareTeamMemberEdit';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { CommunityResourceQuickAdd } from 'dash/src/pages/CommunityResources/modals/CommunityResourceQuickAdd';
import {
  fetchPatientById,
  fetchPatientCommunityResources,
  fetchPatientWarnings,
  onCareTeamMembersUpdated,
} from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import {
  CreateCareTeamMemberParams,
  activateCareTeamMember,
  changeRoleCareTeamMember,
  deleteCareTeamCandidate,
  fetchCareTeamCandidates,
  fetchMemberCareTeamMembers,
  invalidateCareTeamMember,
  quickAddCareTeam,
  updateCareTeamMember,
} from 'dash/src/services/CareTeamServices';
import {
  addMemberCommunityResource,
  createCommunityResource,
  deleteMemberCommunityResource,
  updateCommunityResource,
  updateMemberCommunityResource,
} from 'dash/src/services/CommunityResourcesServices';
import {
  createHouseHoldMember,
  deleteHouseHoldMember,
  updateHouseHoldMember,
} from 'dash/src/services/HouseHoldServices';
import { updatePatient } from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import CommunityResourceEditModal from '../../Admin/CommunityResources/CommunityResourceEdit';
import { CareTeamCandidateTable } from './CareTeamCandidateTable';
import { CareTeamMemberTable } from './CareTeamMemberTable';
import { CommunityResourcesTable } from './CommunityResourcesTable';
import HouseHoldEditModal from './HouseHoldEdit';
import { HouseHoldMembersTable } from './HouseHoldTable';
import { VestaTeamCard } from './VestaTeamCard';
import { EditVestaTeamModal } from './modals/EditVestaTeamModal';

const useStyles = makeStyles({
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.5)`,
      fontWeight: 500,
    },
  },
});

export const MemberNetwork = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const member = useSelector((state) => state.memberInfoSlice.patient);
  const houseHoldMembers = useSelector(
    (state) => state.memberInfoSlice.patient?.householdMembers,
  );
  const memberCommunityResources = useSelector(
    (state) => state.memberInfoSlice.communityResources,
  );
  const { actingUser } = Session;

  const [candidates, setCandidates] = useState<CareTeamCandidate[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [openModalVestaTeam, setOpenModalVestaTeam] = useState<boolean>(false);
  const [openModalCareTeam, setOpenModalCareTeam] = useState<boolean>(false);
  const [openModalAddCareTeam, setOpenAddModalCareTeam] = useState<boolean>(
    false,
  );
  const [openModalDeleteCareTeam, setOpenDeleteModalCareTeam] = useState<
    boolean
  >(false);
  const [editModalMember, setEditModalMember] = useState<CareTeamMember>();
  const [
    openModalAddCommunityResource,
    setOpenAddModalCommunityResource,
  ] = useState<boolean>(false);
  const [
    openModalEditCommunityResource,
    setOpenEditModalCommunityResource,
  ] = useState<boolean>(false);
  const [
    openModalDeleteCommunityResource,
    setOpenDeleteModalCommunityResource,
  ] = useState<boolean>(false);
  const [editModalCommunityResource, setEditModalCommunityResource] = useState<
    CommunityResource
  >();
  const [
    editModalMemberCommunityResource,
    setEditModalMemberCommunityResource,
  ] = useState<MemberCommunityResource>();
  const [candidate, setCandidate] = useState<CareTeamCandidate>();
  const [openModalHouseHoldMember, setOpenModalHouseHoldMember] = useState<
    boolean
  >(false);
  const [modalHouseHoldMemberData, setModalHouseHoldMemberData] = useState<
    HouseHoldMember
  >();

  const members = [
    ...useSelector((state) => state.memberInfoSlice.careTeamMembers),
  ]
    .sort(
      (
        { careTeamPerson: { fullName: a } },
        { careTeamPerson: { fullName: b } },
      ) => a.localeCompare(b),
    )
    .sort(({ preferred: a }, { preferred: b }) => {
      if (a && !b) return -1;
      if (!a && b) return 1;
      return 0;
    });

  const refetchPatient = async (member: Patient) => {
    await dispatch(fetchPatientById(member.id));
  };

  const geCareTeamCandidates = async () => {
    const candidates = await fetchCareTeamCandidates({ memberId: member?.id });
    setCandidates(candidates?.filter(({ consolidated }) => !consolidated));
  };

  const getData = async () => {
    if (member?.id) {
      setLoading(true);
      await geCareTeamCandidates();
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [member]);

  return (
    <Panel>
      <Panel.Heading title={translate('memberProfile.network.title')} />
      <Panel.Body loading={loading}>
        <div className="grid-wrapper fit">
          <VestaTeamCard
            allowEdit={actingUser.canEditVestaTeam}
            className="grid-span-6"
            member={member}
            onEdit={() => setOpenModalVestaTeam(true)}
          />

          <div className="grid-span-12">
            <h2 className={classNames(styles.title, 'flex gap')}>
              {translate('memberProfile.network.careTeam')}
              <IconButton
                className="care-team-edit"
                size="small"
                onClick={() => setOpenAddModalCareTeam(true)}
                tooltip={translate('memberProfile.network.careTeamAdd')}
              >
                <AddIcon color="primary" />
              </IconButton>
            </h2>
            <CareTeamMemberTable
              className="grid-span-12"
              members={members as CareTeamMember[]}
              onActivate={async (ctm) => {
                try {
                  await activateCareTeamMember(ctm.id);
                  if (member) {
                    const ctms = await fetchMemberCareTeamMembers(member.id);
                    dispatch(onCareTeamMembersUpdated(ctms.items));
                  }
                  setOpenDeleteModalCareTeam(false);
                } catch (e) {
                  showGlobalError(e as string);
                }
              }}
              onAdd={() => setOpenAddModalCareTeam(true)}
              onClickPreferred={async (ctm) => {
                await updateCareTeamMember(ctm.id, ctm.type, {
                  preferred: !ctm.preferred,
                });
                if (member) {
                  const ctms = await fetchMemberCareTeamMembers(member.id);
                  dispatch(onCareTeamMembersUpdated(ctms.items));
                }
              }}
              onDelete={(member) => {
                setOpenDeleteModalCareTeam(true);
                setEditModalMember(member);
              }}
              onEdit={(member) => {
                setOpenModalCareTeam(true);
                setEditModalMember(member);
              }}
            />
            {!!candidates?.length && (
              <>
                <h2 className={classNames(styles.title)}>
                  {translate('memberProfile.network.careTeamCandidates')}
                </h2>
                <CareTeamCandidateTable
                  className="grid-span-12"
                  candidates={candidates}
                  onCreate={(candidate) => {
                    setCandidate(candidate);
                    setOpenAddModalCareTeam(true);
                  }}
                  onDelete={async (ct) => {
                    setLoading(true);
                    await deleteCareTeamCandidate(ct.id);
                    await geCareTeamCandidates();
                    setLoading(false);
                  }}
                />
              </>
            )}
          </div>
          <div className="grid-span-12">
            <h2 className={classNames(styles.title, 'flex gap')}>
              {translate('memberProfile.network.communityResources')}
              <IconButton
                className="community-resource-add"
                size="small"
                onClick={() => setOpenAddModalCommunityResource(true)}
                tooltip={translate(
                  'memberProfile.network.communityResourcesAdd',
                )}
              >
                <AddIcon color="primary" />
              </IconButton>
            </h2>
            <CommunityResourcesTable
              className="grid-span-12"
              communityResources={memberCommunityResources}
              onAdd={() => setOpenAddModalCommunityResource(true)}
              onDelete={(mcr) => {
                setEditModalMemberCommunityResource(mcr);
                setEditModalCommunityResource(mcr.communityResource);
                setOpenDeleteModalCommunityResource(true);
              }}
              onEdit={(mcr) => {
                const {
                  communityResource,
                  pocEmail,
                  pocExtension,
                  pocName,
                  pocNumber,
                  notes,
                } = mcr;
                communityResource.pocEmail = pocEmail;
                communityResource.pocExtension = pocExtension;
                communityResource.pocName = pocName;
                communityResource.pocNumber = pocNumber;
                communityResource.notes = notes;
                setEditModalCommunityResource(communityResource);
                setEditModalMemberCommunityResource(mcr);
                setOpenEditModalCommunityResource(true);
              }}
            />
          </div>
          <div className="grid-span-12">
            <h2 className={classNames(styles.title, 'flex gap')}>
              {translate('memberProfile.network.houseHoldMembers')}
              {!houseHoldMembers?.length && (
                <IconButton
                  className="community-resource-add"
                  size="small"
                  onClick={() => {
                    setModalHouseHoldMemberData(undefined);
                    setOpenModalHouseHoldMember(true);
                  }}
                  tooltip={translate(
                    'memberProfile.network.houseHoldMembersAdd',
                  )}
                >
                  <AddIcon color="primary" />
                </IconButton>
              )}
            </h2>
            <HouseHoldMembersTable
              className="grid-span-12"
              items={houseHoldMembers || []}
              onAdd={() => {
                setModalHouseHoldMemberData(undefined);
                setOpenModalHouseHoldMember(true);
              }}
              onDelete={async (hhm: HouseHoldMember) => {
                await deleteHouseHoldMember(hhm.id);
                if (member) {
                  dispatch(fetchPatientById(member.id));
                }
              }}
              onEdit={(hhm: HouseHoldMember) => {
                setModalHouseHoldMemberData(hhm);
                setOpenModalHouseHoldMember(true);
              }}
            />
          </div>
          <EditVestaTeamModal
            member={member}
            onClose={() => setOpenModalVestaTeam(false)}
            onSubmit={async (params) => {
              if (member) {
                await updatePatient(member.id, params);
                await refetchPatient(member);
              }
            }}
            open={openModalVestaTeam}
          />
          <CareTeamMemberEditModal
            editMode="person"
            member={editModalMember}
            onClose={() => setOpenModalCareTeam(false)}
            onSubmit={async (_, params, changeRole) => {
              try {
                if (changeRole && editModalMember?.id) {
                  await changeRoleCareTeamMember(
                    editModalMember.id,
                    (params as CreateCareTeamMemberParams).type,
                    {
                      careTeamPersonId: editModalMember?.careTeamPerson?.id,
                      memberId: member?.id,
                      ...params,
                    } as CreateCareTeamMemberParams,
                  );
                } else if (editModalMember?.id) {
                  await updateCareTeamMember(
                    editModalMember.id,
                    editModalMember.type,
                    params,
                  );
                }
                if (member) {
                  const ctm = await fetchMemberCareTeamMembers(member.id);
                  dispatch(onCareTeamMembersUpdated(ctm.items));
                  dispatch(fetchPatientWarnings(member.id));
                }
                setOpenModalCareTeam(false);
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
            open={openModalCareTeam}
            person={editModalMember?.careTeamPerson as CareTeamPersonExtended}
          />
          <CareTeamQuickAddModal
            disabledPeople={members?.map(
              ({ careTeamPerson }) => careTeamPerson,
            )}
            open={openModalAddCareTeam}
            onClose={() => {
              setCandidate(undefined);
              setOpenAddModalCareTeam(false);
            }}
            onSubmit={async (params) => {
              let ctm: CareTeamMember | undefined;
              try {
                if (member) {
                  ctm = await quickAddCareTeam(params);
                  const { items } = await fetchMemberCareTeamMembers(member.id);
                  await geCareTeamCandidates();
                  dispatch(onCareTeamMembersUpdated(items));
                  dispatch(fetchPatientWarnings(member.id));
                }
              } catch (e) {
                showGlobalError(e as string);
              }
              return ctm?.careTeamPerson || false;
            }}
            selectedCareTeamCandidate={candidate}
            selectedMember={member}
          />
          <CareTeamMemberDeleteModal
            member={editModalMember}
            onClose={() => setOpenDeleteModalCareTeam(false)}
            onSubmit={async (id, params) => {
              try {
                await invalidateCareTeamMember(id, params);
                if (member) {
                  const ctm = await fetchMemberCareTeamMembers(member.id);
                  dispatch(onCareTeamMembersUpdated(ctm.items));
                }
                if (member) {
                  dispatch(fetchPatientWarnings(member.id));
                }
                setOpenDeleteModalCareTeam(false);
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
            open={openModalDeleteCareTeam}
          />
          <CommunityResourceEditModal
            communityResource={editModalCommunityResource as CommunityResource}
            memberCommunityResource={editModalMemberCommunityResource}
            open={openModalEditCommunityResource}
            onClose={() => setOpenEditModalCommunityResource(false)}
            onCreate={async () => {}}
            onEdit={async (_, params, ctmParams, hasChangeParentFields) => {
              if (
                editModalCommunityResource &&
                editModalMemberCommunityResource
              ) {
                const {
                  pocEmail,
                  pocExtension,
                  pocName,
                  pocNumber,
                  notes,
                  ...rest
                } = params;
                if (hasChangeParentFields) {
                  await updateCommunityResource(
                    editModalCommunityResource.id,
                    rest,
                  );
                }
                await updateMemberCommunityResource(
                  editModalMemberCommunityResource.id,
                  ctmParams,
                );
                if (member) {
                  dispatch(fetchPatientCommunityResources(member.id));
                  dispatch(fetchPatientWarnings(member.id));
                }
              }
            }}
            onlyEditPOC
            showNotes
          />
          <Modal
            open={openModalDeleteCommunityResource}
            body={translate(
              'memberProfile.network.communityResourcesDeleteBody',
              {
                member: member?.fullName,
                communityResource: editModalCommunityResource?.name,
              },
            )}
            maxWidth="sm"
            onClose={() => setOpenDeleteModalCommunityResource(false)}
            onSubmit={async () => {
              if (editModalMemberCommunityResource) {
                setLoading(true);
                await deleteMemberCommunityResource(
                  editModalMemberCommunityResource.id,
                );

                if (member) {
                  dispatch(fetchPatientCommunityResources(member.id));
                  dispatch(fetchPatientWarnings(member.id));
                }
                setLoading(false);
              }
            }}
            title={translate('global.warning')}
            cancelText={translate('global.no')}
            submitText={translate('global.yes')}
          />
          <CommunityResourceQuickAdd
            disabledCRs={memberCommunityResources
              ?.filter(({ active }) => active)
              ?.map(({ communityResource }) => communityResource)}
            onClose={() => setOpenAddModalCommunityResource(false)}
            onLink={async (
              { pocEmail, pocExtension, pocName, pocNumber, notes, ...params },
              cr,
              ctmParams,
            ) => {
              try {
                if (cr?.id) {
                  await updateCommunityResource(cr.id, params);
                }
                if (member) {
                  await addMemberCommunityResource({
                    memberId: member.id,
                    communityResourceId: cr.id,
                    ...ctmParams,
                  });
                  dispatch(fetchPatientCommunityResources(member.id));
                  dispatch(fetchPatientWarnings(member.id));
                }
              } finally {
                setLoading(false);
              }
            }}
            onSubmit={async (params, cr, ctmParams) => {
              if (member) {
                setLoading(true);
                try {
                  if (cr?.id) {
                    await updateCommunityResource(cr.id, params);
                    await addMemberCommunityResource({
                      memberId: member.id,
                      communityResourceId: cr.id,
                      ...ctmParams,
                    });
                  } else {
                    const cr = await createCommunityResource(params);
                    await addMemberCommunityResource({
                      memberId: member.id,
                      communityResourceId: cr.id,
                      ...ctmParams,
                    });
                  }

                  dispatch(fetchPatientCommunityResources(member.id));
                  dispatch(fetchPatientWarnings(member.id));
                } finally {
                  setLoading(false);
                }
              }
            }}
            open={openModalAddCommunityResource}
            showMemberCRFields
            showNotes
          />
          <HouseHoldEditModal
            houseHold={modalHouseHoldMemberData}
            member={member}
            onClose={() => {
              setOpenModalHouseHoldMember(false);
              setTimeout(() => setModalHouseHoldMemberData(undefined), 500);
            }}
            onCreate={async (params) => {
              await createHouseHoldMember(params);
              if (member) {
                dispatch(fetchPatientById(member.id));
              }
            }}
            onEdit={async (id, params) => {
              await updateHouseHoldMember(id, params);
              if (member) {
                dispatch(fetchPatientById(member.id));
              }
            }}
            open={openModalHouseHoldMember}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default MemberNetwork;
