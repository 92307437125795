import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  EventReporterType,
  IssueDetailFieldType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamMember,
  EventModel,
  IssueDetailFieldGroup,
  IssueDetailFieldOption,
  IssueType,
  MemberCommunityResource,
  Patient,
  SourceChannel,
} from '@vestahealthcare/common/models';
import { IssueDetailValue } from '@vestahealthcare/common/models/IssueDetailValue';

import {
  Button,
  CollapsableSidebar,
  Select,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { onCareTeamMembersUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices } from 'dash/src/services';
import {
  fetchMemberCareTeamMembers,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import {
  CreateEventIssueParams,
  IssueField,
} from 'dash/src/services/EventServices';

import { EventCustomField } from '../components/EventCustomField';
import { EventReporter } from '../components/EventReporter';

interface Props {
  event?: EventModel;
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: CreateEventIssueParams) => Promise<boolean>;
  patient?: Patient;
}

export const MemberEventAddIssueModal = ({
  event,
  open,
  onClose,
  onSubmit,
  patient,
}: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [allIssueDetailFields, setAllIssueDetailFields] = useState<
    IssueDetailFieldGroup[]
  >([]);
  const [issueDetailFields, setIssueDetailFields] = useState<
    IssueDetailFieldGroup[]
  >([]);
  const [currentDetailFields, setCurrentDetailFields] = useState<
    IssueDetailFieldGroup[]
  >([]);

  const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
  const [issueType, setIssueType] = useState<IssueType>();
  const [eventSources, setEventSources] = useState<SourceChannel[]>([]);
  const [eventSource, setEventSource] = useState<SourceChannel>();
  const [eventSourceOther, setEventSourceOther] = useState<string>();
  const [eventDirections, setEventDirections] = useState<BaseEnum[]>([]);
  const [eventDirection, setEventDirection] = useState<BaseEnum>();
  const [reporterType, setReporterType] = useState(EventReporterType.MEMBER);
  const [careTeamMember, setCareTeamMember] = useState<CareTeamMember>();
  const [communityResource, setCommunityResource] = useState<
    MemberCommunityResource
  >();
  const [note, setNote] = useState<string>();
  const [detailFields, setDetailFields] = useState<IssueField[]>([]);

  const [openAddCTM, setOpenAddCTM] = useState<boolean>(false);

  const getInitialData = async () => {
    setLoadingData(true);
    const [
      issueTypes,
      eventSources,
      issueDetailFields,
      directions,
    ] = await Promise.all([
      await CacheServices.getIssueTypes(),
      await CacheServices.getSourceChannels(),
      await CacheServices.getIssueDetailFields(),
      await CacheServices.getSourceChannelDirections(),
    ]);
    setIssueTypes(issueTypes);
    setEventSources(
      eventSources.filter(({ isManual }: SourceChannel) => isManual),
    );
    setAllIssueDetailFields(issueDetailFields);
    setEventDirections(directions);
    setLoadingData(false);
  };

  useEffect(() => {
    if (open && !issueTypes?.length) {
      getInitialData();
    }
  }, [open]);

  const getIssueDetailsFromDefinition = () =>
    currentDetailFields
      ?.filter(
        ({ issueDetailField: { fieldType } }) =>
          fieldType === IssueDetailFieldType.TOGGLE,
      )
      ?.map(
        ({ issueDetailField: { id } }) =>
          ({ issueDetailFieldId: id, value: false } as IssueField),
      );

  const reset = () => {
    setSubmitted(false);
    setIssueType(undefined);
    setEventSource(undefined);
    setEventDirection(undefined);
    setEventSourceOther('');
    setNote('');
    setIssueDetailFields([]);
    setDetailFields([]);

    setReporterType(EventReporterType.MEMBER);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (issueType) {
      setIssueDetailFields(
        allIssueDetailFields.filter(
          ({ issueTypeId }) => issueTypeId === issueType.id,
        ),
      );
    } else {
      setIssueDetailFields([]);
    }
  }, [issueType]);

  useEffect(() => {
    setCurrentDetailFields(issueDetailFields);
    setDetailFields(getIssueDetailsFromDefinition() || []);
  }, [issueDetailFields]);

  // FIXME: Check condition for VESTA-2508
  useEffect(() => {
    if (issueType?.id === IssueType.HOSPITALIZATION) {
      const dischargeDateField = detailFields?.find(
        ({ issueDetailFieldId, value }) =>
          issueDetailFieldId ===
            IssueDetailValue.HOSPITALIZATION_DISCHARGE_DATE && value,
      );
      const medChangeField = currentDetailFields?.find(
        ({ issueDetailField }) =>
          issueDetailField.id ===
          IssueDetailValue.HOSPITALIZATION_MEDICATION_CHANGE,
      );
      const followApptField = currentDetailFields?.find(
        ({ issueDetailField }) =>
          issueDetailField.id === IssueDetailValue.HOSPITALIZATION_FOLLOW_UP,
      );
      if (dischargeDateField) {
        if (
          medChangeField?.required === false &&
          followApptField?.required === false
        ) {
          medChangeField.required = true;
          followApptField.required = true;
          setCurrentDetailFields([
            ...(currentDetailFields?.map((x) => ({ ...x })) || []),
          ]);
        }
      } else if (
        medChangeField?.required === true &&
        followApptField?.required === true
      ) {
        medChangeField.required = false;
        followApptField.required = false;
        setCurrentDetailFields([
          ...(currentDetailFields?.map((x) => ({ ...x })) || []),
        ]);
      }
    }
  }, [detailFields]);

  const getDetail = (id: string) =>
    detailFields.find(({ issueDetailFieldId: idid }) => idid === id);

  const followUpOptions = currentDetailFields
    ?.reduce(
      (acc, { issueDetailField: { options } }) => [...acc, ...(options || [])],
      [] as IssueDetailFieldOption[],
    )
    .filter(({ followUpFieldId }) => !!followUpFieldId);

  const followUpIds = followUpOptions
    ?.filter(({ id }) => detailFields?.find(({ value }) => id === value))
    ?.map(({ followUpFieldId }) => followUpFieldId);

  const filteredIssueFields = currentDetailFields?.filter(
    ({ issueDetailField: { followUp, id } }) =>
      !followUp || followUpIds?.includes(id),
  );

  const validate = () =>
    issueType &&
    eventSource &&
    eventDirection &&
    (!eventSource.isOther() || eventSourceOther) &&
    (reporterType !== EventReporterType.CARE_TEAM || careTeamMember) &&
    (reporterType !== EventReporterType.COMMUNITY_RESOURCE ||
      communityResource) &&
    (!filteredIssueFields?.length ||
      !filteredIssueFields.find(
        ({ issueDetailField: { id, fieldType }, required }) => {
          if (!required || fieldType === IssueDetailFieldType.TOGGLE)
            return false;
          const value = getDetail(id)?.value;
          return value === undefined || value === '';
        },
      ));

  const doSubmit = async () => {
    setSubmitted(true);

    if (event && validate()) {
      setLoading(true);
      try {
        const result = await onSubmit(event.id, {
          issueTypeId: issueType,
          reporterMember:
            reporterType === EventReporterType.MEMBER ? patient?.id : undefined,
          reporterCareTeamMember:
            reporterType === EventReporterType.CARE_TEAM
              ? careTeamMember?.id
              : undefined,
          reporterMemberCommunityResource:
            reporterType === EventReporterType.COMMUNITY_RESOURCE
              ? communityResource?.id
              : undefined,
          source: eventSource,
          sourceOther: eventSourceOther,
          direction: eventDirection,
          note: note || undefined,
          details: detailFields,
        });
        if (result) {
          onClose();
        }
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={
          <h2>
            {translate('memberEvents.modals.addIssueModalTitle', {
              type: translate('memberEvents.modals.issue'),
            })}
          </h2>
        }
        size={550}
      >
        <CollapsableSidebar.Body>
          <Select
            className="grid-span-6"
            error={submitted && !issueType}
            getItemLabel={({ description }: IssueType) => description}
            items={issueTypes.filter(
              ({ eventType: issueET }) => issueET?.id === event?.type?.id,
            )}
            label={translate('memberEvents.issueType')}
            onChange={setIssueType}
            required
            value={issueType}
          />

          <div className="grid-wrapper fit">
            <Select
              className={
                eventSource?.isOther() ? 'grid-span-6' : 'grid-span-12'
              }
              error={submitted && !eventSource}
              getItemLabel={({ description }: SourceChannel) => description}
              items={eventSources}
              label={translate('memberEvents.method')}
              loading={loadingData}
              onChange={setEventSource}
              required
              value={eventSource}
            />
            {eventSource?.isOther() && (
              <TextInput
                className="grid-span-6"
                error={submitted && !eventSourceOther}
                label={translate('memberEvents.methodOther')}
                onChange={setEventSourceOther}
                required
                value={eventSourceOther}
              />
            )}
          </div>

          <Select
            className="grid-span-12"
            error={submitted && !eventDirection}
            getItemLabel={({ description }: BaseEnum) => description}
            items={eventDirections}
            label={translate('memberEvents.direction')}
            loading={loadingData}
            onChange={setEventDirection}
            required
            value={eventDirection}
          />

          <EventReporter
            addCareTeamMember
            onAddCareTeamMember={() => setOpenAddCTM(true)}
            onChange={(type, reporter) => {
              setReporterType(type);
              if (type === EventReporterType.CARE_TEAM) {
                setCareTeamMember(reporter as CareTeamMember);
              } else if (type === EventReporterType.COMMUNITY_RESOURCE) {
                setCommunityResource(reporter as MemberCommunityResource);
              }
            }}
            patient={patient}
            submitted={submitted}
            showCommunityResources
          />

          <div className="grid-wrapper fit">
            {currentDetailFields?.map(
              ({
                issueDetailField: {
                  id,
                  description,
                  fieldType,
                  options,
                  followUp,
                },
                required,
              }) =>
                !followUp || followUpIds?.includes(id) ? (
                  <EventCustomField
                    className={`grid-span-${
                      [
                        IssueDetailFieldType.TEXT_AREA,
                        IssueDetailFieldType.TOGGLE,
                      ].indexOf(fieldType) !== -1
                        ? 12
                        : 6
                    }`}
                    items={options
                      ?.sort(({ position: a }, { position: b }) => a - b)
                      ?.map(({ active, id, description }) => ({
                        value: id,
                        label: description,
                        disabled: !active,
                      }))}
                    key={`issue-add-field-${id}`}
                    label={description}
                    onChange={(value) => {
                      const detail = getDetail(id);
                      if (detail) {
                        detail.value = value;
                        setDetailFields([...detailFields]);
                      } else {
                        setDetailFields([
                          ...detailFields,
                          { issueDetailFieldId: id, value },
                        ]);
                      }
                    }}
                    required={required}
                    submitted={submitted}
                    type={fieldType}
                    value={getDetail(id)?.value}
                  />
                ) : (
                  ![
                    IssueDetailFieldType.TEXT_AREA,
                    IssueDetailFieldType.TOGGLE,
                  ].includes(fieldType) && <div className="grid-span-6" />
                ),
            )}
          </div>

          <TextArea
            label={translate('memberEvents.note')}
            onChange={setNote}
            rows={3}
          />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-event-submit"
            loading={loading}
            onClick={doSubmit}
          >
            {translate('global.add')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <CareTeamQuickAddModal
        hideExtraButtons
        open={openAddCTM}
        onClose={() => setOpenAddCTM(false)}
        onSubmit={async (params) => {
          let ctm: CareTeamMember | undefined;
          const patientId = patient instanceof Patient ? patient.id : patient;
          try {
            if (patientId) {
              await quickAddCareTeam(params);
              const ctm = await fetchMemberCareTeamMembers(patientId);
              dispatch(onCareTeamMembersUpdated(ctm.items));
              setOpenAddCTM(false);
            }
          } catch (e) {
            showGlobalError(e as string);
          }
          return ctm?.careTeamPerson || false;
        }}
        selectedMember={patient instanceof Patient ? patient : undefined}
      />
    </>
  );
};

export default MemberEventAddIssueModal;
