import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { Language } from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  Organization,
  PodConfig,
  ProgramExtension,
} from '@vestahealthcare/common/models';

import { IconButton, Panel } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  createPodConfig,
  fetchPodConfigs,
  updatePodConfig,
} from 'dash/src/services/PodServices';

import PodConfigAddModal from './PodConfigAddModal';
import PodConfigEditModal from './PodConfigEditModal';
import { PodConfigTable } from './PodConfigTable';

export const PODsConfigDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [editCondig, setEditConfig] = useState<PodConfig>();
  const [configs, setConfigs] = useState<PodConfig[]>([]);

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);

  const getInitialData = async () => {
    setLoadingData(true);

    const [e, l, r, p] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getLanguages(),
      CacheServices.getOrganizations(),
      CacheServices.getAllProgramExtensions(),
    ]);

    setEmployees(Employee.toSelectable(e));
    setLanguages(Language.toSelectable(l).filter(({ disabled }) => !disabled));
    setReferralSources(Organization.toSelectable(r));
    setProgramExtensions(ProgramExtension.toSelectable(p));

    setLoadingData(false);
  };

  const getPodConfigs = async () => {
    setLoading(true);
    const { items } = await fetchPodConfigs({
      limit: 100,
    });
    setConfigs(items);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
    getPodConfigs();
  }, []);

  return (
    <Panel id="pods-config-section">
      <Panel.Heading title={translate('pods.config.title')}>
        <Panel.Actions>
          <IconButton
            onClick={() => setOpenAddModal(true)}
            tooltip={translate('pods.config.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <PodConfigTable
          configs={configs}
          onAdd={() => setOpenAddModal(true)}
          onEdit={(config) => {
            setEditConfig(config);
            setOpenEditModal(true);
          }}
        />
        <PodConfigAddModal
          data={{
            employees,
            languages,
            programExtensions,
            referrals: referralSources,
          }}
          loadingData={loadingData}
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
          onSubmit={async (params) => {
            await createPodConfig(params);
            await getPodConfigs();
          }}
        />
        <PodConfigEditModal
          data={{
            employees,
            languages,
            programExtensions,
            referrals: referralSources,
          }}
          loadingData={loadingData}
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSubmit={async (id, params) => {
            await updatePodConfig(id, params);
            await getPodConfigs();
          }}
          pod={editCondig}
        />
      </Panel.Body>
    </Panel>
  );
};

export default PODsConfigDashboard;
