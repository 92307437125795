import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { TaskCustomFieldType } from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { translate } from '@vestahealthcare/common/i18n';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';

import { Select } from 'styleguide-v2';

import { EditDateCustomField } from './EditDateCustomField';
import { EditDropdownCustomField } from './EditDropdownCustomField';

type Props = {
  customFields: TaskCustomField[];
  onEdit: (list: TaskCustomField[]) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  header: {
    alignItems: 'baseline',
    display: 'flex',
    flexFlow: 'column',
  },
  fieldsList: {
    display: 'flex',
    flexFlow: 'column',
  },
  select: {
    minWidth: '200px',
  },
});

const CUSTOM_FIELDS_OPTIONS = [
  {
    value: TaskCustomFieldType.DATE,
    label: translate('global.date'),
  },
  {
    value: TaskCustomFieldType.DATETIME,
    label: translate('global.time'),
  },
  {
    value: TaskCustomFieldType.CHECKBOX,
    label: translate('taskDefinitions.subtask.multipleSelect'),
  },
];

export const CustomFields = (props: Props) => {
  const styles = useStyles();
  const { customFields, onEdit, submitted } = props;
  const [list, setList] = useState<TaskCustomField[]>([]);

  useEffect(() => {
    setList([...customFields]);
  }, [customFields]);

  const addCustomField = (type: any) => {
    const newList = [...list, { type, required: false } as TaskCustomField];
    setList(newList);
    onEdit(newList);
  };

  const removeCustomField = (index?: number) => {
    list.splice(index || 0, 1);
    setList([...list]);
    onEdit([...list]);
  };

  const editDateCustomField = (field: TaskCustomField, index: number) => {
    list[index] = { ...field };
    setList([...list]);
    onEdit([...list]);
  };

  const renderCustomField = (field: TaskCustomField, index: number) => {
    let result;
    if (field.type.value === TaskCustomFieldType.DATE.value) {
      result = (
        <EditDateCustomField
          key={index}
          field={field}
          onDelete={() => removeCustomField(index)}
          onEdit={(field) => editDateCustomField(field, index)}
          submitted={submitted}
        />
      );
    } else if (field.type.value === TaskCustomFieldType.DATETIME.value) {
      result = (
        <EditDateCustomField
          key={index}
          field={field}
          onDelete={() => removeCustomField(index)}
          onEdit={(field) => editDateCustomField(field, index)}
          submitted={submitted}
        />
      );
    } else {
      result = (
        <EditDropdownCustomField
          key={index}
          field={field}
          onDelete={() => removeCustomField(index)}
          onEdit={(field) => editDateCustomField(field, index)}
          submitted={submitted}
        />
      );
    }
    return result;
  };
  return (
    <article className="grid-span-12">
      <header className="grid-wrapper">
        <div className={`${styles.header} grid-span-6`}>
          <span className="h7">
            {translate('taskDefinitions.subtask.customFields')}
          </span>
        </div>
        <Select
          className={classNames('grid-span-6', styles.select)}
          data-cy="add-custom-field"
          items={CUSTOM_FIELDS_OPTIONS}
          onChange={(value: Selectable) =>
            value && addCustomField(value?.value)
          }
          placeholder={translate('taskDefinitions.subtask.addField')}
        />
      </header>

      {list?.length ? (
        <div className={styles.fieldsList}>{list.map(renderCustomField)}</div>
      ) : undefined}
    </article>
  );
};

export default CustomFields;
