/* eslint-disable react/no-find-dom-node */
import React, { Component, RefObject, createRef } from 'react';
import { FormControl, FormGroup, Popover } from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translate } from '@vestahealthcare/common/i18n';
import { PatientSearch as Patient } from '@vestahealthcare/common/models';

import { Spinner } from 'styleguide-v2';

import PatientSearchList from 'dash/src/components/Header/PatientSearch/PatientSearchList';
import { searchPatientsV2 } from 'dash/src/services/PatientServices';

import './main.less';

const SEARCH_WAIT = 300;

interface State {
  query: string;
  loading: boolean;
  patients: Patient[] | null;
  selectedItem: number | null;
}

export class PatientSearch extends Component<RouteComponentProps, State> {
  // binded methods
  bindedKeyListener = this.keyListener.bind(this);

  clickHandler = () => this.clearSearch();

  listItemRefs?: HTMLElement[];

  inputElementRef = createRef<HTMLInputElement>();

  searchEl = createRef<HTMLInputElement>();

  popover = createRef<HTMLElement>();

  timer: NodeJS.Timeout | undefined;

  state: State = {
    query: '',
    loading: false,
    patients: null,
    selectedItem: null,
  };

  componentDidMount() {
    this.addClickHandler();
    this.attachKeydownListener();
  }

  componentWillUnmount() {
    this.removeClickHandler();
    this.detachKeydownListener();

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  keyListener(evt: KeyboardEvent) {
    const omittedTags = ['INPUT', 'TEXTAREA'];
    const targetElement = evt.target as HTMLElement;

    if (
      targetElement &&
      evt.key === '/' &&
      !omittedTags.includes(targetElement.tagName)
    ) {
      const node = findDOMNode(this.inputElementRef.current) as HTMLElement;
      if (node) {
        node.focus();
      }

      evt.preventDefault();
    }
  }

  attachKeydownListener() {
    window.addEventListener('keydown', this.bindedKeyListener);
  }

  detachKeydownListener() {
    window.removeEventListener('keydown', this.bindedKeyListener);
  }

  addClickHandler() {
    this.removeClickHandler();
    window.addEventListener('click', this.clickHandler);
  }

  removeClickHandler() {
    window.removeEventListener('click', this.clickHandler);
  }

  queryUpdated(query: string) {
    this.setState(
      {
        query,
        patients: null,
      },
      () => {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.updateSearch(), SEARCH_WAIT);
      },
    );
  }

  async updateSearch() {
    const { query } = this.state;

    const hasMinLength = query.length > 1;
    const isNumeric = query.match(/^\d+$/);

    if (query && (hasMinLength || isNumeric)) {
      this.setState({ loading: true });
      const { items } = await searchPatientsV2({
        queryString: query.trim(),
        limit: 30,
        sort: 'firstName asc',
      });
      this.setState(
        { patients: items, loading: false },
        this.selectedItemCheck,
      );
    } else {
      this.clearSearch();
    }
  }

  clearSearch(shouldClearQuery: boolean = false) {
    this.setState({
      query: shouldClearQuery ? '' : this.state.query,
      loading: false,
      patients: null,
      selectedItem: null,
    });
  }

  selectedItemCheck() {
    const { patients, selectedItem } = this.state;
    // when the request completes, we check if the user had selected an
    // item with an index greater than the new number of items returned
    if (patients && selectedItem && selectedItem >= this.getRequestLength()) {
      this.setState({
        selectedItem: this.getRequestLength() - 1,
      });
    }
  }

  selectedListItemClicked() {
    const { patients, selectedItem } = this.state;

    const patientIsSelected =
      selectedItem !== null && !!patients && selectedItem < patients.length;

    if (patients && selectedItem !== null && patientIsSelected) {
      const person = patients[selectedItem];
      this.listItemClicked(person);
    }
  }

  listItemClicked(person: Patient) {
    this.clearSearch(true);
    this.props.history.push(`/patients/${person.id}/`);
  }

  setRef(offset: number) {
    return (ref: HTMLElement, index: number) => {
      this.listItemRefs = this.listItemRefs || [];
      this.listItemRefs[index + offset] = ref;
    };
  }

  scrollIntoView() {
    if (
      this.listItemRefs &&
      this.state.selectedItem &&
      this.listItemRefs[this.state.selectedItem] &&
      this.popover
    ) {
      const node = findDOMNode(
        this.listItemRefs[this.state.selectedItem],
      ) as HTMLElement;
      const popover = findDOMNode(this.popover.current) as HTMLElement;

      if (!node || !popover) {
        return;
      }

      const nodeIsBelow =
        node.offsetTop + node.clientHeight >
        popover.scrollTop + popover.clientHeight;
      const nodeIsAbove = node.offsetTop < popover.scrollTop;

      if (nodeIsBelow) {
        // align to bottom
        node.scrollIntoView(false);
      } else if (nodeIsAbove) {
        // align to top
        node.scrollIntoView(true);
      }
    }
  }

  getRequestLength() {
    const { patients } = this.state;
    return patients ? patients.length : 0;
  }

  handleKeyPress(keyCode: number) {
    const { selectedItem } = this.state;
    const UP = 38;
    const DOWN = 40;
    const ENTER = 13;

    if (!this.getRequestLength()) {
      return;
    }

    switch (keyCode) {
      case UP: {
        this.setState(
          {
            selectedItem: selectedItem
              ? selectedItem - 1
              : // 0 or unset => start at end
                this.getRequestLength() - 1,
          },
          this.scrollIntoView.bind(this),
        );
        break;
      }
      case DOWN: {
        this.setState(
          {
            selectedItem:
              selectedItem === null ||
              selectedItem === this.getRequestLength() - 1
                ? 0
                : selectedItem + 1,
          },
          this.scrollIntoView.bind(this),
        );
        break;
      }
      case ENTER: {
        this.selectedListItemClicked();
        break;
      }
      default:
    }
  }

  renderLists() {
    const { patients, selectedItem, query } = this.state;
    const patientIsSelected =
      selectedItem !== null && patients && selectedItem < patients.length;

    return (
      <PatientSearchList
        key="patientresults"
        searchTerm={query}
        patients={patients || []}
        onClick={(event) => this.listItemClicked(event)}
        selectedItem={
          patientIsSelected && selectedItem !== null ? selectedItem : undefined
        }
      />
    );
  }

  renderResults() {
    const { loading, patients } = this.state;

    return (
      <Popover
        className="patient-search-popover"
        id="patient-search-popover"
        placement="bottom"
        ref={this.popover as RefObject<any>}
      >
        {loading && (
          <div className="flex center">
            <Spinner color="primary" />
          </div>
        )}
        {patients && this.renderLists()}
      </Popover>
    );
  }

  render() {
    const { query, loading, patients } = this.state;

    return (
      <div className="patient-search-textbox" ref={this.searchEl}>
        <FormGroup>
          <FormControl
            placeholder={translate('search.title')}
            value={query}
            onChange={(evt) =>
              this.queryUpdated(
                evt.target && (evt.target as HTMLInputElement).value,
              )
            }
            onClick={() => this.updateSearch()}
            onKeyDown={(evt) => this.handleKeyPress(evt.keyCode)}
            ref={this.inputElementRef as RefObject<any>}
            data-cy="patient-search"
          />
          <FormControl.Feedback>
            <i className="fa fa-search" />
          </FormControl.Feedback>
        </FormGroup>
        {(loading || patients) && this.renderResults()}
      </div>
    );
  }
}

export default withRouter(PatientSearch);
