import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { TaskDefinitionOutcome } from '@vestahealthcare/common/models/TaskDefinitionOutcome';

import { SortableList } from 'styleguide';
import { Button } from 'styleguide-v2';
import { SortableData } from 'styleguide/src/components/SortableList';

type Props = {
  outcomes: TaskDefinitionOutcome[];
  onEdit: (outcome: TaskDefinitionOutcome[]) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  header: {
    alignItems: 'baseline',
    display: 'flex',
    flexFlow: 'column',
  },
});

export const Outcomes = (props: Props) => {
  const styles = useStyles();
  const { outcomes, onEdit, submitted } = props;
  const [outcomeList, setOutcomeList] = useState<TaskDefinitionOutcome[]>([]);

  useEffect(() => {
    setOutcomeList(outcomes.filter((outcome) => outcome.enabled !== false));
  }, [outcomes]);

  const addOutcome = () => {
    setOutcomeList([...outcomeList, {} as TaskDefinitionOutcome]);
    onEdit([...outcomeList, {} as TaskDefinitionOutcome]);
  };

  return (
    <article className="grid-span-12">
      <header>
        <div className={`grid-span-11 ${styles.header}`}>
          <span className="h7">{translate('taskDefinitions.outcomes')}</span>
          {submitted && !outcomeList.length ? (
            <span className="error-red hint">
              {translate('taskDefinitions.subtask.noOutcomesError')}
            </span>
          ) : (
            <span className="hint">
              {translate('taskDefinitions.subtask.dragdropOutcome')}
            </span>
          )}
        </div>
        <div className="grid-span-1">
          <Button
            color="info"
            type="outlined"
            data-cy="add-outcome"
            onClick={addOutcome}
            size="s"
          >
            +&nbsp;{translate('taskDefinitions.subtask.addOutcome')}
          </Button>
        </div>
      </header>

      <br />

      {outcomeList?.length ? (
        <SortableList
          data-cy="outcome-list"
          items={outcomeList}
          isEditable
          isRemovable
          onChange={(outcomes: SortableData[]) => {
            setOutcomeList([...outcomes] as TaskDefinitionOutcome[]);
            onEdit([...outcomes] as TaskDefinitionOutcome[]);
          }}
          submitted={submitted}
        />
      ) : undefined}
    </article>
  );
};

export default Outcomes;
