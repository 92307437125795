import moment, { Moment } from 'moment';
// @ts-ignore
import qs from 'qs';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  SERVER_DATE_TIME_FORMAT_ZONED,
} from '@vestahealthcare/common/utils/constants';

import { FilterItem, FilterSelectable } from 'styleguide-v2';

import { isDate } from './dateUtils';

export const getStoredFilters = (key: string) => {
  const filtersString = localStorage.getItem(key) || '';
  return qs.parse(filtersString) || {};
};

export const getAsQuery = (filters: any) =>
  qs.stringify(filters, { arrayFormat: 'repeat' });

export const saveStoredFilters = (key: string, filters: any) => {
  localStorage.setItem(key, qs.stringify(filters));
};

export const getDateRange = (
  query: URLSearchParams,
  filters: any,
  field: string,
) => {
  const fieldString = query.get(field) || filters[field];
  return isNaN(Number(fieldString)) ? fieldString : Number(fieldString);
};

export const getBoolean = (
  query: URLSearchParams,
  filters: any,
  field: string,
) => {
  const fieldString = query.get(field) || filters[field] || null;
  return fieldString !== null ? fieldString === 'true' : undefined;
};

export const getCustom = (
  query?: string[],
  storage?: string[],
  mapper?: (value: string) => any,
) => {
  let result: string[] = [];
  if (query instanceof Array && query.length) {
    result = query;
  } else if (storage instanceof Array && storage.length) {
    result = storage;
  }
  return result.map(mapper || ((x) => x));
};

const isSelectable = (x: any): x is Selectable =>
  x?.value !== undefined || x?.label !== undefined;

const isSelectableArray = (x: any): x is Selectable[] =>
  x instanceof Array && x?.length !== 0 && isSelectable(x[0]);

export const getSafeValues = (
  array: (Selectable | { id: number | string })[],
  ids?: number[] | string[],
) =>
  ids
    ?.map((filterId: string | number) =>
      array.find((item) =>
        isSelectable(item) ? item?.value === filterId : item?.id === filterId,
      ),
    )
    .filter(Boolean) || [];

const isBoolean = (bool: string) => ['true', 'false'].includes(bool);

const getFilterItemValue = (
  value: any,
  valueLabel?: (value: any) => string,
): FilterSelectable | FilterSelectable[] | undefined => {
  if (value === undefined || value === '') return undefined;
  if (valueLabel) {
    return {
      value,
      label: valueLabel(value),
    };
  }
  if (typeof value === 'number') {
    return {
      label: String(value),
      value,
    };
  }
  if (typeof value === 'string') {
    if (isBoolean(value)) {
      const bool = value === 'true';
      return {
        value: bool,
        label: translate(`global.${bool ? 'yes' : 'no'}`),
      };
    }
    if (isDate(value)) {
      const date = moment(value, SERVER_DATE_TIME_FORMAT_ZONED);
      return {
        label: date.format(DATE_FORMAT_SHORT),
        value: date,
      };
    }
    return {
      label: value,
      value,
    };
  }
  if (value instanceof Array && typeof value[0] === 'string') {
    return value.map((value) => ({
      label: value,
      value,
    }));
  }
  if (value instanceof Date) {
    return {
      label: moment(value.getTime()).format(DATE_FORMAT_SHORT),
      value: moment(value.getTime()),
    };
  }
  if (isSelectable(value) || isSelectableArray(value)) {
    return value;
  }
  if (value instanceof Array && value[0] instanceof BaseEnum) {
    return value.map(({ id, description }) => ({
      value: id,
      label: description,
    }));
  }
  if (value instanceof BaseEnum) {
    return {
      value: value.id,
      label: value.description,
    };
  }
  if (typeof value === 'boolean') {
    return {
      value,
      label: translate(`global.${value ? 'yes' : 'no'}`),
    };
  }
  return undefined;
};

export const getFilterItem = (
  label: string,
  value: any,
): FilterItem | undefined => {
  const currentValue = getFilterItemValue(value);
  if (currentValue === undefined) return undefined;

  return {
    label,
    value: currentValue,
  };
};

export const getFilterValue = (
  item?: FilterSelectable | FilterSelectable[],
) => {
  if (!item) return undefined;
  if (item instanceof Array) {
    return item.map(({ value }) => value);
  }

  return item.value;
};

const getFilterValueFlatted = (
  item?: FilterSelectable | FilterSelectable[],
) => {
  if (!item) return undefined;
  if (!(item instanceof Array) && item.value instanceof moment)
    return (item.value as Moment).isValid()
      ? (item.value as Moment).format(SERVER_DATE_TIME_FORMAT_ZONED)
      : undefined;
  if (item instanceof Array) {
    return item.map(({ value }) => value);
  }

  return item.value;
};

export const getFilterCount = (
  filters?: { [x: string]: FilterItem },
  keys?: string[],
) =>
  Object.entries(filters || {}).reduce((acc, [key, value]) => {
    let current = 0;
    if (!keys?.length || keys.includes(key)) {
      if (value?.value instanceof Array) {
        current = value?.value?.length ? 1 : 0;
      } else if (typeof value?.value === 'boolean') {
        current = value?.value !== undefined ? 1 : 0;
      } else {
        current = value?.value ? 1 : 0;
      }
    }
    return acc + current;
  }, 0);

export const getBaseEnumFromFilter = (
  item?: FilterSelectable | FilterSelectable[],
) => {
  if (!item) return undefined;
  if (item instanceof Array) {
    return item.map(
      ({ value, label }) => new BaseEnum({ id: value, description: label }),
    );
  }

  return new BaseEnum({ id: item.value, description: item.label });
};

export const getServerFilters = (filters: { [x: string]: FilterItem }) =>
  Object.entries(filters)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = getFilterValue(value.value);
      return acc;
    }, {} as { [x: string]: any });

const getServerFiltersFlatted = (filters: { [x: string]: FilterItem }) =>
  Object.entries(filters)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = getFilterValueFlatted(value.value);
      return acc;
    }, {} as { [x: string]: any });

export const saveNewFilters = (filters: { [x: string]: FilterItem }) =>
  qs.stringify(getServerFiltersFlatted(filters), { arrayFormat: 'repeat' });

export const saveNewFiltersToStorage = (
  key: string,
  filters: { [x: string]: FilterItem },
) => {
  localStorage.setItem(key, saveNewFilters(filters));
};

export type LoadFilterOption = {
  data?: (Selectable | BaseEnum)[];
  default?: any;
  hidden?: boolean;
  label?: string;
  multiple?: boolean;
  valueLabel?: (value: any) => string;
};

export const loadNewFilters = (
  filtersString: string,
  filterData: {
    [x: string]: LoadFilterOption;
  },
  query?: URLSearchParams,
) => {
  const mappedFilters: { [x: string]: any } = qs.parse(filtersString) || {};
  return Object.entries(filterData || {}).reduce((acc, [key, data]) => {
    const storedValue = mappedFilters[key];
    const queryValue = data?.multiple ? query?.getAll(key) : query?.get(key);
    const value = queryValue?.length ? queryValue : storedValue;

    if ((!value || !value?.length) && data.default === undefined) return acc;

    let currentData = [] as Selectable[];
    if (data?.data?.length) {
      if (data.data[0] instanceof BaseEnum) {
        currentData = (data.data as BaseEnum[]).map(({ id, description }) => ({
          value: id,
          label: description,
        }));
      } else if (isSelectableArray(data.data)) {
        currentData = data.data as Selectable[];
      }
    }
    const multiple = data?.multiple;
    let currentValue = getFilterItemValue(
      value || (data?.multiple ? undefined : data.default),
      data?.valueLabel,
    );

    if (data?.data) {
      if (multiple) {
        const valueWithDefault = value || data.default?.map(String);
        if (valueWithDefault instanceof Array) {
          currentValue = currentData.filter((item) => {
            if (item instanceof BaseEnum) {
              return valueWithDefault.includes(String(item.id));
            }
            return valueWithDefault.includes(String(item.value));
          });
        } else {
          currentValue = currentData.filter((item) => {
            if (item instanceof BaseEnum) {
              return String(item.id) === valueWithDefault;
            }
            return String(item.value) === valueWithDefault;
          });
        }
      } else {
        currentValue =
          currentData.find((item) => {
            if (item instanceof BaseEnum) {
              return String(item.id) === value;
            }
            return String(item.value) === value;
          }) || currentValue;
      }
    }
    if (currentValue) {
      acc[key] = {
        hidden: !!data.hidden,
        label: data?.label || '',
        value: currentValue,
      };
    }
    return acc;
  }, {} as { [x: string]: FilterItem });
};

export const loadNewFiltersFromStorage = (
  key: string,
  filterData: {
    [x: string]: LoadFilterOption;
  },
  query?: URLSearchParams,
) => {
  const filtersString = localStorage.getItem(key) || '';
  return loadNewFilters(filtersString, filterData, query);
};

export const getFiltersEnabled = (
  filters?: { [x: string]: FilterItem & { readonly?: boolean } },
  filterData?: {
    [x: string]: (Selectable | BaseEnum)[];
  },
  disabledKeys?: string[],
) => {
  const result = { ...filters };
  disabledKeys?.forEach((key) => delete result[key]);
  if (filterData) {
    Object.keys(result).forEach((key) => {
      const itemValues = filterData[key]?.map((item: Selectable | BaseEnum) =>
        item instanceof BaseEnum ? item.id : item.value,
      );
      if (itemValues?.length && result[key]?.value) {
        if (result[key].value instanceof Array) {
          const values = result[key].value as FilterSelectable[];
          const newItems = values.filter(({ value }) =>
            itemValues.includes(value as string | number),
          );
          if (newItems?.length) {
            result[key].value = newItems;
          } else {
            delete result[key];
          }
        } else {
          const value = (result[key].value as FilterSelectable)?.value;
          if (!itemValues.find((item) => item === value)) {
            delete result[key];
          }
        }
      }
    });
  }
  return result;
};
