import { RPMVendorDeviceType } from '../enums';
import { makeArray } from './Model';
import { RPMVendorDevice } from './RPMVendorDevice';

export class RPMVendorModel {
  id = 0;

  vendorId = '';

  type: RPMVendorDeviceType;

  name = '';

  devices: RPMVendorDevice[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.type = RPMVendorDeviceType.byKey[obj.type];
    this.devices = makeArray(obj.devices, RPMVendorDevice);
  }
}

export default RPMVendorModel;
