import moment, { Moment } from 'moment';

import {
  DayOfWeek,
  Language,
  MemberContactTimeType,
  RPMAdherenceLevel,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BHIMember,
  BaseEnum,
  CCMMember,
  CCMMemberDetail,
  PodReportingData,
  PodReportingGroup,
  PodReportingSummary,
  RPMMember,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, {
  PaginatedResponseWithTime,
  PaginationParams,
} from 'dash/src/services/Api';

export interface GetCCMMemberParams extends PaginationParams {
  aideScheduleDay?: DayOfWeek[];
  aideScheduleTime?: MemberContactTimeType[];
  bhiMinutesFrom?: number;
  bhiMinutesTo?: number;
  brandId?: string[];
  careCoordinatorId?: number[];
  carePlanDateFrom?: Moment;
  carePlanDateTo?: Moment;
  carePlanDue?: boolean;
  carePlanGroupId?: number[];
  careTeamDiscoveryDue?: boolean;
  ccmCallStatus?: string[];
  contactDay?: DayOfWeek[];
  contactTime?: MemberContactTimeType[];
  engagementOwnerId?: number[];
  excludedMemberIds?: number[];
  excludedProgramExtensionId?: number[];
  excludedReferralSource?: string[];
  hasCarePlan?: boolean;
  hasAideScheduleInfo?: boolean;
  hasContactInfo?: boolean;
  hasLanguage?: boolean;
  hasProfessionalTime?: boolean;
  hasProfessionalTimeOrTouchedByNp?: boolean;
  hasProgramExtensions?: boolean;
  hasRiskLevel?: boolean;
  hasValidCCM?: boolean;
  healthCoachId?: number[];
  isBiannualComplete?: boolean;
  isComplete?: boolean;
  initialCPDue?: boolean;
  isUnreachable?: boolean;
  insurance?: string[];
  language?: Language[];
  memberIds?: number[];
  memberName?: string;
  npOwnerId?: number[];
  numberOfCalls?: number;
  podManagerId?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  referralSource?: string[];
  riskLevel?: RiskLevel[];
  rnOwnerId?: number[];
  recentCyclesMetCount?: number[];
  rpmAdherenceId?: string[];
  rpmMinutesFrom?: number;
  rpmMinutesTo?: number;
  lastReadingDateFrom?: Moment;
  lastReadingDateTo?: Moment;
  rpmStartDateFrom?: Moment;
  rpmStartDateTo?: Moment;
  startDateFrom?: Moment;
  startDateTo?: Moment;
  state?: States[];
  touchedByNp?: boolean;
  totalMinutesFrom?: number;
  totalMinutesTo?: number;
  touchPointReason?: string[];
  validMinutesFrom?: number;
  validMinutesTo?: number;
  worklistGroupId?: number[];
}

const prepareGetParams = ({
  aideScheduleDay,
  aideScheduleTime,
  careCoordinatorId,
  carePlanDateFrom,
  carePlanDateTo,
  contactDay,
  contactTime,
  engagementOwnerId,
  excludedMemberIds,
  healthCoachId,
  language,
  npOwnerId,
  programExtensionId,
  programExtensionStatus,
  riskLevel,
  lastReadingDateFrom,
  lastReadingDateTo,
  rpmStartDateFrom,
  rpmStartDateTo,
  state,
  startDateFrom,
  startDateTo,
  ...params
}: GetCCMMemberParams) => ({
  aideScheduleDay: aideScheduleDay?.length
    ? aideScheduleDay?.map((item) => item?.valueOf())
    : undefined,
  aideScheduleTime: aideScheduleTime?.length
    ? aideScheduleTime?.map((item) => item?.valueOf())
    : undefined,
  careCoordinatorId: careCoordinatorId?.length ? careCoordinatorId : undefined,
  carePlanDateFrom: carePlanDateFrom?.format(DATE_FORMAT),
  carePlanDateTo: carePlanDateTo?.format(DATE_FORMAT),
  contactDay: contactDay?.length
    ? contactDay?.map((item) => item?.valueOf())
    : undefined,
  contactTime: contactTime?.length
    ? contactTime?.map((item) => item?.valueOf())
    : undefined,
  engagementOwnerId: engagementOwnerId?.length ? engagementOwnerId : undefined,
  excludedMemberIds: excludedMemberIds?.length ? excludedMemberIds : undefined,
  healthCoachId: healthCoachId?.length ? healthCoachId : undefined,
  language: language?.length
    ? language?.map((item) => item?.valueOf())
    : undefined,
  month: moment().startOf('month').format(DATE_FORMAT),
  npOwnerId: npOwnerId?.length ? npOwnerId : undefined,
  programExtensionId: programExtensionId?.length
    ? programExtensionId
    : undefined,
  programExtensionStatus: programExtensionStatus?.length
    ? programExtensionStatus?.map((item) => item?.valueOf())
    : undefined,
  riskLevel: riskLevel?.length
    ? riskLevel?.map((item) => item?.valueOf())
    : undefined,
  lastReadingDateFrom: lastReadingDateFrom?.format(DATE_FORMAT),
  lastReadingDateTo: lastReadingDateTo?.format(DATE_FORMAT),
  rpmStartDateFrom: rpmStartDateFrom?.format(DATE_FORMAT),
  rpmStartDateTo: rpmStartDateTo?.format(DATE_FORMAT),
  state: state?.length ? state?.map((item) => item?.valueOf()) : undefined,
  startDateFrom: startDateFrom?.format(DATE_FORMAT),
  startDateTo: startDateTo?.format(DATE_FORMAT),
  ...params,
});

export const fecthCCMMembers = async (
  params: GetCCMMemberParams,
): Promise<PaginatedResponseWithTime<CCMMember>> => {
  const {
    ccmdashboardMembers: { items, pagination, lastUpdatedAt },
  } = await Api.getv2('/ccm-dashboard', prepareGetParams(params));
  return {
    items: items.map((x: any) => new CCMMember(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const fecthRPMMembers = async (
  params: GetCCMMemberParams,
): Promise<PaginatedResponseWithTime<RPMMember>> => {
  const {
    rpmDashboardMembers: { items, pagination, lastUpdatedAt },
  } = await Api.getv2('/rpm-dashboard', prepareGetParams(params));
  return {
    items: items.map((x: any) => new RPMMember(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const fecthBHIMembers = async (
  params: GetCCMMemberParams,
): Promise<PaginatedResponseWithTime<BHIMember>> => {
  const {
    bhiDashboardMembers: { items, pagination, lastUpdatedAt },
  } = await Api.getv2('/bhi-dashboard', prepareGetParams(params));
  return {
    items: items.map((x: any) => new BHIMember(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const fecthCCMTouchpointReasons = async (): Promise<string[]> => {
  const { touchpointReasons } = await Api.getv2(
    '/ccm-dashboard/touchpoint-reasons',
  );
  return touchpointReasons as string[];
};

export const fetchCCMPods = async (): Promise<BaseEnum[]> => {
  const { pods } = await Api.getv2('/ccm-dashboard/pods');
  return pods.map((x: any) => new BaseEnum(x));
};

export type PatchAssignToWorklistParams = {
  worklistGroupId: number | null;
  includedMemberIds?: number[];
  filter?: GetCCMMemberParams;
};

export const assignToGroupWorklist = async ({
  filter,
  ...params
}: PatchAssignToWorklistParams) => {
  await Api.patchv2JSON('/ccm-dashboard/actions/worklist-bulk', {
    filter: filter ? prepareGetParams(filter) : undefined,
    month: moment().startOf('month').format(DATE_FORMAT),
    ...params,
  });
};

export const getMemberInfoData = async (id: number) => {
  const { ccmdashboardDetail } = await Api.getv2(
    `/ccm-dashboard/${id}/detail`,
    {
      month: moment().startOf('month').format(DATE_FORMAT),
    },
  );
  return new CCMMemberDetail(ccmdashboardDetail);
};

export const downloadCCMDashboard = async (params: GetCCMMemberParams) => {
  const blob: Blob = await Api.getv2Blob(
    '/ccm-dashboard/export',
    prepareGetParams(params),
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'ccm_member_export.csv');
  tempLink.click();
};

export const downloadRPMDashboard = async (params: GetCCMMemberParams) => {
  const blob: Blob = await Api.getv2Blob(
    '/rpm-dashboard/export',
    prepareGetParams(params),
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'rpm_member_export.csv');
  tempLink.click();
};

export type GetPodReportDataParams = {
  careCoordinatorId?: number[];
  excludedProgramExtensionId?: number[];
  healthCoachId?: number[];
  isComplex?: boolean;
  isUnreachable?: boolean;
  language?: string[];
  month?: Moment;
  npOwnerId?: number[];
  podManagerId?: number[];
  rnOwnerId?: number[];
  status?: string[];
  worklistGroupId?: number[];
};

const preparePodParams = ({
  month,
  npOwnerId,
  worklistGroupId,
  ...rest
}: GetPodReportDataParams) => ({
  month: month?.format(DATE_FORMAT),
  npOwnerId: npOwnerId?.filter((id) => id >= 0),
  worklistGroupId: worklistGroupId?.filter((id) => id >= 0),
  ...rest,
});

export const fetchCCMPodReportingSummary = async (
  params: GetPodReportDataParams,
): Promise<PodReportingSummary> => {
  const { podReportingSummary } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/summary',
    preparePodParams(params),
  );
  const summary = new PodReportingSummary(podReportingSummary);

  summary.perDay = Math.round(summary.perDay);
  summary.perDayGoal = Math.round(summary.perDayGoal);

  return summary;
};

export const fetchCCMPodReportingGroups = async (
  params: GetPodReportDataParams,
): Promise<PodReportingGroup[]> => {
  const { podReportingWorklists } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-groups',
    preparePodParams(params),
  );

  (podReportingWorklists as PodReportingGroup[]).sort(
    (
      { worklistName: a, worklistId: aId },
      { worklistName: b, worklistId: bId },
    ) => {
      if (!aId) return -1;
      if (!bId) return 1;
      return a.localeCompare(b);
    },
  );

  return podReportingWorklists.map((x: any) => new PodReportingGroup(x));
};

export const exportCCMPodReportingGroups = async (
  params: GetPodReportDataParams,
): Promise<void> => {
  const blob: Blob = await Api.getv2Blob(
    '/ccm-dashboard/pod-reporting/by-groups/export',
    preparePodParams(params),
  );
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'pod_reporting_summary.csv');
  tempLink.click();
};

export const fetchCCMPodReportingMemberStatus = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const { podReportingStatuses } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-member-status',
    preparePodParams(params),
  );
  const total = podReportingStatuses.reduce(
    (acc: number, { total }: { total: number }) => acc + total,
    0,
  );
  return [
    ...podReportingStatuses.map((x: any) => new PodReportingData(x)),
    new PodReportingData({
      name: translate('podReporting.table.total'),
      total,
    }),
  ];
};

export const fetchCCMPodReportingMinutes = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const { podReportingMinutes } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-minutes',
    preparePodParams(params),
  );
  return podReportingMinutes.map((x: any) => new PodReportingData(x));
};

export const fetchCCMPodReportingProvider = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const { podReportingProvider } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-provider',
    preparePodParams(params),
  );
  return podReportingProvider.map((x: any) => new PodReportingData(x));
};

export const fetchCCMPodReportingBianualVisit = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const {
    podReportingBiannual: { completed, notCompleted },
  } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-visit',
    preparePodParams(params),
  );
  return [
    new PodReportingData({
      name: translate('podReporting.table.completed'),
      total: completed,
    }),
    new PodReportingData({
      name: translate('podReporting.table.notCompleted'),
      total: notCompleted,
    }),
  ];
};

export const fetchCCMPodReportingRPMAdherence = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const { podReportingRpmAdherence } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-rpm-adherence',
    preparePodParams(params),
  );

  const total = podReportingRpmAdherence.reduce(
    (acc: number, { total }: { total: number }) => acc + total,
    0,
  );

  return [
    ...podReportingRpmAdherence.map(
      ({ name, ...rest }: any) =>
        new PodReportingData({
          name: RPMAdherenceLevel.byKey[name]
            ? RPMAdherenceLevel.byKey[name].toString()
            : name,
          ...rest,
        }),
    ),
    new PodReportingData({
      name: translate('podReporting.table.total'),
      total,
    }),
  ];
};

export const fetchCCMPodReportingRPMStatus = async (
  params: GetPodReportDataParams,
): Promise<PodReportingData[]> => {
  const { podReportingRpmStatus } = await Api.getv2(
    '/ccm-dashboard/pod-reporting/by-rpm-status',
    preparePodParams(params),
  );

  const total = podReportingRpmStatus.reduce(
    (acc: number, { total }: { total: number }) => acc + total,
    0,
  );

  return [
    ...podReportingRpmStatus.map(
      ({ name, ...rest }: any) =>
        new PodReportingData({
          name,
          ...rest,
        }),
    ),
    new PodReportingData({
      name: translate('podReporting.table.total'),
      total,
    }),
  ];
};
