import { MemberStatus, RiskLevel } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import {
  CommunityResource,
  DBEnum,
  EmployeeGroup,
} from '@vestahealthcare/common/models';

export enum Op {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  EDIT = 'EDIT',
}

export const CHANGE_TYPES = {
  CARE_COORDINATOR: 'careCoordinator',
  CARE_PLAN_GROUP: 'carePlanGroup',
  COMMUNITY_RESOURCE: 'communityResource',
  ENGAGEMENT_OWNER: 'engagementOwner',
  HEALTH_COACH: 'healthCoach',
  NP_OWNER: 'npOwner',
  POD_MANAGER: 'podManager',
  PROGRAM_EXTENSION: 'programExtension',
  RISK_LEVEL: 'riskLevel',
  RN_OWNER: 'rnOwner',
  STATUS: 'status',
  WORKLIST_GROUP: 'worklistGroup',
};

export type ChangeType =
  | CommunityResource
  | DBEnum
  | EmployeeGroup
  | MemberStatus
  | ProgramExtensionOp[]
  | RiskLevel
  | Selectable
  | Selectable[]
  | undefined
  | null;

export type ProgramExtensionOp = {
  operation?: Op;
  id?: number;
  status?: string;
};
