import React, { Component } from 'react';
import { connect } from 'react-redux';

import { translate } from '@vestahealthcare/common/i18n';

import { Modal } from 'styleguide-v2';

import { getUserChangedWarningStatus } from 'dash/src/redux/actions/GlobalActions';
import Session from 'dash/src/services/SessionServices';

interface Props {
  showUserChangedMessage: boolean;
}

const mapStateToProps = (state: any): Props => ({
  showUserChangedMessage: getUserChangedWarningStatus(state),
});

class UserChangedWarning extends Component<Props> {
  doReload() {
    // Todo: improve this
    Session.removeImpersonator();
    window.location.reload();
  }

  render() {
    const { showUserChangedMessage } = this.props;

    return (
      <Modal
        open={showUserChangedMessage}
        onSubmit={async () => this.doReload()}
        title={translate('global.authenticatedUserMismatch')}
        submitText={translate('global.reload')}
      >
        <p>{translate('global.authenticatedUserMismatchDetail')}</p>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(UserChangedWarning);
