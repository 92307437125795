import { BaseEnum } from './BaseEnum';
import { make } from './Model';

export class PodRuleTrigger extends BaseEnum {
  status: BaseEnum;

  constructor(obj: any) {
    super(obj);
    this.status = make(obj.status, BaseEnum);
  }
}

export default PodRuleTrigger;
