import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Icon, Modal } from 'styleguide-v2';

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'circle',
  },
  subItem: {
    marginLeft: 25,
  },
  subItemList: {
    listStyleType: 'square',
  },
});

export const EscalationCriteriaModal = () => {
  const [showModal, setShowModal] = useState(false);
  const styles = useStyles();
  return (
    <>
      <a className="flex gap" onClick={() => setShowModal(true)}>
        <Icon className="fa fa-info" color="info" />
        <span style={{ marginTop: '0.25rem' }}>
          {translate('events.escalationCriteria.title')}
        </span>
      </a>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          open
          maxWidth="lg"
          title={translate('events.escalationCriteria.title')}
        >
          <ul style={{ padding: '0.5rem 4rem 0' }}>
            <li className={styles.listItem}>
              <p className="semi-bold">
                {translate('events.escalationCriteria.firstCriteria')}
              </p>
            </li>
            <li className={styles.listItem}>
              <p>{translate('events.escalationCriteria.secondCriteria')}</p>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.thirdCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.thirdCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.thirdCriteria.secondItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.thirdCriteria.thirdItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.forthCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.forthCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.forthCriteria.secondItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.forthCriteria.thirdItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.fifthCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.fifthCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.fifthCriteria.secondItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.sixthCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.sixthCriteria.firstItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.seventhCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.seventhCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.seventhCriteria.secondItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p style={{ textDecoration: 'underline' }}>
                {translate('events.escalationCriteria.eightCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.eightCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.eightCriteria.secondItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
            <li className={styles.listItem}>
              <p>
                {translate('events.escalationCriteria.ninethCriteria.title')}
              </p>
              <ul className={styles.subItem}>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.ninethCriteria.firstItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.ninethCriteria.secondItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.ninethCriteria.thirdItem',
                    )}
                  </p>
                </li>
                <li className={styles.subItemList}>
                  <p>
                    {translate(
                      'events.escalationCriteria.ninethCriteria.forthItem',
                    )}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </Modal>
      )}
    </>
  );
};
