import classNames from 'classnames';
import { Moment } from 'moment';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import StarOutlinedIcon from '@mui/icons-material/StarOutline';
import { Box, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { StoredFilter } from '@vestahealthcare/common/models';

import { Collapse as MCollapse } from 'styleguide-v2/src/components/Collapse';
import { Spinner } from 'styleguide-v2/src/components/Spinner';
import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Button } from '../Button';
import { Chip } from '../Chip';
import { CollapseIcon } from '../CollapseIcon';
import { IconButton } from '../IconButton';
import { Modal } from '../Modal';
import { Select } from '../Select';
import { TextInput } from '../TextInput';
import { Detail } from './Detail';
import { VerticalDetail } from './VerticalDetail';
import { VerticalSeparator } from './VerticalSeparator';

interface Props extends React.HTMLProps<HTMLDivElement> {
  'data-cy'?: string;
  loading?: boolean;
}

interface PanelProps extends Props {
  detail?: 'horizontal' | 'vertical';
}

type StyleProps = {
  height?: number;
};

const panelStyles = makeStyles<Theme, StyleProps>({
  container: {
    display: 'grid',
    backgroundColor: BackgroundColors.gray,
    fontFamily: Fonts.fontFamily,
    fontSize: Fonts.fontSize,
    gridTemplateAreas: "'header header' 'body detail' 'footer footer'",
    gridTemplateRows: 'max-content 1fr minmax(0, auto)',
    gridTemplateColumns: '1fr',
    height: '100%',
    width: '100%',
  },
  verticalContainer: {
    gridTemplateAreas: "'header' 'body' 'separator' 'detail' 'footer'",
    gridTemplateRows: ({ height }) =>
      `max-content minmax(0, ${
        height ? `${height}px` : 'auto'
      }) 3rem 1fr minmax(0, auto)`,
  },
});

const PanelComponent = ({
  children,
  'data-cy': dataCy,
  className,
  detail = 'horizontal',
}: PanelProps) => {
  const [height, setHeight] = useState<number>(400);
  const useStyles = panelStyles({ height });
  const classes = classNames(
    className,
    useStyles.container,
    detail === 'vertical' && useStyles.verticalContainer,
    'v2-panel',
  );

  if (detail === 'vertical') {
    return (
      <div className={classes} data-cy={dataCy}>
        <VerticalSeparator onChangeHeight={setHeight} />
        {children}
      </div>
    );
  }

  return (
    <div className={classes} data-cy={dataCy}>
      {children}
    </div>
  );
};

const headingStyles = makeStyles({
  container: {
    backgroundColor: Colors.white,
    display: 'grid',
    gap: '1rem',
    gridArea: 'header',
    gridAutoColumns: 'max-content max-content 2fr max-content',
    gridAutoRows: 'minmax(6.5rem, max-content) max-content max-content',
    gridTemplateAreas:
      "'title filters none actions' 'collapsible collapsible collapsible collapsible' 'tabs tabs tabs tabs'",
    width: '100%',

    '& .title': {
      gridArea: 'title',
      alignSelf: 'center',
      display: 'flex',
      flexFlow: 'column',
      margin: '2.5rem 0 0 4rem',
    },
    '& .filters': {
      alignSelf: 'center',
      gridArea: 'filters',
      margin: '2.5rem 0 0 1rem',
    },
    '& .actions': {
      alignSelf: 'center',
      display: 'flex',
      gap: '0.5rem',
      gridArea: 'actions',
      margin: '2rem 4rem 0 0',
    },
    '& .collapsible': {
      alignSelf: 'center',
      gridArea: 'collapsible',
      margin: '0 4rem',
    },
    '& .tabs': {
      alignSelf: 'end',
      borderTop: `1px solid ${Colors.lighterGray}`,
      borderBottom: `1px solid ${Colors.lighterGray}`,
      gridArea: 'tabs',
      padding: '0 4rem',
      height: '4.9rem',

      '&.buttons': {
        height: '5.5rem',
      },
    },
  },
  containerFilters: {
    gridTemplateAreas:
      "'title filters none actions' 'filter-bar filter-bar filter-bar filter-bar' 'tabs tabs tabs tabs'",
  },
  containerCompact: {
    '& > .title': {
      margin: '1.5rem 0 0 4rem',
    },
    '& > .filters': {
      margin: '1.5rem 0 0 1rem',
    },
    '& > .actions': {
      margin: '0rem 4rem 0 0',
    },
  },
});

interface HeaderProps extends Omit<Props, 'title'> {
  compact?: boolean;
  title?: string | ReactNode;
  filtersV2?: boolean;
}

export const Heading = ({
  children,
  className,
  compact,
  'data-cy': dataCy = 'page-title',
  title,
  filtersV2,
}: HeaderProps) => {
  const styles = headingStyles();
  const classes = classNames(
    className,
    compact && styles.containerCompact,
    styles.container,
    filtersV2 && styles.containerFilters,
    'heading',
  );

  return (
    <div className={classes} data-cy={dataCy} id="panel-header">
      {title ? <h2 className="title">{title}</h2> : null}
      {children}
    </div>
  );
};

export const Filters = ({ children, className, 'data-cy': dataCy }: Props) => (
  <div className={classNames('filters', className)} data-cy={dataCy}>
    {children}
  </div>
);

export const Actions = ({ children, className, 'data-cy': dataCy }: Props) => (
  <div className={classNames('actions', className)} data-cy={dataCy}>
    {children}
  </div>
);

export const Tabs = ({ children, className, 'data-cy': dataCy }: Props) => (
  <div className={classNames('tabs', className)} data-cy={dataCy}>
    {children}
  </div>
);

interface CollapseProps extends Props {
  open: boolean;
  static?: ReactNode;
}

const renderCollapse = (
  open: boolean,
  children: ReactNode,
  className?: string,
  dataCy?: string,
) => (
  <MCollapse className={className} data-cy={dataCy} open={open}>
    {children}
  </MCollapse>
);

export const Collapse = ({
  children,
  className,
  'data-cy': dataCy,
  open,
  static: staticNode,
}: CollapseProps) =>
  staticNode ? (
    <div className={classNames('collapsible', className)} data-cy={dataCy}>
      {staticNode}
      {renderCollapse(open, children)}
    </div>
  ) : (
    renderCollapse(open, children, classNames('collapsible', className), dataCy)
  );

export type FilterSelectable = Omit<SelectableInfo<any>, 'value'> & {
  value: boolean | string | number | Moment;
};

export type FilterItem = {
  label: string;
  value: FilterSelectable | FilterSelectable[];
  hidden?: boolean;
};

export type SortOption = Selectable & {
  sort: string;
};

type FilterBarProps = {
  className?: string;
  chips?: { [x: string]: (FilterItem & { readonly?: boolean }) | undefined };
  inputs?: ReactNode;
  onClearFilters?: () => void;
  onChangeSort?: (sort: SortOption) => void;
  onDeleteFilter?: (item: string) => void;
  onOpenFilters?: () => void;
  onSelectFilter?: (option: StoredFilter) => void;
  storedFilters?: StoredFilter[];
  sortDefault?: SortOption;
  sortOptions?: SortOption[];
};

const filterBarStyles = makeStyles({
  button: {
    '&&': {
      alignSelf: 'baseline',
      backgroundColor: Colors.mint,
      border: `1px solid ${Colors.mint}`,
      color: `${Colors.textGreen}!important`,
      width: 'max-content',

      '&:hover': {
        border: `1px solid ${Colors.lightMint}`,
        backgroundColor: Colors.lightMint,
      },
    },
  },
  buttonShowStoredFilters: {
    '&&': {
      padding: 0,
    },
  },
  buttonStoredFilers: {
    '&& > span': {
      marginTop: '-0.5rem',
    },
  },
  chip: {
    paddingRight: '0.5rem',
    display: 'flex',
    gap: '0.5rem',
  },
  chipValue: {
    display: 'block',
    maxWidth: '15rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  clearChip: {
    borderColor: BackgroundColors.grayLine,
    fontWeight: 500,
    height: '2.4rem',
    lineHeight: '20px',
    padding: '0.25rem 0.9rem',

    '&:hover': {
      backgroundColor: BackgroundColors.lightMint,
    },
  },
  closeChip: {
    '&&': {
      padding: 0,
      color: Colors.iconGreen,
      height: 16,
      marginTop: '-0.125rem',

      '&& > svg': {
        height: 16,
      },

      '&&:hover': {
        color: Colors.textGreen,
      },
    },
  },
  container: {
    alignItems: 'center',
    borderTop: `1px solid ${Colors.lighterGray}`,
    display: 'flex',
    gap: '0.5rem',
    gridArea: 'filter-bar',
    padding: '1rem 4rem 0',

    '& > *:first-child': {
      flex: 10,
    },

    '& > *:last-child': {
      minWidth: 'max-content',
    },

    '& .sub-container': {
      display: 'flex',
      flexFlow: 'column',
      gap: '0.5rem',
    },

    '& .filter-inputs': {
      display: 'flex',
      gap: '0.5rem',
      flexWrap: 'wrap',

      '& > *': {
        flex: '1',
        minWidth: '10rem',
        maxWidth: '22.5rem',
      },
    },

    '& .filter-chips': {
      display: 'flex',
      gap: '0.5rem',
      flexWrap: 'wrap',
    },
  },
  filterMenuTitle: {
    '&& span': {
      color: Colors.iconGray,
      fontSize: Fonts.fontSize,
      fontWeight: 500,
    },

    '&&': {
      opacity: '1!important',
    },

    '&&:hover': {
      backgroundColor: Colors.white,
    },
  },
  filterMenuRow: {
    fontSize: Fonts.fontSize,
    marginLeft: '1rem',
    width: '100%',
  },
  sort: {
    '&&': {
      alignSelf: 'baseline',
      minWidth: '27.5rem',
    },
  },
  verticalLine: {
    backgroundColor: `${Colors.textGreen}99`,
    height: 32,
    marginLeft: '1.5rem',
    marginRight: '0.5rem',
    width: 2,
  },
});

export const FilterBar = (props: FilterBarProps) => {
  const {
    className,
    chips,
    inputs,
    onChangeSort,
    onClearFilters,
    onDeleteFilter,
    onSelectFilter,
    onOpenFilters,
    storedFilters,
    sortDefault,
    sortOptions,
  } = props;
  const styles = filterBarStyles();

  const classes = classNames(styles.container, className);

  const [anchorFilters, setAnchorFilters] = useState<Element>();
  const openSavedFilters = Boolean(anchorFilters);

  const [sort, setSort] = useState<SortOption | undefined>(
    sortDefault || (sortOptions?.length ? sortOptions[0] : undefined),
  );

  const getLabel = (item: FilterSelectable | FilterSelectable[]) => {
    if (item instanceof Array) {
      return item?.length ? item[0].label : '';
    }
    return item.label;
  };

  const currentChips = useMemo(
    () =>
      Object.entries(chips || {})
        .filter(([, item]) => !!item)
        .filter(([, item]) => !item?.hidden),
    [chips],
  );

  const storedPreferredFilters = storedFilters?.filter(
    ({ preferred }) => !!preferred,
  );
  const storedNotPreferredFilters = storedFilters?.filter(
    ({ preferred }) => !preferred,
  );

  useEffect(() => {
    if (sort && onChangeSort) onChangeSort(sort);
  }, [sort]);

  useEffect(() => {
    setSort(sortDefault);
  }, [sortDefault]);

  return (
    <Box className={classes}>
      <div className="sub-container">
        <div className="filter-inputs">{inputs}</div>
        {!!currentChips?.length && (
          <div className="filter-chips">
            {currentChips.map(([key, item]) => {
              if (!item) return <></>;
              const { label, value, readonly } = item;
              return (
                <Chip
                  className={styles.chip}
                  color="secondary"
                  type="contained-2"
                  key={`panel-filter-chip-${label}-${value}`}
                  title={
                    value instanceof Array && value.length > 1 ? (
                      <>
                        {value?.map((singleValue) => (
                          <p
                            className="white no-margin"
                            key={`panel-filter-chip-${label}-${singleValue.value}`}
                          >
                            {singleValue.label}
                          </p>
                        ))}
                      </>
                    ) : undefined
                  }
                >
                  <span>{label}:</span>
                  <span className={classNames(styles.chipValue, 'semi-bold')}>
                    {value instanceof Array && value.length > 1
                      ? `${value.length} selected`
                      : getLabel(value)}
                  </span>
                  {readonly || !onDeleteFilter ? (
                    <span />
                  ) : (
                    <IconButton
                      className={styles.closeChip}
                      size="small"
                      onClick={() => onDeleteFilter(key)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Chip>
              );
            })}
            {onClearFilters &&
              !!currentChips?.filter(([, value]) => !value?.readonly)
                ?.length && (
                <Chip
                  className={styles.clearChip}
                  color="secondary"
                  type="outlined"
                  key="panel-filter-chip-clear"
                  onClick={onClearFilters}
                >
                  {translate('components.Panel.FilterBar.clearAll')}
                </Chip>
              )}
          </div>
        )}
      </div>
      {onOpenFilters && (
        <Button
          className={classNames(
            styles.button,
            !!storedFilters?.length && styles.buttonStoredFilers,
          )}
          color="secondary"
          type="contained"
          icon={<FilterIcon fontSize="small" />}
          onClick={onOpenFilters}
          size="s"
        >
          {translate('components.Panel.FilterBar.seeAll')}
          {!!storedFilters?.length && onSelectFilter && (
            <>
              <div className={styles.verticalLine} />
              <IconButton
                className={styles.buttonShowStoredFilters}
                size="small"
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (anchorFilters) {
                    setAnchorFilters(undefined);
                  } else {
                    setAnchorFilters(
                      evt.currentTarget?.parentElement?.parentElement ||
                        undefined,
                    );
                  }
                }}
              >
                <CollapseIcon
                  open={openSavedFilters}
                  htmlColor={Colors.textGreen}
                />
              </IconButton>
            </>
          )}
        </Button>
      )}
      {!!storedFilters?.length && onSelectFilter && (
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorFilters}
          open={openSavedFilters}
          onClose={() => setAnchorFilters(undefined)}
          PaperProps={{
            style: {
              marginTop: '0.5rem',
              minWidth: '17rem',
            },
          }}
        >
          {!!storedPreferredFilters?.length && (
            <MenuItem className={styles.filterMenuTitle} disabled>
              <span>{translate('components.Panel.FilterBar.preferred')}</span>
            </MenuItem>
          )}
          {(storedPreferredFilters || []).map((filter) => (
            <MenuItem
              key={`stored-filter-${filter.id}`}
              onClick={() => {
                onSelectFilter(filter);
                setAnchorFilters(undefined);
              }}
            >
              <span className={styles.filterMenuRow}>{filter.description}</span>
            </MenuItem>
          ))}
          {!!storedPreferredFilters?.length &&
            !!storedNotPreferredFilters?.length && (
              <MenuItem className={styles.filterMenuTitle} disabled>
                <span>{translate('components.Panel.FilterBar.other')}</span>
              </MenuItem>
            )}
          {(storedNotPreferredFilters || []).map((filter) => (
            <MenuItem
              key={`stored-filter-${filter.id}`}
              onClick={() => {
                onSelectFilter(filter);
                setAnchorFilters(undefined);
              }}
            >
              <span
                className={classNames(
                  styles.filterMenuRow,
                  !storedPreferredFilters?.length && 'no-margin',
                )}
              >
                {filter.description}
              </span>
            </MenuItem>
          ))}
        </Menu>
      )}
      {!!sortOptions?.length && onChangeSort && (
        <Select
          className={styles.sort}
          disableClearable
          flow="horizontal"
          items={sortOptions}
          label={translate('common.sortBy')}
          onChange={setSort}
          value={sort}
          size="xs"
        />
      )}
    </Box>
  );
};

const useSotredFilterStyles = makeStyles({
  container: {
    alignItems: 'center',
    gap: '0.625rem',
    display: 'flex',
    padding: '0.25rem 2rem',

    '&&:first-child': {
      marginTop: '0.75rem',
    },

    '&& span': {
      flex: 1,
      marginLeft: '0.25rem',
    },

    '&& button': {
      marginTop: '0.375rem',
    },
  },
  preferredButton: {
    color: Colors.iconGreen,
    background: 'transparent',
  },
});

const StoredFilterItem = ({
  item,
  onClickPreferred,
  onEdit,
  onDelete,
}: {
  item: StoredFilter;
  onClickPreferred: (item: StoredFilter) => void;
  onEdit: (item: StoredFilter) => void;
  onDelete: (item: StoredFilter) => void;
}) => {
  const styles = useSotredFilterStyles();

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.preferredButton}
        onClick={() => onClickPreferred(item)}
      >
        {item.preferred ? (
          <StarIcon fontSize="large" />
        ) : (
          <StarOutlinedIcon fontSize="large" />
        )}
      </button>
      <span className="ellipsis" style={{ maxWidth: 'calc(100% - 6rem)' }}>
        {item.description}
      </span>
      <button
        type="button"
        className={styles.preferredButton}
        onClick={() => onEdit(item)}
      >
        <EditIcon />
      </button>
      <button
        type="button"
        className={styles.preferredButton}
        onClick={() => onDelete(item)}
      >
        <DeleteIcon />
      </button>
    </div>
  );
};

type FilterProps = {
  className?: string;
  children: ReactNode;
  onClear: () => void;
  onClose: () => void;
  onDeleteFilter?: (option: StoredFilter) => Promise<void>;
  onEditFilter?: (option: StoredFilter) => Promise<void>;
  onSaveFilter?: (option: StoredFilter) => Promise<void>;
  onSubmit: () => void;
  open: boolean;
  size?: number;
  storedFilters?: StoredFilter[];
};

type FilterStyleProps = {
  position: number;
  size: number;
};

const filterStyles = makeStyles<Theme, FilterStyleProps>({
  closeButton: {
    color: Colors.iconGreen,
    background: 'transparent',
    fontSize: `calc(${Fonts.fontSize} * 1.75)`,
    fontFamily: 'Roboto',
  },
  container: {
    background: Colors.white,
    boxShadow: `-20px 0 15px -15px ${Colors.lightGray}`,
    display: 'grid',
    gridTemplateAreas: "'header' 'body' 'footer'",
    gridTemplateRows: '6rem calc(100% - 11.5rem) 5.5rem',
    height: ({ position }) => `calc(100vh - ${position}px)`,
    marginTop: '-2rem',
    position: 'fixed',
    right: ({ size }) => `-${size + 20}px`,
    transition: 'right 0.3s ease',
    width: ({ size }) => `${size}px`,
    zIndex: 5,
  },
  open: {
    right: '0!important',
  },
  titleContainer: {
    borderBottom: `1px solid ${Colors.lightGray}`,
    display: 'flex',
    gridArea: 'header',
    padding: '1rem 2rem 0',
  },
  title: {
    '&&': {
      flex: 10,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontFamily: Fonts.fontTitle,
    },
  },
  body: {
    gridArea: 'body',
    maxHeight: '100%',
    overflow: 'auto',
    width: ({ size }) => `${size}px`,
  },
  footer: {
    alignItems: 'center',
    borderTop: `1px solid ${Colors.lightGray}`,
    display: 'flex',
    gridArea: 'footer',
    gap: '1rem',
    padding: '1rem 2rem',
    height: '6rem',

    '& > *': {
      flex: 1,
    },
  },
  storedFilterButton: {
    color: Colors.iconGreen,
    background: 'transparent',
    fontSize: `calc(${Fonts.fontSize} * 1.75)`,
    fontFamily: 'Roboto',
    marginRight: '0.75rem',
  },
});

const NEW_FILTER_OPTION = new StoredFilter({
  id: '',
  description: translate('components.Panel.FilterModal.addNewFilter'),
  filters: null,
});

export const FilterModal = (props: FilterProps) => {
  const {
    className,
    children,
    onClear,
    onClose,
    onDeleteFilter,
    onEditFilter,
    onSaveFilter,
    onSubmit,
    open,
    size = 350,
    storedFilters,
  } = props;

  const containerRef = useRef<HTMLInputElement>(null);
  const position = containerRef?.current?.offsetTop || 0;
  const styles = filterStyles({ position, size });

  const classes = classNames(styles.container, className, open && styles.open);

  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const [selectedStoredFilter, setSelectedStoredFilter] = useState<
    StoredFilter
  >();
  const [filterName, setFilterName] = useState<string>();

  const handleKeyClose = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyClose);
    } else {
      document.removeEventListener('keydown', handleKeyClose);
      setShowSaveModal(false);
      setTimeout(() => {
        setShowSettings(false);
      }, 300);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyClose);
    };
  }, [open]);

  useEffect(() => {
    if (showEditNameModal) {
      setFilterName(selectedStoredFilter?.description || '');
    } else {
      setFilterName('');
    }
  }, [showEditNameModal, showSaveModal]);

  return (
    <>
      <Box className={classes} ref={containerRef}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>
            {translate('components.panelFilters.title')}
          </h2>
          {storedFilters && (
            <>
              {onSaveFilter && !showSettings && (
                <button
                  type="button"
                  className={styles.storedFilterButton}
                  onClick={() => {
                    setSelectedStoredFilter(
                      storedFilters?.length ? undefined : NEW_FILTER_OPTION,
                    );
                    setShowSaveModal(true);
                  }}
                >
                  <SaveIcon />
                </button>
              )}
              {(showSettings || !!storedFilters?.length) && (
                <button
                  type="button"
                  className={styles.storedFilterButton}
                  onClick={() => setShowSettings(!showSettings)}
                >
                  {showSettings ? <FilterIcon /> : <SettingsIcon />}
                </button>
              )}
            </>
          )}
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
          >
            <span>&times;</span>
          </button>
        </div>
        <div className={styles.body}>
          {showSettings && onEditFilter ? (
            <>
              {storedFilters?.length ? (
                storedFilters?.map((item) => (
                  <StoredFilterItem
                    key={`stored-filter-${item.id}`}
                    item={item}
                    onClickPreferred={(filter) =>
                      onEditFilter({
                        ...filter,
                        preferred: !filter.preferred,
                      })
                    }
                    onDelete={(filter) => {
                      setSelectedStoredFilter(filter);
                      setShowDeleteModal(true);
                    }}
                    onEdit={(filter) => {
                      setSelectedStoredFilter(filter);
                      setShowEditNameModal(true);
                    }}
                  />
                ))
              ) : (
                <p className="gray italic" style={{ padding: '2rem' }}>
                  {translate('components.Panel.FilterModal.noFiltersStored')}
                </p>
              )}
            </>
          ) : (
            children
          )}
        </div>
        {!showSettings && (
          <div className={styles.footer}>
            <Button
              color="secondary"
              type="outlined"
              data-cy="filter-panel-reset"
              onClick={() => {
                onClear();
                onClose();
              }}
              size="s"
            >
              {translate('components.panelFilters.reset')}
            </Button>
            <Button
              color="secondary"
              data-cy="filter-panel-apply"
              onClick={() => {
                onSubmit();
                onClose();
              }}
              size="s"
            >
              {translate('components.panelFilters.apply')}
            </Button>
          </div>
        )}
      </Box>
      {!!storedFilters && (
        <>
          {onEditFilter && (
            <Modal
              submitDisabled={!filterName}
              open={showEditNameModal}
              onClose={() => setShowEditNameModal(false)}
              onSubmit={async () =>
                selectedStoredFilter &&
                filterName &&
                onEditFilter({
                  ...selectedStoredFilter,
                  description: filterName,
                })
              }
              maxWidth="xs"
              submitText={translate('components.Panel.FilterModal.save')}
              title={translate('components.Panel.FilterModal.editTitle')}
            >
              <TextInput
                onChange={setFilterName}
                label={translate('components.Panel.FilterModal.filterName')}
                value={filterName}
              />
            </Modal>
          )}
          {onDeleteFilter && selectedStoredFilter && (
            <Modal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onSubmit={async () => onDeleteFilter(selectedStoredFilter)}
              maxWidth="xs"
              submitText={translate('components.Panel.FilterModal.delete')}
              title={translate('components.Panel.FilterModal.deleteTitle')}
              body={translate('components.Panel.FilterModal.deleteBody', {
                filter: selectedStoredFilter?.description,
              })}
            />
          )}
          {onSaveFilter && (
            <Modal
              submitDisabled={
                !selectedStoredFilter ||
                (!selectedStoredFilter.id && !filterName)
              }
              open={showSaveModal}
              onClose={() => setShowSaveModal(false)}
              onSubmit={async () =>
                onSaveFilter(
                  selectedStoredFilter?.id
                    ? selectedStoredFilter
                    : new StoredFilter({
                        id: '',
                        description: filterName,
                        filters: null,
                      }),
                )
              }
              maxWidth="xs"
              submitText={translate('components.Panel.FilterModal.save')}
              title={translate('components.Panel.FilterModal.saveTitle')}
            >
              {!!storedFilters?.length && (
                <Select
                  getItemLabel={({ description }: StoredFilter) => description}
                  renderOption={({ id, description }: StoredFilter) =>
                    id ? (
                      description
                    ) : (
                      <span className="flex middle gap">
                        <AddIcon />
                        {description}
                      </span>
                    )
                  }
                  items={[...storedFilters, NEW_FILTER_OPTION]}
                  onChange={setSelectedStoredFilter}
                  label={translate('components.Panel.FilterModal.filter')}
                  value={selectedStoredFilter}
                />
              )}
              {!!storedFilters?.length &&
                selectedStoredFilter &&
                !selectedStoredFilter.id && <br />}
              {selectedStoredFilter && !selectedStoredFilter.id && (
                <TextInput
                  onChange={setFilterName}
                  label={translate('components.Panel.FilterModal.filterName')}
                  value={filterName}
                />
              )}
            </Modal>
          )}
        </>
      )}
    </>
  );
};

type FilterGroupProps = {
  children: ReactNode;
  className?: string;
  count?: number;
  open?: boolean;
  title: string;
};

const filterGroupStyles = makeStyles({
  collapseContainer: {
    padding: '0.5rem 2rem 2.5rem',
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  collapseIcon: {
    fontSize: '1.75em!important',
    marginRight: '-0.5rem',
  },
  container: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${Colors.paleGray}`,
    },
  },
  titleContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: '1rem 2rem',
  },
  title: {
    flex: 10,
  },
});

export const FilterGroup = ({
  children,
  className,
  count,
  open: defaultOpen = false,
  title,
}: FilterGroupProps) => {
  const styles = filterGroupStyles();

  const [open, setOpen] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={styles.titleContainer}
        onClick={() => setOpen(!open)}
        role="presentation"
      >
        <span className={classNames(styles.title, 'semi-bold')}>
          {title}
          {!!count && (
            <>
              &nbsp;&nbsp;&nbsp;
              <Chip color="secondary" type="contained-2" className="semi-bold">
                {count}
              </Chip>
            </>
          )}
        </span>
        <CollapseIcon
          className={styles.collapseIcon}
          open={open}
          size="large"
        />
      </div>
      <MCollapse open={open}>
        <div className={styles.collapseContainer}>{children}</div>
      </MCollapse>
    </div>
  );
};

interface BodyProps extends Props {
  fixedTableHeader?: boolean;
  onScrollBottom?: () => void;
  onScrollTop?: () => void;
}

const bodyStyles = makeStyles({
  container: {
    backgroundColor: BackgroundColors.gray,
    gridArea: 'body',
    opacity: '1',
    overflowY: 'auto',
    padding: '2rem 4rem',
    transition: 'opacity 300ms',
    width: '100%',
  },
  overlay: {
    background: Colors.white,
    opacity: '.3',
    overflowY: 'hidden',
  },
  loading: {
    left: '50%',
    position: 'fixed',
  },
  fixedTableHeader: {
    '&&': {
      paddingTop: 0,
    },
  },
});

export const Body = ({
  children,
  className,
  fixedTableHeader,
  loading,
  onScrollBottom,
  onScrollTop,
  ...others
}: BodyProps) => {
  const styles = bodyStyles();
  const classes = classNames(
    className,
    styles.container,
    loading ? styles.overlay : '',
    fixedTableHeader && styles.fixedTableHeader,
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollState, setScrollState] = useState<'top' | 'middle' | 'bottom'>(
    'top',
  );

  const onScroll = () => {
    const { scrollTop = 1, scrollHeight = 1, clientHeight = 1 } =
      scrollRef.current || {};
    if (onScrollTop && scrollTop < 50) {
      if (scrollState !== 'top') {
        onScrollTop();
      }
      setScrollState('top');
    } else if (
      onScrollBottom &&
      scrollTop >= scrollHeight - clientHeight - 50
    ) {
      if (scrollState !== 'bottom') {
        onScrollBottom();
      }
      setScrollState('bottom');
    } else {
      setScrollState('middle');
    }
  };

  return (
    <div
      className={classes}
      id="panel-body"
      ref={scrollRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
      onScroll={(onScrollTop || onScrollBottom) && onScroll}
    >
      {loading && <Spinner className={styles.loading} width={60} />}
      {children}
    </div>
  );
};

interface FooterProps extends Props {
  buttons?: boolean;
  white?: boolean;
}

const footerStyles = makeStyles({
  footer: {
    backgroundColor: BackgroundColors.gray,
    borderTop: `1px solid ${Colors.lightGray}`,
    gridArea: 'footer',
    height: '7.5rem',
    padding: '1.5rem 4rem',
    width: '100%',
  },
  buttons: {
    display: 'grid',
    gridTemplateAreas: '". buttons"',
    gridTemplateColumns: '75% 1fr',
  },
  button: {
    display: 'flex',
    gap: '0.5rem',
    gridArea: 'buttons',

    '& > *': {
      flex: 1,
    },
  },
  white: {
    '&&': {
      backgroundColor: Colors.white,
    },
  },
});

export const Footer = ({
  buttons,
  children,
  className,
  'data-cy': dataCy,
  white,
}: FooterProps) => {
  const styles = footerStyles();
  const classes = classNames(
    className,
    styles.footer,
    buttons && styles.buttons,
    white && styles.white,
  );

  return (
    <footer className={classes} data-cy={dataCy} id="panel-footer">
      {buttons ? <div className={styles.button}>{children}</div> : children}
    </footer>
  );
};

export const Panel = Object.assign(PanelComponent, {
  Detail,
  VerticalDetail,
  Heading,
  Body,
  Footer,
  Filters,
  FilterGroup,
  FilterModal,
  FilterBar,
  Actions,
  Tabs,
  Collapse,
});

export default Panel;
