import React, { useMemo } from 'react';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee } from '@vestahealthcare/common/models';

import { FilterItem, Select } from 'styleguide-v2';
import { FilterGroup } from 'styleguide-v2/src/components/Panel';

import { getFilterCount, getFilterItem } from 'dash/src/utils/filterUtils';

export type VestaTeamKey =
  | 'careCoordinatorId'
  | 'engagementOwnerId'
  | 'healthCoachId'
  | 'npOwnerId'
  | 'podManagerId'
  | 'rnOwnerId';

type FilterType = Partial<
  {
    [x in VestaTeamKey]?: FilterItem;
  }
>;

type Props = {
  data: {
    employees: SelectableInfo<Employee>[];
  };
  filters: FilterType;
  keys: VestaTeamKey[];
  loading?: boolean;
  onChange: (filters: FilterType) => void;
};

export const FiltersVestaTeam = ({
  data,
  keys,
  loading,
  filters,
  onChange,
}: Props) => {
  const nps = useMemo(
    () => data.employees?.filter(({ info }) => info?.isNP || !info?.id),
    [data.employees],
  );
  const rns = useMemo(
    () => data.employees?.filter(({ info }) => info?.isRN || !info?.id),
    [data.employees],
  );
  const podManagers = useMemo(
    () => data.employees?.filter(({ info }) => info?.isPodManager || !info?.id),
    [data.employees],
  );
  const healthCoaches = useMemo(
    () =>
      data.employees?.filter(
        ({ info }) => info?.isInHealthCoaches || !info?.id,
      ),
    [data.employees],
  );
  const ccs = useMemo(
    () =>
      data.employees?.filter(
        ({ info }) => info?.isCareCoordinator || !info?.id,
      ),
    [data.employees],
  );
  const engagementOwners = useMemo(
    () =>
      data.employees?.filter(({ info }) => info?.isInEngagement || !info?.id),
    [data.employees],
  );

  return (
    <FilterGroup
      title={translate('components.FiltersVestaTeam.title')}
      count={getFilterCount(filters, keys)}
    >
      {keys.includes('podManagerId') && (
        <Select
          data-cy="ccm-filter-modal-pod-manager"
          items={podManagers}
          label={translate('components.FiltersVestaTeam.podManager')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              podManagerId: getFilterItem(
                translate('components.FiltersVestaTeam.podManager'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.podManagerId?.value || []}
        />
      )}
      {keys.includes('npOwnerId') && (
        <Select
          data-cy="ccm-filter-modal-np"
          items={nps}
          label={translate('components.FiltersVestaTeam.npOwner')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              npOwnerId: getFilterItem(
                translate('components.FiltersVestaTeam.npOwner'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.npOwnerId?.value || []}
        />
      )}
      {keys.includes('rnOwnerId') && (
        <Select
          data-cy="ccm-filter-modal-rn"
          items={rns}
          label={translate('components.FiltersVestaTeam.rnOwner')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              rnOwnerId: getFilterItem(
                translate('components.FiltersVestaTeam.rnOwner'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.rnOwnerId?.value || []}
        />
      )}
      {keys.includes('healthCoachId') && (
        <Select
          data-cy="ccm-filter-modal-health-coach"
          items={healthCoaches}
          label={translate('components.FiltersVestaTeam.healthCoach')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              healthCoachId: getFilterItem(
                translate('components.FiltersVestaTeam.healthCoach'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.healthCoachId?.value || []}
        />
      )}
      {keys.includes('careCoordinatorId') && (
        <Select
          data-cy="ccm-filter-modal-care-coordinator"
          items={ccs}
          label={translate('components.FiltersVestaTeam.clinicalAssistant')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              careCoordinatorId: getFilterItem(
                translate('components.FiltersVestaTeam.clinicalAssistant'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.careCoordinatorId?.value || []}
        />
      )}
      {keys.includes('engagementOwnerId') && (
        <Select
          data-cy="ccm-filter-modal-engagement-owners"
          items={engagementOwners}
          label={translate('components.FiltersVestaTeam.engagementOwner')}
          limitTags={1}
          loading={loading}
          multiple
          onChange={(values: SelectableInfo<Employee>[]) => {
            onChange({
              ...filters,
              engagementOwnerId: getFilterItem(
                translate('components.FiltersVestaTeam.engagementOwner'),
                values,
              ),
            });
          }}
          size="small"
          value={filters.engagementOwnerId?.value || []}
        />
      )}
    </FilterGroup>
  );
};
