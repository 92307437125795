// @ts-ignore
import { Moment } from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';

import { Language, States } from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Brand,
  CommunityResource,
  Employee,
  EmployeeGroup,
  Organization,
  ProgramExtension,
  ProgramExtensionStatus,
  SkilledItem,
  TOCItem,
  TOCStatus,
} from '@vestahealthcare/common/models';
import { PaginationType } from '@vestahealthcare/common/models/Pagination';
import { LS_DASHBOARD_TOC_FILTERS_V2 } from '@vestahealthcare/common/utils/constants';

import {
  FilterItem,
  FilterSelectable,
  IconButton,
  LastUpdated,
  Panel,
  Select,
  Tabs,
  ToggleButton,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';
import {
  GetTOCParams,
  TOCCounts,
  downloadSkilledList,
  downloadTOCList,
  fetchSkilledCount,
  fetchSkilledList,
  fetchTOCCount,
  fetchTOCList,
  updateSkilledRecord,
  updateTOCRecord,
} from 'dash/src/services/TOCServices';
import {
  LoadFilterOption,
  getFilterItem,
  getServerFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { SkilledFilterBar } from './SkilledFilterBar';
import { TOCFiltersBar } from './TOCFilterBar';
import { TOCFiltersModal } from './TOCFilterModal';
import { TOCTable } from './TOCTable';
import {
  TOC_TAB_ALL,
  TOC_TAB_DISCHARGED_INITIAL,
  TOC_TAB_DISCHARGED_POST,
  TOC_TAB_HANDOFF,
  TOC_TAB_INPATIENT,
  TOC_TAB_REHAB,
  TOC_TAB_TOC,
  TOC_TOGGLE_ITEMS,
  TOC_TOGGLE_ITEM_SKILLED,
  TOC_TOGGLE_ITEM_TOC,
} from './types';

const useStyles = makeStyles({
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggle: {
    marginLeft: '2rem',
    '& > div': {
      height: '3rem',

      '& > button': {
        minWidth: '5rem',
      },
    },
  },
  sort: {
    minWidth: '27.5rem',
  },
});

const getCount = (count?: number) => (count ? ` (${count})` : '');
const getTabs = (toggle: string, count?: TOCCounts) =>
  toggle === TOC_TOGGLE_ITEM_TOC
    ? [
        {
          value: TOC_TAB_ALL,
          label: `${translate('toc.tabs.all')}${getCount(count?.all)}`,
        },
        {
          value: TOC_TAB_DISCHARGED_INITIAL,
          label: (
            <>
              <p
                className="no-margin"
                style={{
                  color: 'inherit',
                  lineHeight: '16px',
                  margin: '0.5rem 1rem 0',
                }}
              >
                {translate('toc.tabs.discharged')}
                {getCount(count?.dischargedInitial)}
              </p>
              <p className="size-xxs no-margin" style={{ color: 'inherit' }}>
                {translate('toc.tabs.dischargedEligible')}
              </p>
            </>
          ),
        },
        {
          value: TOC_TAB_DISCHARGED_POST,
          label: (
            <>
              <p
                className="no-margin"
                style={{
                  color: 'inherit',
                  lineHeight: '16px',
                  margin: '0.5rem 1rem 0',
                }}
              >
                {translate('toc.tabs.discharged')}
                {getCount(count?.dischargedPost)}
              </p>
              <p className="size-xxs no-margin" style={{ color: 'inherit' }}>
                {translate('toc.tabs.dischargedPostInitial')}
              </p>
            </>
          ),
        },
        {
          value: TOC_TAB_INPATIENT,
          label: `${translate('toc.tabs.inpatient')}${getCount(
            count?.inpatient,
          )}`,
        },
        {
          value: TOC_TAB_REHAB,
          label: `${translate('toc.tabs.rehab')}${getCount(count?.rehab)}`,
        },
        {
          value: TOC_TAB_HANDOFF,
          label: `${translate('toc.tabs.handoff')}${getCount(count?.handoff)}`,
        },
      ]
    : [
        {
          value: TOC_TAB_ALL,
          label: `${translate('toc.tabs.open')}${getCount(count?.all)}`,
        },
        {
          value: TOC_TAB_HANDOFF,
          label: `${translate('toc.tabs.handoff')}${getCount(count?.handoff)}`,
        },
        {
          value: TOC_TAB_TOC,
          label: `${translate('toc.tabs.toc')}${getCount(count?.toc)}`,
        },
      ];

const DEFAULT_PAGE_SIZE = 25;

interface SortOption extends Selectable {
  sort: string;
}

const getSortLabel = (tab: string) => {
  if (tab === TOC_TAB_ALL) return translate('toc.sort.status');
  if (tab === TOC_TAB_DISCHARGED_INITIAL || tab === TOC_TAB_DISCHARGED_POST)
    return translate('toc.sort.discharge');
  if (tab === TOC_TAB_INPATIENT || tab === TOC_TAB_REHAB)
    return translate('toc.sort.admit');
  if (tab === TOC_TAB_HANDOFF) return translate('toc.sort.handoff');
  return '';
};

const getSortOption = (tab: string) => {
  if (tab === TOC_TAB_ALL) return 'statusUpdatedAt';
  if (tab === TOC_TAB_DISCHARGED_INITIAL || tab === TOC_TAB_DISCHARGED_POST)
    return 'dischargeDate';
  if (tab === TOC_TAB_INPATIENT || tab === TOC_TAB_REHAB) return 'admitDate';
  if (tab === TOC_TAB_HANDOFF) return 'handoffDate';
  return '';
};

const COMMON_SORT_OPTIONS: SortOption[] = [
  {
    label: translate('toc.sort.npVisitDateNewest'),
    value: 'np-visit-date-newest',
    sort: 'npVisitDate desc',
  },
  {
    label: translate('toc.sort.npVisitDateOldest'),
    value: 'np-visit-date-oldest',
    sort: 'npVisitDate asc',
  },
  {
    label: translate('toc.sort.rnCarePlanDateNewest'),
    value: 'rn-care-plan-date-newest',
    sort: 'rnCarePlanDate desc',
  },
  {
    label: translate('toc.sort.rnCarePlanDateOldest'),
    value: 'rn-care-plan-date-oldest',
    sort: 'rnCarePlanDate asc',
  },
];

const SORT_OPTIONS = (toggle: string, tab: string): SortOption[] =>
  toggle === TOC_TOGGLE_ITEM_TOC
    ? [
        {
          label: translate('toc.sort.newestDate', { date: getSortLabel(tab) }),
          value: `date-newest-${tab}`,
          sort: `${getSortOption(tab)} desc`,
        },
        {
          label: translate('toc.sort.oldestDate', { date: getSortLabel(tab) }),
          value: `date-oldest-${tab}`,
          sort: `${getSortOption(tab)} asc`,
        },
        {
          label: translate('toc.sort.newestTOCEncounter'),
          value: 'newest-tcm-encounter',
          sort: 'lastTcmEncounterDate desc',
        },
        {
          label: translate('toc.sort.oldestTOCEncounter'),
          value: 'oldest-tcm-encounter',
          sort: 'lastTcmEncounterDate asc',
        },
        {
          label: translate('toc.sort.facilityName'),
          value: 'facility-name',
          sort: 'facility asc',
        },
        ...COMMON_SORT_OPTIONS,
      ]
    : [
        {
          label: translate('toc.sort.episodeStartNewest'),
          value: 'episode-start-newest',
          sort: 'episodeStartDate desc',
        },
        {
          label: translate('toc.sort.episodeStartOldest'),
          value: 'episode-start-oldest',
          sort: 'episodeStartDate asc',
        },
        {
          label: translate('toc.sort.episodeEndNewest'),
          value: 'episode-end-newest',
          sort: 'episodeEndDate desc',
        },
        {
          label: translate('toc.sort.episodeEndOldest'),
          value: 'episode-end-oldest',
          sort: 'episodeEndDate asc',
        },
        ...COMMON_SORT_OPTIONS,
      ];

type KeyGetTOCParams = keyof GetTOCParams;

let idle = true;

export const TOCDashboard = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const styles = useStyles();

  const [selectedTab, setSelectedTab] = useState<string>(TOC_TAB_ALL);
  const [selectedToggle, setSelectedToggle] = useState<string>(
    queryParams.get('toggle') || TOC_TOGGLE_ITEM_TOC,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const [acuityLevels, setAcuityLevels] = useState<Selectable[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);
  const [communityResources, setCommunityResources] = useState<Selectable[]>(
    [],
  );
  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<
    SelectableInfo<EmployeeGroup>[]
  >([]);
  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [nps, setNPs] = useState<Selectable[]>([]);
  const [visitStatus, setVisitStatus] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<Selectable[]>([]);
  const [eligibilityStatuses, setEligibilityStatuses] = useState<Selectable[]>(
    [],
  );
  const [handoffDispositions, setHandoffDispositions] = useState<Selectable[]>(
    [],
  );
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    Selectable[]
  >([]);
  const [statuses, setStatuses] = useState<Selectable[]>([]);

  const sortOptions = useMemo(() => SORT_OPTIONS(selectedToggle, selectedTab), [
    selectedToggle,
    selectedTab,
  ]);

  const [sort, setSort] = useState<SortOption>(sortOptions[0]);

  const [tabsCount, setTabsCount] = useState<TOCCounts>();
  const [items, setItems] = useState<(TOCItem | SkilledItem)[]>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Moment>();
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const defaultPageSize = queryParams.get('pageSize');
  const [pageSize, setPageSize] = useState<number>(
    defaultPageSize ? +defaultPageSize : DEFAULT_PAGE_SIZE,
  );

  const tabs = useMemo(() => getTabs(selectedToggle, tabsCount), [
    selectedToggle,
    tabsCount,
  ]);

  const { canEditTOC } = Session.actingUser;

  const tabFilters = useMemo(() => {
    if (TOC_TOGGLE_ITEM_TOC === selectedToggle) {
      if (TOC_TAB_DISCHARGED_INITIAL === selectedTab)
        return {
          status: [TOCStatus.DISCHARGED],
          dischargedInitial2BusinessDays: true,
        };
      if (TOC_TAB_DISCHARGED_POST === selectedTab)
        return {
          status: [TOCStatus.DISCHARGED],
          dischargedInitial2BusinessDays: false,
        };
      if (
        TOC_TOGGLE_ITEM_TOC === selectedToggle &&
        selectedTab === TOC_TAB_HANDOFF
      )
        return { status: [TOCStatus.HANDOFF] };
      if (selectedTab === TOC_TAB_INPATIENT)
        return { status: [TOCStatus.INPATIENT] };
      if (selectedTab === TOC_TAB_REHAB) return { status: [TOCStatus.REHAB] };
    }

    if (TOC_TOGGLE_ITEM_SKILLED === selectedToggle) {
      if (selectedTab === TOC_TAB_ALL)
        return { handoff: false, closed: false, hasTOCOpen: false };
      if (selectedTab === TOC_TAB_HANDOFF)
        return { handoff: true, hasTOCOpen: false };
      if (selectedTab === TOC_TAB_TOC)
        return { hasTOCOpen: true, closed: false };
    }
    return {};
  }, [selectedTab, selectedToggle]);

  const checkTabs = (toggle: string) => {
    const tabs = getTabs(toggle, tabsCount);
    const foundTab = tabs.find(({ value }) => value === selectedTab);
    if (!foundTab) {
      setSelectedTab(tabs[0].value);
      return tabs[0];
    }
    return foundTab;
  };

  const checkSort = (toggle: string, tab: string) => {
    const sortOptions = SORT_OPTIONS(toggle, tab);
    if (!sortOptions.find(({ value }) => value === sort.value)) {
      setSort(sortOptions[0]);
    }
  };

  const [filters, setFilters] = useState<
    {
      [x in KeyGetTOCParams]?: FilterItem;
    }
  >();

  useEffect(() => {
    if (
      !acuityLevels?.length ||
      !brands?.length ||
      !communityResources?.length ||
      !employees?.length ||
      !handoffDispositions?.length ||
      !languages?.length ||
      !statuses?.length ||
      !visitStatus?.length
    )
      return;

    const {
      selectedTab,
      selectedToggle,
      sort,
      ...storedFilters
    } = loadNewFiltersFromStorage(
      LS_DASHBOARD_TOC_FILTERS_V2,
      {
        acuityLevel: {
          data: acuityLevels,
          label: translate('toc.common.acuityLevel'),
          multiple: true,
        },
        assigneeId: {
          data: [...employeeGroups, ...employees],
          label: translate('toc.common.assignee'),
          multiple: true,
        },
        assigneeGroupId: {
          data: [...employeeGroups, ...employees],
          label: translate('toc.common.assignee'),
          multiple: true,
        },
        brandId: {
          data: brands,
          label: translate('toc.common.brand'),
          multiple: true,
        },
        closed: { label: translate('toc.common.closed') },
        communityResourceId: {
          data: communityResources,
          label: translate('toc.common.homeHealth'),
          multiple: true,
        },
        handoffDisposition: {
          data: handoffDispositions,
          label: translate('toc.common.handoffDisposition'),
          multiple: true,
        },
        hasTOCOpen: { label: translate('toc.common.hasTOCOpen') },
        language: {
          data: languages,
          label: translate('toc.common.handoffDisposition'),
          multiple: true,
        },
        memberId: { label: translate('toc.common.memberId') },
        memberName: { label: translate('toc.common.memberName') },
        needsInitialCarePlan: {
          label: translate('toc.common.initialCP'),
        },
        npOwnerId: {
          data: employees,
          label: translate('toc.common.npOwner'),
          multiple: true,
        },
        npVisitStatus: {
          data: visitStatus,
          label: translate('toc.common.npVisitStatus'),
          multiple: true,
        },
        programExtensionId: {
          data: programExtensions,
          label: translate('toc.common.programExtensions'),
          multiple: true,
        },
        programExtensionStatus: {
          data: programExtensionStatuses,
          label: translate('toc.common.programExtensionStatus'),
          multiple: true,
        },
        referralSourceId: {
          data: referralSources,
          label: translate('toc.common.referralSources'),
          multiple: true,
        },
        rnVisitStatus: {
          data: visitStatus,
          label: translate('toc.common.rnVisitStatus'),
          multiple: true,
        },
        selectedTab: {},
        selectedToggle: {},
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('toc.common.state'),
          multiple: true,
        },
        status: {
          data: statuses,
          label: translate('toc.common.status'),
          multiple: true,
        },
        sort: {},
        trueTCMStatus: { label: translate('toc.common.tcmEligibility') },
      } as {
        [x in
          | KeyGetTOCParams
          | 'selectecTab'
          | 'selectedToggle'
          | 'sort']?: LoadFilterOption;
      },
      queryParams,
    );
    const selectedTabValue = (selectedTab?.value as FilterSelectable)
      ?.value as string;
    const selectedToggleValue = (selectedToggle?.value as FilterSelectable)
      ?.value as string;
    const sortValue = (sort?.value as FilterSelectable)?.value as string;

    if (selectedTabValue) setSelectedTab(selectedTabValue);
    if (selectedToggleValue) setSelectedToggle(selectedToggleValue);
    if (sortValue) {
      const sortFound = sortOptions.find(({ value }) => value === sortValue);
      if (sortFound) {
        setSort(sortFound);
      } else if (selectedTab && selectedToggle) {
        checkSort(selectedToggleValue, selectedTabValue);
      }
    }
    if (
      selectedToggleValue === TOC_TOGGLE_ITEM_SKILLED &&
      storedFilters?.assigneeId?.value
    ) {
      storedFilters.assigneeId.value = (storedFilters?.assigneeId
        ?.value as FilterSelectable[])?.filter(
        ({ info }) => info instanceof Employee,
      );
    }
    setFilters(storedFilters);
  }, [
    acuityLevels,
    brands,
    communityResources,
    employees,
    handoffDispositions,
    languages,
    statuses,
    visitStatus,
  ]);

  useEffect(() => {
    if (!filters) return;

    saveNewFiltersToStorage(LS_DASHBOARD_TOC_FILTERS_V2, {
      ...filters,
      sort: getFilterItem('', sort.value) as FilterItem,
      selectedToggle: getFilterItem('', selectedToggle) as FilterItem,
      selectedTab: getFilterItem('', selectedTab) as FilterItem,
    });
  }, [filters, sort, selectedTab, selectedToggle]);

  const getInitialData = async () => {
    setLoadingFilters(true);

    const [
      ac,
      brands,
      hh,
      emp,
      eg,
      lan,
      visitStatus,
      o,
      es,
      pes,
      pess,
      s,
      hd,
    ] = await Promise.all([
      CacheServices.getAcuityLevels(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getHomeHealthCRs(),
      CacheServices.getEmployees(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getLanguages(),
      CacheServices.getVisitStatus(),
      CacheServices.getOrganizations(),
      CacheServices.getTOCEligibilityStatus(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getTOCStatus(),
      CacheServices.getTOCHandoffDispositions(),
    ]);

    setAcuityLevels(BaseEnum.toSelectable(ac));
    setBrands(Brand.toSelectable(brands));
    setCommunityResources(CommunityResource.toSelectable(hh.items));
    setEmployees(Employee.toSelectable(emp));
    setEmployeeGroups(EmployeeGroup.toSelectable(eg));
    setLanguages(Language.toSelectable(lan));
    setNPs(Employee.toSelectable(emp.filter(({ isNP }) => isNP)));
    setVisitStatus([
      { value: '', label: translate('toc.common.noAppointment') },
      ...BaseEnum.toSelectable(visitStatus),
    ]);
    setReferralSources(Organization.toSelectable(o));
    setEligibilityStatuses(BaseEnum.toSelectable(es));
    setHandoffDispositions(BaseEnum.toSelectable(hd));
    setProgramExtensions(ProgramExtension.toSelectable(pes));
    setProgramExtensionStatuses(ProgramExtensionStatus.toSelectable(pess));
    setStatuses(BaseEnum.toSelectable(s));

    setLoadingFilters(false);
  };

  const getTOCItems = async () => {
    if (!filters) return;
    if (page !== 0 && page * pageSize < items?.length) return;

    setLoading(true);

    if (!filters) return;
    const currentFilters = getServerFilters(filters);

    currentFilters.assigneeId = (filters?.assigneeId
      ?.value as FilterSelectable[])
      ?.filter((x) => x.info instanceof Employee)
      ?.map(({ value }) => value);

    currentFilters.assigneeGroupId = (filters?.assigneeId
      ?.value as FilterSelectable[])
      ?.filter((x) => x.info instanceof EmployeeGroup)
      ?.map(({ value }) => value);

    if (selectedTab !== TOC_TAB_ALL) {
      delete currentFilters.status;
    }
    if (selectedTab !== TOC_TAB_HANDOFF) {
      delete currentFilters.handoffDisposition;
    }

    try {
      idle = false;
      const { items: newItems, pagination, lastUpdatedAt } = await fetchTOCList(
        {
          ...currentFilters,
          ...tabFilters,
          offset: page * pageSize,
          limit: pageSize,
          sort: sort?.sort as string,
        },
      );
      idle = true;
      if (page !== 0) {
        setItems([...items, ...newItems]);
      } else {
        setItems(newItems);
      }
      setLastUpdatedAt(lastUpdatedAt);
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getSkilledItems = async () => {
    if (!filters) return;
    if (page !== 0 && page * pageSize < items?.length) return;

    setLoading(true);

    if (!filters) return;
    const currentFilters = getServerFilters(filters);

    currentFilters.assigneeId = (filters?.assigneeId
      ?.value as FilterSelectable[])
      ?.filter((x) => x.info instanceof Employee)
      ?.map(({ value }) => value);

    delete currentFilters.acuityLevel;
    delete currentFilters.trueTCMStatus;
    currentFilters.closed = currentFilters.closed || false;

    try {
      idle = false;
      const {
        items: newItems,
        pagination,
        lastUpdatedAt,
      } = await fetchSkilledList({
        ...currentFilters,
        ...tabFilters,
        offset: page * pageSize,
        limit: pageSize,
        sort: sort?.sort as string,
      });
      idle = true;

      if (page !== 0) {
        setItems([...items, ...newItems]);
      } else {
        setItems(newItems);
      }
      setLastUpdatedAt(lastUpdatedAt);
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getTOCCounts = async () => {
    if (!filters) return;

    try {
      const currentFilters = getServerFilters(filters);
      currentFilters.assigneeId = (filters?.assigneeId
        ?.value as FilterSelectable[])
        ?.filter((x) => x.info instanceof Employee)
        ?.map(({ value }) => value);

      if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
        currentFilters.assigneeGroupId = (filters?.assigneeId
          ?.value as FilterSelectable[])
          ?.filter((x) => x.info instanceof EmployeeGroup)
          ?.map(({ value }) => value);

        const tabsCount = await fetchTOCCount({
          ...currentFilters,
          sort: sort?.sort as string,
        });
        setTabsCount(tabsCount);
      } else {
        const tabsCount = await fetchSkilledCount({
          ...currentFilters,
          sort: sort?.sort as string,
        });
        setTabsCount(tabsCount);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
  };

  const download = async () => {
    if (!filters) return;

    try {
      setLoadingDownload(true);
      const currentFilters = getServerFilters(filters);
      if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
        await downloadTOCList({
          ...currentFilters,
          ...tabFilters,
          sort: sort?.sort as string,
        });
      } else {
        await downloadSkilledList({
          ...currentFilters,
          ...tabFilters,
          sort: sort?.sort as string,
        });
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingDownload(false);
  };

  const getFiltersBar = () => {
    if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
      return (
        <TOCFiltersBar
          data={{
            acuityLevels,
            languages,
            eligibilityStatuses,
            employees,
            employeeGroups,
            statuses,
          }}
          filters={filters}
          loading={loadingFilters}
          onChange={(filters) => {
            setPage(0);
            setFilters(filters);
          }}
          type={selectedTab === TOC_TAB_ALL ? 'ALL' : 'OTHER'}
        />
      );
    }
    return (
      <SkilledFilterBar
        data={{
          employees,
          languages,
          referralSources,
        }}
        loading={loadingFilters}
        filters={filters}
        onChange={(filters) => {
          setPage(0);
          setFilters(filters);
        }}
      />
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setItems([]);
    if (page !== 0) {
      setPage(0);
    } else if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
      getTOCItems();
    } else {
      getSkilledItems();
    }
  }, [selectedToggle, selectedTab]);

  useEffect(() => {
    if (idle) {
      if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
        getTOCItems();
      } else {
        getSkilledItems();
      }
    }
  }, [sort, page, pageSize, filters]);

  useEffect(() => {
    getTOCCounts();
  }, [filters, selectedToggle]);

  useEffect(() => {
    history.replace(`?toggle=${selectedToggle}&pageSize=${pageSize}`);
  }, [selectedToggle, pageSize]);

  useEffect(() => {
    if (
      selectedToggle === TOC_TOGGLE_ITEM_SKILLED &&
      filters?.assigneeId?.value
    ) {
      filters.assigneeId.value = (filters?.assigneeId
        ?.value as FilterSelectable[])?.filter(
        ({ info }) => info instanceof Employee,
      );
    }
  }, [selectedToggle]);

  return (
    <Panel id="toc-section">
      <Panel.Heading filtersV2>
        <Panel.Filters className="flex gap">
          <ToggleButton
            className={styles.toggle}
            items={TOC_TOGGLE_ITEMS}
            onChange={(toggle) => {
              setSelectedToggle(toggle);
              const tab = checkTabs(toggle);
              checkSort(toggle, tab.value);
            }}
            value={selectedToggle}
          />
        </Panel.Filters>
        <Panel.FilterBar
          onClearFilters={() => {
            setPage(0);
            setFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setPage(0);
            setFilters({
              ...filters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpenFilters(!openFilters)}
          chips={{
            ...filters,
            acuityLevel:
              selectedToggle === TOC_TOGGLE_ITEM_SKILLED
                ? undefined
                : filters?.acuityLevel,
            closed:
              selectedToggle === TOC_TOGGLE_ITEM_SKILLED &&
              selectedTab === TOC_TAB_HANDOFF
                ? {
                    ...(filters?.closed ||
                      (getFilterItem(
                        translate('toc.common.closed'),
                        false,
                      ) as FilterItem)),
                    readonly: true,
                  }
                : undefined,

            handoffDisposition:
              selectedToggle === TOC_TOGGLE_ITEM_TOC &&
              selectedTab === TOC_TAB_HANDOFF
                ? filters?.handoffDisposition
                : undefined,
            status:
              selectedToggle === TOC_TOGGLE_ITEM_TOC &&
              selectedTab === TOC_TAB_ALL
                ? filters?.status
                : undefined,
            trueTCMStatus:
              selectedToggle === TOC_TOGGLE_ITEM_SKILLED
                ? undefined
                : filters?.trueTCMStatus,
          }}
          inputs={getFiltersBar()}
        />
        <Panel.Actions>
          {lastUpdatedAt && (
            <LastUpdated
              lastUpdatedAt={lastUpdatedAt}
              tooltip={translate('toc.actions.lastUpdatedTooltip')}
            />
          )}
          <IconButton
            data-cy="download-action"
            loading={loadingDownload}
            onClick={download}
            tooltip={translate('global.download')}
          >
            <DownloadIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.Tabs className={styles.tabsContainer}>
          <Tabs
            items={tabs}
            onChange={(tab: string) => {
              setPage(0);
              setItems([]);
              setSelectedTab(tab);
              checkSort(selectedToggle, tab);
            }}
            value={selectedTab}
          />
          <Select
            className={styles.sort}
            disableClearable
            flow="horizontal"
            items={sortOptions}
            label={translate('incidents.sort.sortBy')}
            onChange={(sort: SortOption) => {
              setPage(0);
              setSort(sort);
            }}
            size="xs"
            value={sort}
          />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <TOCFiltersModal
          data={{
            acuityLevels,
            assignees: [...(employeeGroups || []), ...(employees || [])],
            brands,
            communityResources,
            eligibilityStatuses,
            handoffDispositions,
            languages,
            nps,
            programExtensions,
            programExtensionStatuses,
            referralSources,
            statuses,
            visitStatus,
          }}
          filters={filters}
          loadingFilters={loadingFilters}
          open={openFilters}
          onChange={(filters) => {
            setPage(0);
            setFilters(filters);
          }}
          onClose={() => setOpenFilters(false)}
          tab={selectedTab}
          toggle={selectedToggle}
        />
        {(!loading || !!items?.length) && (
          <TOCTable
            employees={employees.map(({ info }) => info) as Employee[]}
            employeeGroups={
              employeeGroups.map(({ info }) => info) as EmployeeGroup[]
            }
            items={items}
            onChangeAssignee={async (item, assignee) => {
              const isGroup =
                assignee instanceof EmployeeGroup &&
                selectedToggle === TOC_TOGGLE_ITEM_TOC;
              if (selectedToggle === TOC_TOGGLE_ITEM_TOC) {
                await updateTOCRecord(item.id, {
                  assigneeId: isGroup ? null : assignee?.id,
                  assigneeGroupId: isGroup ? assignee?.id : null,
                });
              } else {
                await updateSkilledRecord(item.id, {
                  assigneeId: assignee?.id,
                });
              }
              const recordIdx = items.findIndex(({ id }) => id === item.id);
              if (recordIdx !== -1) {
                if (isGroup && items[recordIdx] instanceof TOCItem) {
                  (items[recordIdx] as TOCItem).assigneeGroup = assignee;
                } else {
                  items[recordIdx].assignee = assignee as Employee;
                }
                setItems([...items]);
              }
            }}
            onChangePage={setPage}
            onChangePageSize={setPageSize}
            onClickRow={(item) =>
              history.push(
                `/dashboard/${item instanceof TOCItem ? 'toc' : 'skilled'}/${
                  item.id
                }?fullName=${item.member?.fullName}&memberId=${
                  item.member?.id
                }&status=${
                  item instanceof TOCItem ? item.status?.description : ''
                }&assignee=${item?.assignee?.fullName || ''}`,
              )
            }
            pagination={pagination}
            pageSize={pageSize}
            readonly={!canEditTOC}
            toggle={selectedToggle}
            type={selectedTab}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default TOCDashboard;
