import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Sidebar } from 'styleguide';
import { SidebarSectionProps } from 'styleguide/src/components/Sidebar';

import Session from 'dash/src/services/SessionServices';

export const AdminSidebar = () => {
  const { showAdminPodsPage } = useFlags();

  const { pathname } = useLocation();

  const ADMIN_LINKS: SidebarSectionProps = {
    header: 'Admin',
    links: [
      {
        active: pathname.includes('campaigns/manager'),
        href: Session.actingUser.isInAdmin
          ? '/admin/campaigns/manager'
          : '/admin/dashboard',
        text: 'Campaign Management',
        dataCy: 'sidebar-campaigns-manager-link',
        childrenLinksExpanded: true,
        childrenLinks: [
          {
            active: pathname.includes('app-modals'),
            href: '/admin/campaigns/app-modals',
            text: 'App Modals/Banners',
            dataCy: 'sidebar-campaigns-modals-link',
          },
          {
            active: pathname.includes('content-library'),
            href: '/admin/campaigns/content-library',
            text: 'Content Library',
            dataCy: 'sidebar-campaigns-content-link',
          },
        ],
      },
      {
        active: pathname.includes('care-team'),
        href: '/admin/care-team',
        text: 'Care Team',
        dataCy: 'sidebar-care-team-link',
      },
      {
        active: pathname.includes('community-resources'),
        href: '/admin/community-resources',
        text: 'Community Resources',
        dataCy: 'sidebar-community-resources-link',
      },
      {
        active: pathname.includes('employees'),
        href: '/admin/employees',
        text: 'Employees',
        dataCy: 'sidebar-employees-link',
      },
    ],
  };

  const eventLinks = {
    active: pathname.includes('event-types'),
    href: '/admin/event-types',
    text: 'Events',
    dataCy: 'sidebar-events-types-link',
    childrenLinksExpanded: true,
    childrenLinks: [
      {
        active: pathname.includes('event-mapping'),
        href: '/admin/event-mapping',
        text: 'Event Mapping/Config',
        dataCy: 'sidebar-events-mapping-link',
      },
    ],
  };

  if (Session.actingUser.isInClinicalLeadership) {
    eventLinks.childrenLinks.push({
      active: pathname.includes('event-interventions'),
      href: '/admin/event-interventions',
      text: 'Interventions',
      dataCy: 'sidebar-events-interventions-link',
    });
  }

  ADMIN_LINKS?.links?.push(eventLinks);

  ADMIN_LINKS?.links?.push({
    active: pathname.includes('facilities'),
    href: '/admin/facilities',
    text: 'Facilities',
    dataCy: 'sidebar-facilities-link',
  });

  ADMIN_LINKS?.links?.push({
    active: pathname.includes('file-history'),
    href: '/admin/file-history',
    text: 'File History',
    dataCy: 'sidebar-file-history-link',
  });

  ADMIN_LINKS?.links?.push({
    active: pathname.includes('groups'),
    href: '/admin/groups',
    text: 'Groups',
    dataCy: 'sidebar-groups-link',
  });

  if (Session.actingUser.isInIncidentsManagement) {
    ADMIN_LINKS?.links?.push({
      active: pathname.includes('incidents'),
      href: '/admin/incidents',
      text: 'Incidents',
      dataCy: 'sidebar-incidents-link',
    });
  }

  ADMIN_LINKS?.links?.push({
    active: pathname.includes('insurance-plans'),
    href: '/admin/insurance-plans',
    text: 'Insurance Plans',
    dataCy: 'sidebar-insurance-plans-link',
  });

  if (showAdminPodsPage && Session.actingUser.isInPODs) {
    ADMIN_LINKS.links?.push({
      active: pathname.endsWith('pods'),
      href: '/admin/pods',
      text: 'PODs',
      dataCy: 'sidebar-pods-link',
      childrenLinksExpanded: true,
      childrenLinks: [
        {
          active: pathname.includes('pods/config'),
          href: '/admin/pods/config',
          text: 'POD Config',
          dataCy: 'sidebar-pods-config-link',
        },
        {
          active: pathname.includes('pods/rule-builder'),
          href: '/admin/pods/rule-builder',
          text: 'Program Rule Builder',
          dataCy: 'sidebar-pods-rule-builder-link',
        },
      ],
    });
  }

  ADMIN_LINKS?.links?.push({
    active: pathname.includes('referrals'),
    href: '/admin/referrals',
    text: 'Referrals',
    dataCy: 'sidebar-referrals-link',
  });

  if (Session.actingUser.isInAdmin)
    ADMIN_LINKS?.links?.push({
      active: pathname.includes('task-templates'),
      href: '/admin/task-templates',
      text: 'Task Templates',
      dataCy: 'sidebar-task-templates-link',
    });

  return (
    <Sidebar
      collapsible
      sections={[Session.actingUser.isAdmin && ADMIN_LINKS]}
    />
  );
};

export default AdminSidebar;
