import { States } from '../enums';
import { Selectable } from '../enums/Enum';
import { Employee } from './Employee';
import { Facility } from './Facility';
import { InsurancePlanEcw } from './InsurancePlanEcw';
import { make, makeArray } from './Model';
import { Organization } from './Organization';

export class InsurancePlan {
  abilityPayerId?: number;

  createdAt?: number;

  createdBy?: Employee;

  id = 0;

  ecwIds: InsurancePlanEcw[];

  facilityExceptions?: Facility[];

  inNetwork = false;

  isMedicare = false;

  isMedicaid = false;

  isGeneric = false;

  name = '';

  payer?: Organization;

  states: States[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.objCreatedBy, Employee);
    this.ecwIds = makeArray(obj.ecwIds, InsurancePlanEcw);
    this.facilityExceptions = makeArray(obj.facilityExceptions, Facility);
    this.payer = make(obj.payer, Organization);
    this.states = obj.states
      ? obj.states.map((state: any) => States.byKey[state])
      : [];
  }

  static toSelectable(items: InsurancePlan[]): Selectable[] {
    return items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  static sort({ name: a }: InsurancePlan, { name: b }: InsurancePlan) {
    return a.localeCompare(b);
  }
}

export default InsurancePlan;
