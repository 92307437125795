import { useFlags } from 'launchdarkly-react-client-sdk';
import { Moment } from 'moment';
// @ts-ignore
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberCarePlan,
  MemberDiagnostic,
  PatientMedicalEquipment,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
  LS_DASHBOARD_CLINICAL_SUMMARY_FILTERS,
} from '@vestahealthcare/common/utils/constants';

import {
  Colors,
  Fonts,
  IconButton,
  Panel,
  PanelInfo,
  Tabs,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  fetchPatientAllergies,
  fetchPatientDiagnostics,
  fetchPatientEquipment,
  fetchPatientMedications,
} from 'dash/src/redux/slices/MemberInfoSlice';
import { useSelector } from 'dash/src/redux/store';
import {
  addEquipment,
  discontinueEquipment,
} from 'dash/src/services/EquipmentServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { fetchMemberCarePlan } from '../../../services/ClinicalSummarySevices';
import { AllergiesTable } from './AllergiesTable';
import { CarePlanCard } from './CarePlanCard';
import { DMEAddModal } from './DMEAddModal';
import { DMEDeleteModal } from './DMEDeleteModal';
import { DMETable } from './DMETable';
import { MedicationsTable } from './MedicationsTable';
import { ProblemsTable } from './ProblemsTable';

type Props = {
  memberId: string;
};

const CLINICAL_SUMMARY_PROBLEMS = 'clinical-summary-problems';
const CLINICAL_SUMMARY_MEDICATION = 'clinical-summary-medication';
const CLINICAL_SUMMARY_ALLERGIES = 'clinical-summary-allergies';
const CLINICAL_SUMMARY_DME = 'clinical-summary-dme';
const CLINICAL_SUMMARY_CARE_PLAN = 'clinical-summary-care-plan';
const TABS = [
  {
    value: CLINICAL_SUMMARY_PROBLEMS,
    label: (
      <>
        &nbsp;{translate('memberProfile.clinicalSummary.tabs.problems')}&nbsp;
      </>
    ),
  },
  {
    value: CLINICAL_SUMMARY_MEDICATION,
    label: (
      <>
        &nbsp;{translate('memberProfile.clinicalSummary.tabs.medications')}
        &nbsp;
      </>
    ),
  },
  {
    value: CLINICAL_SUMMARY_ALLERGIES,
    label: translate('memberProfile.clinicalSummary.tabs.allergies'),
  },
  {
    value: CLINICAL_SUMMARY_CARE_PLAN,
    label: translate('memberProfile.clinicalSummary.tabs.careplan'),
  },
  {
    value: CLINICAL_SUMMARY_DME,
    label: translate('memberProfile.clinicalSummary.tabs.dme'),
  },
];

const useStyles = makeStyles({
  lastUpdated: {
    minWidth: 'max-content',
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    color: Colors.textGray,
  },
});

export const ClinicalSummary = ({
  match: {
    params: { memberId },
  },
}: RouteComponentProps<Props>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();

  const { hideEcwDeprecatedPages } = useFlags();

  const getFilters = () => {
    const filtersString =
      localStorage.getItem(LS_DASHBOARD_CLINICAL_SUMMARY_FILTERS) || '';
    const filters = qs.parse(filtersString) || {};
    return {
      selectedTab:
        query.get('selectedTab') ||
        filters.selectedTab ||
        CLINICAL_SUMMARY_PROBLEMS,
    };
  };

  const [selectedTab, setSelectedTab] = useState<string>(
    hideEcwDeprecatedPages ? CLINICAL_SUMMARY_DME : getFilters().selectedTab,
  );
  const [careplan, setCareplan] = useState<MemberCarePlan[]>([]);
  const [careplanUpdatedAt, setCareplanUpdatedAt] = useState<Moment>();
  const [careplanUpdatedBy, setCareplanUpdatedBy] = useState<string>();
  const [careplanNotFound, setCareplanNotFound] = useState<boolean>(false);

  const allergies = useSelector(
    (state) => state.memberInfoSlice.allergies?.items,
  );
  const dmes = useSelector((state) => state.memberInfoSlice.equipment);
  const medications = useSelector((state) =>
    state.memberInfoSlice.medications?.items
      ? [
          ...state.memberInfoSlice.medications.items,
        ].sort(({ name: a }, { name: b }) => a.localeCompare(b))
      : undefined,
  );
  const nkda = useSelector((state) => !!state.memberInfoSlice.allergies?.nkda);
  const nkp = useSelector((state) => state.memberInfoSlice.diagnostics?.nkp);
  const problems = useSelector((state) =>
    state.memberInfoSlice.diagnostics?.items
      ? [...state.memberInfoSlice.diagnostics.items].sort(
          ({ status: as, onsetDate: ao }, { status: bs, onsetDate: bo }) => {
            if (as !== bs) {
              if (!as && !bs) return 0;
              if (as === MemberDiagnostic.ACTIVE_STATUS) return -1;
              if (bs === MemberDiagnostic.ACTIVE_STATUS) return 1;
              return as ? 1 : -1;
            }
            if (!ao && !bo) return 0;
            if (!ao) return 1;
            if (!bo) return -1;
            return ao.isAfter(bo) ? -1 : 1;
          },
        )
      : undefined,
  );
  const updatedAtECW = useSelector(
    (state) => state.memberInfoSlice.updatedAtECW,
  );
  const sortedDMEs = useMemo(
    () =>
      [...(dmes || [])]?.sort(
        (
          { description: ad, discontinueDate: a },
          { description: bd, discontinueDate: b },
        ) => {
          if ((a && b) || (!a && !b)) return ad.localeCompare(bd);
          if (a) return 1;
          return -1;
        },
      ),
    [dmes],
  );

  const [openDMEAddModal, setOpenDMEAddModal] = useState<boolean>(false);
  const [openDMEDeleteModal, setOpenDMEDeleteModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<PatientMedicalEquipment>();

  const getAddTooltip = (tab: string) => {
    if (tab === CLINICAL_SUMMARY_DME)
      return translate('memberProfile.clinicalSummary.button.dme');
  };

  const updateDMEs = () => {
    dispatch(fetchPatientEquipment(Number(memberId)));
  };

  const getCareplan = async (id: number) => {
    setCareplanNotFound(false);
    try {
      const { items, updatedAt, updatedBy } = await fetchMemberCarePlan(id);
      if (items?.length) {
        setCareplan(items);
        setCareplanUpdatedAt(updatedAt);
        setCareplanUpdatedBy(updatedBy);
      } else {
        setCareplanNotFound(true);
      }
    } catch (e) {
      setCareplan([]);
      setCareplanNotFound(true);
    }
  };

  useEffect(() => {
    if (selectedTab === CLINICAL_SUMMARY_ALLERGIES && !allergies)
      dispatch(fetchPatientAllergies(Number(memberId)));
    if (selectedTab === CLINICAL_SUMMARY_MEDICATION && !medications)
      dispatch(fetchPatientMedications(Number(memberId)));
    if (selectedTab === CLINICAL_SUMMARY_PROBLEMS && !problems)
      dispatch(fetchPatientDiagnostics(Number(memberId)));
    if (selectedTab === CLINICAL_SUMMARY_DME && !dmes)
      dispatch(fetchPatientEquipment(Number(memberId)));
    if (selectedTab === CLINICAL_SUMMARY_CARE_PLAN && !careplan.length)
      getCareplan(Number(memberId));
  }, [selectedTab]);

  useEffect(() => {
    setCareplan([]);
    setCareplanUpdatedAt(undefined);
    setCareplanUpdatedBy(undefined);
  }, [memberId]);

  const getLoading = () =>
    (selectedTab === CLINICAL_SUMMARY_ALLERGIES && !allergies) ||
    (selectedTab === CLINICAL_SUMMARY_MEDICATION && !medications) ||
    (selectedTab === CLINICAL_SUMMARY_PROBLEMS && !problems) ||
    (selectedTab === CLINICAL_SUMMARY_DME && !dmes) ||
    (selectedTab === CLINICAL_SUMMARY_CARE_PLAN &&
      !careplan.length &&
      !careplanNotFound);

  const getLastUpdated = () => {
    if (selectedTab === CLINICAL_SUMMARY_CARE_PLAN)
      return translate('global.lastUpdatedBy', {
        date: careplanUpdatedAt?.format(DATE_FORMAT_SHORT) || EMPTY,
        editor: careplanUpdatedBy || EMPTY,
      });
    if (selectedTab !== CLINICAL_SUMMARY_DME && updatedAtECW) {
      return translate('global.lastUpdated', {
        date: updatedAtECW.format(DATE_FORMAT_SHORT_WITH_TIME),
      });
    }
  };

  useEffect(() => {
    const flatFilters = {
      selectedTab,
    };
    const query = qs.stringify(flatFilters, { arrayFormat: 'repeat' });
    history.replace(`?${query}`);

    localStorage.setItem(
      LS_DASHBOARD_CLINICAL_SUMMARY_FILTERS,
      qs.stringify(flatFilters),
    );
  }, [selectedTab]);

  const onAddItem = () => {
    if (selectedTab === CLINICAL_SUMMARY_DME) setOpenDMEAddModal(true);
  };

  const getEmptyList = () => {
    let text = '';

    if (
      selectedTab === CLINICAL_SUMMARY_ALLERGIES &&
      allergies &&
      nkda &&
      !allergies?.length
    ) {
      text = 'emptyAllergies';
    }
    if (
      selectedTab === CLINICAL_SUMMARY_MEDICATION &&
      medications &&
      !medications?.length
    ) {
      text = 'emptyMedications';
    }
    if (
      selectedTab === CLINICAL_SUMMARY_PROBLEMS &&
      problems &&
      nkp &&
      !problems.length
    ) {
      text = 'emptyProblems';
    }
    if (selectedTab === CLINICAL_SUMMARY_DME && dmes && !dmes.length) {
      text = 'emptyDMEs';
    }

    if (selectedTab === CLINICAL_SUMMARY_CARE_PLAN && careplanNotFound) {
      text = 'emptyCarePlan';
    }

    return text ? (
      <PanelInfo
        icon={<></>}
        title={translate(`memberProfile.clinicalSummary.info.${text}`)}
        type="info"
      />
    ) : (
      ''
    );
  };

  return (
    <Panel id="clinicalSummary-section">
      <Panel.Heading title={translate('memberProfile.clinicalSummary.title')}>
        <Panel.Actions>
          {getAddTooltip(selectedTab) && (
            <IconButton
              onClick={onAddItem}
              tooltip={getAddTooltip(selectedTab)}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          )}
        </Panel.Actions>
        {!hideEcwDeprecatedPages && (
          <Panel.Tabs>
            <div className="flex spaced">
              <Tabs
                items={TABS}
                onChange={setSelectedTab}
                value={selectedTab}
              />
              <span className={styles.lastUpdated}>{getLastUpdated()}</span>
            </div>
          </Panel.Tabs>
        )}
      </Panel.Heading>
      <Panel.Body loading={getLoading()}>
        {getEmptyList()}
        {selectedTab === CLINICAL_SUMMARY_PROBLEMS &&
          (problems?.length || !nkp) && (
            <ProblemsTable problems={problems || []} />
          )}
        {selectedTab === CLINICAL_SUMMARY_ALLERGIES &&
          (allergies?.length || !nkda) && (
            <AllergiesTable allergies={allergies || []} />
          )}
        {selectedTab === CLINICAL_SUMMARY_MEDICATION &&
          !!medications?.length && (
            <MedicationsTable medications={medications} />
          )}
        {selectedTab === CLINICAL_SUMMARY_DME && !!sortedDMEs?.length && (
          <DMETable
            dmes={sortedDMEs}
            onDelete={(dme) => {
              setOpenDMEDeleteModal(true);
              setModalData(dme);
            }}
          />
        )}
        {selectedTab === CLINICAL_SUMMARY_CARE_PLAN && !!careplan?.length && (
          <CarePlanCard careplanData={careplan} />
        )}
        <DMEAddModal
          dmes={sortedDMEs.filter(({ discontinueDate }) => !discontinueDate)}
          onClose={() => setOpenDMEAddModal(false)}
          onSubmit={async (params) => {
            let result = false;
            const promises = [] as Promise<any>[];
            try {
              params.forEach((mde) =>
                promises.push(
                  addEquipment(Number(memberId), { equipment: mde }),
                ),
              );
              await Promise.all(promises);
              updateDMEs();
              result = true;
            } catch (e) {
              showGlobalError(e as string);
            }
            return result;
          }}
          open={openDMEAddModal}
        />
        <DMEDeleteModal
          dme={modalData}
          onClose={() => setOpenDMEDeleteModal(false)}
          onSubmit={async (params) => {
            let result = false;
            if (modalData) {
              try {
                await discontinueEquipment(
                  Number(memberId),
                  modalData.id,
                  params,
                );
                updateDMEs();
                result = true;
              } catch (e) {
                showGlobalError(e as string);
              }
            }
            return result;
          }}
          open={openDMEDeleteModal}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ClinicalSummary;
