import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';

import { translate } from '@vestahealthcare/common/i18n';
import { BaseEnum, EventDetail } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { IconButton } from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

import { EventDetailCard } from './EventDetailCard';

const useStyles = makeStyles({
  answer: {
    fontWeight: 500,
  },
});

type Props = {
  className?: string;
  event?: EventDetail;
  onEdit: (evt: EventDetail) => void;
};

export const EventDetailEDDiversion = ({ className, event, onEdit }: Props) => {
  const styles = useStyles();
  const canEdit = Session.actingUser.isAdmin;
  const showEDDiversion = event?.isClosed && !event?.resolution;

  if (!event?.isClosed) return <></>;

  return !event?.isClosed ? (
    <></>
  ) : (
    <EventDetailCard
      className={className}
      title={translate(
        `memberEvents.${showEDDiversion ? 'edDiversion' : 'resolution'}`,
      )}
      titleIcon={
        canEdit && (
          <IconButton
            className="note-edit"
            size="small"
            onClick={() => onEdit(event)}
          >
            <EditIcon color="primary" />
          </IconButton>
        )
      }
    >
      {showEDDiversion ? (
        <>
          <p>
            <span>{translate('memberEvents.modals.wouldVisitER')}</span>{' '}
            <span className={styles.answer}>
              {event.wouldVisitER !== undefined &&
                translate(`global.${event.wouldVisitER ? 'yes' : 'no'}`)}
              {event.wouldVisitER === undefined && EMPTY}
            </span>
          </p>
          {event.wouldVisitER === false && (
            <p>
              <span>{translate('memberEvents.modals.divertedFrom')}</span>{' '}
              <span className={styles.answer}>
                {event?.divertedFrom?.description}
              </span>
            </p>
          )}
          <p>
            <span>
              {translate('memberEvents.modals.interventionPreventedER')}
            </span>{' '}
            <span className={styles.answer}>
              {event.interventionPreventedER !== undefined &&
                translate(
                  `global.${event.interventionPreventedER ? 'yes' : 'no'}`,
                )}
              {event.interventionPreventedER === undefined && EMPTY}
            </span>
          </p>
          <p>
            <span>{translate('memberEvents.modals.npEscalationRequired')}</span>{' '}
            <span className={styles.answer}>
              {event.npEscalationRequired !== undefined &&
                translate(
                  `global.${event.npEscalationRequired ? 'yes' : 'no'}`,
                )}
              {event.npEscalationRequired === undefined && EMPTY}
            </span>
          </p>
        </>
      ) : (
        <>
          <p>
            <span className={styles.answer}>
              {event.resolution?.description}
              {BaseEnum.OTHER === event.resolution?.id && event.resolutionOther
                ? ` - ${event.resolutionOther}`
                : ''}
            </span>
          </p>
        </>
      )}
    </EventDetailCard>
  );
};
export default EventDetailEDDiversion;
