import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { PodConfig } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { IconButton, Table, UpdatedBy } from 'styleguide-v2';

interface Props {
  configs: PodConfig[];
  onAdd: () => void;
  onEdit: (group: PodConfig) => void;
}

const POD_CONFIG_COLUMNS = ({ onEdit }: Props) => [
  { headerName: translate('pods.config.common.name'), field: 'name' },
  {
    headerName: translate('pods.config.common.states'),
    component: ({ id, states }: PodConfig) =>
      states?.map((r) => (
        <p className="no-margin" key={`pod-config-${id}-lang-${r.value}`}>
          {r.toString()}
        </p>
      )) || EMPTY,
  },
  {
    headerName: translate('pods.config.common.languages'),
    component: ({ id, languages }: PodConfig) =>
      languages?.map((r) => (
        <p className="no-margin" key={`pod-config-${id}-lang-${r.value}`}>
          {r.toString()}
        </p>
      )) || EMPTY,
  },
  {
    headerName: translate('pods.config.common.vestaTeam'),
    component: ({
      id,
      npOwners,
      rnOwners,
      careCoordinators,
      healthCoaches,
      engagementOwners,
      podManager,
    }: PodConfig) => (
      <div key={`pod-config-${id}-vesta-team`} className="grid-wrapper fit">
        <div className="grid-span-6">
          <p>
            <span className="bold">
              {translate('pods.config.common.podManager')}:&nbsp;
            </span>
            <span>{podManager?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.nps')}:&nbsp;
            </span>
            <span>
              {npOwners?.length
                ? npOwners
                    ?.map(
                      ({ employee }) =>
                        employee?.fullName || translate('global.unassigned'),
                    )
                    ?.join(', ')
                : translate('global.unassigned')}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.rns')}:&nbsp;
            </span>
            <span>
              {rnOwners?.length
                ? rnOwners
                    ?.map(
                      ({ employee }) =>
                        employee?.fullName || translate('global.unassigned'),
                    )
                    ?.join(', ')
                : translate('global.unassigned')}
            </span>
          </p>
        </div>
        <div className="grid-span-6">
          <p>
            <span className="bold">
              {translate('pods.config.common.careCoordinators')}:&nbsp;
            </span>
            <span>
              {careCoordinators?.length
                ? careCoordinators
                    ?.map(
                      ({ employee }) =>
                        employee?.fullName || translate('global.unassigned'),
                    )
                    ?.join(', ')
                : translate('global.unassigned')}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.healthCoaches')}:&nbsp;
            </span>
            <span>
              {healthCoaches?.length
                ? healthCoaches
                    ?.map(
                      ({ employee }) =>
                        employee?.fullName || translate('global.unassigned'),
                    )
                    ?.join(', ')
                : translate('global.unassigned')}
            </span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.engagementOwners')}:&nbsp;
            </span>
            <span>
              {engagementOwners?.length
                ? engagementOwners
                    ?.map(
                      ({ employee }) =>
                        employee?.fullName || translate('global.unassigned'),
                    )
                    ?.join(', ')
                : translate('global.unassigned')}
            </span>
          </p>
        </div>
      </div>
    ),
  },
  {
    headerName: translate('pods.config.common.active'),
    component: ({ active }: PodConfig) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </span>
    ),
  },
  {
    headerName: translate('pods.config.common.updated'),
    component: ({ updatedAt, updatedBy }: PodConfig) =>
      updatedAt ? (
        <UpdatedBy user={updatedBy?.fullName} date={updatedAt} />
      ) : (
        EMPTY
      ),
    width: 175,
  },
  {
    headerName: '',
    component: (config: PodConfig) => (
      <IconButton size="small" onClick={() => onEdit(config)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const PodConfigTable = (props: Props) => (
  <Table
    config={{
      columns: POD_CONFIG_COLUMNS(props),
      compact: true,
      data: props.configs,
      paginationOptions: [-1],
    }}
    empty={translate('pods.config.table.noResults')}
    emptyLink={translate('pods.config.table.add')}
    fontSize="small"
    onClickEmptyLink={props.onAdd}
  />
);

export default PodConfigTable;
