import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

import { MemberConsentType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import MemberConsentAnswer, {
  ConsentAnswer,
} from '@vestahealthcare/common/models/MemberConsentAnswer';
import { DATE_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapsableSidebar,
  Colors,
  DateTimePicker,
  RadioGroup,
  Tabs,
  TextArea,
} from 'styleguide-v2';
import { RadioItem } from 'styleguide-v2/src/components/RadioButton/RadioGroup';

import { MemberConsentAnswerParam } from 'dash/src/services/ConsentServices';

import { ClearConsentConfirmModal } from './ClearConsentConfirmModal';

const useStyles = makeStyles({
  contactsSelect: {
    '& .MuiChip-root': {
      background: `${Colors.lighterGray}`,
    },
    '& .MuiChip-root .MuiSvgIcon-root': {
      color: `${Colors.iconGray}`,
    },
  },
  lastUpdated: {
    marginTop: '1rem',
  },
});

export interface MemberConsentSubmitParam extends MemberConsentAnswerParam {
  id?: number;
}
interface Props {
  answers?: MemberConsentAnswer[];
  onClear: (consentId: number) => Promise<boolean>;
  onClose: () => void;
  onSubmit: (param: MemberConsentSubmitParam) => Promise<boolean>;
  open: boolean;
  request?: ConsentRequest;
}

export const ConsentEditModal = ({
  answers,
  onClear,
  onClose,
  onSubmit,
  open,
  request,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showClearConsentModal, setShowClearConsentModal] = useState(false);

  const [type, setType] = useState<MemberConsentType>(MemberConsentType.VERBAL);
  const [currentAnswer, setAnswer] = useState<MemberConsentAnswer>();

  const [selectedOption, setSelectedOption] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [dateSent, setDateSent] = useState<Moment>();
  const [dateReceived, setDateReceived] = useState<Moment>();

  const getIcon = (answer?: MemberConsentAnswer) => {
    if (answer?.isReceived) return <CheckCircleIcon color="primary" />;
    if (answer?.isDenied) return <CancelIcon color="error" />;
    if (answer?.dateSent) return <ArrowCircleRightIcon color="warning" />;
    return <CancelIcon color="disabled" />;
  };

  const getTabItems = (newAnswers?: MemberConsentAnswer[]) => {
    const writtenAnswer = newAnswers?.find(
      ({ type }) => type === MemberConsentType.WRITTEN,
    );
    const verbalAnswer = newAnswers?.find(
      ({ type }) => type === MemberConsentType.VERBAL,
    );

    return [
      {
        label: (
          <span className="flex">
            {MemberConsentType.VERBAL.toString()}
            &nbsp;
            {getIcon(verbalAnswer)}
          </span>
        ),
        value: MemberConsentType.VERBAL,
      },
      {
        label: (
          <span className="flex">
            {MemberConsentType.WRITTEN.toString()}
            &nbsp;
            {getIcon(writtenAnswer)}
          </span>
        ),
        value: MemberConsentType.WRITTEN,
      },
    ];
  };

  useEffect(() => {
    const answer =
      answers?.find((answer) => type === answer.type) ||
      ({} as MemberConsentAnswer);
    setAnswer(answer);
  }, [answers, type]);

  useEffect(() => {
    setSubmitted(false);
    setNotes(currentAnswer?.notes || '');
    setSelectedOption(currentAnswer?.answer || '');
    setDateSent(currentAnswer?.dateSent);
    setDateReceived(currentAnswer?.dateReceived);
  }, [currentAnswer]);

  const doClear = async () => {
    setClearLoading(true);
    if (currentAnswer?.id) {
      await onClear(currentAnswer.id);
    }
    setClearLoading(false);
    setShowClearConsentModal(false);
    return true;
  };

  const doSubmit = async () => {
    setSubmitted(true);
    if (selectedOption && !dateReceived) {
      return;
    }

    setLoading(true);

    try {
      await onSubmit({
        id: currentAnswer?.id,
        consentRequestId: request?.id || 0,
        dateSent: dateSent ? dateSent.unix() : undefined,
        dateReceived: dateReceived ? dateReceived.unix() : undefined,
        answer: selectedOption || undefined,
        type: type.value,
        notes,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      tabs={
        <Tabs
          fullWidth
          items={getTabItems(answers)}
          onChange={setType}
          value={type}
        />
      }
      title={
        <h2>
          {translate('memberConsents.modalTitle', { name: request?.title })}
        </h2>
      }
    >
      <CollapsableSidebar.Body>
        <ClearConsentConfirmModal
          loading={clearLoading}
          open={open && showClearConsentModal}
          onCancel={() => setShowClearConsentModal(false)}
          onSubmit={doClear}
        />

        <h2>{type.toString()}</h2>

        {type === MemberConsentType.WRITTEN && (
          <DateTimePicker
            data-cy="consent-edit-date-sent"
            label={translate('memberConsents.dateSent')}
            maxDate={Date.now()}
            onChange={(value) => {
              const date = moment(value);
              if (date.isValid()) {
                setDateSent(date);
              } else if (!value) {
                setDateSent(undefined);
              }
            }}
            type="date"
            value={dateSent?.toDate()}
          />
        )}

        <RadioGroup
          data-cy="consent-edit-answer-options"
          title={translate('global.pleaseSelectOption')}
          onChange={setSelectedOption}
          items={(request?.sortedAnswerOptions as RadioItem[]) || []}
          value={selectedOption}
        />

        {selectedOption && (
          <DateTimePicker
            data-cy="consent-edit-date-received"
            disabled={!selectedOption}
            error={submitted && !dateReceived}
            label={
              selectedOption === ConsentAnswer.NO ||
              selectedOption === ConsentAnswer.ONLY_MEDICAL_EMERGENCY
                ? translate('memberConsents.dateDenied')
                : translate('memberConsents.dateReceived')
            }
            maxDate={Date.now()}
            onChange={(value) => {
              const date = moment(value);
              if (date.isValid()) {
                setDateReceived(date);
              } else if (!value) {
                setDateReceived(undefined);
              }
            }}
            type="date"
            value={dateReceived?.toDate()}
            required
          />
        )}

        <TextArea
          data-cy="notes-input"
          label="Notes"
          onChange={setNotes}
          rows={3}
          value={notes}
        />
      </CollapsableSidebar.Body>

      {currentAnswer?.updatedBy?.fullName && (
        <p className={classNames('small grey', styles.lastUpdated)}>
          {translate('global.lastUpdatedBy', {
            date: currentAnswer?.updatedAt?.format(DATE_TIME_FORMAT),
            editor: currentAnswer?.updatedBy?.fullName,
          })}
        </p>
      )}

      <CollapsableSidebar.Buttons>
        <Button
          color="primary"
          data-cy="clear"
          disabled={!currentAnswer?.id}
          type="outlined"
          onClick={() => setShowClearConsentModal(true)}
        >
          {translate('global.remove')}
        </Button>
        <Button
          color="secondary"
          data-cy="submit-consent-answer"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default ConsentEditModal;
