import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { TaskDefinitionStatus } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { TaskCustomFieldType } from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { TaskCustomField } from '@vestahealthcare/common/models/TaskCustomField';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';
import { TaskDefinitionOutcome } from '@vestahealthcare/common/models/TaskDefinitionOutcome';

import {
  Button,
  CheckboxWithLabel,
  Colors,
  Modal,
  Panel,
  Select,
  TextInput,
} from 'styleguide-v2';

import { TaskDefinitionContext } from 'dash/src/contexts/TaskDefinitionContext';
import { CacheServices } from 'dash/src/services';

import { CustomFields } from './CustomFields';
import { Outcomes } from './Outcomes';

type Params = {
  templateId: string;
  subtaskId: string;
};

const useStyles = makeStyles({
  button: {
    minWidth: '15rem',
  },
  whiteBackground: {
    backgroundColor: Colors.white,
    transition: 'none!important',
  },
});

export const SubtaskDefinitionDetail = () => {
  const history = useHistory();
  const searchParams = useParams<Params>();
  const styles = useStyles();
  const { templateId, subtaskId } = searchParams;

  const parseId = (id: string) => parseFloat(id);

  const [loading, setLoading] = useState(false);
  const { definition, setDefinition } = useContext(TaskDefinitionContext);
  const [subtask, setSubtask] = useState({} as TaskDefinition);
  const [groups, setGroups] = useState<Selectable[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [outcomes, setOutcomes] = useState<TaskDefinitionOutcome[]>([]);
  const [customFields, setCustomFields] = useState<TaskCustomField[]>([]);
  const [showInactiveSubtaskModal, setShowInactiveSubtaskModal] = useState(
    false,
  );
  const { fetchDefinition } = useContext(TaskDefinitionContext);

  const fetchGroups = async () => {
    const groups = await CacheServices.getEmployeeGroupsAssignee();
    setGroups(
      groups.map(({ name, id }: EmployeeGroup) => ({ label: name, value: id })),
    );
  };

  const getTaskDefinition = async () => {
    setLoading(true);
    const id = templateId === 'new' ? -1 : parseId(templateId);
    await fetchDefinition(id);
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups();
    getTaskDefinition();
  }, []);

  useEffect(() => {
    const subtask = definition.subtasks?.find(
      ({ id }) => id === parseId(subtaskId),
    );
    if (subtask) {
      setSubtask({ ...subtask });
      setOutcomes(subtask.outcomes ? [...subtask.outcomes] : []);
      setCustomFields(subtask.customFields ? [...subtask.customFields] : []);
    }
  }, [definition]);

  const checkBeforeToggleActive = (checked: boolean) => {
    if (!checked) {
      if (subtask.taskPrecedentIds && subtask.taskPrecedentIds.length > 0) {
        setShowInactiveSubtaskModal(true);
        return false;
      }
    }
    onToggleActive(checked);
  };
  const onToggleActive = (checked: boolean) => {
    if (checked) {
      setSubtask({ ...subtask, status: TaskDefinitionStatus.PUBLISHED });
    } else {
      setSubtask({
        ...subtask,
        status: TaskDefinitionStatus.INACTIVE,
        taskPrecedentIds: [],
      });
    }
  };

  const validate = () => {
    if (!subtask.name || !outcomes?.length) return false;
    const invalidOutcome = outcomes?.find(({ name }) => !name);
    const invalidCustomField = (customFields || []).find(
      ({ label, type, options }) =>
        !label || (type === TaskCustomFieldType.CHECKBOX && !options?.length),
    );
    if (invalidCustomField || invalidOutcome) return false;
    return true;
  };

  const saveSubtask = () => {
    setSubmitted(true);
    if (validate()) {
      if (definition.subtasks?.length) {
        const index = definition.subtasks.findIndex(
          ({ id }) => id === parseId(subtaskId),
        );
        if (index !== -1) {
          definition.subtasks[index] = { ...subtask, outcomes, customFields };
        }
        setDefinition({ ...definition });
      }
      setSubmitted(false);
      history.goBack();
    }
  };

  return (
    <Panel>
      <Panel.Heading
        data-cy="task-definitions-page"
        title={subtask.name || translate('taskDefinitions.subtask.name')}
      />
      <Panel.Body
        loading={loading}
        className={classNames(styles.whiteBackground)}
      >
        <div className="grid-wrapper" style={{ height: 'fit-content' }}>
          <TextInput
            data-cy="subtask-definition-name-input"
            className="grid-span-6"
            error={
              submitted &&
              !subtask.name &&
              translate('global.missingRequiredField')
            }
            label={translate('taskDefinitions.name')}
            onChange={(name?: string) =>
              name && setSubtask({ ...subtask, name })
            }
            required
            value={subtask.name}
          />
          <Select
            data-cy="subtask-definition-group-input"
            className="grid-span-6"
            label={translate('taskDefinitions.group')}
            onChange={(value?: Selectable) =>
              value &&
              setSubtask({
                ...subtask,
                employeeGroupId: value?.value as number,
              })
            }
            items={groups}
            value={groups?.find(
              ({ value }) => value === subtask.employeeGroupId,
            )}
          />

          <CheckboxWithLabel
            checked={
              subtask.status?.value !== TaskDefinitionStatus.INACTIVE.value
            }
            data-cy="subtask-definition-name-input"
            label={translate('taskDefinitions.active')}
            onChange={(value) => checkBeforeToggleActive(!!value)}
          />

          <Outcomes
            onEdit={setOutcomes}
            outcomes={outcomes || []}
            submitted={submitted}
          />

          <CustomFields
            onEdit={setCustomFields}
            customFields={subtask.customFields || []}
            submitted={submitted}
          />
        </div>
      </Panel.Body>
      <Panel.Footer>
        <div className="flex spaced">
          <div />
          <div>
            <Button
              className={styles.button}
              color="quaternary"
              data-cy="subtask-cancel"
              onClick={() => history.goBack()}
            >
              {translate('global.cancel')}
            </Button>
            <Button
              className={styles.button}
              color="info"
              data-cy="subtask-save"
              onClick={() => saveSubtask()}
            >
              {translate('global.save')}
            </Button>
          </div>
        </div>
      </Panel.Footer>
      {showInactiveSubtaskModal && (
        <Modal
          onClose={() => setShowInactiveSubtaskModal(false)}
          open
          title={translate('global.warning')}
          onSubmit={async () => {
            setShowInactiveSubtaskModal(false);
            onToggleActive(false);
          }}
          submitText={translate('global.confirm')}
        >
          {translate('taskDefinitions.subtask.inactiveWithPrecedent')}
        </Modal>
      )}
    </Panel>
  );
};

export default SubtaskDefinitionDetail;
