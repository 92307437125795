import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';

import { TaskDefinitionStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';

import { Button } from 'styleguide-v2';

import SubTaskSortableList from './SubTaskSortableList';

type Props = {
  items: TaskDefinition[];
  onEdit: (subtask: TaskDefinition[]) => void;
  onEditItem?: (subtask: TaskDefinition) => void;
  submitted: boolean;
};

const useStyles = makeStyles({
  header: {
    alignItems: 'baseline',
    display: 'flex',
    flexFlow: 'column',
  },
});

export const SubTaskList = (props: Props) => {
  const styles = useStyles();
  const { items, onEdit, onEditItem, submitted } = props;
  const [list, setList] = useState<TaskDefinition[]>([]);

  useEffect(() => {
    setList(items || []);
  }, [items]);

  const addSubtask = () => {
    const id = items.length ? items[items.length - 1].id + 1 : 1;
    const newItem = new TaskDefinition({
      id,
      outcomes: [],
      customFields: [],
      new: true,
      taskPrecedentIds: [],
      status: TaskDefinitionStatus.PUBLISHED.value,
    });
    setList([...list, newItem]);
    onEdit([...list, newItem]);
  };

  return (
    <article className="grid-span-12">
      <header>
        <div className={`grid-span-11 ${styles.header}`}>
          <span className="h7">
            {translate('taskDefinitions.subtask.list')}
          </span>
          {submitted && list.find(({ name }) => !name) ? (
            <span className="error-red hint">
              {translate('taskDefinitions.subtask.noValidSubtask')}
            </span>
          ) : (
            <>
              <span className="hint">
                {translate('taskDefinitions.subtask.dragdropSubtask')}
              </span>
              <span className="hint">
                {translate('taskDefinitions.subtask.aboutPrecedents')}
              </span>
            </>
          )}
        </div>
        <div className="grid-span-1">
          <Button
            color="info"
            data-cy="add-subtask"
            onClick={addSubtask}
            type="outlined"
            size="xs"
          >
            +&nbsp;{translate('taskDefinitions.subtask.addSubtask')}
          </Button>
        </div>
      </header>

      <br />

      {list?.length ? (
        <SubTaskSortableList
          data-cy="subtask-list"
          getLabel={(definition: TaskDefinition) => {
            if (definition.name) {
              return `${definition?.name}${
                definition?.status?.value ===
                TaskDefinitionStatus.INACTIVE.value
                  ? ' (Inactive)'
                  : ''
              }`;
            }
            return translate('taskDefinitions.subtask.name');
          }}
          items={list}
          onChange={(items: TaskDefinition[]) => {
            setList([...items]);
            onEdit([...items]);
          }}
          onRemoveRule={(definition: TaskDefinition) => {
            const mappedList = list.map((current) =>
              current.id === definition.id ? definition : current,
            );
            setList(mappedList);
            onEdit(mappedList);
          }}
          onAddRule={(definition: TaskDefinition) => {
            definition.taskPrecedentIds?.push(-1);
            const mappedList = list.map((current) =>
              current.id === definition.id ? definition : current,
            );
            setList(mappedList);
            onEdit(mappedList);
          }}
          onChangeRule={(definition: TaskDefinition) => {
            const mappedList = list.map((current) =>
              current.id === definition.id ? definition : current,
            );
            setList(mappedList);
            onEdit(mappedList);
          }}
          onEditItem={
            onEditItem ? (item: TaskDefinition) => onEditItem(item) : undefined
          }
        />
      ) : undefined}
    </article>
  );
};

export default SubTaskList;
