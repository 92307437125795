import React, { useEffect, useState } from 'react';

import { EmployeeRole } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { FilterItem, FilterSelectable, Select, TextInput } from 'styleguide-v2';

import { EmployeeParams } from 'dash/src/services/EmployeeServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetEmployeeParams = keyof EmployeeParams;

type Props = {
  data: {
    employeeGroups: Selectable[];
    permissions: Selectable[];
  };
  filters?: {
    [x in KeyGetEmployeeParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetEmployeeParams]?: FilterItem;
    },
  ) => void;
};

export const EmployeeFiltersBar = ({
  data,
  filters,
  loading,
  onChange,
}: Props) => {
  const [name, setName] = useState<string>();
  const debouncedName = useDebounce(name, 300);

  const [id, setID] = useState<string>();
  const debouncedID = useDebounce(id, 300);

  useEffect(() => {
    setName((filters?.searchName?.value as FilterSelectable)?.value as string);
    setID((filters?.employeeId?.value as FilterSelectable)?.value as string);
  }, [filters?.employeeId, filters?.searchName]);

  useEffect(() => {
    if (
      debouncedName !==
      ((filters?.searchName?.value as FilterSelectable)?.value as string)
    )
      onChange({
        ...filters,
        searchName: getFilterItem(
          translate('employees.filters.name'),
          debouncedName,
        ),
      });
  }, [debouncedName]);

  useEffect(() => {
    if (
      debouncedID !==
      ((filters?.employeeId?.value as FilterSelectable)?.value as string)
    )
      onChange({
        ...filters,
        employeeId: getFilterItem(
          translate('employees.filters.id'),
          debouncedID,
        ),
      });
  }, [debouncedID]);

  return (
    <>
      <TextInput
        data-cy="employee-filter-bar-id"
        onChange={setID}
        placeholder={translate('employees.filters.id')}
        size="xs"
        value={id}
      />
      <TextInput
        data-cy="employee-filter-bar-name"
        onChange={setName}
        placeholder={translate('employees.filters.name')}
        size="xs"
        value={name}
      />
      <Select
        data-cy="employee-filter-bar-groups"
        items={data.employeeGroups}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            employeeGroupId: getFilterItem(
              translate('employees.filters.groups'),
              values,
            ),
          })
        }
        placeholder={translate('employees.filters.groups')}
        placeholderV2
        size="xs"
        value={filters?.employeeGroupId?.value || []}
      />
      <Select
        data-cy="employee-filter-bar-role"
        items={EmployeeRole.toSelectable()}
        limitTags={1}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            role: getFilterItem(translate('employees.filters.role'), values),
          })
        }
        placeholder={translate('employees.filters.role')}
        placeholderV2
        size="xs"
        value={filters?.role?.value || []}
      />
      <Select
        data-cy="employee-filter-bar-permissions"
        items={data.permissions}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            permission: getFilterItem(
              translate('employees.filters.permission'),
              values,
            ),
          })
        }
        placeholder={translate('employees.filters.permission')}
        placeholderV2
        size="xs"
        value={filters?.permission?.value || []}
      />
      <Select.Choice
        data-cy="employee-filter-bar-active"
        onChange={(value?: boolean) => {
          onChange({
            ...filters,
            enabled: getFilterItem(
              translate('employees.filters.active'),
              value,
            ),
          });
        }}
        placeholder={translate('employees.filters.active')}
        placeholderV2
        size="xs"
        value={
          (filters?.enabled?.value as FilterSelectable)?.value as
            | boolean
            | undefined
        }
      />
    </>
  );
};
