import React, { useEffect, useState } from 'react';

import {
  CollectionMethod,
  MemberRisk,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Organization,
} from '@vestahealthcare/common/models';

import { Panel, Select } from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import {
  FiltersVestaTeam,
  VestaTeamKey,
} from 'dash/src/components/filters/FiltersVestaTeam';
import { GetParams } from 'dash/src/services/EventServices';
import { getFilterCount, getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetEventParams = keyof GetParams;

type Props = {
  data: {
    assignee: SelectableInfo<Employee | EmployeeGroup>[];
    brands: Selectable[];
    statusId: Selectable[];
    employees: SelectableInfo<Employee>[];
    eventTypes: Selectable[];
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
    referralSources: Selectable[];
  };
  disabledFilters: KeyGetEventParams[];
  filters?: {
    [x in KeyGetEventParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetEventParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
};

const MEMBER_FILTERS: KeyGetEventParams[] = [
  'referralSourceId',
  'language',
  'brandId',
  'stateId',
];

const EVENT_FILTERS: KeyGetEventParams[] = [
  'typeId',
  'statusId',
  'assigneeId',
  'assigneeGroupId',
  'everEscalated',
  'pendingIncident',
];

const PROGRAM_FILTERS: KeyGetEventParams[] = [
  'programExtensionId',
  'programExtensionStatus',
  'riskLevel',
  'vitalCollectionMethod',
];

const VESTA_TEAM_FILTERS: KeyGetEventParams[] = [
  'podManagerId',
  'npOwnerId',
  'rnOwnerId',
  'careCoordinatorId',
];

export const EventFiltersModal = ({
  data,
  disabledFilters,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
}: Props) => {
  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetEventParams]?: FilterItem;
    }
  >(filters || {});

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      open={open}
      onClear={() => {
        onChange({});
      }}
      onClose={onClose}
      onSubmit={() => onChange(pendingFilters)}
    >
      <FilterGroup
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <Select
          data-cy="events-filter-referral-sources"
          getItemDisabled={(child) =>
            !!(pendingFilters?.referralSourceId?.value as SelectableInfo<
              Organization
            >[])?.reduce(
              (acc, item) => acc || !!item?.info?.hasDescendant(child.value),
              false,
            )
          }
          items={data.referralSources}
          label={translate('dashboardEvents.filters.referralSource')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              referralSourceId: getFilterItem(
                translate('dashboardEvents.filters.referralSource'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.referralSourceId?.value || []}
        />

        <Select
          data-cy="events-filter-language"
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('dashboardEvents.filters.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(
                translate('dashboardEvents.filters.language'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
        <Select
          data-cy="events-filter-brand"
          items={data.brands}
          label={translate('dashboardEvents.filters.brand')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              brandId: getFilterItem(
                translate('dashboardEvents.filters.brand'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.brandId?.value || []}
        />

        <Select
          data-cy="events-filter-state"
          items={Enum.toSelectable(States.asArray)}
          label={translate('dashboardEvents.filters.state')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              stateId: getFilterItem(
                translate('dashboardEvents.filters.state'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.stateId?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Event Details"
        count={getFilterCount(pendingFilters, EVENT_FILTERS)}
      >
        <Select
          data-cy="events-filter-event-type"
          items={data.eventTypes}
          label={translate('dashboardEvents.filters.type')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              typeId: getFilterItem(
                translate('dashboardEvents.filters.type'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.typeId?.value || []}
        />

        <Select
          data-cy="events-filter-event-status"
          disabled={disabledFilters.includes('statusId')}
          items={data.statusId}
          label={translate('dashboardEvents.filters.status')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              statusId: getFilterItem(
                translate('dashboardEvents.filters.status'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.statusId?.value || []}
        />

        <Select
          data-cy="events-filter-assignee"
          disabled={disabledFilters.includes('assigneeId')}
          getItemDisabled={(item?: SelectableInfo<Employee>) => {
            return !!(
              item &&
              item.info?.id !== 0 &&
              pendingFilters.assigneeId?.value &&
              ((pendingFilters.assigneeId.value as FilterSelectable[])[0]
                .value as number) === 0
            );
          }}
          grouped={(item: SelectableInfo<Employee>) => {
            if (item.info instanceof EmployeeGroup) {
              return translate('global.groups');
            }
            if (item.info instanceof Employee && item.value) {
              return translate('global.employees');
            }
            return '';
          }}
          items={data.assignee}
          isOptionEqualToValue={(
            { info: a }: SelectableInfo<Employee | EmployeeGroup>,
            { info: b }: SelectableInfo<Employee | EmployeeGroup>,
          ) => {
            if (a instanceof Employee && b instanceof Employee)
              return a.id === b.id;
            if (a instanceof EmployeeGroup && b instanceof EmployeeGroup)
              return a.id === b.id;
            return false;
          }}
          label={translate('dashboardEvents.filters.assignee')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              assigneeId: getFilterItem(
                translate('dashboardEvents.filters.assignee'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.assigneeId?.value || []}
        />

        <Select.Choice
          data-cy="events-filter-ever-escalated"
          disabled={disabledFilters.includes('everEscalated')}
          label={translate('dashboardEvents.filters.everEscalated')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              everEscalated: getFilterItem(
                translate('dashboardEvents.filters.everEscalated'),
                value,
              ),
            });
          }}
          size="small"
          value={
            disabledFilters.includes('everEscalated')
              ? true
              : ((pendingFilters.everEscalated?.value as FilterSelectable)
                  ?.value as boolean | undefined)
          }
        />
        <Select.Choice
          data-cy="events-filter-pending-incident"
          label={translate('dashboardEvents.filters.pendingIncident')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              pendingIncident: getFilterItem(
                translate('dashboardEvents.filters.pendingIncident'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.pendingIncident?.value as FilterSelectable)
              ?.value as boolean | undefined
          }
        />
      </FilterGroup>
      <FilterGroup
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <Select
          data-cy="events-filter-pe"
          items={data.programExtensions}
          label={translate('dashboardEvents.filters.programExtension')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionId: getFilterItem(
                translate('dashboardEvents.filters.programExtension'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionId?.value || []}
        />

        <Select
          data-cy="events-filter-pe"
          items={data.programExtensionStatuses}
          label={translate('dashboardEvents.filters.programExtensionStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionStatus: getFilterItem(
                translate('dashboardEvents.filters.programExtensionStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionStatus?.value || []}
        />
        <Select
          data-cy="events-filter-risk-level"
          items={Enum.toSelectable(MemberRisk.asArray)}
          label={translate('dashboardEvents.filters.riskLevel')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              riskLevel: getFilterItem(
                translate('dashboardEvents.filters.riskLevel'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.riskLevel?.value || []}
        />
        <Select
          data-cy="events-filter-vitals-level"
          items={CollectionMethod.toVendorSelectable().sort(Enum.sort)}
          label={translate('dashboardEvents.filters.vitalsCollectionMethod')}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              vitalCollectionMethod: getFilterItem(
                translate('dashboardEvents.filters.vitalsCollectionMethod'),
                values,
              ),
            });
          }}
          value={pendingFilters.vitalCollectionMethod?.value || []}
          size="small"
        />
      </FilterGroup>
      <FiltersVestaTeam
        data={{ employees: data.employees }}
        filters={pendingFilters}
        keys={VESTA_TEAM_FILTERS as VestaTeamKey[]}
        loading={loadingFilters}
        onChange={(updatedFilters) =>
          setPendingFilters({ ...pendingFilters, ...updatedFilters })
        }
      />
    </Panel.FilterModal>
  );
};
