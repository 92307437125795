import moment from 'moment';
import React from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskCustomFieldType from '@vestahealthcare/common/enums/TaskCustomFieldType';
import { translate } from '@vestahealthcare/common/i18n';
import TaskCustomField from '@vestahealthcare/common/models/TaskCustomField';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import { DateTimePicker, Select } from 'styleguide-v2';
import { GridColumns } from 'styleguide/src/utils/types';

type Props = {
  columns?: GridColumns;
  customField: TaskCustomField;
  disabled?: boolean;
  onUpdate: (answer: string | null) => void;
  showError?: boolean;
  required?: boolean;
  value?: string;
};

export const EditTaskCustomField = ({
  columns = 3,
  customField,
  disabled,
  onUpdate,
  showError,
  required,
  value,
}: Props) => {
  const customFieldToRender = () => {
    switch (customField.type) {
      case TaskCustomFieldType.DATE:
        return (
          <DateTimePicker
            className={columns ? `grid-span-${columns}` : undefined}
            disabled={disabled}
            error={showError && translate('global.missingRequiredField')}
            label={customField.label}
            onChange={(date) =>
              onUpdate(date ? moment(date.getTime()).format(DATE_FORMAT) : null)
            }
            required={required}
            value={moment(value).toDate()}
          />
        );

      case TaskCustomFieldType.DATETIME:
        return (
          <>
            <DateTimePicker
              className={columns ? `grid-span-${columns}` : undefined}
              disabled={disabled}
              error={showError && translate('global.missingRequiredField')}
              label={`${customField.label}`}
              onChange={(date) =>
                onUpdate(date ? moment(date.getTime()).format() : null)
              }
              required={required}
              value={moment(value).toDate()}
            />

            <DateTimePicker
              className={columns ? `grid-span-${columns}` : undefined}
              disabled={disabled}
              error={showError && translate('global.missingRequiredField')}
              label={`${customField.label} Time`}
              onChange={(date) =>
                onUpdate(date ? moment(date.getTime()).format() : null)
              }
              required={required}
              type="time"
              value={moment(value).toDate()}
            />
          </>
        );

      case TaskCustomFieldType.CHECKBOX:
        if (!customField.options) {
          return <div />;
        }

        // eslint-disable-next-line no-case-declarations
        const values = value?.split(',')?.map((i) => Number(i));
        // eslint-disable-next-line no-case-declarations
        const items = customField.options.map((option) => ({
          label: option.name,
          value: option.id,
        }));

        return (
          <Select
            className={columns ? `grid-span-${columns}` : undefined}
            disabled={disabled}
            error={showError && translate('global.missingRequiredField')}
            label={customField.label}
            multiple
            onChange={(values: Selectable[]) =>
              onUpdate(
                values.length > 0
                  ? values.map(({ value }) => value).join(',')
                  : null,
              )
            }
            items={items}
            required={required}
            value={items.filter(({ value }) => values?.includes(value))}
          />
        );
      default:
        return <div />;
    }
  };

  return customFieldToRender();
};

export default EditTaskCustomField;
