import React, { useEffect, useState } from 'react';

import {
  CollectionMethod,
  MemberContactMethodType,
  MemberStatus,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  Organization,
  StoredFilter,
} from '@vestahealthcare/common/models';

import { Panel, Select, TextInput } from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import {
  FiltersVestaTeam,
  VestaTeamKey,
} from 'dash/src/components/filters/FiltersVestaTeam';
import { MemberViewParams } from 'dash/src/services/PatientServices';
import {
  getFilterCount,
  getFilterItem,
  getFilterValue,
} from 'dash/src/utils/filterUtils';

import { ReferralDropdown } from '../ReferralDropdown';

type KeyGetMemberParams = keyof MemberViewParams;

type Props = {
  data: {
    brands: Selectable[];
    employees: SelectableInfo<Employee>[];
    hciReporterStatuses: Selectable[];
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
    referralSources: SelectableInfo<Organization>[];
    statuses: Selectable[];
    worklistGroups: Selectable[];
  };
  filters?: {
    [x in KeyGetMemberParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetMemberParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
  onDeleteFilter?: (filter: StoredFilter) => Promise<void>;
  onEditFilter?: (filter: StoredFilter) => Promise<void>;
  onSaveFilter?: (filter: StoredFilter) => Promise<void>;
  storedFilters?: StoredFilter[];
};

const MEMBER_FILTERS: KeyGetMemberParams[] = [
  'brandId',
  'fullName',
  'id',
  'language',
  'referralSourceId',
  'excludedReferralSourceId',
  'state',
  'status',
];

const PROGRAM_FILTERS: KeyGetMemberParams[] = [
  'programExtensionId',
  'programExtensionStatus',
  'carePlanGroupId',
  'riskLevel',
];

const VESTA_TEAM_FILTERS: VestaTeamKey[] = [
  'podManagerId',
  'npOwnerId',
  'rnOwnerId',
  'healthCoachId',
  'careCoordinatorId',
  'engagementOwnerId',
];

const OPS_FILTERS: KeyGetMemberParams[] = [
  'vitalCollectionMethod',
  'hasCareTeam',
  'hciReporter',
  'hciReporterMethod',
  'hciReporterStatus',
];

type FilterType = {
  [x in KeyGetMemberParams]?: FilterItem;
};
export const MemberViewFiltersModal = ({
  data,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
  onDeleteFilter,
  onEditFilter,
  onSaveFilter,
  storedFilters,
}: Props) => {
  const [pendingFilters, setPendingFilters] = useState<FilterType>(
    filters || {},
  );

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      open={open}
      onClear={() => {
        onChange({});
      }}
      onClose={onClose}
      onDeleteFilter={onDeleteFilter}
      onEditFilter={onEditFilter}
      onSaveFilter={onSaveFilter}
      onSubmit={() => onChange(pendingFilters)}
      size={400}
      storedFilters={storedFilters}
    >
      <FilterGroup
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <Select
          allowAddNewValue
          data-cy="toc-filter-modal-id"
          items={[]}
          inputType="number"
          multiple
          noOptionsText={translate('members.typeToSearch')}
          onChange={(values: Selectable[]) =>
            setPendingFilters({
              ...pendingFilters,
              id: getFilterItem(
                translate('members.id'),
                values.map(({ label }) => ({
                  value: label,
                  label,
                })),
              ),
            })
          }
          label={translate('members.id')}
          size="small"
          value={pendingFilters.id?.value}
        />
        <TextInput
          data-cy="toc-filters-modal-name"
          label={translate('members.name')}
          onChange={(name?: string) =>
            setPendingFilters({
              ...pendingFilters,
              fullName: getFilterItem(translate('members.name'), name),
            })
          }
          size="small"
          value={getFilterValue(pendingFilters.fullName?.value) as string}
        />
        <Select
          data-cy="member-filter-modal-state"
          items={Enum.toSelectable(States.asArray)}
          label={translate('members.state')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              state: getFilterItem(translate('members.state'), values),
            });
          }}
          size="small"
          value={pendingFilters.state?.value || []}
        />
        <Select
          data-cy="member-filter-modal-language"
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('members.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(translate('members.language'), values),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
        <Select
          data-cy="member-filter-modal-brand"
          items={Enum.toSelectable(MemberStatus.asArray)}
          label={translate('members.status')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              status: getFilterItem(translate('members.status'), values),
            });
          }}
          size="small"
          value={pendingFilters.status?.value || []}
        />
        <Select
          data-cy="member-filter-modal-status"
          items={data.brands}
          label={translate('members.brand')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              brandId: getFilterItem(translate('members.brand'), values),
            });
          }}
          size="small"
          value={pendingFilters.brandId?.value || []}
        />
        <ReferralDropdown
          data-cy="member-filter-modal-referral"
          getItemDisabled={(referral: Selectable) =>
            !!(pendingFilters?.excludedReferralSourceId
              ?.value as FilterSelectable[])?.find(
              ({ value }) => value === referral.value,
            ) ||
            (pendingFilters?.excludedReferralSourceId
              ?.value as FilterSelectable[])?.reduce(
              (acc, item) =>
                acc || item.info.hasDescendant(referral.value as number),
              false,
            )
          }
          items={data.referralSources}
          label={translate('members.referralSources')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              referralSourceId: getFilterItem(
                translate('members.referralSources'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.referralSourceId?.value || []}
        />
        <ReferralDropdown
          data-cy="member-filter-modal-excluded-referral"
          getItemDisabled={(referral: Selectable) =>
            !!(pendingFilters?.referralSourceId
              ?.value as FilterSelectable[])?.find(
              ({ value }) => value === referral.value,
            ) ||
            (pendingFilters?.referralSourceId
              ?.value as FilterSelectable[])?.reduce(
              (acc, item) =>
                acc || item.info.hasDescendant(referral.value as number),
              false,
            )
          }
          items={data.referralSources.filter(({ value }) => !!value)}
          label={translate('members.excludedReferralSources')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              excludedReferralSourceId: getFilterItem(
                translate('members.excludedReferralSources'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.excludedReferralSourceId?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <Select
          data-cy="member-filter-modal-pe"
          items={data.programExtensions}
          label={translate('members.programExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionId: getFilterItem(
                translate('members.programExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionId?.value || []}
        />
        <Select
          data-cy="member-filter-modal-pe-status"
          items={data.programExtensionStatuses}
          label={translate('members.programExtensionStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              programExtensionStatus: getFilterItem(
                translate('members.programExtensionStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.programExtensionStatus?.value || []}
        />

        <Select
          data-cy="member-filter-modal-care-plan-group"
          items={data.worklistGroups}
          label={translate('members.carePlanGroup')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              carePlanGroupId: getFilterItem(
                translate('members.carePlanGroup'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.carePlanGroupId?.value || []}
        />
        <Select
          data-cy="member-filter-modal-risk-level"
          items={Enum.toSelectable(RiskLevel.asArray)}
          label={translate('members.riskLevel')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              riskLevel: getFilterItem(translate('members.riskLevel'), values),
            });
          }}
          size="small"
          value={pendingFilters.riskLevel?.value || []}
        />
      </FilterGroup>
      <FiltersVestaTeam
        data={{ employees: data.employees }}
        filters={pendingFilters}
        keys={VESTA_TEAM_FILTERS}
        loading={loadingFilters}
        onChange={(updatedFilters) =>
          setPendingFilters({ ...pendingFilters, ...updatedFilters })
        }
      />
      <FilterGroup
        title="Operational Details"
        count={getFilterCount(pendingFilters, OPS_FILTERS)}
      >
        <Select
          data-cy="member-filter-modal-viutals-collection-method"
          items={Enum.toSelectable(CollectionMethod.asArray)}
          label={translate('members.vitalsCollectionMethod')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              vitalCollectionMethod: getFilterItem(
                translate('members.vitalsCollectionMethod'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.vitalCollectionMethod?.value || []}
        />
        <Select.Choice
          data-cy="member-filter-modal-hasCareTeam"
          disableClearable
          label={translate('members.hasCareTeam')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              hasCareTeam: getFilterItem(
                translate('members.hasCareTeam'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.hasCareTeam?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select.Choice
          data-cy="member-filter-modal-hciReporter"
          disableClearable
          label={translate('members.selfReporter')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              hciReporter: getFilterItem(
                translate('members.selfReporter'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.hciReporter?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select
          data-cy="member-filter-modal-reporter-status"
          items={data.hciReporterStatuses}
          label={translate('members.reporterStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              hciReporterStatus: getFilterItem(
                translate('members.reporterStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.hciReporterStatus?.value || []}
        />
        <Select
          data-cy="member-filter-modal-reporter-method"
          items={Enum.toSelectable(MemberContactMethodType.asArray)}
          label={translate('members.reportingPreference')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              hciReporterMethod: getFilterItem(
                translate('members.reportingPreference'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.hciReporterMethod?.value || []}
        />
      </FilterGroup>
    </Panel.FilterModal>
  );
};
