import {
  BaseEnum,
  PodConfig,
  PodRule,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import PodRuleTrigger from '@vestahealthcare/common/models/PodRuleInternalTrigger';

import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

export type GetPodConfigParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodConfigs = async (
  params?: GetPodConfigParams,
): Promise<PaginatedResponse<PodConfig>> => {
  const {
    pods: { items, pagination },
  } = await Api.getv2('pods', params);
  return {
    items: items.map((item: any) => new PodConfig(item)),
    pagination,
  };
};

export type PodConfigEmployeeSlot = {
  employeeId?: number;
  id?: number;
  role: string;
};

export type CreatePodConfigParams = {
  employees: PodConfigEmployeeSlot[];
  languages: string[];
  managerId: number;
  maxMembers?: number;
  name: string;
  programExtensionIds: number[];
  referralSourceIds?: number[];
  states: string[];
};

export const createPodConfig = async (params?: CreatePodConfigParams) =>
  await Api.postv2JSON('pods', params);

export type UpdatePodConfigParams = Partial<
  CreatePodConfigParams & {
    active: boolean;
  }
>;

export const updatePodConfig = async (
  id: number,
  { employees, ...params }: UpdatePodConfigParams,
) =>
  await Api.patchv2JSON(`pods/${id}`, {
    employees: employees?.map(({ id, employeeId, ...item }) => ({
      id: id || undefined,
      employeeId: employeeId || undefined,
      ...item,
    })),
    ...params,
  });

export type GetPodRuleParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodRules = async (
  params?: GetPodRuleParams,
): Promise<PaginatedResponse<PodRule>> => {
  const {
    podRules: { items, pagination },
  } = await Api.getv2('pods/rules', params);
  return {
    items: items.map((item: any) => new PodRule(item)),
    pagination,
  };
};

export const fetchPodPrograms = async (): Promise<ProgramExtension[]> => {
  const {
    podPrograms: { items },
  } = await Api.getv2('pods/programs');
  return items.map((item: any) => new ProgramExtension(item));
};

export const fetchPodFrequencies = async (): Promise<BaseEnum[]> => {
  const {
    podFrequencies: { items },
  } = await Api.getv2('pods/frequencies');
  return items.map((item: any) => new BaseEnum(item));
};

export const fetchPodExternalTriggers = async (): Promise<BaseEnum[]> => {
  const {
    podExternalTriggers: { items },
  } = await Api.getv2('pods/external-triggers');
  return items.map((item: any) => new BaseEnum(item));
};

export const fetchPodTaskTriggers = async (): Promise<PodRuleTrigger[]> => {
  const {
    podTaskTriggers: { items },
  } = await Api.getv2('pods/task-triggers');
  return items.map((item: any) => new PodRuleTrigger(item));
};

export const fetchPodTriggerStatuses = async (): Promise<BaseEnum[]> => {
  const {
    podTriggerStatuses: { items },
  } = await Api.getv2('pods/trigger-statuses');
  return items.map((item: any) => new BaseEnum(item));
};

export type CreatePodRuleParams = {
  closeAfterAttemps: boolean;
  closeAfterDue: boolean;
  createIfPeriodEmpty: boolean;
  delayAfterTrigger?: number;
  externalTriggers?: string[];
  flexible: boolean;
  frequency: string;
  maxAttempts: number;
  name: string;
  programExtensionIds: number[];
  roles: string[];
  selfTrigger: boolean;
  taskSpacing?: number;
  taskTriggers?: {
    trigger: string;
    status: string;
  }[];
};

export const createPodRule = async (params?: CreatePodRuleParams) =>
  await Api.postv2JSON('pods/rules', params);

export type UpdatePodRuleParams = Partial<
  CreatePodRuleParams & {
    active: boolean;
  }
>;

export const updatePodRule = async (
  id: number,
  { ...params }: UpdatePodRuleParams,
) =>
  await Api.patchv2JSON(`pods/rules/${id}`, {
    ...params,
  });
