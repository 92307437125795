export const PreUploadMemberColumns = [
  {
    headerName: 'First Name',
    field: 'First Name',
    column: 0,
  },
  {
    headerName: 'Last Name',
    field: 'Last Name',
    column: 1,
  },
  {
    headerName: 'Referral Source',
    field: 'Referral Source',
    width: 200,
    column: 6,
  },
  {
    headerName: 'Phone Number',
    field: 'Phone Number',
    column: 2,
  },
  {
    headerName: 'Phone Type',
    field: 'Phone Type',
    column: 3,
  },
  {
    headerName: 'Birth Date',
    field: 'Birth Date',
    column: 4,
  },
  {
    headerName: 'Language',
    field: 'Language',
    column: 5,
  },
  {
    headerName: 'Gender',
    field: 'Gender',
    column: 7,
  },
  {
    headerName: 'Address',
    field: 'Address',
    column: 8,
  },
  {
    headerName: 'Address 2',
    field: 'Address 2',
    column: 9,
  },
  {
    headerName: 'City',
    field: 'City',
    column: 10,
  },
  {
    headerName: 'State',
    field: 'State',
    column: 11,
  },
  {
    headerName: 'Zip Code',
    field: 'Zip Code',
    column: 12,
  },
  {
    headerName: 'Phone 2',
    field: 'Phone Number 2',
    column: 13,
  },
  {
    headerName: 'Phone Type 2',
    field: 'Phone Type 2',
    column: 14,
  },
  {
    headerName: 'Phone 3',
    field: 'Phone Number 3',
    column: 15,
  },
  {
    headerName: 'Phone Type 3',
    field: 'Phone Type 3',
    column: 16,
  },
  {
    headerName: 'Phone 4',
    field: 'Phone Number 4',
    column: 17,
  },
  {
    headerName: 'Phone Type 4',
    field: 'Phone Type 4',
    column: 18,
  },
  {
    headerName: 'Contact First Name',
    field: 'Contact First Name',
    column: 19,
  },
  {
    headerName: 'Contact Last Name',
    field: 'Contact Last Name',
    column: 20,
  },
  {
    headerName: 'Contact Phone Number',
    field: 'Contact Phone Number',
    column: 21,
  },
  {
    headerName: 'Primary Insurance Plan',
    field: 'Primary Insurance Plan',
    column: 22,
  },
  {
    headerName: 'Primary Subscriber Id',
    field: 'Primary Subscriber Id',
    column: 23,
  },
  {
    headerName: 'Secondary Insurance Plan',
    field: 'Secondary Insurance Plan',
    column: 24,
  },
  {
    headerName: 'Secondary Subscriber Id',
    field: 'Secondary Subscriber Id',
    column: 25,
  },
  {
    headerName: 'MLTC Code',
    field: 'MLTC Code',
    column: 26,
  },
  {
    headerName: 'MLTC Id',
    field: 'MLTC Id',
    column: 27,
  },
  {
    headerName: 'External Id',
    field: 'External Id',
    column: 28,
  },
  {
    headerName: 'Enrollment End',
    field: 'Enrollment End',
    column: 29,
  },
  {
    headerName: 'Asap',
    field: 'Asap',
    column: 30,
  },
  {
    headerName: 'Notes',
    field: 'Notes',
    column: 31,
  },
  {
    headerName: 'CR Id',
    field: 'CR Id',
    column: 32,
  },
  {
    headerName: 'MLTC/LTSS Plan Id',
    field: 'MLTC/LTSS Plan Id',
    column: 33,
  },
  {
    headerName: 'MLTC/LTSS Subscriber Id',
    field: 'MLTC/LTSS Subscriber Id',
    column: 34,
  },
];

export const PreUploadCareTeamColumns = [
  {
    headerName: 'Member ID',
    field: 'Member ID',
  },
  {
    headerName: 'Referral',
    field: 'Referral',
  },
  {
    headerName: 'External ID',
    field: 'External ID',
  },
  {
    headerName: 'Member First Name',
    field: 'Member First Name',
  },
  {
    headerName: 'Member Last Name',
    field: 'Member Last Name',
  },
  {
    headerName: 'CT Person First Name',
    field: 'CT Person First Name',
  },
  {
    headerName: 'CT Person Last Name',
    field: 'CT Person Last Name',
  },
  {
    headerName: 'CT Person Phone Number',
    field: 'CT Person Phone Number',
  },
  {
    headerName: 'CT Person Phone Type',
    field: 'CT Person Phone Type',
  },
  {
    headerName: 'Preferred',
    field: 'Preferred',
  },
  {
    headerName: 'Relationship',
    field: 'Relationship',
  },
];
