import classNames from 'classnames';
import { Moment } from 'moment';
import React, { Fragment, useMemo } from 'react';

import { makeStyles } from '@mui/styles';

import { AppointmentStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  SkilledItem,
  TOCAcuityLevel,
  TOCItem,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Chip,
  Colors,
  Icon,
  StatusIndicator,
  StatusVisit,
  Table,
  Tooltip,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import {
  TOC_TOGGLE_ITEM_SKILLED,
  TOC_TOGGLE_ITEM_TOC,
} from 'dash/src/pages/Clinical/TOC/types';

interface Props {
  items: TOCItem[] | SkilledItem[];
  onClickRow: (row: TOCItem | SkilledItem) => void;
  toggle: string;
}

const getDateWithDiffDays = (date?: Moment, days?: number) => (
  <>
    {date?.format(DATE_FORMAT_SHORT) || EMPTY}{' '}
    {date && days !== undefined && (
      <Chip color="action" size="small">
        <span>{days}</span>
      </Chip>
    )}
  </>
);

const TOC_LIST_COLUMNS = ({ toggle }: Props) => {
  let columns = [] as Column[];

  if (toggle === TOC_TOGGLE_ITEM_SKILLED) {
    columns = [
      {
        headerName: translate('toc.table.episodeStartDate'),
        component: ({ startDate, episodeDuration }: SkilledItem) => (
          <p className="no-margin">
            {getDateWithDiffDays(startDate, episodeDuration)}
          </p>
        ),
        width: 200,
      },
      {
        headerName: translate('toc.table.episodeEndDate'),
        component: ({ endDate, businessDaysSinceEndDate }: SkilledItem) => (
          <p className="no-margin">
            {getDateWithDiffDays(endDate, businessDaysSinceEndDate)}
          </p>
        ),

        width: 200,
      },
      {
        headerName: translate('toc.table.lastSkilledEncounter'),
        component: ({ id, lastTcmEncounter }: TOCItem) =>
          lastTcmEncounter?.details ? (
            <StatusIndicator
              color={AppointmentStatus.getColor(lastTcmEncounter?.details)}
              key={`toc-item-${id}-last-tcm-encounter`}
              status={lastTcmEncounter?.details.toString()}
              subtitle={
                lastTcmEncounter?.dateTime?.format(
                  DATE_FORMAT_SHORT_WITH_TIME,
                ) || lastTcmEncounter?.date.format(DATE_FORMAT_SHORT)
              }
            />
          ) : (
            EMPTY
          ),
      },
      {
        headerName: translate('toc.table.npVisit'),
        component: ({ id, npVisit }: TOCItem) => (
          <StatusVisit
            color={AppointmentStatus.getColor(npVisit?.details)}
            key={`toc-item-${id}-np-visit`}
            date={npVisit?.dateTime || npVisit?.date}
          />
        ),
      },
      {
        headerName: translate('toc.table.rnCarePlan'),
        component: ({ id, rnCarePlan }: TOCItem) => (
          <StatusVisit
            color={AppointmentStatus.getColor(rnCarePlan?.details)}
            key={`toc-item-${id}-rn-care-plan`}
            date={rnCarePlan?.dateTime || rnCarePlan?.date}
          />
        ),
      },
    ];
  } else {
    columns = [
      {
        headerName: translate('toc.table.status'),
        component: ({ id, status, statusUpdatedAt }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin semi-bold">{status?.description}</p>
            <p className="no-margin">
              {statusUpdatedAt?.format(DATE_FORMAT_SHORT) || EMPTY}
            </p>
          </Fragment>
        ),
        width: 150,
      },
      {
        headerName: translate('toc.table.admitDate'),
        component: ({ id, admitDate, daysInpatient }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin">
              {getDateWithDiffDays(admitDate, daysInpatient)}
            </p>
          </Fragment>
        ),
        width: 150,
      },
      {
        headerName: translate('toc.table.dischargeDate'),
        component: ({
          id,
          dischargeDate,
          businessDaysSinceDischargeDate,
        }: TOCItem) => (
          <Fragment key={`toc-item-${id}-status`}>
            <p className="no-margin">
              {getDateWithDiffDays(
                dischargeDate,
                businessDaysSinceDischargeDate,
              )}
            </p>
          </Fragment>
        ),
        width: 150,
      },
      {
        headerName: translate('toc.table.diagnosis'),
        component: ({ chiefComplaint }: TOCItem) =>
          chiefComplaint ? (
            <Tooltip text={chiefComplaint}>
              <div
                className="ellipsis-2-lines"
                style={{
                  maxWidth: 'calc(100vw - 100rem)',
                  width: 'fit-content',
                }}
              >
                {chiefComplaint}
              </div>
            </Tooltip>
          ) : (
            EMPTY
          ),
      },
      {
        headerName: translate('toc.table.facility'),
        field: 'facility',
        width: 200,
      },
    ];
  }

  return [
    ...columns,
    ...[
      {
        headerName: ' ',
        component: () => (
          <Icon
            className="fa fa-chevron-right"
            size="xs"
            color="green"
            style={{ padding: '1.4rem 0' }}
          />
        ),
        width: 25,
      },
    ],
  ];
};

const useStyles = makeStyles({
  noBorder: {
    '&&': {
      borderBottom: '0!important',
    },
  },
  critical: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px 0px ${Colors.purple}`,
      paddingLeft: '1rem',
    },
  },
  severe: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px 0px ${Colors.error}`,
      paddingLeft: '1rem',
    },
  },
  moderate: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px 0px ${Colors.gold}`,
      paddingLeft: '1rem',
    },
  },
  low: {
    '&&:first-child': {
      boxShadow: `inset 4px 0px 0px 0px ${Colors.iconGreen}`,
      paddingLeft: '1rem',
    },
  },
  default: {
    '&&:first-child': {
      boxShadow: `inset 3px 0px 0px 0px ${Colors.darkGray}`,
      paddingLeft: '1rem',
    },
  },
});

export const TOCTable = (props: Props) => {
  const { items, onClickRow } = props;
  const styles = useStyles();

  const columns = useMemo(() => TOC_LIST_COLUMNS(props), [props.toggle]);

  return (
    <Table
      config={{
        columns,
        data: items,
        getRowClass: ({ acuityLevel }: TOCItem, idx: number) => {
          if (props.toggle !== TOC_TOGGLE_ITEM_TOC) return '';

          const className = idx < items.length - 1 ? '' : styles.noBorder;
          if (acuityLevel?.id === TOCAcuityLevel.CRITICAL)
            return classNames(className, styles.critical);
          if (acuityLevel?.id === TOCAcuityLevel.SEVERE)
            return classNames(className, styles.severe);
          if (acuityLevel?.id === TOCAcuityLevel.MODERATE)
            return classNames(className, styles.moderate);
          if (acuityLevel?.id === TOCAcuityLevel.LOW)
            return classNames(className, styles.low);

          return classNames(className, styles.default);
        },
        paginationOptions: [-1],
      }}
      fontSize="small"
      onClickRow={onClickRow}
    />
  );
};

export default TOCTable;
