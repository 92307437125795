import { BaseEnum } from './BaseEnum';

export class IncidentStatus extends BaseEnum {
  type = '';

  constructor(obj: any) {
    super(obj);
    this.type = obj.type;
  }

  static NEW = 'NEW';

  static IN_PROGRESS = 'IN_PROGRESS';

  static COMPLETED = 'COMPLETED';

  static NOT_REQUIRED = 'NOT_REQUIRED';

  static INVALID = 'INVALID';

  get isClosed() {
    return this.type !== 'OPEN';
  }

  static getColor(status?: IncidentStatus) {
    switch (status?.id) {
      case IncidentStatus.NEW:
        return 'info';
      case IncidentStatus.COMPLETED:
        return 'success';
      case IncidentStatus.INVALID:
      case IncidentStatus.NOT_REQUIRED:
        return 'closed';
      case IncidentStatus.IN_PROGRESS:
      default:
        return 'warning';
    }
  }
}

export default IncidentStatus;
