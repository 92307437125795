import { useFlags } from 'launchdarkly-react-client-sdk';
// @ts-ignore
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Language,
  MemberContactMethodType,
  MemberRisk,
  MemberStatus,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Employee,
  MemberView,
  Organization,
  PaginationType,
  ProgramExtensionStatus,
  StoredFilter,
} from '@vestahealthcare/common/models';
import {
  LS_MEMBER_FILTERS,
  LS_MEMBER_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  CheckboxWithLabel,
  FilterButton,
  FilterItem,
  IconButton,
  Panel,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { MemberViewFilters } from 'dash/src/components/MemberViewFilters';
import { MemberViewFiltersModal } from 'dash/src/components/MemberViewFilters/MemberViewFiltersModal';
import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import {
  MemberViewParams,
  downloadMemberExport,
  getMembersView,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';
import {
  LoadFilterOption,
  getBoolean,
  getServerFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { MemberViewFiltersBar } from '../../../components/MemberViewFilters/MemberViewFiltersBar';
import { MembersDataTable } from './MembersDataTable';

const useStyles = makeStyles({
  checkbox: {
    '&& > div > label': {
      display: 'flex',
      flexFlow: 'row-reverse',
      margin: 0,
    },
  },
});

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

const MEMBERS_PAGE_FILTER = 'members-page';

type KeyGetMemberParams = keyof MemberViewParams;

export const MembersDashboard = () => {
  const { showStoredFilters, showFiltersRefactorMembers } = useFlags();

  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const query = useQueryParams();

  const storedFilters = useSelector((state) =>
    showStoredFilters
      ? state.employeeSlice.storedFilters?.filter(
          StoredFilter.filter(MEMBERS_PAGE_FILTER),
        )
      : undefined,
  );

  const getFilterValue = (
    query?: string[],
    storage?: string[],
    mapper?: (value: string) => any,
  ) => {
    let result: string[] = [];
    if (query?.length) {
      result = query;
    } else if (storage?.length) {
      result = storage;
    }
    return result.map(mapper || ((x) => x)).filter(Boolean);
  };

  const initFilters = () => {
    const filtersString = localStorage.getItem(LS_MEMBER_FILTERS) || '';
    const filters = qs.parse(filtersString) || {};
    const hasCareTeamString = query.get('hasCareTeam');
    const hasCareTeam =
      hasCareTeamString !== null ? hasCareTeamString === 'true' : undefined;
    filters.hasCareTeam = filters.hasCareTeam
      ? filters.hasCareTeam === 'true'
      : undefined;
    const emptyRiskLevelString = query.get('emptyRiskLevel');
    const emptyRiskLevel =
      emptyRiskLevelString !== null ? Boolean(emptyRiskLevelString) : undefined;
    const carePlanGroupString =
      query.get('carePlanGroupId') || filters.carePlanGroupId;
    const carePlanGroupId =
      carePlanGroupString !== null && carePlanGroupString !== undefined
        ? Number(carePlanGroupString)
        : undefined;

    return {
      brandId: getFilterValue(query.getAll('brandId'), filters.brandId),
      emptyRiskLevel: emptyRiskLevel || filters.emptyRiskLevel || undefined,
      id: getFilterValue(query.getAll('id'), filters.ids, Number) as number[],
      fullName: query.get('fullName') || filters.fullName || undefined,
      carePlanGroupId,
      hasCareTeam: hasCareTeam || filters.hasCareTeam,
      status: getFilterValue(
        query.getAll('status'),
        filters.status,
        (key) => MemberStatus.byKey[key],
      ) as MemberStatus[],
      state: getFilterValue(
        query.getAll('state'),
        filters.state,
        (key) => States.byKey[key],
      ) as MemberStatus[],
      language: getFilterValue(
        query.getAll('language'),
        filters.language,
        (key) => Language.byKey[key],
      ) as Language[],
      rnOwnerId: getFilterValue(
        query.getAll('rnOwnerId'),
        filters.rnOwnerId,
        Number,
      ) as number[],
      npOwnerId: getFilterValue(
        query.getAll('npOwnerId'),
        filters.npOwnerId,
        Number,
      ) as number[],
      careCoordinatorId: getFilterValue(
        query.getAll('careCoordinatorId'),
        filters.careCoordinatorId,
        Number,
      ) as number[],
      engagementOwnerId: getFilterValue(
        query.getAll('engagementOwnerId'),
        filters.engagementOwnerId,
        Number,
      ) as number[],
      excludedReferralSourceId: getFilterValue(
        query.getAll('excludedReferralSourceId'),
        filters.excludedReferralSourceId,
        Number,
      ) as number[],
      hciReporter: getBoolean(query, filters, 'hciReporterMethod'),
      hciReporterMethod: getFilterValue(
        query.getAll('hciReporterMethod'),
        filters.hciReporterMethod,
        (item) => MemberContactMethodType.byKey[item],
      ) as MemberContactMethodType[],
      hciReporterStatus: getFilterValue(
        query.getAll('hciReporterStatus'),
        filters.hciReporterStatus,
      ) as string[],
      healthCoachId: getFilterValue(
        query.getAll('healthCoachId'),
        filters.healthCoachId,
        Number,
      ) as number[],
      podManagerId: getFilterValue(
        query.getAll('podManagerId'),
        filters.podManagerId,
        Number,
      ) as number[],
      programExtensionId: getFilterValue(
        query.getAll('programExtensionId'),
        filters.programExtensionId,
        Number,
      ) as number[],
      programExtensionStatus: getFilterValue(
        query.getAll('programExtensionStatus'),
        filters.programExtensionStatus,
      ) as string[],
      riskLevel: getFilterValue(
        query.getAll('riskLevel'),
        filters.riskLevel,
        (key) => MemberRisk.byKey[key],
      ) as MemberRisk[],
      referralSourceId: getFilterValue(
        query.getAll('referralSourceId'),
        filters.referralSourceId,
        Number,
      ) as number[],
      vitalCollectionMethod: getFilterValue(
        query.getAll('vitalCollectionMethod'),
        filters.vitalCollectionMethod,
        (key) => CollectionMethod.byKey[key],
      ) as CollectionMethod[],
    };
  };

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [members, setMembers] = useState<MemberView[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [filters, setFilters] = useState<MemberViewParams>(initFilters());
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetMemberParams]?: FilterItem;
    }
  >();

  const [downloadingStatus, setDownloadingStatus] = useState<boolean>();
  const [downloadExtendedReport, setDownloadExtendedReport] = useState<boolean>(
    false,
  );

  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    Selectable[]
  >([]);
  const [referralSources, setReferralSources] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [reporterStatuses, setReporterStatuses] = useState<BaseEnum[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const { actingUser } = Session;
  const showDownloadExport =
    actingUser.isInAdmin ||
    actingUser.isInBizOps ||
    actingUser.isInClinicalLeadership ||
    actingUser.isInEligibility;

  const mapEmployees = (array: Employee[]) =>
    Employee.toSelectable(array.sort(Employee.sort));

  const fetchFiltersData = async () => {
    setLoadingFilters(true);
    const [l, cpgs, employees] = await Promise.all([
      CacheServices.getLanguages(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getEmployees(),
    ]);

    const [pe, pes, o, rs, b] = await Promise.all([
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getOrganizations(),
      CacheServices.getCareTeamPersonStatuses(),
      CacheServices.getOrganizationBrands(),
    ]);
    setLanguages(Language.toSelectable(l).filter(({ disabled }) => !disabled));
    setCarePlanGroups([
      UNASSIGNED,
      ...cpgs.map(({ id: value, description: label }) => ({
        value,
        label,
      })),
    ]);
    const referralsOptions = Organization.toSelectable(o);

    setEmployees([UNASSIGNED, ...mapEmployees(employees)]);
    setProgramExtensions(
      pe.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    );
    setProgramExtensionStatuses(ProgramExtensionStatus.toSelectable(pes));
    setReferralSources([
      { value: 0, label: translate('global.noReferral') },
      ...referralsOptions,
    ]);
    setReporterStatuses(rs);
    setBrands(
      b.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setLoadingFilters(false);
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  const fetchPatients = async () => {
    let currentFilters: MemberViewParams;

    setLoading(true);
    try {
      if (showFiltersRefactorMembers) {
        if (!newFilters) return;
        currentFilters = getServerFilters(newFilters);
      } else {
        currentFilters = { ...filters };
      }

      const { items, pagination } = await getMembersView({
        ...currentFilters,
        offset: page * pageSize,
        limit: pageSize,
        sort: 'fullName asc, id asc',
      });
      setPagination(pagination);
      if (page !== 0) {
        setMembers([...members, ...items]);
      } else {
        setMembers(items);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPatients();
  }, [newFilters, filters, page, pageSize]);

  useEffect(() => {
    const {
      hciReporterMethod,
      language,
      programExtensionStatus,
      riskLevel,
      state,
      status,
      vitalCollectionMethod,
      ...rest
    } = filters;

    const flatFilters = {
      hciReporterMethod: hciReporterMethod?.map(({ value }) => value),
      state: state?.map(({ value }) => value),
      status: status?.map(({ value }) => value),
      language: language?.map(({ value }) => value),
      programExtensionStatus,
      riskLevel: riskLevel?.map(({ value }) => value),
      vitalCollectionMethod: vitalCollectionMethod?.map(({ value }) => value),
      ...rest,
    };
    const query = qs.stringify(flatFilters, { arrayFormat: 'repeat' });
    if (!showFiltersRefactorMembers) {
      history.replace(`?${query}`);
    }

    localStorage.setItem(LS_MEMBER_FILTERS, qs.stringify(flatFilters));
  }, [filters]);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_MEMBER_FILTERS_V2, newFilters);
    }
  }, [newFilters]);

  const storedFiltersConfiguration = useMemo(() => {
    if (brands?.length && employees?.length) {
      return {
        brandId: {
          data: brands,
          label: translate('members.brand'),
          multiple: true,
        },
        careCoordinatorId: {
          data: employees,
          label: translate('members.clinicalAssistant'),
          multiple: true,
        },
        excludedReferralSourceId: {
          data: referralSources,
          label: translate('members.excludedReferralSources'),
          multiple: true,
        },
        engagementOwnerId: {
          data: employees,
          label: translate('members.engagementOwners'),
          multiple: true,
        },
        hasCareTeam: {
          label: translate('members.hasCareTeam'),
        },
        hciReporter: {
          label: translate('members.selfReporter'),
        },
        hciReporterStatus: {
          data: reporterStatuses,
          label: translate('members.reporterStatus'),
          multiple: true,
        },
        hciReporterMethod: {
          data: Enum.toSelectable(MemberContactMethodType.asArray),
          label: translate('members.reportingPreference'),
          multiple: true,
        },
        healthCoachId: {
          data: employees,
          label: translate('members.healthCoach'),
          multiple: true,
        },
        language: {
          data: languages,
          label: translate('members.language'),
          multiple: true,
        },
        id: { label: translate('members.id') },
        fullName: { label: translate('members.name') },
        npOwnerId: {
          data: employees,
          label: translate('members.npOwner'),
          multiple: true,
        },
        podManagerId: {
          data: employees,
          label: translate('members.podManager'),
          multiple: true,
        },
        programExtensionId: {
          data: programExtensions,
          label: translate('members.programExtensions'),
          multiple: true,
        },
        programExtensionStatus: {
          data: programExtensionStatuses,
          label: translate('members.programExtensionStatus'),
          multiple: true,
        },
        referralSource: {
          data: referralSources,
          label: translate('members.referralSources'),
          multiple: true,
        },
        riskLevel: {
          data: Enum.toSelectable(RiskLevel.asArray),
          label: translate('members.riskLevel'),
          multiple: true,
        },
        rnOwnerId: {
          data: employees,
          label: translate('members.rnOwner'),
          multiple: true,
        },
        vitalCollectionMethod: {
          data: CollectionMethod.toVendorSelectable(),
          label: translate('members.vitalsCollectionMethod'),
          multiple: true,
        },
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('members.state'),
          multiple: true,
        },
        status: {
          data: Enum.toSelectable(MemberStatus.asArray),
          label: translate('members.status'),
          multiple: true,
        },
        worklistGroupId: {
          data: carePlanGroups,
          label: translate('members.carePlanGroup'),
          multiple: true,
        },
      } as Partial<
        {
          [x in KeyGetMemberParams]: LoadFilterOption;
        }
      >;
    }
  }, [
    brands,
    carePlanGroups,
    employees,
    languages,
    programExtensions,
    referralSources,
    reporterStatuses,
  ]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const storedFilters = loadNewFiltersFromStorage(
        LS_MEMBER_FILTERS_V2,
        storedFiltersConfiguration,
        query,
      );
      setNewFilters(storedFilters);
    }
  }, [storedFiltersConfiguration]);

  return (
    <Panel id="referrals-section">
      <Panel.Heading
        title={translate('members.title')}
        filtersV2={showFiltersRefactorMembers}
      >
        {!showFiltersRefactorMembers && (
          <FilterButton
            className="filters"
            data-cy="members-filters"
            filters={filters}
            onClick={() => setOpen(!isOpen)}
          />
        )}
        {showFiltersRefactorMembers && (
          <Panel.FilterBar
            onClearFilters={() => {
              setNewFilters({});
            }}
            onDeleteFilter={(key: string) => {
              setNewFilters({
                ...newFilters,
                [key]: undefined,
              });
            }}
            onOpenFilters={() => setOpen(!isOpen)}
            onSelectFilter={(filter) =>
              storedFiltersConfiguration &&
              setNewFilters(
                loadNewFilters(filter.filters, storedFiltersConfiguration),
              )
            }
            chips={newFilters}
            inputs={
              <MemberViewFiltersBar
                data={{
                  employees,
                  languages,
                  referralSources,
                  programExtensions,
                  programExtensionStatuses,
                }}
                loading={loadingFilters}
                filters={newFilters}
                onChange={(filters) => {
                  setPage(0);
                  setNewFilters(filters);
                }}
              />
            }
            storedFilters={storedFilters}
          />
        )}
        {showDownloadExport && (
          <Panel.Actions>
            <CheckboxWithLabel
              className={styles.checkbox}
              label={translate('members.extendedReport')}
              checked={downloadExtendedReport || false}
              onChange={() => {
                setDownloadExtendedReport(!downloadExtendedReport);
              }}
            />
            <IconButton
              data-cy="member-export"
              tooltip={translate('members.downloadMembersFile')}
              loading={downloadingStatus}
              onClick={async () => {
                setDownloadingStatus(true);
                let currentFilters: MemberViewParams;
                if (showFiltersRefactorMembers) {
                  if (!newFilters) return;
                  currentFilters = getServerFilters(newFilters);
                } else {
                  currentFilters = { ...filters };
                }
                await downloadMemberExport(
                  currentFilters,
                  downloadExtendedReport,
                );
                setDownloadingStatus(false);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Panel.Actions>
        )}
        {!showFiltersRefactorMembers && (
          <Panel.Collapse data-cy="members-filters-collapsible" open={isOpen}>
            <div className="grid-wrapper fit">
              <MemberViewFilters
                filters={filters}
                onChange={(filters) => {
                  setPage(0);
                  setFilters(filters);
                }}
              />
            </div>
          </Panel.Collapse>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {showFiltersRefactorMembers && (
          <MemberViewFiltersModal
            data={{
              brands,
              employees,
              hciReporterStatuses: BaseEnum.toSelectable(reporterStatuses),
              languages,
              programExtensions,
              programExtensionStatuses,
              referralSources,
              statuses: [],
              worklistGroups: carePlanGroups,
            }}
            filters={newFilters}
            loadingFilters={loadingFilters}
            open={isOpen}
            onChange={(filters) => {
              setPage(0);
              setNewFilters(filters);
            }}
            onClose={() => setOpen(false)}
            onDeleteFilter={async (filter) => {
              await deleteSelfStoredFilter(filter.id);
              dispatch(onStoredFilterDeleted(filter));
            }}
            onEditFilter={async (filter) => {
              await updateSelfStoredFilter(filter.id, filter);
              dispatch(onStoredFilterChange(filter));
            }}
            onSaveFilter={async (filter) => {
              if (filter.id) {
                await updateSelfStoredFilter(filter.id, {
                  filters: saveNewFilters(newFilters || {}),
                });
                dispatch(onStoredFilterChange(filter));
              } else if (newFilters) {
                const updated = await createSelfStoredFilter({
                  description: filter.description,
                  filters: saveNewFilters(newFilters || {}),
                  page: MEMBERS_PAGE_FILTER,
                  preferred: false,
                });
                dispatch(onStoredFilterAdd(updated));
              }
            }}
            storedFilters={storedFilters}
          />
        )}
        <MembersDataTable
          members={members}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
        />
      </Panel.Body>
    </Panel>
  );
};

export default MembersDashboard;
