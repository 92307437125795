import React, { useEffect, useRef, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { CommunityResource } from '@vestahealthcare/common/models';

import { SelectCommunityResourceOption, SelectHandle } from 'styleguide-v2';
import { Select } from 'styleguide-v2/src/components/Select';

import { fetchCommunityResources } from 'dash/src/services/CommunityResourcesServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  multiple?: boolean;
  onChange: (crs: CommunityResource | CommunityResource[]) => void;
  placeholder?: string;
  value?: CommunityResource | CommunityResource[] | null;
  required?: boolean;
  size?: 'xs' | 'small' | 'medium';
};

export const SearchCommunityResource = ({
  className,
  'data-cy': dataCy,
  disabled,
  error,
  label,
  multiple,
  onChange,
  placeholder,
  value,
  required,
  size,
}: Props) => {
  const [communityResources, setCommunityResources] = useState<
    CommunityResource[]
  >([]);
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<SelectHandle>(null);

  const queryString = useDebounce(filter ? String(filter) : undefined, 300);

  const searchCommunityResources = async (queryString: string) => {
    if (!multiple && filter === (value as CommunityResource)?.name) return;

    setLoading(true);
    const { items: crs } = await fetchCommunityResources({
      queryString,
      limit: 25,
    });
    setCommunityResources(crs);
    setTimeout(() => setLoading(false), 0);
  };

  const handleInputChange = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    if (
      filter?.length > 2 &&
      (multiple || filter !== (value as CommunityResource)?.name)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    if (queryString && queryString?.length > 2) {
      searchCommunityResources(queryString);
    } else if (!queryString) {
      setCommunityResources([]);
    }
  }, [queryString]);

  return (
    <Select
      className={className}
      data-cy={dataCy}
      disabled={disabled}
      error={error}
      getItemLabel={({ name }: CommunityResource) => name || ''}
      filterOptions={(options) => options}
      items={communityResources}
      label={label}
      loading={loading}
      multiple={multiple}
      noOptionsText={
        filter.length < 2
          ? translate('components.searchCommunityResources.typeToSearch')
          : translate('components.searchCommunityResources.noMembersFound')
      }
      onChange={
        multiple
          ? onChange
          : (value: CommunityResource) => {
              inputRef?.current?.clearSearch();
              onChange(value as CommunityResource);
            }
      }
      onInputChange={handleInputChange}
      placeholder={placeholder}
      ref={inputRef}
      renderOption={(communityResource: CommunityResource) => (
        <SelectCommunityResourceOption communityResource={communityResource} />
      )}
      value={value}
      required={required}
      size={size}
    />
  );
};
