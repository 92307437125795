import React, { useState } from 'react';

import {
  LS_IPAD_ID,
  LS_REMOTE_BACKEND,
  LS_TEST_PATIENT,
  TEST_IPAD_ID,
  TEST_PATIENT_ID,
} from '@vestahealthcare/common/utils/constants';

import { Button, Panel, Select, TextInput } from 'styleguide-v2';

import config from 'dash/environments';
import {
  linkPlatformDevice,
  unlinkPlatformDevice,
} from 'dash/src/services/PatientServices';

const BACKEND_OPTIONS = ['Stage', 'QA1', 'QA2', 'Demo'].map((val) => ({
  value: val.toLowerCase(),
  label: val,
}));

export const DevSettings = () => {
  const [ipadId, setIpadId] = useState(TEST_IPAD_ID || undefined);
  const [patientId, setPatientId] = useState<number | undefined>(
    TEST_PATIENT_ID,
  );
  const [remoteBackend, setRemoteBackend] = useState(
    localStorage.getItem(LS_REMOTE_BACKEND),
  );

  if (config.isProd) {
    return null;
  }

  return (
    <Panel>
      <Panel.Heading title="Dev Dashboard" />
      <Panel.Body>
        <div className="grid-wrapper" style={{ height: 'fit-content' }}>
          <TextInput
            className="grid-span-3"
            label="Test Patient ID"
            onChange={(val?: string) => {
              const number = val !== undefined ? parseInt(val, 10) : NaN;
              setPatientId(number);
              localStorage.setItem(LS_TEST_PATIENT, `${val}`);
            }}
            type="numeric"
            value={patientId}
          />
          <div className="grid-span-8" />
          <TextInput
            className="grid-span-4"
            value={ipadId}
            label="Test iPad ID"
            onChange={(val?: any) => {
              setIpadId(val);
              localStorage.setItem(LS_IPAD_ID, val);
            }}
          />
          <Button
            className="grid-span-4"
            color="primary"
            disabled={!ipadId || !patientId}
            onClick={async () => {
              if (!ipadId || !patientId) return;
              return linkPlatformDevice(patientId, ipadId);
            }}
          >
            Link test iPad to test patient
          </Button>
          <Button
            className="grid-span-4"
            color="secondary"
            disabled={!patientId}
            onClick={async () => {
              if (!patientId) return;
              return unlinkPlatformDevice(patientId);
            }}
          >
            Unlink test patient iPad
          </Button>
          <Select
            className="grid-span-4"
            label="Remote Backend"
            onChange={(val: string) => {
              // @ts-ignore
              window.setBackend(val);
              setRemoteBackend(val);
            }}
            items={BACKEND_OPTIONS}
            value={BACKEND_OPTIONS.find(({ value }) => value === remoteBackend)}
          />
          <Button
            className="grid-span-4"
            color="secondary"
            disabled={!remoteBackend}
            // @ts-ignore
            onClick={() => window.clearBackend()}
          >
            Clear Remote Backend
          </Button>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default DevSettings;
