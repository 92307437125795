import classNames from 'classnames';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CCMMember, PaginationType } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  BrandMemberIcon,
  CheckboxWithLabel,
  Chip,
  Colors,
  IconButton,
  Table,
  Tooltip,
} from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

interface Props {
  allChecked: boolean;
  blacklist: boolean;
  defaultPageSize?: number;
  defaultSort?: string;
  items: CCMMember[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickRow: (member: CCMMember) => void;
  onDefaultSort: () => void;
  onLogCall: (member: CCMMember) => void;
  onSelectMember: (member: CCMMember) => void;
  onSelectAll: (allChecked: boolean) => void;
  onSort: (field: string, direction: string) => void;
  selectedIds: number[];
}

const TABLE_HEADERS = {
  language: translate('ccm.common.language'),
  lastCarePlan: translate('ccm.common.lastCarePlan'),
  lastCCM: translate('ccm.common.lastCCMTouchpoint'),
  lastAppointmentDate: translate('ccm.common.lastAppt'),
  member: translate('ccm.common.member'),
  nextAppointmentDate: translate('ccm.common.nextAppt'),
  validMinutes: translate('ccm.common.validMinutes'),
  remainingMinutes: translate('ccm.common.remainingMinutes'),
  minutes: translate('ccm.common.ccmMins'),
  numberOfCalls: translate('ccm.common.numberOfCalls'),
  state: translate('ccm.common.state'),
  worklistGroup: translate('ccm.common.worklistGroup'),
};

const SORT_FIELDS = {
  [TABLE_HEADERS.language]: 'preferredLanguage',
  [TABLE_HEADERS.lastCarePlan]: 'lastCarePlanDate',
  [TABLE_HEADERS.lastCCM]: 'lastCCMTouchPointDate',
  [TABLE_HEADERS.lastAppointmentDate]: 'lastAppointment',
  [TABLE_HEADERS.member]: 'memberId',
  [TABLE_HEADERS.nextAppointmentDate]: 'nextAppointment',
  [TABLE_HEADERS.minutes]: 'ccmTotalMinutes',
  [TABLE_HEADERS.remainingMinutes]: 'ccmMinutesToNextCpt',
  [TABLE_HEADERS.numberOfCalls]: 'calls',
};

const CCM_COLUMNS = (
  styles: ClassNameMap<
    | 'validMins'
    | 'minutes'
    | 'successMinutes'
    | 'warningMinutes'
    | 'errorMinutes'
    | 'small'
    | 'extraSmall'
  >,
  {
    allChecked,
    blacklist,
    items,
    onLogCall,
    onSelectAll,
    onSelectMember,
    selectedIds,
  }: Props,
  sort?: string,
) => {
  const field = sort?.split(' ')[0];
  const direction = sort?.split(' ')[1] as 'asc' | 'desc';

  return [
    {
      headerName: (
        <CheckboxWithLabel
          checked={allChecked || blacklist}
          disabled={!items.length}
          onChange={() => onSelectAll(allChecked)}
        />
      ),
      component: (member: CCMMember) => (
        <CheckboxWithLabel
          checked={blacklist !== selectedIds.includes(member.memberId)}
          onChange={() => onSelectMember(member)}
        />
      ),
      width: 50,
    },
    {
      headerName: TABLE_HEADERS.member,
      component: (member: CCMMember) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            {!!member?.householdMembers?.length && (
              <Tooltip
                text={
                  <>
                    {member.householdMembers.map(
                      ({ id, linkedMember: hhm, relationship }) => (
                        <p
                          className="no-margin white"
                          key={`member-${hhm.id}-household-${id}`}
                        >
                          {relationship.description}: {hhm.fullName} (
                          <a
                            className="lighten"
                            href={`#/patients/${hhm.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {hhm.id}
                          </a>
                          )
                        </p>
                      ),
                    )}
                  </>
                }
              >
                <GroupOutlinedIcon
                  color="primary"
                  sx={{ height: '2rem', width: '2rem' }}
                />
              </Tooltip>
            )}
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          {Session.actingUser.isExternal ? (
            member?.memberId
          ) : (
            <a
              className={styles.small}
              href={`#/patients/${member?.memberId}`}
              rel="noreferrer"
              target="_blank"
              onClick={(evt) => evt.stopPropagation()}
            >
              {member?.memberId}
            </a>
          )}
          )
          {member?.language && (
            <>
              {' '}
              <Chip color="info" size="small" type="outlined">
                {member.language?.toString()}
              </Chip>
            </>
          )}
        </>
      ),
      width: 185,
    },
    {
      headerName: TABLE_HEADERS.state,
      component: ({ state }: CCMMember) => state?.value || EMPTY,
      width: 75,
    },
    {
      headerName: TABLE_HEADERS.lastCarePlan,
      component: ({ carePlanDate }: CCMMember) => (
        <span className={styles.small}>
          {carePlanDate?.format(DATE_FORMAT_SHORT) || EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastCarePlan]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.lastCCM,
      component: ({ lastCCMDate, lastCCMReason }: CCMMember) =>
        lastCCMDate ? (
          <Tooltip text={lastCCMReason}>
            <span className={styles.small}>
              {lastCCMDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastCCM] ? direction : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.lastAppointmentDate,
      component: ({ lastAppointmentDate, lastAppointmentType }: CCMMember) =>
        lastAppointmentDate ? (
          <Tooltip text={lastAppointmentType}>
            <span className={styles.small}>
              {lastAppointmentDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastAppointmentDate]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.nextAppointmentDate,
      component: ({ nextAppointmentDate, nextAppointmentType }: CCMMember) =>
        nextAppointmentDate ? (
          <Tooltip text={nextAppointmentType}>
            <span className={styles.small}>
              {nextAppointmentDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.nextAppointmentDate]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.validMinutes,
      component: ({ ccmValidMinutes }: CCMMember) =>
        ccmValidMinutes ? (
          <Tooltip text={ccmValidMinutes}>
            <CheckCircleIcon className={styles.validMins} color="primary" />
          </Tooltip>
        ) : (
          <CancelIcon className={styles.validMins} color="error" />
        ),
    },
    {
      headerName: TABLE_HEADERS.minutes,
      component: ({
        ccmProfessionalMinutes,
        ccmTotalMinutes,
        hasValidCCM,
      }: CCMMember) => {
        if (ccmTotalMinutes === null) return EMPTY;

        let className = styles.errorMinutes;
        if (ccmTotalMinutes >= 20 && hasValidCCM) {
          className = styles.successMinutes;
        } else if (ccmTotalMinutes >= 20 || hasValidCCM) {
          className = styles.warningMinutes;
        }
        return ccmTotalMinutes ? (
          <Tooltip
            text={
              <>
                <p className="no-margin white">
                  {translate('ccm.common.minsTooltipLine1', {
                    mins: ccmProfessionalMinutes || 0,
                  })}
                </p>
                <p className="no-margin white">
                  {translate('ccm.common.minsTooltipLine2', {
                    mins: ccmTotalMinutes - (ccmProfessionalMinutes || 0),
                  })}
                </p>
              </>
            }
          >
            <span className={classNames(className, styles.minutes)}>
              {ccmTotalMinutes}
            </span>
          </Tooltip>
        ) : (
          <span className={classNames(className, styles.minutes)}>
            {ccmTotalMinutes}
          </span>
        );
      },
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.minutes] ? direction : undefined,
      sortable: true,
    },

    {
      headerName: TABLE_HEADERS.remainingMinutes,
      component: ({ ccmMinutesToNextCpt }: CCMMember) => {
        if (ccmMinutesToNextCpt === null)
          return (
            <span className={classNames(styles.errorMinutes, styles.minutes)}>
              MAX
            </span>
          );

        if (!ccmMinutesToNextCpt) return EMPTY;
        return (
          <span className={classNames(styles.warningMinutes, styles.minutes)}>
            {ccmMinutesToNextCpt}
          </span>
        );
      },
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.remainingMinutes]
          ? direction
          : undefined,
      sortable: true,
      width: 140,
    },
    {
      headerName: TABLE_HEADERS.numberOfCalls,
      field: 'calls',
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.numberOfCalls]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.worklistGroup,
      component: ({ worklistGroup }: CCMMember) => (
        <span className="size-xs">{worklistGroup?.name || EMPTY}</span>
      ),
    },
    {
      headerName: ' ',
      component: (member: CCMMember) => (
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onLogCall(member);
          }}
          tooltip={translate('ccm.actions.logCall')}
        >
          <AddCallIcon />
        </IconButton>
      ),
      width: 20,
    },
  ];
};

const useStyles = makeStyles({
  validMins: {
    marginLeft: '0.5rem',
  },
  minutes: {
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontWeight: 500,
    minWidth: '3rem',
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
  },
  successMinutes: {
    color: Colors.textGreen,
    background: Colors.lightMint,
  },
  warningMinutes: {
    background: BackgroundColors.lightYellow,
  },
  errorMinutes: {
    color: Colors.textRed2,
    background: `${Colors.textRed}33`,
  },
  small: { fontSize: '0.875em', lineHeight: '16px' },
  extraSmall: { fontSize: '0.75em', lineHeight: '16px' },
});

export const CCMTable = (props: Props) => {
  const styles = useStyles();

  const columns = useMemo(() => CCM_COLUMNS(styles, props, props.defaultSort), [
    props.onSelectMember,
    props.defaultSort,
    styles,
  ]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.items,
        defaultPageSize: props.defaultPageSize,
        getRowBrand: ({ brand }: CCMMember) => brand,
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      onClickRow={props.onClickRow}
      onDefaultSort={props.onDefaultSort}
      onSort={({ headerName, direction }) => {
        if (SORT_FIELDS[headerName as string] && direction) {
          props.onSort(SORT_FIELDS[headerName as string], direction);
        }
      }}
    />
  );
};

export default CCMTable;
