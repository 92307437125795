import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

import { Brand } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  IncidentAction,
  IncidentDetail,
  IncidentDetailEmail,
  IncidentDetailMember,
  IncidentDetailResponse,
  IncidentStatus,
  IncidentType,
  InsuranceMLTC,
  Organization,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_TIME_FORMAT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Colors,
  Fonts,
  IconButton,
  Modal,
  Panel,
  Select,
  SelectAssignee,
  Tooltip,
  UNASSIGNED,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { NoteSection } from 'dash/src/components/NoteSection';
import NoteModalAdd from 'dash/src/components/NoteSection/NoteModalAdd';
import { CacheServices } from 'dash/src/services';
import {
  UpdateIncidentParams,
  downloadIncidentReport,
  fetchIncidentDetail,
  updateIncident,
} from 'dash/src/services/IncidentServices';
import Session from 'dash/src/services/SessionServices';

import EditEmailModal from './EditEmailModal';
import { IncidentsDetailClient } from './IncidentsDetailClientCard';
import { IncidentsDetailIncident } from './IncidentsDetailIncidentCard';
import { IncidentsDetailMember } from './IncidentsDetailMemberCard';

type Params = {
  id: string;
};

const useStyles = makeStyles({
  buttonContainer: { flexFlow: 'row-reverse', justifyContent: 'space-between' },
  buttonEdit: {
    minWidth: '10rem',
  },
  buttonsFooter: {
    height: 'fit-content',
    minWidth: '15rem',
  },
  ellipsis: {
    maxWidth: '54rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  select: {
    marginTop: '-2rem',
    minWidth: '25rem',
  },
  subtitle: {
    alignItems: 'center',
    display: 'flex',
    gap: '0 0.5rem',
    flexWrap: 'wrap',
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 400,
    letterSpacing: 0,
    maxWidth: '600px',
  },
  verticalLine: {
    backgroundColor: Colors.lightGray,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    height: 25,
    width: 1,
  },
});

type SelectableSource = Selectable & {
  mltcPlan?: InsuranceMLTC;
  referral?: Organization;
};

export const IncidentsDetail = () => {
  const { id } = useParams<Params>();

  const styles = useStyles();

  const [loadingAssignee, setLoadingAssignee] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [openEditNote, setOpenEditNote] = useState<boolean>(false);
  const [openAddEmail, setOpenAddEmail] = useState<boolean>(false);
  const [showCopiedReportTooltip, setShowCopiedReportTooltip] = useState<
    boolean
  >(false);

  const [statuses, setStatuses] = useState<IncidentStatus[]>([]);
  const [assignees, setAssignees] = useState<Employee[]>([]);
  const [assigneeGroups, setAssigneeGroups] = useState<EmployeeGroup[]>([]);
  const [status, setStatus] = useState<IncidentStatus>();
  const [assignee, setAssignee] = useState<Employee>();
  const [assigneeGroup, setAssigneeGroup] = useState<EmployeeGroup>();

  const [response, setResponse] = useState<IncidentDetailResponse>();
  const [detail, setDetail] = useState<IncidentDetail>();
  const [emails, setEmails] = useState<IncidentDetailEmail[]>([]);
  const [memberDetail, setMemberDetail] = useState<IncidentDetailMember>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const [customMails, setCustomMails] = useState<string[]>([]);
  const [params, setParams] = useState<UpdateIncidentParams>({});

  const [selectedSource, setSelectedSource] = useState<SelectableSource>();
  const [openSelectReferralModal, setOpenSelectReferralModal] = useState<
    boolean
  >(false);
  const [pendingOperation, setPendingOperation] = useState<
    'copy' | 'download'
  >();

  const getInitialData = async () => {
    setLoadingData(true);
    try {
      const [s, a, g] = await Promise.all([
        CacheServices.getIncidentStatuses(),
        CacheServices.getIncidentOwners(),
        CacheServices.getEmployeeGroupsAssignee(),
      ]);

      setStatuses(s);
      setAssignees(a);
      setAssigneeGroups(
        g.filter(({ id }) => id === EmployeeGroup.CLINICAL_ACCOUNTS),
      );
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingData(false);
    }
  };

  const getIncidentDetail = async () => {
    setLoadingDetail(true);
    try {
      const response = await fetchIncidentDetail(+id);
      const { emails, incident, memberDetail } = response;
      setResponse(response);
      setDetail(incident);
      setAssignee(incident.assignee);
      setAssigneeGroup(incident.assigneeGroup);
      setStatus(incident.status);
      setEmails(emails?.filter(({ groupName }) => !!groupName) || []);
      setCustomMails(emails?.find(({ groupName }) => !groupName)?.emails || []);
      setMemberDetail(memberDetail);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingDetail(false);
    }
  };

  const reset = () => {
    if (response) {
      const { emails, incident, memberDetail } = response;
      setDetail({ ...incident });
      setEmails(emails?.filter(({ groupName }) => !!groupName) || []);
      setCustomMails(emails?.find(({ groupName }) => !groupName)?.emails || []);
      setMemberDetail(memberDetail);
    }
  };

  const isChangeOfCondition = useMemo(
    () =>
      !!params?.types?.find(
        ({ id }) => id === IncidentType.CHANGE_OF_CONDITION,
      ),
    [params?.types],
  );

  const hasActions = !!params?.actions?.length;

  const isStateFederal = useMemo(
    () =>
      !!params?.actions?.find(({ id }) => id === IncidentAction.STATE_FEDERAL),
    [params?.actions],
  );

  const isActionOther = useMemo(
    () => !!params?.actions?.find(({ id }) => id === IncidentAction.OTHER),
    [params?.actions],
  );

  const isHosp = useMemo(
    () =>
      !!params.types?.find(({ id }) =>
        [IncidentType.ER_VISIT, IncidentType.HOSPITALIZATION].includes(id),
      ),
    [params?.types],
  );

  const validate = () =>
    params.types?.length &&
    (!isChangeOfCondition ||
      params.changeOfCondition?.additionalHelp !== undefined) &&
    (!isChangeOfCondition ||
      !params.changeOfCondition?.additionalHelp ||
      params.changeOfCondition?.changeDescription) &&
    (!isChangeOfCondition || params.changeOfCondition?.changeDescription) &&
    params.incidentDate &&
    params.reportedDate &&
    params.externalNote &&
    (isHosp || params.location) &&
    (!hasActions || params.actionDate) &&
    (!isStateFederal || params.federalEntities?.length) &&
    (!isActionOther || params.actionOther) &&
    params.externalNote;

  const saveEdit = async () => {
    setSubmitted(true);

    if (!validate()) return;

    setLoadingEdit(true);
    setLoadingDetail(true);

    try {
      await updateIncident(+id, {
        ...params,
        emails: customMails,
      });
      await getIncidentDetail();
      setSubmitted(false);
      setEditMode(false);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setLoadingEdit(false);
    }
  };

  const downloadIncident = async () => {
    if (!detail || !memberSources?.length) return;
    const source =
      memberSources?.length > 1 ? selectedSource : memberSources[0];
    setLoadingDownload(true);
    await downloadIncidentReport(detail, (source?.value as string) || '');
    setLoadingDownload(false);
  };

  const copyEmails = async () => {
    const mails = emails.reduce(
      (acc, item) => [...acc, ...(item.emails || [])],
      [] as string[],
    );
    await navigator.clipboard.writeText([...mails, ...customMails].join(', '));
  };

  const fText = (key: string, text?: any) =>
    `${translate(`incidents.email.${key}`)}${text ? `: ${text}` : ''}\n`;
  const fHTMLTitle = (text: string) =>
    `<div style="font-size: 1.25em;"><b>${translate(
      `incidents.email.${text}`,
    )}</b></div>`;
  const fHTMLText = (key: string, text2?: any) =>
    `<div><b>${translate(`incidents.email.${key}`)}: </b>${text2}</div>`;

  const copyReport = async () => {
    const actions = detail?.actions
      ?.map(({ id, description }) =>
        id === IncidentAction.OTHER && detail?.actionOther
          ? `${description} (${detail.actionOther})`
          : description,
      )
      ?.join('; ');
    const anticoagulants = detail?.fall?.anticoagulants
      ?.map(({ description }) => description)
      ?.join('; ');
    const brand = memberDetail?.brand;
    const dateOfIncident = detail?.incidentDate?.format(DATE_FORMAT_SHORT);
    const dateReport = detail?.reportedDate?.format(DATE_FORMAT_SHORT);
    const followUps = detail?.hospitalization?.followUpAppointmentTypes
      ?.map(({ description }) => description)
      ?.join('; ');
    const involved = detail?.involvedActors
      ?.map(({ description }) => description)
      .join('; ');
    const isFall = detail?.types?.find(({ id }) =>
      [IncidentType.FALL_INJURY, IncidentType.FALL_NO_INJURY].includes(id),
    );
    const isFallInjury = detail?.types?.find(
      ({ id }) => IncidentType.FALL_INJURY === id,
    );
    const types = detail?.types
      ?.map(({ description }) => description)
      .join('; ');
    const phone = translate(
      `incidents.email.phoneValue.${brand === Brand.VESTA ? 'vesta' : 'cah'}`,
    );
    const source =
      memberSources?.length > 1 ? selectedSource : memberSources[0] || '';
    const subject = memberDetail?.activeReferrals?.length
      ? `${
          source?.referral ? source.referral.name : source?.mltcPlan?.name
        } - ${types}`
      : types;

    let reportText = '';
    let reportHTML = '';

    reportText += `${subject}\n`;
    reportHTML += `<div>${subject}</div>`;

    reportText += '\n';
    reportHTML += '<br />';

    reportText += fText('patientHeader');
    reportHTML += fHTMLTitle('patientHeader');
    reportText += fText('patientName', memberDetail?.fullName);
    reportHTML += fHTMLText('patientName', memberDetail?.fullName);
    reportText += fText('patientId', source?.value);
    reportHTML += fHTMLText('patientId', source?.value);
    reportText += fText('vestaId', memberDetail?.id);
    reportHTML += fHTMLText('vestaId', memberDetail?.id);

    reportText += '\n';
    reportHTML += '<br />';
    reportText += fText('incidentTitle');
    reportHTML += fHTMLTitle('incidentTitle');

    reportText += fText('incidentTitle', types);
    reportHTML += fHTMLText('incidentTitle', types);
    reportText += fText('incidentDate', dateOfIncident);
    reportHTML += fHTMLText('incidentDate', dateOfIncident);
    reportText += fText('reportDate', dateReport);
    reportHTML += fHTMLText('reportDate', dateReport);
    if (!isHosp) {
      reportText += fText('involved', involved);
      reportHTML += fHTMLText('involved', involved);
      reportText += fText('location', detail?.location?.description || EMPTY);
      reportHTML += fHTMLText(
        'location',
        detail?.location?.description || EMPTY,
      );
    }
    reportText += fText('description', detail?.externalNote || EMPTY);
    reportHTML += fHTMLText('description', detail?.externalNote || EMPTY);

    if (isFall) {
      reportText += fText('dme', detail?.fall?.dme ? 'Yes' : 'No');
      reportHTML += fHTMLText('dme', detail?.fall?.dme ? 'Yes' : 'No');
      if (detail?.fall?.dme) {
        const DMETypesDesdcriptions = detail?.fall?.dmeTypes
          ?.map(({ description }) => description)
          ?.join(', ');
        reportText += fText('dmeType', DMETypesDesdcriptions);
        reportHTML += fHTMLText('dmeType', DMETypesDesdcriptions);
      }
      reportText += fText('anticoagulant', anticoagulants);
      reportHTML += fHTMLText('anticoagulant', anticoagulants);
      if (isFallInjury) {
        reportText += fText(
          'evaluated',
          detail?.fall?.evaluated ? 'Yes' : 'No',
        );
        reportHTML += fHTMLText(
          'evaluated',
          detail?.fall?.evaluated ? 'Yes' : 'No',
        );
      }
    }

    if (isHosp) {
      const hosp = detail?.hospitalization;
      const admitDate = hosp?.admitDate?.format(DATE_FORMAT_SHORT);
      const dischargeDate = hosp?.dischargeDate?.format(DATE_FORMAT_SHORT);
      reportText += fText(
        'medicationChange',
        hosp?.medicationChange
          ? hosp?.medicationChangeDescription || EMPTY
          : 'No',
      );
      reportHTML += fHTMLText(
        'medicationChange',
        hosp?.medicationChange
          ? hosp?.medicationChangeDescription || EMPTY
          : 'No',
      );
      if (admitDate) {
        reportText += fText('admitDate', admitDate);
        reportHTML += fHTMLText('admitDate', admitDate);
      }
      if (dischargeDate) {
        reportText += fText('dischargeDate', dischargeDate);
        reportHTML += fHTMLText('dischargeDate', dischargeDate);
      }
      if (hosp?.followUpAppointmentTypes) {
        reportText += fText('followUpAppointmentType', followUps);
        reportHTML += fHTMLText('followUpAppointmentType', followUps);
      }
      if (hosp?.facilityName) {
        reportText += fText('facilityName', hosp?.facilityName);
        reportHTML += fHTMLText('facilityName', hosp?.facilityName);
      }
    }

    reportText += '\n';
    reportHTML += '<br />';
    reportText += fText('actionsHeader');
    reportHTML += fHTMLTitle('actionsHeader');
    reportText += fText('actions', actions);
    reportHTML += fHTMLText('actions', actions);
    if (detail?.actionDate) {
      reportText += fText(
        'actionsDate',
        detail?.actionDate?.format(DATE_FORMAT_SHORT),
      );
      reportHTML += fHTMLText(
        'actionsDate',
        detail?.actionDate?.format(DATE_FORMAT_SHORT),
      );
    }

    reportText += '\n--\n\n';
    reportHTML += '<p>--</p>';
    reportText += fText('footer');
    reportHTML += `<div>${translate(
      `incidents.email.footer.${brand === Brand.VESTA ? 'vesta' : 'cah'}`,
    )}:</div>`;
    reportText += fText('phone', phone);
    reportHTML += fHTMLText('phone', phone);
    if (brand === Brand.VESTA) {
      reportText += fText('email', translate('incidents.email.emailValue'));
      reportHTML += fHTMLText(
        'email',
        `<a href="mailto:${translate(
          'incidents.email.emailValue',
        )}">${translate('incidents.email.emailValue')}</a>`,
      );
    }

    const blobHtml = new Blob([reportHTML], { type: 'text/html' });
    const blobText = new Blob([reportText], { type: 'text/plain' });

    if (navigator.clipboard.write) {
      const data = [
        new ClipboardItem({
          'text/plain': blobText,
          'text/html': blobHtml,
        }),
      ];
      await navigator.clipboard.write(data);
    } else {
      await navigator.clipboard.writeText(reportText);
    }

    setShowCopiedReportTooltip(true);
    setTimeout(() => {
      setShowCopiedReportTooltip(false);
    }, 1000);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getIncidentDetail();
  }, [id]);

  const editable = status && !status?.isClosed;
  const actingUserIsAllowed =
    assignee && Session.actingUser.id === assignee?.id;
  const memberSources = [
    ...(memberDetail?.activeReferrals || [])
      .filter(({ discontinuedAt, externalId }) => !discontinuedAt && externalId)
      .map(
        ({ externalId, organization }) =>
          ({
            label: `${organization.name} - ${externalId}`,
            value: externalId,
            referral: organization,
          } as SelectableSource),
      ),
    ...(memberDetail?.memberInsurances || [])
      .filter(
        ({ discontinuedAt, subscriberId, mltcPlan }) =>
          !discontinuedAt && subscriberId && mltcPlan,
      )
      .map(
        ({ mltcPlan, subscriberId }) =>
          ({
            label: `${mltcPlan?.name} - ${subscriberId}`,
            value: subscriberId,
            mltcPlan,
          } as SelectableSource),
      ),
  ].filter(Boolean);

  return (
    <Panel>
      <Panel.Heading
        title={
          <div className="block">
            <p>{translate('incidents.detail.title', { id })}</p>
            <div className={styles.subtitle}>
              <span>
                {translate('incidents.detail.subtitle', {
                  at: detail?.reportedDate.format(DATE_TIME_FORMAT) || EMPTY,
                  by: `${detail?.reporter?.fullName || EMPTY}${
                    detail?.externalReporter ? ` (via eCW)` : ''
                  }`,
                })}
              </span>
              <NoteSection
                color="info"
                editable={editable}
                emptyLabel={translate('incidents.detail.addNote')}
                note={detail?.statusNote}
                onEdit={() => setOpenEditNote(true)}
              />
            </div>
          </div>
        }
      >
        <Panel.Actions>
          <div className="flex gap">
            {detail && (
              <>
                <Select
                  className={styles.select}
                  data-cy="incidents-filter-status"
                  getItemLabel={({ description }: IncidentStatus) =>
                    description
                  }
                  disabled={!actingUserIsAllowed}
                  disableClearable
                  items={statuses}
                  label={translate('incidents.filters.status')}
                  loading={loadingStatus || loadingData || loadingDetail}
                  onChange={async (status?: IncidentStatus) => {
                    if (status) {
                      setLoadingStatus(true);
                      const { status: newStatus } = await updateIncident(+id, {
                        status,
                      });
                      setStatus(newStatus);
                      setLoadingStatus(false);
                    }
                  }}
                  size="small"
                  value={status}
                />

                <SelectAssignee
                  assignToMe
                  allowUnassigned
                  assignee={assignee || assigneeGroup || UNASSIGNED}
                  className={styles.select}
                  currentUser={Session.actingUser}
                  data-cy="incidents-filter-assignee"
                  items={[...assigneeGroups, ...assignees]}
                  label={translate('incidents.filters.assignee')}
                  loading={loadingAssignee || loadingData || loadingDetail}
                  onChange={async (assignee?: Employee | EmployeeGroup) => {
                    if (assignee) {
                      setLoadingAssignee(true);
                      const {
                        assignee: newAssignee,
                        assigneeGroup: newAssigneeGroup,
                      } = await updateIncident(+id, {
                        assigneeId:
                          assignee instanceof Employee ? assignee : null,
                        assigneeGroupId:
                          assignee instanceof EmployeeGroup ? assignee : null,
                      });
                      setAssignee(newAssignee);
                      setAssigneeGroup(newAssigneeGroup);
                      setLoadingAssignee(false);
                    }
                  }}
                  size="small"
                />
                <div className={styles.verticalLine} />
              </>
            )}
            <IconButton
              data-cy="download-button"
              loading={loadingDownload}
              onClick={() => {
                if (!memberSources) return;
                if (memberSources?.length > 1) {
                  setPendingOperation('download');
                  setSelectedSource(undefined);
                  setOpenSelectReferralModal(true);
                } else {
                  downloadIncident();
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              data-cy="copy-button"
              onClick={() => {
                if (!memberSources) return;
                if (memberSources?.length > 1) {
                  setPendingOperation('copy');
                  setSelectedSource(undefined);
                  setOpenSelectReferralModal(true);
                } else {
                  copyReport();
                }
              }}
              tooltip={
                showCopiedReportTooltip ? translate('global.copied') : undefined
              }
            >
              <CopyIcon />
            </IconButton>
            <Button
              className={styles.buttonEdit}
              color="secondary"
              data-cy="edit-button"
              disabled={
                editable
                  ? editMode ||
                    (!actingUserIsAllowed &&
                      translate('incidents.detail.disabledEditAssignee'))
                  : translate('incidents.detail.disabledEditStatus')
              }
              loading={loadingEdit}
              icon={<EditIcon />}
              onClick={() => setEditMode(!editMode)}
            >
              {translate('global.edit')}
            </Button>
          </div>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loadingDetail}>
        {detail && (
          <div className="grid-wrapper">
            <div className="grid-span-6">
              <IncidentsDetailMember member={memberDetail} />
              <br />
              <IncidentsDetailClient
                customMails={customMails}
                editable={editMode && editable}
                emails={emails}
                onAddEmail={() => setOpenAddEmail(true)}
                onDeleteMail={(mail) => {
                  const idx = customMails?.findIndex((item) => item === mail);
                  if (idx !== -1) {
                    customMails.splice(idx, 1);
                    setCustomMails([...customMails]);
                  }
                }}
                onCopyEmails={copyEmails}
              />
            </div>
            <IncidentsDetailIncident
              className="grid-span-6"
              detail={detail}
              editable={editMode}
              onChange={setParams}
              submitted={submitted}
            />
          </div>
        )}
        <NoteModalAdd
          note={detail?.statusNote}
          open={openEditNote}
          onEdit={async (statusNote) => {
            const detail = await updateIncident(+id, {
              statusNote,
            });
            setDetail(detail);
          }}
          onClose={() => setOpenEditNote(false)}
        />
        <EditEmailModal
          open={openAddEmail}
          onEdit={async (email?: string) => {
            if (email) setCustomMails([...customMails, email]);
          }}
          onClose={() => setOpenAddEmail(false)}
        />
        <Modal
          onClose={() => setOpenSelectReferralModal(false)}
          onSubmit={async () => {
            if (pendingOperation === 'copy') copyReport();
            if (pendingOperation === 'download') downloadIncident();
          }}
          open={openSelectReferralModal}
          submitDisabled={!selectedSource}
          title={translate('incidents.detail.selectSourceTitle')}
        >
          <Select
            data-cy="incident-member-referral"
            items={memberSources || []}
            renderOption={({ label }) => (
              <Tooltip text={label} followCursor>
                <span className={styles.ellipsis}>{label}</span>
              </Tooltip>
            )}
            label={translate('incidents.detail.selectSourceBody')}
            loading={loadingData}
            onChange={setSelectedSource}
            value={selectedSource}
            required
          />
        </Modal>
      </Panel.Body>
      {editMode && (
        <Panel.Footer white>
          <div className={classNames('flex extra-gap', styles.buttonContainer)}>
            <Button
              className={styles.buttonsFooter}
              color="secondary"
              data-cy="edit-incident-submit"
              loading={loadingEdit}
              onClick={saveEdit}
            >
              {translate('global.save')}
            </Button>
            <Button
              className={styles.buttonsFooter}
              color="tertiary"
              data-cy="edit-incident-cancel"
              onClick={() => {
                reset();
                setEditMode(false);
              }}
            >
              {translate('global.cancel')}
            </Button>
          </div>
        </Panel.Footer>
      )}
    </Panel>
  );
};
