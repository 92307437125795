import { useInterval } from 'beautiful-react-hooks';
import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';

import { makeStyles } from '@material-ui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { Notification } from '@vestahealthcare/common/models';

import { CheckboxWithLabel, Colors, EmptyState } from 'styleguide-v2';

import {
  fetchNotifications,
  readAllNotifications,
  updateNotification,
} from 'dash/src/services/NotificationServices';

import NotificationIcon from './NotificationIcon';
import NotificationItem from './NotificationItem';

const useStyles = makeStyles({
  notificationWrapper: {
    overflowY: 'auto',
    maxHeight: '550px',
  },
  notificationsHeader: {
    alignItems: 'center',
    padding: '15px 20px',
    minWidth: '500px',
    maxWidth: '750px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.1), 0px 0px 0px 0px rgba(0,0,0,0.6)',
  },
  notificationTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationTitle: {
    maerginRight: 'auto',
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px 0px 0px 0px',
  },
  markAllRead: {
    marginLeft: 'auto',
    display: 'flex',
    color: Colors.blue,
  },
});

const REFRESH_INTERVAL = 60 * 1000;
const ROWS_PER_PAGE = 15;

const NotificationMenu = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showAll, setShowAll] = useState(true);
  const [unread, setUnread] = useState<Notification[]>([]);
  const styles = useStyles();

  const getNotificationData = async () => {
    const { items } = await fetchNotifications({
      seen: showAll ? undefined : false,
      limit: ROWS_PER_PAGE,
      sort: 'seen desc, createdAt desc',
    });
    setNotifications(items);
  };

  const markAllRead = async () => {
    await readAllNotifications();
    getNotificationData();
  };

  const editNotification = async (notification: Notification) => {
    await updateNotification(notification.id, true);
    getNotificationData();
  };

  useEffect(() => {
    getNotificationData();
  }, [showAll]);

  useEffect(() => {
    setUnread(notifications.filter((n) => !n.seen));
  }, [notifications]);

  useInterval(getNotificationData, REFRESH_INTERVAL);

  return (
    <NavDropdown
      classID="notification-menu"
      title={<NotificationIcon hasNew={unread.length > 0} />}
      noCaret
      className="pull-right"
      id="ht-nav-notification-menu"
    >
      <div className={styles.notificationsHeader}>
        <div className={styles.notificationTitleWrapper}>
          <span className={styles.notificationTitle}>
            {translate('notification.title')} ({unread.length})
          </span>
        </div>
        <CheckboxWithLabel
          checked={showAll}
          className="slide-toggle"
          label={translate(
            showAll ? 'notification.showAll' : 'notification.showUnread',
          )}
          onChange={setShowAll}
        />
        <div className={styles.markAllRead}>
          <span className="clickable mark-all-read" onClick={markAllRead}>
            {translate('notification.markAllRead')}
          </span>
        </div>
      </div>
      <div className={styles.notificationWrapper}>
        {notifications.map((notif) => (
          <NotificationItem
            key={notif.id}
            data={notif}
            onEdit={editNotification}
          />
        ))}
        {!notifications.length && (
          <EmptyState>{translate('notification.noNotifications')}</EmptyState>
        )}
      </div>
    </NavDropdown>
  );
};

export default NotificationMenu;
