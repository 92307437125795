import { Enum } from './Enum';

export class VirtualVisitPlatform extends Enum {
  translationKey = 'virtualVisitPlatform';

  static asArray: VirtualVisitPlatform[] = [];

  static byKey: { [name: string]: VirtualVisitPlatform } = {};

  static readonly DOXY = new VirtualVisitPlatform('DOXY');

  static readonly ZOOM = new VirtualVisitPlatform('ZOOM');

  static readonly MEET = new VirtualVisitPlatform('MEET');

  static toSelectable() {
    return Enum.toSelectable(VirtualVisitPlatform.asArray);
  }

  constructor(public readonly value: string) {
    super();
    VirtualVisitPlatform.asArray.push(this);
    VirtualVisitPlatform.byKey[value] = this;
  }
}

export default VirtualVisitPlatform;
