import React from 'react';

import { States } from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, EmployeeGroup } from '@vestahealthcare/common/models';

import { FilterItem, Select } from 'styleguide-v2';

import { GetTOCParams } from 'dash/src/services/TOCServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetTOCParams = keyof GetTOCParams;

type Props = {
  data: {
    acuityLevels: Selectable[];
    languages: Selectable[];
    eligibilityStatuses: Selectable[];
    employees: Selectable[];
    employeeGroups: Selectable[];
    statuses: Selectable[];
  };
  filters?: {
    [x in KeyGetTOCParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetTOCParams]?: FilterItem;
    },
  ) => void;
  type: 'ALL' | 'OTHER';
};

export const TOCFiltersBar = ({
  data,
  filters,
  loading,
  onChange,
  type,
}: Props) => (
  <>
    <Select
      allowAddNewValue
      data-cy="toc-filter-bar-member-id"
      items={[]}
      inputType="number"
      multiple
      noOptionsText={translate('members.typeToSearch')}
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          memberId: getFilterItem(
            translate('toc.common.memberId'),
            values.map(({ label }) => ({
              value: label,
              label,
            })),
          ),
        })
      }
      placeholder={translate('toc.common.memberId')}
      size="xs"
      value={filters?.memberId?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-state"
      items={Enum.toSelectable(States.asArray)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          state: getFilterItem(translate('toc.common.state'), values),
        })
      }
      placeholder={translate('toc.common.state')}
      placeholderV2
      size="xs"
      value={filters?.state?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-language"
      items={data.languages.filter(({ disabled }) => !disabled)}
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(translate('toc.common.language'), values),
        })
      }
      placeholder={translate('toc.common.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <Select
      data-cy="toc-filter-acuity-level"
      items={data.acuityLevels}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          acuityLevel: getFilterItem(
            translate('toc.common.acuityLevel'),
            values,
          ),
        })
      }
      placeholder={translate('toc.common.acuityLevel')}
      placeholderV2
      size="xs"
      value={filters?.acuityLevel?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-assignee"
      items={[...data.employeeGroups, ...data.employees]}
      grouped={(item: SelectableInfo<Employee | EmployeeGroup>) => {
        if (item.info instanceof EmployeeGroup) {
          return translate('global.groups');
        }
        if (item.info instanceof Employee) {
          return translate('global.employees');
        }
        return '';
      }}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          assigneeId: getFilterItem(translate('toc.common.assignee'), values),
        })
      }
      placeholder={translate('toc.common.assignee')}
      placeholderV2
      size="xs"
      value={filters?.assigneeId?.value || []}
    />
    {type === 'ALL' ? (
      <Select
        data-cy="toc-filter-status"
        key="toc-filter-status"
        items={data.statuses || []}
        multiple
        onChange={(value?: Selectable) =>
          onChange({
            ...filters,
            status: getFilterItem(translate('toc.common.status'), value),
          })
        }
        placeholder={translate('toc.common.status')}
        placeholderV2
        size="xs"
        value={filters?.status?.value || []}
      />
    ) : (
      <Select
        data-cy="toc-filter-tcm-eligbility"
        key="toc-filter-tcm-eligbility"
        items={data.eligibilityStatuses}
        onChange={(value?: Selectable) =>
          onChange({
            ...filters,
            trueTCMStatus: getFilterItem(
              translate('toc.common.tcmEligibility'),
              value,
            ),
          })
        }
        placeholder={translate('toc.common.tcmEligibility')}
        placeholderV2
        size="xs"
        value={filters?.trueTCMStatus?.value}
      />
    )}
  </>
);
