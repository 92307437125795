import { make } from './Model';
import { PatientBase } from './PatientBase';

export class Notification {
  id = 0;

  title = '';

  body = '';

  relativeUrl = '';

  createdAt = 0;

  seen?: boolean;

  member?: PatientBase;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.member = make(obj.member, PatientBase);
  }
}

export default Notification;
