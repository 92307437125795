import classNames from 'classnames';
import React from 'react';

import CalendarIcon from '@mui/icons-material/Event';
import HandshakeIcon from '@mui/icons-material/HandshakeOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PharmacyIcon from '@mui/icons-material/MedicationOutlined';
import ReportIcon from '@mui/icons-material/Report';
import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  AppointmentStatus,
  AppointmentType,
  EncounterDirection,
  EncounterType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardEncounter } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { StatusIndicator, Table, Tooltip, UNASSIGNED } from 'styleguide-v2';

import images from 'dash/static/images';

type Props = {
  className?: string;
  data?: MemberDashboardEncounter[];
};

const getEncounterIcon = (type: string, className: string) => {
  if (type.startsWith('ONBRD')) {
    return <HandshakeIcon className={className} />;
  }
  if (type.startsWith(AppointmentType.PHARM_CON.value)) {
    return <PharmacyIcon className={className} />;
  }
  switch (type) {
    case EncounterType.CCM.toString():
    case EncounterType.RPM.toString():
      return <LocalPhoneIcon className={className} />;
    case EncounterType.BHI.toString():
      return (
        <img
          src={images.mentalHealth}
          className={className}
          alt="Mental Health"
          style={{ width: '17px', height: '17px' }}
        />
      );
    case AppointmentType.EST_VV.value:
    case AppointmentType.PSTHOSP_VV.value:
    case AppointmentType.TCM_VV.value:
    case AppointmentType.SIX_MO_VV.value:
    case AppointmentType.ACUTE_VV.value:
    case AppointmentType.ANNU_VV.value:
    case AppointmentType.CCA_SIX_MO.value:
    case AppointmentType.SIX_MO_VV_ONE.value:
    case AppointmentType.CCA_SIX_MO_1.value:
      return <CalendarIcon className={className} />;
    case AppointmentType.EST_TEL.value:
    case AppointmentType.PSTHOSP_TE.value:
    case AppointmentType.SIX_MO_TEL.value:
    case AppointmentType.ACUTE_TEL.value:
    case AppointmentType.ANNU_TEL.value:
      return <ReportIcon className={className} />;
    default:
      return <LocalPhoneIcon className={className} />;
  }
};

const RECENT_ENCONUNTERS_COLUMNS = (
  styles: ClassNameMap<'firstParragraph' | 'status' | 'icon'>,
) => [
  {
    headerName: 'Type',
    component: ({
      type,
      typeSecondary,
      date,
      dateTime,
    }: MemberDashboardEncounter) => (
      <>
        <p
          className={classNames(
            styles.firstParragraph,
            'bold no-margin flex middle gap',
          )}
        >
          {getEncounterIcon(type, styles.icon)}
          {type.startsWith(AppointmentType.PHARM_CON.value)
            ? typeSecondary || type?.toString()
            : type?.toString()}
        </p>
        <p className="gray no-margin semi-bold size-xs">
          {dateTime
            ? dateTime.format(DATE_FORMAT_SHORT_WITH_TIME)
            : date.format(DATE_FORMAT_SHORT)}
        </p>
      </>
    ),
    width: 225,
  },
  {
    headerName: 'Details',
    component: ({ details, employeeName }: MemberDashboardEncounter) => (
      <StatusIndicator
        className={styles.status}
        color={details ? AppointmentStatus.getColor(details) : 'success'}
        status={details?.toString() || EncounterDirection.INBOUND.toString()}
        subtitle={employeeName || UNASSIGNED.fullName}
      />
    ),
    width: 260,
  },
  {
    headerName: 'Notes',
    component: ({ notes }: MemberDashboardEncounter) =>
      notes ? (
        <Tooltip text={notes}>
          <div
            className="ellipsis-2-lines"
            style={{ maxWidth: 'calc(50vw - 48.5rem)', width: 'fit-content' }}
          >
            {notes}
          </div>
        </Tooltip>
      ) : (
        EMPTY
      ),
  },
];

const useStyles = makeStyles({
  table: {
    marginTop: 1,
  },
  icon: {
    marginTop: '-3px',
  },
  firstParragraph: {
    '&&': {
      margin: '4px 0 -2px',
    },
  },
  status: {
    '& > :first-child': {
      margin: '4px 0 0',
    },
    '& > :last-child': {
      marginTop: '-3px',
    },
  },
});

export const RecentEncountersTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: RECENT_ENCONUNTERS_COLUMNS(styles),
        compact: true,
        data: props.data || [],
        paginationOptions: [-1],
      }}
      empty={translate('memberProfile.dashboard.noRecentEncounters')}
      whiteBackground
    />
  );
};
