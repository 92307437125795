import React, { useEffect, useState } from 'react';

import { EmployeeRole, States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { Panel, Select, TextInput } from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import { EmployeeParams } from 'dash/src/services/EmployeeServices';
import { getFilterCount, getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetEmployeeParams = keyof EmployeeParams;

type Props = {
  data: {
    employeeGroups: Selectable[];
    permissions: Selectable[];
  };
  filters?: {
    [x in KeyGetEmployeeParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetEmployeeParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
};

const EMPLOYEE_FILTERS: KeyGetEmployeeParams[] = [
  'employeeId',
  'searchName',
  'employeeGroupId',
  'role',
  'permission',
  'enabled',
  'phiAccess',
  'state',
];

export const EmployeeFiltersModal = ({
  data,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
}: Props) => {
  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetEmployeeParams]?: FilterItem;
    }
  >(filters || {});

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      open={open}
      onClear={() => {
        onChange({});
      }}
      onClose={onClose}
      onSubmit={() => onChange(pendingFilters)}
    >
      <FilterGroup
        title="Employee Details"
        count={getFilterCount(pendingFilters, EMPLOYEE_FILTERS)}
        open
      >
        <TextInput
          data-cy="employee-filter-bar-id"
          onChange={(value?: string) => {
            setPendingFilters({
              ...pendingFilters,
              employeeId: getFilterItem(
                translate('dashboardEvents.filters.id'),
                value,
              ),
            });
          }}
          label={translate('employees.filters.id')}
          size="small"
          value={
            (pendingFilters?.employeeId?.value as FilterSelectable)
              ?.value as string
          }
        />
        <TextInput
          data-cy="employee-filter-bar-name"
          onChange={(value?: string) => {
            setPendingFilters({
              ...pendingFilters,
              searchName: getFilterItem(
                translate('employees.filters.name'),
                value,
              ),
            });
          }}
          label={translate('employees.filters.name')}
          size="small"
          value={
            (pendingFilters?.searchName?.value as FilterSelectable)
              ?.value as string
          }
        />
        <Select
          data-cy="employee-filter-referral-groups"
          items={data.employeeGroups}
          label={translate('employees.filters.groups')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              employeeGroupId: getFilterItem(
                translate('employees.filters.groups'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.employeeGroupId?.value || []}
        />
        <Select
          data-cy="employee-filter-referral-role"
          items={EmployeeRole.toSelectable()}
          label={translate('employees.filters.role')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              role: getFilterItem(translate('employees.filters.role'), values),
            });
          }}
          size="small"
          value={pendingFilters.role?.value || []}
        />
        <Select
          data-cy="employee-filter-referral-permission"
          items={data.permissions}
          label={translate('employees.filters.permission')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              permission: getFilterItem(
                translate('employees.filters.permission'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.permission?.value || []}
        />
        <Select.Choice
          data-cy="employee-filter-bar-active"
          label={translate('employees.filters.active')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              enabled: getFilterItem(
                translate('employees.filters.active'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (filters?.enabled?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select.Choice
          data-cy="employee-filter-bar-phiAccess"
          label={translate('employees.filters.phiAccess')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              phiAccess: getFilterItem(
                translate('employees.filters.phiAccess'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (filters?.phiAccess?.value as FilterSelectable)?.value as
              | boolean
              | undefined
          }
        />
        <Select
          data-cy="employee-filter-bar-phiAccess"
          items={Enum.toSelectable(States.asArray)}
          label={translate('employees.filters.state')}
          multiple
          onChange={(values?: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              state: getFilterItem(
                translate('employees.filters.state'),
                values,
              ),
            });
          }}
          size="small"
          value={filters?.state?.value}
        />
      </FilterGroup>
    </Panel.FilterModal>
  );
};
