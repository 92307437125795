import enums from './en-enums';

export default {
  enums: {
    ...enums,
  },
  assessment: {
    languagePicker: {
      addLanguage: 'Add language',
      placeholder: 'Select or start typing a language',
    },
    riskAssessment: {
      riskProfile: 'PHIP Profile',
    },
  },
  biometrics: {
    abnormalEntries: 'Abnormal Readings',
    abnormalEntriesCount: {
      one: '{{count}} abnormal reading',
      other: '{{count}} abnormal readings',
    },
    addReading: 'Add reading',
    addReadingTitle: 'Add {{type}} reading',
    afterMeal: 'After meal',
    baseline: 'Baseline',
    beforeMeal: 'Before meal',
    bp: 'Blood pressure',
    collectionMethod: 'Collection Method',
    dateRange: 'Date Range',
    daily: 'Daily',
    daysAgo: {
      one: '{{count}} day ago',
      other: '{{count}} days ago',
    },
    daysRemaining: {
      one: '{{count}} day remaining',
      other: '{{count}} days remaining',
    },
    daysAgoLabel: 'Days Ago',
    diastolic: 'Diastolic',
    disabled: 'Disabled',
    editRPMOrderId: 'Edit RPM Order Number',
    emptyConfigs: 'No valid vital configs',
    endDate: 'End Date',
    frequency: 'Frequency',
    lastReadings: 'Last Readings',
    location: 'Location',
    lowerThreshold: 'Lower Threshold',
    notes: 'Notes',
    normalRisk: 'Normal Risk',
    readings: {
      one: '{{count}} reading',
      other: '{{count}} readings',
    },
    readOnly: 'view only',
    replaceDevice: 'Replace Device',
    reminderApp: 'In App',
    reminderSMS: 'SMS',
    risk: 'Risk',
    rpmAdherence: {
      allTime: 'All Time',
      basedOn: 'Based on last 3 cycles',
      cycles: 'cycles',
      currentCycle: 'Current Adherence Cycle',
      devices: 'Devices',
      historical: 'Historical Adherence',
      lastReading: 'Last Reading',
      onboardedRPM: 'Onboarded to RPM',
      readings: 'Readings',
      remainingReading: {
        one: '{{count}} reading remaining',
        other: '{{count}} readings remaining',
      },
      recentCycles: 'Recent Cycles',
      seeFullHistory: 'See Full History',
      titleHistoryModal: 'RPM Adherence History for {{member}}',
      totalReadings: 'Total Readings',
      trend: 'Adherence Trend',
      trendTooltip:
        'This indicates how many readings a member has recorded at this point in the current 30-day cycle, when compared to their average number of readings over the last three cycles.',
    },
    rpmDeviceId: 'RPM Device Id',
    rpmOrderId: 'RPM Order Number',
    rpmReminders: 'RPM Reminders',
    rpmTasks: {
      one: '1 Open RPM Task',
      other: '{{count}} Open RPM Tasks',
    },
    setting: 'Setting',
    settings: 'Settings',
    startDate: 'Start Date',
    systolic: 'Systolic',
    timeTaken: 'Time taken',
    title: 'Vitals',
    type: 'Type',
    today: 'Today',
    upperThreshold: 'Upper Threshold',
    view: 'View',
    viewOptions: {
      chart: 'Graph',
      table: 'Table',
    },
    warningInvalidate: 'Are you sure you want to invalidate this reading?',
    warningOrderIdChange:
      'This will override the existing Order Number. Any notifications received from the previous Order ID will not be received which will result in possibly missing shipping notifications from the vendor and/or not receiving readings.',
    weekly: 'Weekly',
  },
  campaigns: {
    active: 'Active',
    addBanner: 'Add Banner',
    addCampaign: 'Add Campaign',
    addModal: 'Add Modal',
    addModalToBanner: 'Add modal to banner',
    audience: 'Audience',
    audienceType: 'Audience Type',
    banner: 'Banner',
    bannerButtonCTA: 'Banner Button CTA',
    bannerImage: 'Banner Image',
    bannerMessage: 'Banner Message',
    bannerName: 'Banner Name',
    banners: 'Banners',
    campaignManagement: 'Campaign Management',
    campaignName: 'Campaign Name',
    campaigns: 'Campaigns',
    confirmDeleteBannerBody:
      'Are you sure you want to delete the banner "{{name}}"?',
    confirmDeleteBannerTitle: 'Confirm Delete Banner',
    confirmDeleteCampaignBody:
      'Are you sure you want to delete the campaign "{{name}}"?',
    confirmDeleteCampaignTitle: 'Confirm Delete Campaign',
    confirmDeleteModalBody:
      'Are you sure you want to delete the modal "{{name}}"?',
    confirmDeleteModalTitle: 'Confirm Delete Modal',
    contentLibrary: {
      active: 'Active',
      add: 'Add Content',
      addLanguage: 'Add Language',
      audience: 'Audience',
      content: 'Content',
      contentType: 'Content Type',
      description: 'Purpose',
      detail: 'Content Detail',
      errorFileSize: 'Max file size allowed is 300MB',
      file: 'File',
      fileNoChange: '(no change)',
      fileType: 'File Type',
      id: 'ID',
      language: 'Language',
      languageAdd: 'Add Language',
      languages: 'Languages',
      messageContent: 'Message Content',
      modalTitleAdd: 'Add Content',
      modalTitleEdit: 'Edit Content',
      name: 'Name',
      textIntro: 'Text Intro',
      textTemplate: 'Text Template',
      title: 'Content Library',
      tooltipAddMessageContent: 'Add message content',
      tooltipAddPerson: 'Add member name',
      tooltipDownload: 'Click to download',
    },
    inactive: 'Inactive',
    includedInBanner: 'Included In Banner',
    includesModal: 'Includes Modal',
    languages: 'Languages',
    learnHow: 'Learn How',
    learnMore: 'Learn More',
    learnWhy: 'Learn Why',
    manager: {
      action: 'Action',
      active: 'Active',
      add: 'Add Campaign',
      addCondition: 'Add Rule',
      addTrigger: 'Add Trigger',
      audience: 'Audience',
      brand: 'Brand',
      caregiver: 'Caregiver',
      conditions: 'Rules',
      conditionsExceptions: 'Conditions/Exceptions',
      content: 'Content',
      deliveryMethod: 'Delivery Method',
      dischargeFacility: 'Facility Discharge',
      dischargeER: 'ER Discharge',
      exceptions: 'Exceptions',
      hasExceptions: 'Has Exceptions',
      id: 'ID',
      nextAvailable: 'Next Available',
      member: 'Member',
      modalTitleAdd: 'Add Campaign',
      modalTitleEdit: 'Edit Campaign',
      name: 'Name',
      onboarded: 'Onboarded',
      PING: 'PING',
      RPM: 'RPM',
      timeUnit: 'Time Unit',
      timing: 'Timing',
      timingDelay: 'Timing Delay',
      timingOption: 'Timing Option',
      title: 'Campaign Manager',
      triggers: 'Triggers',
      vital: 'First Vital',
      warningModalBody:
        'Inactivating a campaign will immediately turn off this campaign for all selected participants. Do you wish to continue?',
      warningMultipleTriggersConditions:
        'When several triggers/rules are added to the campaign, the campaign will only be executed when all of them are met',
    },
    modal: 'Modal',
    modalBody: 'Modal Body',
    modalHeader: 'Modal Header',
    modalImage: 'Modal Image',
    modalName: 'Modal Name',
    modals: 'Modals',
    name: 'Name',
    organizations: 'Organizations',
    referralSource: 'Referral Source',
    selectBannerModal: 'Select banner modal',
    selectedReferrals: 'referral sources selected',
    showCallNurseButton: 'Show call nurse button',
    showModalOnLogin: 'Show modal on login',
    showOnLogin: 'Show On Login',
    status: 'Status',
  },
  careTeam: {
    active: 'Active',
    actions: {
      changeRole: 'Change Role',
      createCandidate: 'Create Care Team Person from Candidate',
      deleteCandidate: 'Delete Care Team Candidate',
      inviteAndroid: 'Send Android invite',
      inviteIOS: 'Send iOS invite',
      sendTextConsent: 'Send text consent',
    },
    add: 'Add Care Team Person',
    all: 'All',
    assignedToMe: 'Assigned To Me',
    backToDashboard: 'Back',
    common: {
      active: 'Active',
      activate: 'Activate',
      address: 'Address',
      address1: 'Address 1',
      address2: 'Address 2',
      agency: 'Agency',
      created: 'Created',
      city: 'City',
      contact: 'Contact',
      daysOfService: 'Dates',
      deleted: 'Deleted',
      details: 'Details',
      email: 'Email',
      engagementOwner: 'Engagement Owner',
      engagementPreference: 'Engagement Preference',
      engagementAppPhone: 'App Authentication Phone',
      expectedDatesOfService: 'Expected Days Of Service',
      externalId: 'External ID',
      faxNumber: 'Fax Number',
      firstName: 'First Name',
      frequency: 'Frequency',
      hci: 'HCI',
      id: 'ID',
      inactive: 'Inactive',
      inactiveReason: 'Inactive Reason',
      language: 'Language',
      languages: 'Languages',
      lastLogin: 'Last Login',
      lastName: 'Last Name',
      linkedMembers: 'Linked Members',
      linkStatus: 'Link Status',
      livesWithMember: 'Lives with Member',
      name: 'Name',
      nameCTPID: 'Name (CTP ID)',
      notes: 'Notes',
      organization: 'Organization',
      otherOrganizationName: 'Organization Name',
      paid: 'Paid',
      permissions: 'Permissions',
      phoneNumber: 'Phone Number',
      phones: 'Phones',
      practiceName: 'Practice Name',
      preferred: 'Preferred',
      preferenceOther: 'Other',
      preferredDateTimes: 'Preferred Days/Times',
      preferredDays: 'Preferred Days',
      preferredTimes: 'Preferred Times',
      providerNotes: 'Notes',
      providerPracticeName: 'Practice Name',
      role: 'Role',
      state: 'State',
      status: 'Status',
      subrole: 'Relationship / Type',
      subroleOther: 'Other Specialty',
      subtypeOther: 'Other Relationship',
      temp: 'Temp',
      temporal: 'Is Temporal',
      tempUntil: 'Temportal Until',
      textConsent: 'Text Consent',
      verified: 'Verified',
      warningNoPhone:
        'Unable to save Preference. Cell phone number is required',
      zip: 'ZIP Code',
    },
    detail: {
      tabs: {
        profile: 'Profile',
        links: 'Links',
      },
      title: 'New Care Team Person',
      titleId: 'CARE TEAM ID #{{id}}',
    },
    errors: {
      healthCareProxyFileLocation:
        'Please include a URL link to the Health Care Proxy file to proceed.',
      invalidAddress: 'Please fill all address fields',
      invalidAddressVerified: 'Please fill all address fields before verifying',
      invalidEmail: 'Please provide a valid email',
    },
    modals: {
      editAddressModalTitle: 'Edit Address for {{name}}',
      editContactModalTitle: 'Edit Contact for {{name}}',
      editEngagementModalTitle: 'Edit Engagement for {{name}}',
      edit: {
        aideFields: 'Aide Details',
        careTeamMember: 'Member',
        careTeamPerson: 'Care Team Member',
        familyFields: 'Family & Friend Details',
        otherFields: 'Other Details',
        providerFields: 'Provider Details',
        title: 'Edit link for {{name}}',
        titleAdd: 'Add link',
      },
      quickAdd: {
        title: 'Add Care Team Person',
        steps: 'Step {{current}} of {{total}}',
        save: 'Save Profile',
        saveAndNew: 'Save & Add New',
        saveAndOpen: 'Save & Open Profile',
      },
      deleteMember: {
        inactivate: 'Inactivate',
        title: 'Inactivate relationship',
        inactiveReasons: 'Inactive reasons',
        inactiveReasonOther: 'Other reason',
      },
    },
    inactive: 'Inactive',
    permissions: {
      campaignEnabled: 'Campaigns',
      emergencyContact: 'Emergency Contact',
      hciNotifications: 'HCI Reminder Texts',
      hciReporter: 'HCI Reporter',
      healthCareProxy: 'Health Care Proxy',
      healthCareProxyFileLocation: 'Health Care Proxy Location',
      notPhiAccess: 'No PHI Access',
      phiAccess: 'PHI Access',
      phiExceptions: 'PHI Expections',
      preferredContact: 'Preferred Contact',
    },
    steps: {
      contact: 'Contact',
      engagement: 'Engagement',
      enterCTPInfo:
        "Enter the care team person's details and relationship to member.",
      linkAMemberBody:
        'Search for a member and to link them to this care person.',
      linkedMembersTitle: 'Linked Members',
      memberAlreadyLinked: 'This member is already linked to the care person',
      notAMatch: 'Not a match?',
      noCarePeopleFoundTitle: 'No Matches Found',
      noCarePeopleFoundBody: 'Continue by creating a new care person',
      noCarePeopleFoundButton: 'Create New Care Team Person',
      noMembersFoundTitle: 'No Members Found',
      noMembersFoundBody: 'Search another member to add',
      selectedCTP: 'Selected care person',
      searchMemberBy: 'Search by Name, Dash ID, Phone Number',
      searchMemberToLink: 'Search for a member to link to this care person.',
      searchCarePerson:
        "Enter the care team person's first and last name to check if they are already registered in DASH.",
      showingResultsFor: 'Showing results for',
      warningChangeRole:
        'Changing the role will deactivate current relationship with the care team person and create a new one.',
    },
    noResults: 'No Care Team Members found.',
    title: 'Care Team',
  },
  ccm: {
    actions: {
      ccm: 'Assign to Worklist',
      clear: 'Clear',
      download: 'Download Filtered Members',
      lastUpdatedTitle: 'LAST UPDATED',
      lastUpdatedTooltip:
        'The CCM Dashboard is refreshed with up-to-date data from eCW at regular intervals during the day',
      logCall: 'Log Call',
      selection: 'Select All',
    },
    common: {
      aideScheduleDays: 'Aide Schedule Days',
      aideScheduleTime: 'Aide Schedule Times',
      attempts: 'Attempts',
      bhiMinutes: 'BHI Minutes',
      bhiMinutesFrom: 'BHI Minutes (From)',
      bhiMinutesTo: 'BHI Minutes (To)',
      bhiTotalMinutes: 'BHI Total Minutes',
      bianualVVCompleted: 'Completed',
      bianualVVNotCompleted: 'Not Completed',
      bianualVVStatus: 'NP Video Visit',
      brand: 'Brand',
      carePlanDue: 'Due: Care Plan',
      carePlanDueNo: 'No (Not Due)',
      carePlanDueYes: 'Yes (Due)',
      carePlanGroup: 'Call Cadence',
      careTeamDiscovery: 'Care Team Discovery',
      ccmMins: 'CCM Mins',
      ccmStatusCompleted: 'Completed',
      ccmStatusAttempted: 'Attempted',
      ccmStatusNotStarted: 'Not Started',
      ccmStatus: 'CCM Status',
      cgScheduleDays: 'CG Schedule Days',
      cgScheduleTime: 'CG Schedule Times',
      clinicalAssistant: 'Clinical Medical Assistant',
      contactPreferenceDate: 'Preferred Dates',
      contactPreferenceTime: 'Preferred Times',
      daysLeft: 'Days Left',
      employeeGroup: 'Worklist Group',
      engagementOwner: 'Engagement Owner',
      excludedprogramExtensions: 'Exclude Program Extensions',
      excludedReferralSources: 'Excluded Referral Sources',
      hasProfessionalTime: 'Has Professional Time',
      hasProfessionalTimeOrTouchedByNp:
        'Has Professional Time Or Touched by NP',
      hasValidCCM: 'Has Valid CCM',
      healthCoach: 'Health Coach',
      initialCP: 'Needs Initial CP',
      insurance: 'Insurance',
      language: 'Language',
      lastAppt: 'Last Appt',
      lastAttempt: 'Last Attempt',
      lastCarePlan: 'Last CP',
      lastCCMTouchpoint: 'Last CCM',
      lastHCI: 'Last HCI',
      lastReading: 'Last Reading',
      lastTouchPointDate: 'Last Touchpoint',
      member: 'Member',
      memberId: 'Member ID',
      memberName: 'Member Name',
      minutes: 'CCM Minutes',
      minsTooltipLine1: 'Professional Time: {{mins}}',
      minsTooltipLine2: 'Staff Time: {{mins}}',
      monthlyCCMStatus: 'Monthly CCM Call',
      monthlyCCMStatusCompleted: 'Completed',
      monthlyCCMStatusRemaining: 'Remaining',
      nextAppt: 'Next Appt',
      npOwner: 'NP Owner',
      numberOfCalls: '# of Calls',
      podManager: 'Pod Manager',
      programExtensions: 'Program Extensions',
      programExtensionStatus: 'Program Extension Status',
      readings: 'Readings',
      recentCycles: 'Recent Cycles',
      referralSources: 'Referral Sources',
      remainingMinutes: 'Min Needed to next CPT',
      riskLevel: 'Risk Level',
      rpmAdherence: 'RPM Adherence',
      rpmMinutes: 'RPM Minutes',
      rpmMinutesFrom: 'RPM Minutes (From)',
      rpmMinutesTo: 'RPM Minutes (To)',
      rpmStartDate: 'RPM Start Date',
      rpmStartDateFrom: 'RPM Start Date (From)',
      rpmStartDateTo: 'RPM Start Date (To)',
      lastReadingDate: 'RPM Last Reading',
      lastReadingDateFrom: 'RPM Last Reading (From)',
      lastReadingDateTo: 'RPM Last Reading (To)',
      rpmTotalMinutes: 'RPM Minutes',
      rnOwner: 'RN Owner',
      selfDirecting: 'Self Directing',
      selfDirectingNo:
        'Remember to identify valid health proxy for this member and get a copy of legal paperwork',
      state: 'State',
      touchpointReasons: 'Touchpoint Reasons',
      unreachable: 'Unreachable',
      validMinutes: 'Valid Mins',
      vestaStartDate: 'Vesta Start Date',
      touchedByNP: 'Touched by NP',
      totalMinutes: 'Total CCM Minutes',
      totalMinutesFrom: 'Total CCM Minutes (From)',
      totalMinutesTo: 'Total CCM Minutes (To)',
      worklistGroup: 'Worklist Group',
    },
    info: {
      membersAssignedTitle: 'Assignment Successful',
      membersAssignedBody: '{{count}} members assigned to "{{group}}"',
      membersUnassignedBody: '{{count}} members unassigned',
    },
    modals: {
      assign: {
        group: 'Worklist group',
        title: 'Assign Worklist Group',
        warning:
          'This action will assign {{count}} members to worklist "{{group}}". Do you wish to continue?',
        warningUnassigned:
          'This action will unassign {{count}} members of the current worklist. Do you wish to continue?',
      },
      info: {
        appointments: 'Appointments',
        appointmentType: 'Appointment Type',
        assignee: 'Assignee',
        caregivers: 'Caregivers',
        careTeam: 'Care Team',
        ccmMins: 'CCM Mins',
        consent: 'Consents',
        consentLink: 'See Consent Details',
        contactInfo: 'Contact Info',
        contacts: 'Contacts',
        date: 'Date',
        description: 'Description',
        dueDate: 'Due Date',
        encounters: 'Recent Encounters',
        encountersLink: 'See all Encounters',
        events: 'Open Events',
        eventsHeader: 'Events',
        eventsLink: 'See all Events',
        firstReading: 'First Reading',
        last3Readings: 'Last 3 Readings',
        noEncounters: 'No encounters in this month or last month',
        notes: 'Notes',
        numberOfDaysWithReadings: '# of Days with readings last 30 days',
        openTasks: 'Open Tasks',
        openTasksLink: 'See all Tasks',
        outcome: 'Outcome',
        phi: 'PHI',
        riskPrograms: 'Risk Programs',
        status: 'Status',
        time: 'Time',
        title: 'Title',
        type: 'Type',
        typeReason: 'Type & Reason',
        valid: 'Valid',
        validMinsTooltip: {
          zero: 'No Valid CCM mins',
          one: '1 Valid CCM min',
          other: '{{count}} Valid CCM mins',
        },
        verbal: 'Verbal',
        vitals: 'Vitals',
        vitalsLink: 'See all Vitals',
        written: 'Written',
      },
    },
    selectedItems: {
      zero: 'No members selected',
      one: '1 member selected',
      other: '{{count}} members selected',
    },
    tabs: {
      bhi: 'BHI',
      ccm: 'CCM',
      rpm: 'RPM',
    },
    title: 'Clinical Dashboard',
  },
  chat: {
    all: 'All',
    archiveConversation: 'Archive Conversation',
    backToMessages: 'Back to Messages',
    caregiver: 'Caregiver',
    caregivers: 'Caregivers',
    careTeam: 'Care Team',
    careTeamPerson: 'Care Team Person',
    createMessage: 'Create Message',
    date: 'Date',
    from: 'From',
    markUnread: 'Mark Unread',
    member: 'Member',
    members: 'Members',
    message: 'Message',
    noConversationsFound: 'No conversations found',
    noCaregiversFound: 'No caregivers found',
    noCareTeamPersonFound: 'No care team person found',
    noMembersFound: 'No members found',
    open: 'Open',
    otherUsers: 'Associated users',
    phoneNumber: 'Phone Number',
    primary: 'Primary',
    searchCaregiver: 'Search caregiver name or CG ID',
    searchCareTeamPerson: 'Search Care Team name or ID',
    searchMember: 'Search member name or Dash ID',
    searchPhoneNumber: 'Search phone number',
    searchMessageType: 'Search message type',
    selectUserType: 'Select User Type',
    sms: 'SMS',
    smsOptOut: 'SMS Opt Out',
    typeToSearch: 'Type to search',
    unread: 'Unread',
  },
  communityResources: {
    add: 'Add Community Resource',
    coverageArea: 'Coverage Area',
    id: 'ID',
    title: 'Community Resources',
    searchByID: 'Search by ID',
    searchByName: 'Search by Name',
    specialities: 'Specialities',
    status: 'Status',
    modal: {
      active: 'Active',
      address1: 'Address 1',
      address2: 'Address 2',
      categoryType: 'Intervention Category',
      city: 'City',
      communityResource: 'Community Resource',
      coverageArea: 'Coverage Area',
      coverageAreaWarning:
        'Please select a state from the Coverage Area dropdown to save this Community Resource.',
      date: 'Hours of Operation',
      mltcPlanCode: 'MLTC/LTSS Plan Code',
      mltcPlanName: 'MLTC/LTSS Plan Name',
      mltcPlanId: 'MLTC/LTSS Plan ID',
      mltcSubscriberId: 'MLTC/LTSS Subscriber ID',
      updateWarning:
        'This community resource is associated with other members. The changes that you made to this community resource profile will be reflected for all of those members. Are you sure you want to proceed?',
      updateWarningCancel: 'No, Back to Editing',
      updateWarningOK: 'Yes, Save Changes',
      updateWarningTitle: 'Please Confirm Changes',
      inactivateWarning:
        'This Community resource is linked to one or more members. Inactivating it from Admin will also remove this community resource from all linked members.',
      insurances: 'Insurances',
      name: 'Name',
      notes: 'Notes',
      phoneNumber: 'Phone Number',
      pocEmail: 'Email',
      pocName: 'Point of Contact - Name',
      pocPhone: 'Phone',
      pocPhoneExt: 'Phone Extension',
      referrals: 'Referrals',
      speciality: 'Specialty',
      state: 'State',
      titleAdd: 'Add Community Resource',
      type: 'Type',
      website: 'Website',
      zip: 'ZIP Code',
      quickAdd: {
        linkExisting: 'Link Existing Resource ',
        save: 'Save',
        steps: 'Step {{current}} of {{total}}',
        title: 'Add Community Resource',
        warningDuplicate:
          "Are you sure? There’s another Community Resource with the name '{{communityResource}}'.",
        yesCreate: 'Yes, Create New',
      },
    },
    steps: {
      notAMatch: 'Not a match?',
      noCommunityResourcesFoundTitle: 'No Matches Found',
      noCommunityResourcesFoundBody:
        'Continue by creating a new Community Resource',
      noCommunityResourcesFoundButton: 'Create new Community Resource',
      selectedCR: 'Selected Community Resource',
      searchCommunityResource:
        "Enter the Community Resources's name or phone number to check if they are already registered in DASH.",
      showingResultsFor: 'Showing results for',
    },
    table: {
      address: 'Address',
      contactInfo: 'Contact Info',
      coverageArea: 'Coverage Area',
      hoursOfOperation: 'Hours of Operation',
      insurances: 'Ins.',
      interventions: 'Int.',
      mltc: 'MLTC/LTSS',
      mltcPlanCode: 'MLTC/LTSS Plan Code',
      mltcPlanName: 'MLTC/LTSS Plan Name',
      mltcPlanId: 'MLTC/LTSS Plan ID',
      mltcSubscriberId: 'MLTC/LTSS Subscriber ID',
      name: 'Name',
      notes: 'Notes',
      phone: 'Phone',
      referrals: 'Ref.',
      status: 'Status',
      type: 'Type',
      website: 'Website',
    },
  },
  components: {
    BrandBanner: {
      label: 'This member is associated with the {{brand}} population.',
      labelVPCVesta: 'This member is shared by both Vesta (CCM) & VPC (PCP).',
    },
    brandMemberIcon: {
      careAtHome: 'Care at Home Member',
    },
    common: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      diastolic: 'Diastolic',
      next: 'Next',
      noResults: 'No Results',
      systolic: 'Systolic',
    },
    editDateTimes: {
      add: 'Add Date & Time',
      days: 'Days',
      endTime: 'To',
      startTime: 'From',
      title: 'Dates',
    },
    editPhones: {
      addAnotherNumber: 'Add another number',
      phoneNumberInvalid: 'Please enter a valid phone number',
      phone: 'Phone',
      primary: 'Primary',
      type: 'Type',
    },
    editStateCities: {
      addAnotherState: 'Add Another State',
      addCity: 'Add City',
      city: 'City',
      coverageArea: 'Coverage Area',
      deleteCity: 'Delete City',
      deleteState: 'Delete State',
      state: 'State',
      zipCode: 'ZIP Code',
    },
    FiltersVestaTeam: {
      clinicalAssistant: 'Clinical Medical Assistant',
      engagementOwner: 'Engagement Owner',
      healthCoach: 'Health Coach',
      npOwner: 'NP Owner',
      podManager: 'Pod Manager',
      rnOwner: 'RN Owner',
      title: 'Vesta Team',
    },
    loading: 'Loading',
    mobileInvite: {
      android: 'Send Android invite',
      ios: 'Send iOS invite',
      sent: 'Sent',
      sms: 'Send text consent',
    },
    noteSection: {
      addNotes: 'Add Notes',
      modalEdit: 'Edit Note',
      modalAdd: 'Add Note',
      note: 'Note',
    },
    Panel: {
      FilterBar: {
        clearAll: 'Clear All',
        other: 'Other',
        preferred: 'Preferred',
        seeAll: 'See All Filters',
      },
      FilterModal: {
        addNewFilter: 'Add New Filter',
        delete: 'Delete',
        deleteBody: 'Are you sure you want to delete "{{filter}}" filter?',
        deleteTitle: 'Delete Filter',
        editTitle: 'Edit Filter',
        filter: 'Filter',
        filterName: 'Filter Name',
        noFiltersStored: 'No filters stored yet',
        other: 'Other',
        preferred: 'Preferred',
        save: 'Save',
        saveTitle: 'Save Filter',
      },
    },
    panelFilters: {
      title: 'Filters',
      apply: 'Apply',
      reset: 'Clear All Filters',
    },
    searchCommunityResources: {
      typeToSearch: 'Type to search',
      noMembersFound: 'No community resources found',
      noItemsFound: 'No items found',
    },
    searchMembers: {
      typeToSearch: 'Type to search',
      noMembersFound: 'No members found',
      noItemsFound: 'No items found',
    },
    select: {
      id: 'ID',
    },
    selectAssignee: {
      assignToMe: 'Assign To Me',
    },
    table: {
      defaultSorting: 'Default Sorting',
    },
    toggleDatePicker: {
      custom: 'Custom',
      label: 'Date Range',
      all: 'All',
      day: {
        one: '{{count}} day',
        other: '{{count}} days',
      },
    },
  },
  contactInfo: {
    title: 'Contact Info',
  },
  taskDefinitions: {
    active: 'Active',
    add: 'Add Task Template',
    addSubTask: 'Add task',
    addRule: 'Add rule',
    created: 'Created',
    createdBy: 'Created By',
    empty: 'No task templates added yet',
    group: 'Group',
    name: 'Name',
    outcomes: 'Outcomes',
    publish: 'Publish',
    reference: 'Reference',
    saveDraft: 'Save Draft',
    status: 'Status',
    title: 'Tasks',
    taskStatus: {
      DRAFT: 'Draft',
      INACTIVE: 'Inactive',
      PUBLISHED: 'Published',
    },
    task: {
      title: 'Task Name',
    },
    subtask: {
      addChoice: 'Add Choice',
      addField: 'Add a field',
      addSubtask: 'Add Sub Task',
      addOutcome: 'Add Outcome',
      choices: 'Choices',
      customFields: 'Custom Fields',
      dateLabel: 'Date Label',
      dragdropOutcome: 'Drag and drop to reorder an outcome',
      dragdropSubtask: 'Drag and drop to reorder an sub tasks',
      aboutPrecedents:
        'To enable precedents editing you need to save all subtasks',
      dropdownLabel: 'Dowpdown Label',
      list: 'Sub Tasks',
      multipleSelect: 'Multiple Select Dropdown',
      noChoicesError: 'At least 2 choices are required',
      noCustomFieldsError: 'Custom fields are required',
      noOutcomesError: 'Outcomes are required',
      noValidSubtask: 'Some Subtask are not valid',
      outcomeName: 'Outcome Name',
      requiredField: 'Required field',
      timeLabel: 'Time Label',
      name: 'Sub Task Name',
      inactiveWithPrecedent:
        'If you inactive this task all dependencies will be removed.',
      dependsOn: 'Depends on',
    },
  },
  clinicalLicensure: {
    mismatch: 'Licensure Mismatch',
    warning: `Warning! According to system records, you are not licensed to practice in this member's state.`,
    yourLicense: 'Your licensed States: ',
    memberResidence: `Patient's State of Residence: `,
    pleaseContact: `Please contact your manager to resolve this.`,
  },
  clinicalDashboard: {
    sidebar: {
      ccm: 'Clinical Dashboard',
      dashboard: 'Dashboard',
      events: 'Events',
      healthReports: 'Health Check-Ins',
      members: 'Members',
      podReporting: 'Pod Reporting',
      tasks: 'Tasks',
      toc: 'ToC / Skilled',
    },
    tasks: {
      filters: {
        assignee: 'Assigned To',
        brand: 'Brand',
        completedDate: 'Completed Date',
        createdAt: 'Created At',
        createdBy: 'Created By',
        dueDate: 'Due Date',
        group: 'Group',
        language: 'Language',
        organization: 'Referral Source',
        programExtensions: 'Program Extensions',
        programExtensionStatus: 'Program Extension Status',
        status: 'Status',
        type: 'Type',
        vitalCollectionMethod: 'RPM Vendor source',
      },
      table: {
        assignee: 'Assignee',
        completedDate: 'Completed',
        created: 'Created',
        description: 'Description',
        dueDate: 'Due Date',
        group: 'Group',
        member: 'Member',
        status: 'Status',
      },
      tabs: {
        all: 'All',
        assigned: 'Assigned to Me',
        closed: 'Closed',
        open: 'Open',
      },
      detail: 'Task Detail',
      title: 'Tasks',
    },
  },
  common: {
    after: 'After',
    assignToMe: 'Assign to me',
    before: 'Before',
    completed: 'Completed',
    created: 'Created',
    daysAgo: {
      one: '{{count}} day ago',
      other: '{{count}} days ago',
    },
    due: 'Due',
    errors: {
      minValue: 'Min value: {{value}}',
      maxValue: 'Max value: {{value}}',
      invalidEmail: 'Please provide a valid email',
      invalidPhone: 'Please provide a valid phone',
      invalidURL: 'Please provide a valid URL',
    },
    memberName: 'Member name',
    member: 'Member',
    moreInfo: 'More Info',
    note: 'Note',
    npOwner: 'NP Owner',
    select: 'Select',
    sortBy: 'Sort By',
    started: 'Started',
    riskLevel: '{{risk}} risk',
  },
  contacts: {
    add: 'Add contact',
    addLanguage: 'Add Language',
    address: 'Address',
    clickToAdd: 'Click to add a contact.',
    contactsFor: 'Contacts for',
    contactNote: 'Contact Note',
    deleteContact: 'Delete contact?',
    deleteContactBody: 'This contact will be permanently removed.',
    doNotSharePHI: 'Do NOT share PHI',
    edit: 'Edit contact',
    email: 'Email',
    empty: 'No contacts added.',
    emergencyContact: 'Emergency contact',
    enterFaxNumber: 'Enter a Fax number',
    enterPhoneNumber: 'Enter a phone number',
    faxNumber: 'Fax number',
    firstName: 'First name',
    healthProxy: 'Self-identified health proxy',
    languages: 'Language(s)',
    languageUnknown: 'Language Unknown',
    lastName: 'Last name',
    member: 'Member',
    otherPhysician: 'Other physician type',
    otherType: 'Other type',
    phiAccess: 'PHI Access',
    phiOk: 'PHI OK',
    phoneNumber: 'Phone number',
    phoneNumbers: 'Phone numbers',
    preferred: 'Preferred',
    type: 'Type',
    validHealthProxy: 'Valid health proxy',
    yesDelete: 'Yes, delete',
  },
  dashboard: {
    all: 'All',
    caregiver: 'Caregiver',
    completed: 'Completed',
    healthReports: 'Health Check-Ins',
    inProgress: 'In Progress',
    noReportsFound: 'No health check-ins found for the selected date range.',
    noReportsFoundStatus:
      'No {{status}} health check-ins found for the selected date range.',
    patient: 'Member',
    progress: 'Progress',
    questions: 'Questions',
    started: 'Started',
  },
  dashboardEvents: {
    filters: {
      assignee: 'Assignee',
      brand: 'Brand',
      careCoordinator: 'Clinical Medical Assistant',
      everEscalated: 'Ever Escalated',
      language: 'Language',
      npOwner: 'NP Owner',
      pendingIncident: 'Missing Incident Report',
      podManager: 'Pod Manager',
      programExtension: 'Program Extension',
      programExtensionStatus: 'Program Extension Status',
      referralSource: 'Referral Sources',
      riskLevel: 'Risk Level',
      rnOwner: 'RN Owner',
      state: 'State',
      status: 'Status',
      testMember: 'View Test members',
      type: 'Type',
      vitalsCollectionMethod: 'RPM Vendor Source',
    },
    sort: {
      createdNewest: 'Created: Newest',
      createdOldest: 'Created: Oldest',
      eventNewest: 'Event Date: Newest',
      eventOldest: 'Event Date: Oldest',
      followUp: 'Follow-up Info',
      urgency: 'Most Urgent',
    },
    table: {
      assignee: 'Assignee',
      created: 'Created',
      event: 'Event',
      followUpInfo: 'F/U Info',
      lastNote: 'Last Note',
      member: 'Member',
      programExtensions: 'Program Ext.',
      referralSources: 'Referral Sources',
      status: 'Status',
    },
    tabs: {
      all: 'All',
      assignedToMe: 'Assigned to me',
      escalated: 'Escalated',
      open: 'Open',
      urgentFollowUp: 'Urgent Follow-Up',
      nonUrgentFollowUp: 'Non Urgent Follow-Up',
      unassigned: 'Unassigned',
      vitalsAbnormal: 'Vitals Recheck Abnormal',
    },
    sortBy: 'Sort By',
    title: 'Events',
  },
  eligibility: {
    table: {
      completed: 'Completed',
      date: 'Date',
      description: 'Description',
      inProgress: 'In Progress',
      records: 'Records',
      status: 'Status',
      error: 'Error',
    },
    tabs: {
      active: 'Active',
      passive: 'Passive',
    },
    title: 'Eligibility',
  },
  employees: {
    add: 'Add Employee',
    title: 'Employees',
    filters: {
      active: 'Active',
      groups: 'Groups',
      id: 'ID',
      mail: 'Email',
      name: 'Name',
      permission: 'Permission',
      phiAccess: 'PHI Access',
      role: 'Role',
      state: 'State Licensure',
    },
    modal: {
      ecwId: 'eCW ID',
      email: 'Email',
      employeeLead: 'Employee Lead',
      emailError: 'Cannot accept email domain',
      enabled: 'Enabled',
      firstName: 'First Name',
      groups: 'Groups',
      incidents: 'Incident Review required',
      language: 'Language(s) Spoken',
      lastName: 'Last Name',
      lead: 'Lead',
      nlc: 'Nurse Licensure Compact member',
      permission: 'Permission',
      phi: 'PHI Access',
      role: 'Role',
      sfId: 'Salesforce ID',
      state: 'State',
      titleAdd: 'Add Employee',
      titleEdit: 'Edit Employee',
      updateLeadWarning:
        '{{employee}} is a lead and has {{count}} employees linked to their lead account. You must choose a replacement Lead for all associated employees to avoid errors in DASH.',
    },
    table: {
      active: 'Active',
      email: 'Email',
      groups: 'Groups',
      id: 'ID',
      impersonate: 'Impersonate this user',
      incidents: 'Incidents',
      language: 'Language(s) Spoken',
      lead: 'Lead',
      links: 'Links',
      name: 'Name',
      permission: 'Permission',
      phiAccess: 'PHI',
      role: 'Role',
      state: 'State Licensure',
    },
  },
  encounters: {
    addEncounter: 'Add Encounter',
    addSuccess: 'Time successfully added to the Encounter Time Tracking page.',
    billingCycle: 'Billing Cycle',
    caregiver: 'Caregiver',
    channel: 'Channel',
    clinician: 'Clinician',
    clinicalMinutes: 'Clinical Minutes',
    contact: 'Contact',
    createdBy: 'Created by',
    dateOccurred: 'Date Occurred',
    dateOccurredStart: 'Starting Occurred Date',
    dateOccurredEnd: 'Ending Occurred Date',
    direction: 'Direction',
    duration: 'Duration',
    documentInElation: 'I will document in Elation',
    gotoSource: 'Go to source',
    invalidateBody:
      'Are you sure you want to delete this entry? This action cannot be undone.',
    invalidateConfirm: 'Yes, invalidate',
    invalidatedAt: 'Invalidated at {{date}}',
    invalidateHeader: 'Invalidate Entry?',
    invalidEncounters: 'Invalid Encounters',
    method: 'Method',
    methodOutcome: 'Outcome',
    minutes: '{{minutes}} minutes',
    modal: {
      add: 'Add Encounter',
      edit: 'Edit Encounter',
      save: 'Save Encounter',
      show: 'Show Encounter',
    },
    notes: 'Notes',
    otherChannel: 'Other Channel',
    otherContact: 'Contact Name',
    otherContactReferral: 'Referral affiliation',
    noEncounters: 'No valid encounters',
    note: 'Note',
    otherReason: 'Other Reason',
    providerMinutes: 'Provider Minutes',
    reason: 'Reason',
    remaining: 'remaining',
    showInvalid: 'Show invalid',
    showAll: 'Show all',
    source: 'Source',
    time: 'Minutes',
    timeLog: '{{month}} {{year}} Time Log',
    timeOccurred: 'Time Occurred',
    timeOverview: '{{month}} {{year}} Overview',
    title: 'Encounter Time Tracking',
    totalMinutes: 'Total Minutes',
    type: 'Type',
    typeReason: 'Type/Reason',
    valid: 'Valid',
  },
  events: {
    active: 'Active',
    addEvent: 'Add Event',
    created: 'Created',
    escalationCriteria: {
      title: 'NP Escalation Criteria',
      firstCriteria:
        'If the member alerts 2 times in a week for the same alert type or symptom',
      secondCriteria: 'Alert should be urgent and validate',
      thirdCriteria: {
        title: 'Blood Pressure',
        firstItem: 'BP outside of parameters with symptoms',
        secondItem: 'BP greater than 175/95 without symptoms',
        thirdItem: 'BP lower than 90/60 without symptoms',
      },
      forthCriteria: {
        title: 'Heart Rate',
        firstItem: 'HR outside of parameters with symptoms',
        secondItem: 'HR of greater than 100 without symptoms',
        thirdItem: 'HR of less than 50 without symptoms',
      },
      fifthCriteria: {
        title: 'Pulse Ox',
        firstItem:
          'O2 sat% - (No COPD)  If less than 94% or outside of parameters with symptoms',
        secondItem:
          'O2 sat% - (With COPD) If less than 90% or outside of parameters with symptoms (COPD)',
      },
      sixthCriteria: {
        title: 'Weight',
        firstItem:
          'CHF with weight gain of 1 lb or more with or without symptoms',
      },
      seventhCriteria: {
        title: 'Hyperglycemia',
        firstItem: 'Member with s/s of hyperglycemia with any glucose reading',
        secondItem:
          'Member with glucose reading > 250 x two with or without symptoms',
      },
      eightCriteria: {
        title: 'Hypoglycemia',
        firstItem:
          'Member with a glucose reading of less than 70 after 15/15 rule',
        secondItem:
          'Follow the 15/15 rule after you’ve determined they can drink or swallow: Give 15 gms of fast acting carbohydrates (4 oz juice or soda, 3-4 glucose tablets, or 1 tube of gel). Wait 10-15 minutes. Repeat glucose reading and if still < 100, repeat 15 grams of fast acting carbohydrates until reading is > 100.',
      },
      ninethCriteria: {
        title: 'If member has any of the following symptoms: ',
        firstItem: 'Chest pain',
        secondItem: 'Shortness of Breath',
        thirdItem: 'Increased swelling',
        forthItem: 'Fall with untreated injury',
      },
    },
    eventMapped: 'Event Mapped',
    eventMapping: 'Event Mapping',
    inactive: 'Inactive',
    incidentTooltip: 'Please submit an incident report for this event',
    interventions: {
      active: 'Active',
      add: 'Add Intervention',
      addModalTitle: 'Add Intervention',
      description: 'Description',
      category: 'Category',
      editModalTitle: 'Edit Intervention',
      intervention: 'Intervention',
      status: 'Status',
      title: 'Interventions',
    },
    mapEvent: 'Map Issue Types',
    mapping: {
      confirmModalTitle: 'Confirm event mapping',
      confirmModalBody: {
        one:
          'The {{events}} issue type is already mapped. Are you sure you want to update the mapping?',
        other:
          'The following issue types ({{events}}) are already mapped. Are you sure you want to update the mapping?',
      },
      eventMapping: 'Event',
      issueType: 'Issue Type',
      map: 'Map',
      modalTitle: 'Map Issue Types',
      title: 'Event Mapping/Config',
    },
    rules: {
      add: 'Add new operation',
      days: 'Days',
      diastolic: 'Diastolic',
      modalTitle: 'Configure {{type}} urgency',
      operation: 'Operation',
      systolic: 'Systolic',
      urgency: 'Urgency',
      urgencyLevel: 'Urgency level',
      urgent: 'Urgent',
      value: 'Value',
      warningInvalidRange: 'Invalid range values',
    },
    name: 'Name',
    settings: 'Settings',
    status: 'Status',
    timeConfig: 'Time enabled',
    timeConfigOption: 'Time option',
    types: {
      addModalTitle: 'Add Event',
      editModalTitle: 'Edit Event',
      title: 'Events',
    },
    updated: 'Updated',
    urgency: 'Urgency',
    urgencyLevel: 'Urgency Level',
    warningInvalidateEventType:
      'This event type has alert types mapped to it. Inactivating it will unmap the alert types. Do you wish to continue?',
  },
  rosterIngestion: {
    dragAndDrop: 'Drag & Drop file or',
    clickHere: 'click here',
    inProgress: 'In Progress',
    notAvailable: 'Not Available',
    previewBelow: 'Preview {{fileName}} below.',
    profilesWillBeCreated: {
      one:
        'If you press Upload, {{count}} {{identifierType}} profile will be created/updated.',
      other:
        'If you press Upload, {{count}} {{identifierType}} profiles will be created/updated.',
    },
    successfullyAdded: '{{fileName}} successfully added!',
    profilesCreated: {
      one: '{{count}} {{identifierType}} profile has been created.',
      other: '{{count}} {{identifierType}} profiles have been created.',
    },
    cannotCreateUsingThisCSV: 'You cannot create members using this CSV as is.',
    careTeamTemplateFile: 'Care Team template file',
    deprecatedCaregiverFile:
      'Since the Care Team feature was released, the caregivers roster files are deprecated. Please add this information on the care team administration page.',
    possibleDuplicate:
      'Row {{row}}: Possible duplicate of {{identifierType}} {{indentifier}} {{extraMsg}}.',
    updateEntityWarning: 'If you proceeed the entity will be updated!',
    validMemberFound: 'Valid member found {{id}}',
    validCTPFound: 'Valid CT Person found {{id}}',
    rowError: 'Row {{row}}: {{errorMsg}}',
    followingMustBeFixed:
      'Your CSV contains {{count}} errors that must be fixed.',
    followingWarnings:
      'Your CSV contains {{count}} warnings that you might need to review.',
    followingInfo: 'Your CSV contains {{count}} info messages',
    uploadedFileSize: '* Uploaded files may have up to {{limit}} rows',
    uploadFile: 'Upload File',
    uploadFileDescription: 'Description',
    fileSizeError:
      'The file you are trying to upload is too large. A roster file may only contain a maximum of {{limit}} rows.',
    fileEmptyError: 'The file you are trying to upload seems empty.',
    invalidPhonesError:
      'The following phone numbers must be fixed before upload can continue:',
    waitForUploadWarning:
      'Please wait for the current roster upload to finish before uploading a new roster.',
    errorValidating:
      'We are not able to validate your file, try uploading your file again.',
    extraColumnsWarning:
      "The following columns from the file aren't recognized but may still be added to the created member profile:",
    missingColumnsError:
      'The following columns are missing from the file and must be provided:',
    memberTemplateFile: 'Member template file',
    caregiverTemplateFile: 'Caregiver template file',
    futureLead: 'Hold patients in ‘Future Lead’ CRM Status',
    futureLeadTooltip:
      'Enabling this functionality will lock members in the ‘Future Lead’ CRM status in Salesforce - to release these locked members for outreach, refer to the ‘Member Lead’ column in the Rosters table.',
    linkFiles: 'Link Files',
    linkFilesAndUpload: 'Link Files and Upload',
    linkToRawFile: 'Link roster to original file',
    linkFileDescription:
      'Use the list below to Link roster to original file received for proper reporting purposes:',
    skipRowWarning: 'This row will be skipped',
    shouldReplaceCT: 'Replace Care Team based on Relationship',
    shouldReplaceCTTooltip:
      'If a new care team person is uploaded for a member who currently has a care team person with the same relationship, the current care team person will be deactivated and replaced by the new care team person from this roster.',
    termByAbsence: 'Term By Absence',
    filters: {
      leadStatus: 'Lead Status',
      referralSources: 'Referral Sources',
    },
    releaseToSFModal: {
      body:
        'Are you sure you want to release the members from this roster to the outreach team? This will update each member’s CRM Status in Salesforce to align with their insurance & lead qualification details.',
      cancel: 'No, Cancel',
      submit: 'Yes, Release to Outreach',
      title: 'Confirm Release to Outreach',
    },
    table: {
      comparisonReport: 'Comparison Report',
      description: 'Description',
      download: 'Download',
      ingestionStatus: 'Ingestion Status',
      leadStatus: 'Lead Status',
      records: '# of Records',
      referral: 'Referral',
      rosterId: 'Roster ID',
      tbaStatus: 'TBA Status',
      type: 'Type',
      upload: 'Upload By',
    },
    tabs: {
      careTeamIngestion: 'Care Team',
      memberIngestion: 'Member',
    },
  },
  facilities: {
    add: 'Add Facility',
    edit: 'Edit Facility',
    title: 'Facilities',

    modal: {
      addTitle: 'Add Facility',
      brand: 'Brand',
      ecwId: 'eCW Facility’s ID',
      deleteTitle: 'Delete Facility',
      deleteBody: 'Are you sure you want to remove "{{facility}}"?',
      editTitle: 'Edit {{facility}} Facility',
      insurances: 'Insurances',
      memberAssociationDescription:
        'Apply the following Insurance, Referral Source, or Brand filters as needed to select the members who should be associated with this facility',
      name: 'Facility Name',
      npi: 'Facility NPI',
      oneOfTheseFieldsMustBeFilled: 'One of these fields cannot be empty',
      referralSources: 'Referral Sources',
      type: 'Type',
      state: 'State',
    },

    table: {
      id: 'Dash ID',
      ecwId: 'eCW ID',
      facility: 'Facility Name',
      type: 'Type',
      npi: 'NPI',
      state: 'State',
      updatedBy: 'Update By',
    },
  },
  formBuilder: {
    addComment: 'Add Comment',
    labelOtherPhoneNumber: 'Other Phone Numbers',
    labelOtherPhoneType: 'Phone Type',
    labelPrimaryPhoneNumber: 'Primary Mobile Phone',
    labelPrimaryPhoneType: 'Mobile Phone Type',
    placeholderNumber: 'Enter a phone number',
    placeholderType: 'Select type of number',
    readOnly: 'Read Only',
    required: 'This field is required',
  },
  global: {
    add: 'Add',
    addAnother: 'Add another',
    addAnotherLanguage: 'Add another language',
    addAnotherNumber: 'Add another number',
    addExternalId: 'Add External Id',
    addMember: 'Add Member',
    addNew: 'Add new',
    addOther: 'Add other',
    after: 'After',
    all: 'All',
    assessments: 'Assessments',
    assignToMe: 'Assign to Me',
    assignToNp: 'Assign to NP Owner',
    assignToRn: 'Assign to RN Owner',
    atTime: 'at {{time}}',
    authenticatedUserMismatch: 'Authenticated user mismatch',
    authenticatedUserMismatchDetail:
      'The window will reload in order to update your user info.',
    automatedMessage: 'Vesta Healthcare Automated Message',
    automatic: 'Automatic',
    back: 'Back',
    before: 'Before',
    cancel: 'Cancel',
    changesCannotBeSubmitted: 'Changes cannot be submitted',
    chooseADate: 'Choose a Date',
    chooseATime: 'Choose a Time',
    chooseAPlatform: 'Choose a Platform',
    chooseAStatus: 'Choose a Status',
    clear: 'Clear',
    clearFilters: 'Clear Filters',
    clearSelection: 'Clear Selection',
    close: 'Close',
    conversationClosedAt: 'Conversation closed at {{at}}',
    conversationClosedBy: 'Conversation closed at {{at}} by {{by}}',
    comment: 'Comment',
    complete: 'Complete',
    confirm: 'Confirm',
    confirmRemove: 'Do you confirm removing this item?',
    contactUs:
      'If this reoccurs, please contact us via Slack #product_analytics_requests or email eng@vestahealthcare.com so we can help.',
    continue: 'Continue',
    copy: 'Copy',
    copyPhone: 'Copy Phone Number',
    copied: 'Copied',
    createdAt: 'Created at',
    createdBy: 'Created by',
    currentOfTotal: '{{current}} of {{total}}',
    date: 'Date',
    days: 'days',
    daysCount: {
      one: '1 day',
      other: '{{count}} days',
    },
    delete: 'Delete',
    del: 'Del',
    deselectAll: 'Deselect all',
    deselectAllFiltered: 'Deselect all filtered',
    disabledDueToMaintenance: 'Disabled due to maintenance',
    duration: 'Duration',
    edit: 'Edit',
    employees: 'Employees',
    emptyResults: 'No results',
    endTime: 'End Time',
    enterPhoneNumber: 'Enter a phone number',
    enterText: 'Enter text',
    enterValue: 'Enter a value',
    expand: 'Expand',
    export: 'Export',
    failed: 'Failed',
    faxNumberInvalid: 'Fax number must be 10 digits',
    filter: 'Filter',
    filters: {
      one: '1 Filter',
      other: '{{count}} Filters',
    },
    filtersApplied: '{{count}} filters applied',
    from: 'From',
    genericError: 'Something went wrong. Try again',
    groups: 'Groups',
    hoursCount: {
      one: '1 hour',
      other: '{{count}} hours',
    },
    info: 'Info',
    invalidate: 'Invalidate',
    keep: 'Keep',
    lastWeek: 'Last Week',
    language: 'Language',
    lastUpdated: 'Last updated {{date}}',
    lastUpdatedBy: 'Last updated {{date}} by {{editor}}',
    leaveWithoutSaving: 'Leave Without Saving',
    link: 'Link',
    loggingIn: 'Logging in...',
    location: 'Location',
    locked: 'Locked',
    manual: 'Manual',
    markUnread: 'Mark unread',
    member: 'Member',
    minMaxNumberInvalid: 'Min should be greater than max',
    missingRequiredField: 'This field is required',
    missingRequiredFieldMin: 'Required',
    na: 'N/A',
    name: 'Name',
    navPromptBody:
      'You have unsaved changes. If you go to another page without saving, your changes will be lost.',
    navPromptTitle: 'Leave Without Saving?',
    newUpdate: 'A new update is available.',
    next: 'Next',
    nextWeek: 'Next Week',
    new: 'New',
    no: 'No',
    noAnswer: 'No answer',
    none: 'None',
    noReferral: 'No Referral',
    noResults: 'No Results',
    noResultsFound: 'No Results Found',
    note: 'Note',
    notes: 'Notes',
    notesOptional: 'Notes (optional)',
    notesRequired: 'Notes (required)',
    notLoggedIn1:
      'You are not logged in. Automatically redirecting you to login...',
    notLoggedIn2: 'Click here to login if you are not redirected.',
    notVerified: 'Not Verified',
    numbersNotAllowed: 'Numbers are not allowed',
    offline: 'You’re offline. Trying again in',
    offsite: 'Offsite',
    ok: 'OK',
    open: 'Open',
    optional: 'Optional',
    other: 'Other',
    patientRequired: 'Member required',
    patients: 'Members',
    pending: 'Pending',
    phoneNumberInvalid: 'Please enter a valid phone number',
    primary: 'Primary',
    preferred: 'Preferred',
    externalIdInvalid: 'Please enter a external id',
    externalIdTypeInvalid: 'Please enter a source type',
    externalId: 'External Id',
    externalIdSource: 'Source',
    pleaseMakeSelection: 'Please make a selection',
    pleaseSelectOne: 'Please select one.',
    pleaseSelectOption: 'Please select an option.',
    positiveNumberInvalid: 'Please enter a positive number',
    prev: 'Prev',
    preview: 'Preview',
    previous: 'Previous',
    refresh: 'Refresh',
    refreshToSeeChanges:
      '"Refresh" to see those new updates. You can then edit to make any additional changes.',
    reload: 'Reload',
    remove: 'Remove',
    required: 'Required',
    results: 'Results',
    retry: 'Retry',
    rn: 'RN',
    save: 'Save',
    saveChanges: 'Save Changes',
    saveAndContinue: 'Save and Continue',
    search: 'Search',
    seeAll: 'See All',
    select: 'Select',
    selected: 'selected',
    selectAll: 'Select all',
    selectAllFiltered: 'Select all filtered',
    selectPatient: 'Select member',
    selectResponse: 'Select response',
    send: 'Send',
    sendAppInvite: 'Send App Invite',
    sent: 'Sent',
    settings: 'Settings',
    startTime: 'Start Time',
    startTyping: 'Start typing something',
    status: 'Status',
    submit: 'Submit',
    success: 'Success!',
    time: 'Time',
    to: 'to',
    today: 'Today',
    type: 'Type',
    typeOther: 'Type other',
    total: 'Total',
    unassigned: 'Unassigned',
    unknown: 'Unknown',
    upload: 'Upload',
    unchanged: 'Unchanged',
    uniqueConstraintField: 'This field should be unique',
    updateAvailable: 'New update available, click this banner to update dash.',
    download: 'Download',
    verified: 'Verified',
    warning: 'Warning!',
    weAreSorry: 'We’re sorry, please try again.',
    with: 'with',
    yearsOld: '{{age}}yo',
    yes: 'Yes',
    youAreOffline: 'You’re Offline',
    you: 'You',
    cityFormatError: 'Only alpha characters allowed',
    mailFormatError: 'This is not a valid mail address',
    zipFormatError: '5 digit minimum required. Only numbers and - are allowed',
  },
  goalsOfCare: {
    addGoal: 'Add Goal',
    editGoal: 'Edit Goal',
    emptyState: 'No goals added yet.',
    goal: 'Goal',
    goals: 'Goals',
    goalsOfCare: 'Chronic Care Goals',
    goalType: 'Goal Type',
    hideTips: 'Hide Tips & Examples',
    otherType: 'Other Type',
    outcomeNote: 'Outcome Note',
    noGoals: 'Set Goals',
    setGoals: '{{numGoals}} Set Goals',
    showTips: 'See Tips & Examples',
    status: 'Status',
    strengthsBarriers: 'Strengths / Barriers',
    targetDate: 'Target Date',
    tips: [
      "Ask the member about their goals and what's needed to reach their goals.",
      'Identify strengths that can help them reach goals, like supportive family relationships. ',
      'Identify barriers to achieving their goals - like physical disabilities or financial difficulties.',
      'Make sure goals are specific and attainable.',
      'Break long-term goals into discrete steps, and make large generic goals into smaller supportive goals.',
      "Suggest measurable clinical goals that tie to member's goals - like controlling blood pressure levels so that the member can stay healthy.",
      'If a stated goal is "I want to stay healthy", ask what that means. For some, it could mean staying out of the hospital, for others it could mean being able to go for walks every day or being able to maintain their garden.',
      'Write goals in personalized language that the member can also easily understand, for example: "Sally will lose 15 pounds over the next 6 months, so that she can be more independent"',
    ],
    type: 'Type',
    whatAreStrengthsBarriers:
      'What are strengths that can help member achieve goals? What are barriers that might hinder them?',
  },
  groups: {
    addGroup: 'Add Group',
    addModalTitle: 'Add Group',
    active: 'Active',
    createdBy: 'Created',
    editModalTitle: 'Edit Group',
    inactive: 'Inactive',
    name: 'Name',
    updatedBy: 'Updated',
    status: 'Status',
    title: 'Groups',
    type: 'Type',
  },
  fileHistory: {
    title: 'File History',
    table: {
      id: 'ID',
      name: 'Name',
      date: 'Date',
      originalFile: 'Orig. File',
      transferDate: 'Transfer',
      source: 'Source',
      destination: 'Destination',
    },
    dateFileRecieved: 'Date file received',
    destinationOrganization: 'Destination',
    fileDateFrom: 'File Date From',
    fileDateTo: 'File Date To',
    fileName: 'File Name',
    noFiles: 'No valid archive files',
    referralSource: 'Referral source',
    sourceOrganization: 'Source',
    startDate: 'Start Date',
    transferDateFrom: 'Transfer Date From',
    transferDateTo: 'Transfer Date To',
    uploadFile: 'Upload file',
    uploadReferralFile: 'Upload referral file',
    uploadReferralSourceFile: 'Upload referral source file',
  },
  healthReports: {
    add: 'Add Check-In',
    table: {
      completion: 'Completion',
      date: 'Date',
      direction: 'Direction',
      enteredBy: 'Entered By',
      member: 'Member',
      method: 'Method',
      reporter: 'Reporter',
    },
    detail: 'Check-In detail',
    direction: 'Direction',
    hciNotifications: 'HCI Reminder Texts',
    method: 'Method',
    reporter: 'Reporter',
    title: 'Health Check-Ins',
  },
  home: {
    rn: {
      patientName: 'Member Name',
    },
    sidebar: {
      events: 'Events',
      tasks: 'Tasks',
    },
  },
  houseHold: {
    modal: {
      member: 'Member',
      relationship: 'Relationship',
      titleAdd: 'Add Household Member',
    },
    table: {
      member: 'Member',
      relationship: 'Relationship',
    },
  },
  icdDetection: {
    getICDCodes: 'Get ICD Codes',
    group: 'group',
    title: 'ICD Detection',
    score: 'Score',
  },
  incidents: {
    title: 'Incidents',
    common: {
      actionsDate: 'Date of Actions',
      actionsOther: 'Other Action',
      admitDate: 'Admit Date',
      anticoagulantQuestion: 'Does member take a blood thinner? ',
      changeQuestion: 'What is the change?',
      description: 'Description',
      dmeQuestion: 'DME in use?',
      dmeTypeQuestion: 'DME Type used?',
      dischargeDate: 'Discharge Date',
      evaluatedQuestion: 'Was member evaluated?',
      entityNotified: 'Federal / State Entity Notified',
      facilityName: 'Facility Name',
      followUp: 'Follow up Appointment',
      immediateActions: 'Immediate Action Taken',
      incidentDate: 'Date of Incident',
      incidentType: 'Incident Type',
      involvedQuestion: 'Who was involved in the incident?',
      insurancePlan: 'Insurance Plan',
      kindHelpQuestion: 'What kind of additional help is required?',
      location: 'Location',
      locationQuestion: 'Location of Incident?',
      medicationChange: 'Medication Change',
      medicationChangeDesc: 'Medication Change Description',
      member: 'Member',
      needsAdditionalHelpQuestion: 'Does member require additional help?',
      npOwner: 'NP Owner',
      partnerNote: 'Partner Note',
      readonlyDescription: 'Initial Description',
      reportDate: 'Date Reported to Clinical Team',
      rnOwner: 'RN Owner',
    },
    detail: {
      addEmail: 'Add Email Address',
      additionalEmails: 'Additional Email Addresses',
      addNote: 'Add Status Note',
      clientCardTitle: 'Client Details',
      copyEmails: 'Copy Email Addresses',
      disabledEditAssignee:
        'You must be assigned to the incident to perform this action',
      disabledEditStatus: 'The incident must be opened to perform this action',
      incidentCardTitle: 'Incident Details',
      memberCardTitle: 'Member Details',
      noEmails: 'No emails registered',
      openEvent: 'Open Event',
      title: 'Review Incident #{{id}}',
      memberReferralSource: 'Referral Source & ID',
      selectSourceTitle: 'Select Source',
      selectSourceBody: 'Select source to be included in the report',
      send: 'Send to Client',
      source: 'Source',
      subtitle: 'Reported {{at}} by {{by}}',
    },
    email: {
      actions: 'Clinical Actions Taken',
      actionsDate: 'Date of Actions',
      actionsHeader: 'Clinical Actions',
      admitDate: 'Date of Admission',
      anticoagulant: 'Anticoagulant In Use',
      description: 'Description',
      dischargeDate: 'Discharge Date',
      dme: 'DME In Use',
      dmeType: 'DME Type Used',
      email: 'Email',
      emailValue: 'clientcommunications@vestahealthcare.com',
      evaluated: 'Member Evaluated',
      facilityName: 'Facility Name',
      followUpAppointmentType: 'Follow-Up Appointment',
      footer: {
        cah: 'For questions or assistance, call our clinical team:',
        vesta: 'For questions or assistance, call or email our clinical team',
      },
      incidentDate: 'Date of incident',
      incidentTitle: 'Incident Details',
      intro: 'Report details below and in the attached PDF.',
      involved: 'Witnessed By',
      location: 'Location of Incident',
      medicationChange: 'Medication Change',
      patientHeader: 'Patient Details',
      patientId: 'Patient ID#',
      patientName: 'Patient Name',
      phone: 'Phone',
      phoneValue: {
        cah: '(866) 533-5275',
        vesta: '(883) 553-2823',
      },
      reportDate: 'Date Reported to Vesta',
      vestaId: 'Vesta ID#',
    },
    filters: {
      assignee: 'Assignee',
      brand: 'Brand',
      dateFrom: 'Date From',
      dateTo: 'Date To',
      excludedReferralSources: 'Exclude Referral Sources',
      homeHealth: 'Home Health',
      memberId: 'Member Dash ID',
      memberName: 'Member Name',
      npOwner: 'NP Owner',
      referralSources: 'Referral Sources',
      status: 'Review Status',
      type: 'Incident Type',
    },
    modals: {
      email: 'Email',
      fallNote: 'Fall Incidents are reported via the Events workflow',
      titleAdd: 'Report an Incident for {{member}}',
      titleFall: 'Report a Fall Incident for {{member}}',
      tittleMultiStep: 'Report Incident for {{type}} Event',
      titleAddEmail: 'Add Email',
      steps: 'Step {{current}} of {{total}}',
      submitReport: 'Submit Report',
      subtitleAdd:
        'Complete the following information to report an Incident for review.',
      subtitleFall:
        'Complete the following information to report a Fall Incident for review.',
    },
    sort: {
      dashId: 'Dash ID ',
      newest: 'Created: Newest',
      sortBy: 'Sort By',
    },
    table: {
      assignee: 'Assignee',
      member: 'Member',
      referral: 'Referral Source',
      reported: 'Reported',
      status: 'Review Status',
      type: 'Incident Type',
    },
    tabs: {
      all: 'All',
      open: 'Open',
    },
  },
  insurancePlans: {
    abilityPayerId: 'Ability Payer ID',
    addECWID: 'Add eCW Id',
    addInsurance: 'Add Insurance Plan',
    addModalTitle: 'Add Insurance Plan',
    created: 'Created',
    dashId: 'Dash ID',
    default: 'Default',
    ecwId: 'ECW ID',
    ecwIds: 'ECW IDs',
    editModalTitle: 'Edit Insurance Plan',
    eligibilityExceptions: 'Eligibility Exceptions',
    eligibilityExceptionsAdd: 'Add Exception',
    errorStateLength: 'When "Generic" is set, only one state must be informed',
    exceptions: 'Exceptions',
    name: 'Name',
    insuranceType: 'Insurance Type',
    inNetwork: 'In Network',
    inNetworkAddException: 'Add Exception',
    isGeneric: 'Generic',
    isMedicare: 'Medicare',
    isMedicaid: 'Medicaid',
    outOfNetwork: 'Out of Network',
    planNumber: 'Plan Number',
    title: 'Insurance Plans',
  },
  interventions: {
    additionalInfoPlaceholder: 'Add details',
    completeIntervention: 'Complete Intervention',
    interventionIsClosed: 'This intervention is now closed',
    interventions: 'Interventions',
    optionalNote: 'Optional Note',
    wasInterventionDone: 'Was the intervention done?',
    whyWasntTheInterventionDone: "Why wasn't the intervention done?",
    whyWasntTheInterventionDoneFully: "Why wasn't the intervention done fully?",
  },
  issueDetails: {
    originalText: 'Original text',
    translation: 'Translation',
  },
  members: {
    bp: 'Blood Pressure',
    brand: 'Brand',
    careCoordinators: 'Clinical Medical Assistant(s)',
    carePlanGr: 'Call Cadence',
    carePlanGroup: 'Call Cadence',
    communityResource: 'Community Resource',
    downloadMembersFile: 'Download Members File',
    engagementOwners: 'Engagement Owner(s)',
    extendedReport: 'Include expanded Info',
    excludedReferralSources: 'Excluded Referral Sources',
    hasCaregiver: 'Caregiver',
    hasCareTeam: 'Has Care Team',
    healthCoaches: 'Health Coach(es)',
    id: 'ID',
    language: 'Language',
    name: 'Name',
    npOwner: 'NP Owner',
    podManager: 'Pod Manager',
    programExtensions: 'Program Extensions',
    programExtensionStatus: 'Program Extension Status',
    referralSources: 'Referral Sources',
    reporterStatus: 'Reporter Status',
    reportingPreference: 'Reporting Preference',
    riskLevel: 'Risk Level',
    rnOwner: 'RN Owner',
    selfReporter: 'Self Reporter',
    state: 'State',
    status: 'Status',
    title: 'Members',
    vestaTeam: 'Vesta Team',
    vitals: 'Vitals',
    vitalsCollectionMethod: 'RPM Vendor Source',
    worklistGroup: 'Worklist Group',
  },
  memberEvents: {
    addAddendum: 'Add Addendum',
    addAddendumModalTitle: 'Add Addendum',
    addCareTeam: 'Add Care Team',
    addContact: 'Add Contact',
    addEncounterModalTitle: 'Add Encounter',
    addEvent: 'Add Event',
    addFollowUpModalTitle: 'Edit Follow Up Info',
    addInterventionModalTitle: 'Add Intervention',
    addModalTitle: 'Add Event',
    addNoteModalTitle: 'Add Note',
    addTaskModalTitle: 'Add Task',
    addTriageModalTitle: 'Triage',
    allEvents: 'All',
    appUser: 'App User',
    categoryType: 'Category',
    channel: 'Channel',
    channelOther: 'Other Channel',
    chiefComplaint: 'Chief Complaint',
    chronological: 'Chronological',
    creationDate: 'Creation Date',
    closedEvents: 'Closed',
    contact: 'Contact',
    created: 'Created',
    createEvent: 'Create New Event',
    dateRange: 'Date Range',
    detail: {
      addEncounter: 'Add Encounter',
      addendums: 'Addendums',
      addIntervention: 'Add Intervention',
      addNote: 'Add Note',
      addTriage: 'Add Triage',
      advice: 'Advice',
      closeDocumented: 'Set to Documentation Only',
      closeEvaluation: 'Close Evaluation',
      confirmNewEvaluationTitle: 'Create Evaluation',
      confirmNewEvaluationBody:
        'Are you sure you wish to create a new evaluation?',
      confirmNewEvaluationWarning:
        'Please Note: All evaluations require triage, interventions and outcomes',
      encounters: 'Encounters',
      evaluationTab: {
        one: 'Initial Evaluation',
        other: 'Evaluation {{count}}',
      },
      evaluationTabNew: 'Add Evaluation',
      events: 'Events',
      handouts: 'Handouts',
      interventionResults: 'Was it effective?',
      interventions: 'Interventions',
      interventionStatus: 'Were actions taken?',
      invalidate: 'Invalidate Event',
      invalidIssue: 'Invalid Issue',
      invalidReading: 'Invalid Reading',
      interventionsCount: {
        one: '{{count}} int',
        other: '{{count}} int',
      },
      linkedEvents: 'Linked Events',
      missingFieldsTooltip: 'Please fill missing required fields',
      notes: 'Notes',
      questions: 'Questions',
      relationship: 'Link Reason',
      saveEvaluation: 'Save Evaluation',
      startEvaluation: 'Start Evaluation',
      triage: 'Triage',
      triageMoreInfo: 'Click to see advice',
      validIssue: 'Valid Issue',
      validReading: 'Valid Reading',
      warningCloseEvaluation:
        'The evaluation cannot be closed until triage has been completed and has at least one intervention',
      warningCloseEvent:
        'The event cannot be closed until it is assigned and all evaluations are closed',
      warningNoAssignedEvent:
        'The event must be assigned to perform this action',
      warningMissigFields:
        'All issues of the event must have their required fields completed',
      warningNoAssignedYouEvent:
        'The event must be assigned to you to perform this action',
    },
    direction: 'Direction',
    documentationOnly: 'Set to Documentation Only',
    documentationOnlyNote: 'Does not require outreach from Triage',
    edDiversion: 'ED Diversion',
    editAddendumModalTitle: 'Edit Addendum',
    editFollowUpModalTitle: 'Edit Follow Up Info',
    editIssueModalTitle: 'Edit Issue',
    editNoteModalTitle: 'Edit Note',
    encounterAddDate: 'Added {{date}}',
    encounterDate: 'Encounter Date',
    encounterTime: 'Encounter Time',
    encounterMinutes: '{{count}} min',
    encounters: 'Encounters: {{count}} total',
    event: 'Event',
    eventDate: 'Date of Event',
    eventMethod: 'Event Method',
    eventTime: 'Time of Event',
    eventType: 'Event Type',
    followUpDate: 'Follow-Up Date',
    followUpTime: 'Follow-Up Time',
    interventionCount: 'Count',
    interventionDates: 'Date(s)',
    interventionName: 'Intervention Name',
    interventionsSummary: 'Interventions',
    interventionType: '{{category}} interventions',
    issues: {
      abnormal: 'Abnormal',
      reading: 'Reading Taken',
      received: 'Recorded in Dash',
      valid: 'Valid',
      value: 'Value',
    },
    issueType: 'Issue',
    issueTypeNote:
      'Note: Clicking the issue will open side panel to edit specific fields.',
    lastEncounter: 'Last Encounter',
    member: 'Member',
    method: 'Method',
    methodOther: 'Other Method',
    modals: {
      addTask: 'Add Task',
      addIssueModalTitle: 'Add {{type}}',
      closeEvent: 'Close Event',
      closeEventTitle: 'Close Event',
      confirmDeleteLinkBody:
        'Are you sure you want to unlink {{event1}} from {{event2}}?',
      confirmDeleteLinkTitle: 'Warning',
      divertedFrom: 'If no, what would the member have done?',
      edDiversionTitle: 'ED Diversion',
      editIssueModalTitle: 'Edit {{type}}',
      history: 'Audit History',
      historyClosed: 'Event closed',
      historyCreated: 'Event created',
      historyEventInfo: 'Event Info',
      historyIssuesInfo: 'Issue(s) Info',
      historyUpdated: 'Event last updated',
      historyIssueInfo: '{{issue}} issue',
      historyIssueCreated: 'Issue created',
      historyIssueUpdated: 'Issue last updated',
      historyInvalidated: 'Event invalidated',
      infoLinkEvent:
        'This action will link {{event}} with another event creating a chain of events for this member.',
      invalidateEvent: 'Invalidate',
      invalidateEventTitle: 'Invalidate Event',
      invalidateReason: 'Reason',
      interventionPreventedER:
        'Did you perform a nursing intervention that prevented the member from needing an ER visit?',
      issue: 'Issue',
      linkEventTitle: 'Link Event',
      npEscalationRequired:
        'Does this alert require escalation to Vesta NP for further evaluation?',
      other: 'Other',
      resolution: 'Event Resolution',
      status: 'Status',
      taskAssignTo: 'Assign To',
      taskDate: 'Due Date',
      taskGroup: 'Group',
      taskName: 'Name',
      taskTime: 'Due Time',
      wouldVisitER:
        'Without Vesta, would the member have gone to the ER, called 911 or pressed PERS?',
    },
    modifyUrgencyModalTitle: 'Modify Urgency',
    note: 'Note',
    openEvents: 'Open',
    otherContact: 'Contact Name',
    otherContactReferral: 'Referral Affiliation',
    panelNewEvent: 'New events or event updates available',
    panelNewEventRefresh: 'Refresh screen',
    reporterAppUser: 'Reporter App User',
    reporterCareTeam: 'Reporter Care Team',
    reporterCommunityResource: 'Reporter Community Resource',
    reporterContact: 'Reporter Contact',
    reporterMember: 'Reporter Member',
    reporterType: 'Reporter Type',
    resolution: 'Event Resolution',
    saveEncounter: 'Save Encounter',
    showTriageModalTitle: 'Triage Details',
    source: 'Source',
    sourceOutcome: 'Outcome',
    table: {
      followUpDate: 'Follow Up Date',
      npEscalation: 'NP Escalation Criteria Met',
      numberOfIssues: {
        one: '{{count}} issue',
        other: '{{count}} issues',
      },
      urgent: 'Urgent',
      urgencyLevel: 'Urgency level {{count}}',
    },
    tasks: 'Tasks: {{count}} total',
    title: 'Events',
    type: 'Type',
    totalMinutes: 'Total Minutes',
    unlinkAlert:
      'Alert: Unlinking the selected issue(s) will remove the issue(s) from the current event permanently.',
    unlinkModalTitle: {
      one: 'Unlink {{count}} issue',
      other: 'Unlink {{count}} issues',
    },
    urgent: 'Urgent',
    urgencyLevel: 'Urgengy level',
    unknownTime: 'Unknown Time',
    warningAddNoteBody: 'Cannot add notes until all evaluations are closed',
    warningAddNoteTitle: 'Validation Warning',
    warningCloseEventBody:
      'Cannot close event until all evaluations are closed',
    warningCloseEventTitle: 'Validation Warning',
    warningMissingFieldsBody:
      'Cannot perform action until all issue details are filled',
    warningMissingFieldsTitle: 'Missing Fields Warning',
  },
  pagination: {
    of: 'of',
    perPage: 'per page',
  },
  patient: {
    addNotes: 'Add Notes',
    addNotesSuccess: 'Notes successfully added to {{name}} profile.',
    notes: 'Notes',
    modal: {
      editNotes: 'Edit Notes for {{name}}',
    },
    error: {
      maxCharacterLimit:
        'The maximum character allowed is {{NOTE_MAX_LENGTH}}.',
    },
  },
  personalDetails: {
    addComment: 'Add a comment',
    addressModal: {
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      state: 'State',
      title: 'Home address',
      zip: 'Zip',
    },
    age: 'Age',
    appVersion: 'App version',
    birthDate: 'Birth date',
    bloodPressure: 'Blood Pressure',
    brand: 'Brand',
    careCoordinator: 'Clinical Medical Assistant',
    careManager: 'CM',
    carePlanGroup: 'Call Cadence',
    careTeam: {
      addInfo: 'Add info',
      addTitle: 'Add Patient Notes',
      clickToAdd: 'Click to add optional info.',
      editTitle: 'Edit Patient Notes',
      info: 'Patient Notes',
      noneAdded: 'No info added. ',
      placeholder: 'Type communications protocol and family dynamics here',
    },
    careUpdates: 'Care updates',
    changeProgram: 'Change program',
    changeProgramWarning:
      'Changing a member’s program may impact their Health Check In Questions and other configurations.',
    keywordConfirmation:
      'Please type {{keyword}} to confirm you want to make this change',
    client: 'Client',
    contact: 'Contact',
    contacts: 'Contacts',
    deceasedDate: 'Deceased date',
    eligibilityStatus: 'Eligibility Status',
    eligibilityStatuses: {
      inProgress: 'In Progress',
    },
    eligibilityStatusReason: 'Eligibility Reason',
    eligibilityDate: 'Last Eligibility Check',
    emergencyContact: 'Emergency contact',
    emptyContacts: "This customer doesn't have contacts.",
    encounterAdded: 'Encounter Added',
    encounterAddedFormatted: 'Encounter {{formattedDate}}',
    enterNote: 'Enter Note',
    externalIds: 'External Ids',
    emr: 'EMR Ids',
    expectedWeeklyHealthReports: 'Expected Weekly Health Check-Ins',
    externalId: 'Client ID',
    fullName: 'Full Name',
    gender: 'Gender',
    healthDecisions: 'Health decisions',
    homeAddress: 'Home',
    isClientSelfDirecting: 'Is the client self-directing?',
    joinedHT: 'Joined HT',
    language: 'Language',
    languages: 'Language(s)',
    livesWith: 'Lives with',
    memberStatus: 'Member status',
    note: 'Note',
    npOwner: 'NP Owner',
    other: 'Other',
    partner: 'Partner',
    patientId: 'Member ID',
    patientID: 'Member ID',
    payer: 'Payer',
    personalDetails: 'Personal Details',
    phoneNumber: 'Phone Number',
    phoneNumbers: 'Phone Numbers',
    preferred: 'Preferred',
    preferredContactModal: {
      addDateTime: 'Add Preferred Contact Datetime',
      authenticationPhone: 'App Authentication Phone',
      date: 'Days',
      fromTime: 'From',
      label: 'Preferred Contact Info',
      method: 'Method',
      note: 'Notes',
      other: 'Contact',
      time: 'Time',
      title: 'Preferred Contact Info',
      toTime: 'To',
    },
    primaryContact: 'Primary contact',
    program: 'Program',
    programDetails: 'Program Details',
    programExt: 'Program ext.',
    programExtensions: 'Program extensions',
    programExtensionStatus: 'Program extension status',
    reasonWithdraw: 'Reason Withdraw',
    referralSources: 'Referral Sources',
    relationship: 'Relationship',
    residence: 'Residence',
    riskLevel: 'Risk level',
    riskPrograms: 'Risk Programs',
    rpmVendor: 'RPM Vendor',
    rnOwner: 'RN Owner',
    seeAll: 'See all',
    selectNewProgram: 'Select the new program',
    selfDirecting: 'Self-Directing',
    selfReporter: {
      hciNotifications: 'HCI Reminder Texts',
      hciReporterMethod: 'Self Reporter Method',
      label: 'Self Reporter',
      lastLoginInfo: 'Last login info',
      selfReporter: 'Self Reporter',
      selfReporterStatus: 'Reporter Onboarding Status',
      sendAppInvite: 'Send App Invite',
      sendAppInviteBody:
        'Would you like to send an {{os}} invite to {{member}} at {{number}}?',
      title: 'Self Reporter',
    },
    startDate: 'Start Date',
    startedReporting: 'Started Reporting',
    state: 'State',
    status: 'Status',
    title: 'Member Details',
    testMember: 'Test member',
    vestaProgramDetails: 'Vesta Program Details',
    warningBiometricsSMS:
      "The member does not have a primary number set, so it won't receive any reminders via SMS.",
    warningBiometricsDevice:
      "The member has never used the mobile app, so it won't receive any reminders via Push notification.",
    warningBrand:
      'If you manually override a member’s Brand, then that member will be associated with that Brand for the duration of their service with Vesta. This can be updated by editing the Brand again from this dropdown.',
    warningCRMPhones: 'Locked phone numbers can only be edited in CRM',
    warningECWPhones:
      'Locked phone numbers can only be edited in eCW - if needed, please update from eCW, and it will be updated automatically in Dash within 5 minutes.',
    virtualVisit: 'Virtual Visit',
    virtualVisitDate: 'Virtual Visit Date',
    virtualVisitHost: 'Virtual Visit Host',
    virtualVisitInfo: 'Virtual Visit Info',
    virtualVisitTime: 'Virtual Visit Time',
    virtualVisitPlatform: 'Virtual Visit Platform',
    virtualVisitStatus: 'Virtual Visit Status',
    warningBrandTitle: '{{brand}} Member',
    warningBrandBody: 'This member is associated with the {{brand}} population',
    warningECWStartDate:
      'Locked Start Date can only be edited in eCW - if needed, please update from eCW, and it will be updated automatically.',
    warningVPCVestaBody:
      'This member belongs to VPC for primary care, but they receive CCM from Vesta.',
    warningInvalidStatus:
      'Check that this patient does not have active Tasks or Health Alerts. This action will remove caregivers and member relationships.',
    whereDoesTheClientReside: 'Where does the client reside?',
    whoDoesTheClientLiveWith: 'Who does the client live with?',
    workListGroup: 'Worklist Group',
    errorInvalidStatus:
      'Cannot set this status while user has opened health alerts, incomplete tasks or biometrics enabled',
  },
  memberBulkEdit: {
    action: 'Action',
    addProgramExtension: 'Add Program Extension Operation',
    header: 'Member Bulk Edit',
    emptyResults: 'No members matching with the criteria',
    bulkEdit: 'Bulk Edit',
    addAllToSelection: 'Select all Filtered results',
    customSelection: 'Custom selection',
    customSelectionText:
      'Paste all DASH IDs in the text area below. You can use a space or comma to separate DASH IDs. All members currently selected will be replaced with the DASH IDs you paste below.',
    clearSelection: 'Clear selection',
    errorStateAssignment:
      'You cannot assign a member to a clinician who is not licensed in that member’s state of residence:',
    errorStateAssignmentNotLicensed: '{{name}} is not licensed in {{state}}',
    errorStateAssignmentSubtitle:
      "Please use the ‘State' filter in the Bulk Edit tool to ensure that only members that align with a clinician’s state(s) of licensure are selected for assignment. If you believe a clinician’s allowed states are not up-to-date, please contact a Dash Admin to confirm the clinician’s state credentialing or licensure in Dash.",
    noRiskLevel: 'No Risk Level',
    noDataInfo:
      'Search without filters is disabled. To start selecting members for editing, use the filters or add the ids in the "Custom selection" button',
    programExtension: 'Program Extension',
    programExtensionStatus: 'PE Status',
    reviewTable: {
      careCoordinator: 'Clinical Medical Assistant(s)',
      carePlanGroup: 'Call Cadence',
      communityResource: 'Community Resource',
      engagementOwner: 'Engagement Owner(s)',
      healthCoach: 'Health Coach(es)',
      member: 'Member',
      npOwner: 'NP Owner',
      podManager: 'Pod Manager',
      programExtension: 'Program Extensions',
      riskLevel: 'Risk Level',
      rnOwner: 'RN Owner',
      state: 'State',
      status: 'Status',
      worklistGroup: 'Worklist Group',
    },
    seeFilters: 'See Filters',
    seeSelection: 'See selection',
    selectedItems: {
      zero: 'No members selected',
      one: '{{count}} member selected',
      other: '{{count}} members selected',
    },
    warning: {
      zero: 'No members selected',
      one: 'Warning! {{count}} member will be affected by the operation',
      other: 'Warning! {{count}} members will be affected by the operation',
    },
    warningNoSetInvalidStatus:
      'Only users in the ‘Eligibility Management’ group are allowed to update a member’s status to Invalid.',
    warningState:
      "You are trying to assign {{count}} members to a clinician who is not licensed in that member’s state of residence. Please use the 'State' filter in the Bulk Edit tool to ensure that only members that align with a clinician’s state(s) of licensure are selected for assignment. If you believe a clinician's allowed states are not up-to-date, please contact a Dash Admin to confirm the clinician's state credentialing or licensure in Dash.",

    review: 'Review',
  },
  memberRPM: {
    rpmSettings: 'RPM Settings',
    noRPMDevicesSetup: 'There are currently no RPM devices set up.',
    setUpRPMDevices: 'Set up RPM devices',
    kitIDNumber: 'Kit ID Number',
    alsoReceivingTablet: 'The patient is also receiving a tablet',
    connectRPMKit: 'Connect RPM kit',
  },
  memberDevice: {
    deviceName: 'Device Name',
    lastHeartbeat: 'Last Heartbeat',
    micEnabled: 'Mic Enabled',
    pushEnabled: 'Push Enabled',
    batteryLevel: 'Battery Level',
    deviceInfo: 'Device Info',
  },
  memberProfile: {
    add: {
      emergencyRoomVisits: 'Add ER Visit',
      hospitalizations: 'Add Hosp Visit',
      skilledNursingFacilities: 'Add SNF Visit',
      surgeries: 'Add Surgery',
      falls: 'Add Fall',
      diagnosis: 'Add Diagnosis',
      alert: 'Add Alert',
      task: 'Add Task',
    },
    allergies: 'Allergies',
    clickTo: {
      allergies: 'Click to add an allergy.',
      contacts: 'Click to add a contact.',
      diagnoses: 'Click to add a diagnosis.',
      dme: 'Click to add DME.',
      erVisit: 'Click to add an ER visit.',
      facilityStays: 'Click to view major episodes.',
      falls: 'Click to add a fall.',
      healthReport: 'Click to add report for today.',
      hosp: 'Click to add a hospital visit.',
      interventions: 'Click here to go to the PHIP Manager.',
      keyDiagnoses: 'Click to view diagnoses.',
      medications: 'Click to add a medication.',
      snf: 'Click to add a SNF visit.',
      surgeries: 'Click to add a surgery.',
    },
    clinicalSummary: {
      button: {
        allergies: 'Add Allergie',
        dme: 'Add DME',
        medications: 'Add Medication',
        problems: 'Add Problem',
      },
      info: {
        emptyAllergies: 'No Known Drug Allergies',
        emptyCarePlan: 'Care Plan Not Found',
        emptyMedications: 'No Known Medications',
        emptyProblems: 'No Known Problems',
        emptyDMEs: 'No Known Medical Equipment',
      },
      tables: {
        allergies: {
          agent: 'Agent',
          reaction: 'Reaction',
          onsetDate: 'Onset Date',
          status: 'Status',
        },
        careplan: {
          goal: 'Goal',
          objective: 'Objective',
          interventions: 'Interventions',
        },
        dme: {
          active: 'Active',
          addModal: 'Add DME(s)',
          dme: 'Durable Medical Equipment',
          deleteModal: 'Discontinue {{dme}}',
          discontinueDate: 'Discontinued at',
          status: 'Status',
        },
        medications: {
          name: 'Name',
          directions: 'Directions',
          duration: 'Duration',
          dispense: 'Dispense',
          refills: 'Refills',
          source: 'Source',
          action: 'Action',
        },
        problems: {
          code: 'Code',
          name: 'Name',
          onsetDate: 'Onset Date',
          status: 'Status',
        },
      },
      tabs: {
        allergies: 'Allergies',
        careplan: 'Care Plan',
        dme: 'DMEs',
        medications: 'Medications',
        problems: 'Problem List',
      },
      title: 'Clinical Summary',
    },
    contacts: 'Contacts',
    dashboard: {
      access: 'Access',
      assignments: 'Assignments',
      additionalBarriers: 'Additional Barriers',
      barriersToAdherence: 'Barriers to Adherence',
      barriersNoBarriers: 'No barriers to medication adherence identified',
      callCadence: 'Call Cadence',
      ccm: 'CCM',
      ccmLastCarePlan: 'Last Care Plan',
      ccmLastCompleted: 'Last Completed',
      ccmTotalMinutes: 'Total CCM Minutes',
      clinicalSummary: 'Clinical Summary',
      cumulative: 'Cumulative',
      goToClinicalSummary: 'Go to Clinical Summary',
      goToEvents: 'Go to Events',
      goToMemberNetwork: 'Go to Member Network',
      goToTasks: 'Go to Tasks',
      goToVitals: 'Go to Vitals',
      hideMore: 'Hide {{count}}',
      insuranceCoverage: 'Insurance Coverage',
      keyContacts: 'Key Contacts',
      lastAppointment: 'Last Appointment',
      lastUpdatedECW: 'Data last updated from eCW:',
      logCall: 'Log Call',
      missingPCP: 'MISSING: PCP INFO',
      missingPharmacy: 'MISSING: PHARMACY INFO',
      nextAppointment: 'Next Appointment',
      noAddress: 'Missing Address',
      noDMEs: 'No DMEs',
      noMedications: 'No Medications',
      noOpenTasks: 'No Open Tasks',
      noPhone: 'Missing phone',
      noProblemList: 'No Problem List items',
      noRecentActions: 'No Recent Actions',
      noRecentEncounters: 'No Recent Encounters',
      noRecentEvents: 'No Recent Events',
      npBiannuals: 'NP Visits',
      openTasks: 'Open Tasks',
      pcp: 'PCP',
      pharmacistRecommendations: 'Pharmacist Recommendations',
      pharmacy: 'Pharmacy',
      pharmacyVisitEmpty: 'No Recent Pharmacy Visit Info',
      pharmacyVisitSummary: 'Pharmacy Visit Summary',
      recentActions: 'Recent Actions (eCW)',
      recentActionsTooltip:
        'All actions from eCW in the last 60 days. Data from eCW refreshes in Dash approximately once every 4 hours',
      recentEncounters: 'Recent Encounters',
      recentEncountersTooltip:
        'Encounters from Dash, and provider appointments from eCW, in the last 60 days. Data from eCW refreshes in Dash approximately once every 4 hours',
      recentEvents: 'Recent Events',
      recentEventsTooltip:
        'Events in Dash from the last 60 days. Go to Member Events page for full history',
      rpmAdherence: 'RPM Adherence',
      rpmTotalMinutes: 'Total RPM Minutes',
      rpmTotalMinutesTooltip:
        'Please log additional RPM time this month as CCM time.',
      seeMore: 'See {{count}} more',
      understandingOfMedication: 'Understanding of Medication',
      totalBHIMInutes: 'Total BHI Minutes',
      worklistGroup: 'Worklist Group',
    },
    durableMedicalEquipment: 'Durable Medical Equipment',
    empty: {
      allergies: 'No allergies added.',
      diagnoses: 'No diagnoses added.',
      contacts: 'No contacts added.',
      dme: 'No DME added.',
      erVisit: 'No ER visits added.',
      facilityStays: 'No facility stays.',
      falls: 'No falls added.',
      healthReport: 'No health check-ins found for the selected date range.',
      hosp: 'No hospital visits added.',
      interventions: 'No interventions added.',
      keyDiagnoses: 'No key diagnoses starred.',
      medications: 'No medications added.',
      snf: 'No SNF visits added.',
      surgeries: 'No surgeries added.',
    },
    header: {
      actions: {
        careTeam: 'Care Team',
        reportIncident: 'Report Incident',
      },
      appUsers: 'App Users',
      careTeam: 'Care Team',
      contacts: 'Contacts',
      core: 'CORE',
      coreTitle: 'This member is associated with the Vesta Core population',
      events: 'Events',
      householdCount: {
        one: '1 Household Member',
        other: '{{count}} Household Members',
      },
      lite: 'LITE',
      liteTitle: 'This member is associated with the Vesta Lite population',
      member: 'Member',
      openedEvents: '{{count}} open',
      primary: 'Primary',
      test: 'TEST',
      testTitle: 'This is a test member',
      totalEvents: '{{count}} total',
      vpc: 'VPC',
      vpcShared: 'VPC*',
      vpcTitle:
        'This member is associated with the Vesta Vesta Primary Care population',
      vpcVestaTitle: 'This member is shared by both Vesta (CCM) & VPC (PCP)',
    },
    medications: 'Medications',
    network: {
      addCareTeam: 'Add Care Team Person',
      careCoordinators: 'Clinical Medical Assistant(s)',
      careTeam: 'Care Team',
      careTeamCandidates: 'Care Team Candidates',
      careTeamAdd: 'Add Care Team Member',
      communityResources: 'Community Resources',
      communityResourcesAdd: 'Add Community Resource',
      communityResourcesDeleteBody:
        'This will remove "{{communityResource}}" from {{member}} resource list. Do you wish to continue?',
      communityResourcesDuplicated:
        'Community Resource already linked to member',
      engagementOwners: 'Engagement Owner(s)',
      healthCoaches: 'Health Coach(es)',
      houseHoldMembers: 'Member Household',
      houseHoldMembersAdd: 'Add Household Member',
      npOwner: 'NP Owner',
      podManager: 'POD Manager',
      rnOwner: 'RN Owner',
      title: 'Member Network',
      vestaTeam: 'Vesta Team',
      vestaTeamModalTitle: 'Edit Vesta Team',
      warningEmployeeState: {
        tooltip1:
          'You cannot assign a member to a clinician who is not licensed in that member’s state of residence. ',
        tooltip2: '{{name}} is not licensed in the state of {{state}}. ',
        tooltip3:
          'Please contact a Dash Admin to confirm the clinician’s state credentialing or licensure in Dash. ',
      },
    },
    toc: {
      title: 'TOC / Skilled List',
    },
  },
  platform: {
    addSetupCode: 'Add iPad ID',
    invalidIpad: 'Invalid iPad ID',
    linkIpad: 'Link iPad',
    setupCode: 'iPad ID',
    typeUnlink: 'Type UNLINK',
    typeUnlinkToConfirm: 'Type UNLINK to confirm',
    typeIpadID: 'Type iPad ID',
    unlink: 'Unlink',
    unlinkError: 'Please enter text exactly as displayed to confirm',
    unlinkIpad: 'Unlink iPad',
  },
  planDocuments: {
    carePlanBy: 'Care plan by {{employee}} {{date}}',
    createPlan: 'Create Plan',
    header: 'Plan Documents',
    modalBody: 'Plan Document will include details from the Health Profile:',
    modalDetails: [
      'Key Diagnoses',
      'Medications',
      'Care Team Contacts',
      'Chronic Care Goals',
      'Interventions',
    ],
    modalHeader: 'Plan Document',
    preview: 'Preview',
    signCreate: 'Sign & Create',
    summaryNote: 'Summary Note',
  },
  profile: {
    addAllergies: 'Add Allergies',
    addAnotherAllergy: 'Add Another Allergy',
    addDME: 'Add DME',
    addDMEs: 'Add DMEs',
    addAnotherDME: 'Add Another DME',
    allergies: 'Allergies',
    allergyHistory: 'Allergy history',
    discontinueDMENote: 'Discontinued DME will appear in DME History',
    dme: 'Durable Medical Equipment',
    dmeHistory: 'DME history',
    errorDuplicatedOtherAllergy: 'There are duplicated allergies',
    errorMissingOtherAllergy: 'Must specify other allergy',
    errorDuplicatedOtherDme: 'There are duplicated DMEs',
    errorMissingOtherDme: 'Must specify other DME',
    otherAllergyPlaceholder: 'Write other allergy',
    otherDmePlaceholder: 'Write other DME',
    removeAllergy: 'Remove allergy',
    removeAllergyMessage: 'Remove {{allergy}}?',
    selectAllergy: 'Select an allergy',
    selectDme: 'Select a DME',
  },
  search: {
    CGID: '(CG ID {{id}})',
    dashID: '(Dash ID {{id}})',
    externalID: '(External ID {{id}})',
    noPatientsFound: 'No members found',
    title: 'Search Members',
  },
  PATIENT_PPS_SCORE: {
    '100%': {
      ambulation: 'Full',
      activity: 'Normal activity & work, no evidence of disease',
      selfCare: 'Full',
      intake: 'Normal',
      conscious: 'Full',
    },
    '90%': {
      ambulation: 'Full',
      activity: 'Normal activity & work, some evidence of disease',
      selfCare: 'Full',
      intake: 'Normal',
      conscious: 'Full',
    },
    '80%': {
      ambulation: 'Full',
      activity: 'Normal activity with effort, some evidence of disease',
      selfCare: 'Full',
      intake: 'Normal/reduced',
      conscious: 'Full',
    },
    '70%': {
      ambulation: 'Reduced',
      activity: 'Unable to do normal work/job, significant disease',
      selfCare: 'Full',
      intake: 'Normal/reduced',
      conscious: 'Full',
    },
    '60%': {
      ambulation: 'Reduced',
      activity: 'Unable to do hobby/house work, significant disease',
      selfCare: 'Occasional assistance necessary',
      intake: 'Normal/reduced',
      conscious: 'Full or confusion',
    },
    '50%': {
      ambulation: 'Mainly sit or lie',
      activity: 'Unable to do any work, extensive disease',
      selfCare: 'Considerable assistance necessary',
      intake: 'Normal/reduced',
      conscious: 'Full or confusion',
    },
    '40%': {
      ambulation: 'Mainly in bed',
      activity: 'Unable to do most activity, extensive disease',
      selfCare: 'Mainly assistance',
      intake: 'Normal/reduced',
      conscious: 'Full or drowsy +/- confusion',
    },
    '30%': {
      ambulation: 'Totally bed bound',
      activity: 'Unable to do any activity, extensive disease',
      selfCare: 'Total care',
      intake: 'Normal/reduced',
      conscious: 'Full or drowsy +/- confusion',
    },
    '20%': {
      ambulation: 'Totally bed bound',
      activity: 'Unable to do any activity, extensive disease',
      selfCare: 'Total care',
      intake: 'Minimal to sips',
      conscious: 'Full or drowsy +/- confusion',
    },
    '10%': {
      ambulation: 'Totally bed bound',
      activity: 'Unable to do any activity, extensive disease',
      selfCare: 'Total care',
      intake: 'Mouth care only',
      conscious: 'Drowsy or coma +/- confusion',
    },
  },
  PATIENT_FAST_SCORE: {
    1: {
      characteristics: 'No functional decrement',
      diagnosis: 'Normal adult',
      duration: '50 years',
    },
    2: {
      characteristics: 'Subjective word difficulties',
      diagnosis: 'Normal aged adult',
      duration: '15 years',
    },
    3: {
      characteristics: 'Decreased function in demanding employment settings',
      diagnosis:
        "Comatible with possible incipient Alzheimer's in minority of cases",
      duration: '7 years',
    },
    4: {
      characteristics:
        'Decreased ability to handle complex tasks ex: finances or planning dinner for guests',
      diagnosis: "Mild Alzheimer's",
      duration: '2 years',
    },
    5: {
      characteristics: 'Requires assistance in choosing proper clothing',
      diagnosis: "Moderate Alzheimer's",
      duration: '18 months',
    },
    '6a': {
      characteristics: 'Difficulty dressing properly',
      diagnosis: "Moderately severe Alzheimer's",
      duration: '5 months',
    },
    '6b': {
      characteristics: 'Requires assistance bathing',
      diagnosis: "Moderately severe Alzheimer's",
      duration: '5 months',
    },
    '6c': {
      characteristics: 'Inability to handle mechanics of toileting',
      diagnosis: "Moderately severe Alzheimer's",
      duration: '5 months',
    },
    '6d': {
      characteristics: 'Urinary incontinence',
      diagnosis: "Moderately severe Alzheimer's",
      duration: '4 months',
    },
    '6e': {
      characteristics: 'Fecal incontinence',
      diagnosis: "Moderately severe Alzheimer's",
      duration: '10 months',
    },
    '7a': {
      characteristics: 'Ability to speak limited to about 6 words',
      diagnosis: "Severe Alzheimer's",
      duration: '12 months',
    },
    '7b': {
      characteristics: 'Intelligible vocabulary limited to a single word',
      diagnosis: "Severe Alzheimer's",
      duration: '18 months',
    },
    '7c': {
      characteristics: 'Ambulatory ability lost',
      diagnosis: "Severe Alzheimer's",
      duration: '12 months',
    },
    '7d': {
      characteristics: 'Ability to sit up lost',
      diagnosis: "Severe Alzheimer's",
      duration: '12 months',
    },
    '7e': {
      characteristics: 'Ability to smile lost',
      diagnosis: "Severe Alzheimer's",
      duration: '18 months',
    },
    '7f': {
      characteristics: 'Ability to hold head up lost',
      diagnosis: "Severe Alzheimer's",
      duration: 'NA',
    },
  },
  podReporting: {
    allGroups: 'All groups',
    allPods: 'All Pods',
    lastUpdatedTooltip:
      'Pod Reporting data is refreshed from eCW at regular intervals during the day',
    filters: {
      clinicalAssistant: 'Clinical Medical Assistant',
      excludedProgramExtensions: 'Exclude Program Extensions',
      groups: 'Worklist Groups',
      healthCoach: 'Health Coach',
      language: 'Language',
      memberStatus: 'Member Status',
      month: 'Month',
      pods: 'Pods',
      podManager: 'Pod Manager',
      rnOwner: 'RN Owner',
      unreachable: 'Unreachables',
    },
    summary: {
      total: 'Total',
      completed: 'Completed',
      attempted: 'Attempted',
      ofPatients: 'of patients',
      notStarted: 'Not Started',
      businessDays: 'Business Days',
      businessDaysFooter: 'Elapsed vs. Remaining',
      patientsPerDay: 'Patients Per Day',
      patientsPerDayFooter: 'Completed vs. Remaining',
    },
    table: {
      attempted: 'Attempted',
      completed: 'Completed',
      minutes: 'Minutes',
      moreAttempts: 'Three+ Attempts',
      notCompleted: 'Not Completed',
      notStarted: 'Not Started',
      ofPatients: 'of patients',
      oneAttempt: 'One Attempt',
      opportunityLevel: 'Opportunity Level',
      patientsPerDay: 'Per Day',
      patientsPerDayGoal: 'Per Day (Goal)',
      providerTime: 'Provider Time',
      status: 'Status',
      total: 'Total',
      twoAttempts: 'Two Attempts',
      unassigned: 'Unassigned',
      visitStatus: 'Visit Status',
      worklist: 'Worklist group',
    },
    tabs: {
      all: 'All',
      complexCCM: 'Complex CCM',
    },
    tooltips: {
      attempted:
        'Members that we have attempted to contact, but who are not yet Completed. Based on encounters in Dash.',
      biannualVisit:
        'Members who have completed a Virtual Visit in the past 6 months.',
      businessDays:
        'The number of elapsed business days this month, compared to the number of remaining business days (which includes the current day).',
      completed:
        'Members who have both a monthly call completed, and who have 20+ total CCM minutes.',
      notStarted:
        'Members that we have not yet attempted to contact this month. Based on encounters in Dash.',
      patientsPerDay:
        'The number of members that have been Completed in the business days that have elapsed, compared to the number of remaining members that need to be completed in the remaining business days this month.',
      rpmAdherence:
        'Only includes data for members who have an ‘Active’ RPM program extension. Note: RPM adherence cycles are on a rolling 30-day interval which is unique to each member (not on the same monthly basis as CCM), so this data will fluctuate over the course of a month.',
      rpmStatus: "Status of members' RPM program extension.",
      total:
        'Members who have an ‘Active’ or ‘Accepted’ status, or who started the current month in an ‘Active’ or ‘Accepted’ status.',
      worklistGroups:
        'Note: This list will exclude worklist groups that are inactive or have 0 members.',
    },
    title: 'Pod Reporting',
    titleBianualVisit: 'NP Video Visit',
    titleMemberStatus: 'Member Statuses',
    titleMinutes: 'Total CCM Minutes',
    titleProviderTime: 'Provider Time',
    titleWorklist: 'Worklist Groups',
    titleRPMAdherence: 'RPM Adherence',
    titleRPMStatus: 'RPM PE Status',
  },
  pods: {
    config: {
      add: 'Add POD',
      common: {
        active: 'Active',
        careCoordinators: 'Clinical Medical Assistant(s)',
        engagementOwners: 'Engagement',
        healthCoaches: 'Health Coach(es)',
        languages: 'Language(s)',
        podManager: 'Manager',
        name: 'Name',
        nps: 'NP(s)',
        programExtensions: 'Program(s) Supported',
        referralSources: 'Referral Source(s)',
        rns: 'RN(s)',
        states: 'State(s)',
        unassignedSlot: 'Unassigned Slot',
        updated: 'Updated',
        vestaTeam: 'Vesta Team',
      },
      modal: {
        addAnotherEmployee: 'Add Employee',
        titleAdd: 'Add POD',
        titleEdit: 'Edit {{pod}} Details',
      },
      table: {
        add: 'Add POD',
        noResults: 'No PODs found',
      },
      title: 'POD Config',
    },
    rules: {
      add: 'Add POD Rule',
      common: {
        active: 'Active',
        closeAfterAttemps: 'Close After Attemps',
        closeAfterDue: 'Close After Due',
        configuration: 'Configuration',
        createPeriodIfEmpty: 'Create Period If Empty',
        delayDaysAfterTrigger: 'Delay',
        externalTriggers: 'External Triggers',
        flexible: 'Flexible',
        frequency: 'Frequency',
        maxAttempts: 'Max Attempts',
        name: 'Name',
        period: 'Period',
        programExtensions: 'Program(s)',
        roles: 'Roles',
        selfTrigger: 'Self Trigger',
        taskSpacingDays: 'Spacing',
        taskTriggers: 'Task Triggers',
        triggers: 'Trigger(s)',
      },
      modal: {
        addAnotherTrigger: 'Add Trigger',
        titleAdd: 'Add POD Rule',
        titleEdit: 'Edit {{rule}}',
      },
      table: {
        add: 'Add POD Rule',
        noResults: 'No POD Rules found',
      },
      title: 'POD Rules',
    },
  },
  riskProfile: {
    riskProfile: 'PHIP Profile',
    unevaluated: 'Unevaluated Care Tracks',
    activePathways: 'Active Care Tracks',
    inactivePathways: 'Inactive Care Tracks',
    setRiskLevel: 'Set Risk Level',
    updateRiskLevel: 'Update Risk Level',
    optionalNote: 'Optional Note',
    optionalNotePlaceholder: 'Optional note about this risk level...',
    optionalNotePlaceholderChange:
      'Optional note about this risk level change...',
    optionalNotePlaceholderIntervention:
      'Optional note about this intervention...',
    evaluateCareTrack: 'Evaluate Care Track',
    evaluateInterventions: 'Evaluate Interventions',
    noLevelSet: 'No Level Set',
    summary: 'Summary',
    riskLevel: 'Risk Level',
    seeCriteria: 'See Criteria',
    hideCriteria: 'Hide Criteria',
    noInterventions: 'No Interventions',
    lpocInterventions: 'PHIP Interventions',
    formerlyInPHIP: 'Formerly In PHIP',
    excludedInterventions: 'Excluded Interventions',
    seeTasks: 'See Tasks',
    sourceOfInformation: 'Source of Information',
    careTrackRiskLevel: '{{careTrack}} Risk Level',
    riskLevelSet: '{{careTrack}} Risk Level Set',
    riskLevelUpdated: '{{careTrack}} Risk Level Updated',
    riskTooLowToRepeat: 'Risk level too low to repeat intervention',
    riskTooLowToEvaluate: 'Risk level too low to\nre-evaluate intervention',
    nowEvaluateInterventions: 'Now evaluate interventions for this care track.',
    markReviewed: 'Mark Reviewed',
    markReviewedTitle: '{{careTrack}} Risk Marked As Reviewed',
    markReviewedBody: 'You’ve confirmed the current information is accurate.',
    noLongerNecessary:
      'The following interventions are still open and should be closed if no longer necessary:',
    goToTasks: 'Go To Tasks',
    selectInterventions: 'Select Interventions',
    evaluate: 'Evaluate',
    repeat: 'Repeat',
    reevaluate: 'Reevaluate',
    evaluateIntervention: 'Evaluate Intervention',
    repeatIntervention: 'Repeat Intervention',
    reevaluateIntervention: 'Reevaluate Intervention',
    reasonForExluding: 'Reason for Excluding',
    evaluateToast: {
      Evaluate: 'Intervention Successfully Evaluated',
      Reevaluate: 'Intervention Successfully Reevaluated',
      Repeat: 'Intervention Successfully Repeated',
    },
  },
  riskCriteria: {
    CEREBROVASCULAR: {
      NONE: ['No dx or other indication of risk'],
      LOW: [
        'Member does not need or take a statin or is not on aspirin therapy',
        'No ED visit or admissions for CVA in past year',
      ],
      MODERATE: [
        'Current use of statin and/or aspirin therapy',
        '1 to 2 hospital admissions for CVA in the past year',
      ],
      HIGH: [
        'Dx of CVA with residual effects',
        'More than 2 admissions for CVA in the past year',
        'Member unaware of dx and/or non-adherence to medication tx plan',
      ],
    },
    CHF_EXACERBATION: {
      NONE: ['No dx or other indication of risk'],
      LOW: [
        'Does not have a need for O2',
        'No ED visit or admissions related to this dx in the past year',
      ],
      MODERATE: [
        'Uses O2 as needed (PRN)',
        '1 or 2 hospital admissions related to dx in the past year',
      ],
      HIGH: [
        'Need for continuous O2',
        'On daily dose of diuretic',
        'More than 2 admissions for this dx in the past year',
        'Member unaware of dx and/or non-adherence to medication tx plan',
      ],
    },
    COPD_EXACERBATION: {
      NONE: ['No dx or other indication of risk'],
      LOW: [
        'Does not have a need for O2',
        'No ED visit or admissions related to this dx in the past year',
      ],
      MODERATE: [
        'Uses O2 as needed (PRN)',
        '1 or 2 hospital admissions related to dx in the past year',
      ],
      HIGH: [
        'Need for continuous O2',
        'On daily dose of diuretic',
        'More than 2 admissions for this dx in the past year',
        'Member unaware of dx and/or non-adherence to medication tx plan',
      ],
    },
    DIABETES_TYPE_II: {
      NONE: ['No dx or other indication of risk'],
      LOW: [
        'Member not prescribed dual therapy (oral + insulin) to manage MDII',
        'No Recent ED visit or admissions related to this DMII in the past year',
      ],
      MODERATE: [
        'Current use of Metformin and/or sulfonylureas and aspirin therapy',
        '1 or 2 admissions related to DMII in the past year',
      ],
      HIGH: [
        'Blood glucose uncontrolled with current therapy',
        'Dx of cardiovascular disease and/or chronic kidney disease',
        'More than 2 admissions for DMII in the past year',
        'Member unaware of dx and/or non-adherence to medication tx plan',
      ],
    },
    DEMENTIA: {
      NONE: ['No dx or other indication of risk'],
      LOW: ['Less than 4 on FAST'],
      MODERATE: [
        '4-5 on FAST',
        'Member not oriented to time',
        'Evidence of memory issues',
      ],
      HIGH: [
        '6 or greater on FAST',
        'Dx of Dementia or Alzheimers',
        'Member not oriented to person',
      ],
    },
    FALL: {
      NONE: ['No dx or other indication of risk'],
      LOW: ['Alternate Fall Risk less than 4'],
      MODERATE: ['Alternate Fall Risk between 4 and 6'],
      HIGH: ['Alternate Fall Risk between 7 and 10'],
    },
    GENERAL: {
      NONE: ['No dx or other indication of risk'],
      LOW: ['50% or more PPS Score', 'Less than 6d FAST Stage'],
      MODERATE: ['40% to 50% PPS Score', '6d or 6e FAST Stage'],
      HIGH: ['30% or less PPS Score', '7a or more FAST stage'],
    },
    GI_COMPLICATION: {
      NONE: ['No dx or other indication of risk'],
      LOW: ['No reported history of GI-related disorders'],
      MODERATE: [
        'Hx of GERD or constipation',
        'Self-reported or observed poor appetite',
      ],
      HIGH: ['Hx of GERD', 'Abdominal bleeding', 'Alcohol use'],
    },
    PRESSURE_ULCER: {
      NONE: ['No dx or other indication of risk'],
      LOW: [
        'Braden Scale score 15 to 23',
        'No histoy of decubitus ulcer',
        'Ambulatory with cane/rollator',
      ],
      MODERATE: [
        'Braden Scale score 13 to 14',
        'Evidence of deconditioning',
        'History of decubitus ulcer more than 1 year ago',
      ],
      HIGH: [
        'Braden Scale score 1 to 12',
        'Evidence of decubitus ulcer',
        'Bed-bound',
        'History of CVA with hemiplegia',
      ],
    },
    SOCIAL: {
      NONE: [''],
      LOW: [''],
      MODERATE: [''],
      HIGH: [''],
    },
    UTI_DEHYDRATION: {
      NONE: ['No dx or other indication of risk'],
      LOW: ['No reported history of UTI in past 2 years'],
      MODERATE: [
        'History of UTI in past year',
        'Dx of diabetes (uncontrolled)',
      ],
      HIGH: [
        'History of UTI in past 14 days',
        'Chronic or recurrent UTIs',
        'Foley catheter use or urinary incontinence',
        'Dx of diabetes',
        "Female with Alzheimer's Parkinsons, and/or dementia",
        'Member unaware of dx and/or non-adherence to medication tx plan',
      ],
    },
    TRANSITIONS_OF_CARE: {
      NONE: ['No inpatient stays in past 90 days'],
      HIGH: ['Inpatient stay within the past 90 days'],
    },
    ELEMENT_CARE: {
      HIGH: 'Is part of Element Care custom track',
    },
  },
  sidebar: {
    chat: 'Chats',
    clinicalSummary: 'Clinical Summary',
    diagnoses: 'Diagnoses',
    DME: 'DME',
    documents: 'Documents',
    dashboard: 'Member Dashboard',
    encounters: 'Encounter Time Tracking',
    healthOverview: 'Health Overview',
    healthProfile: 'Health Profile',
    healthReports: 'Health Check-Ins',
    helpfulLinks: 'Helpful Links',
    links: {
      eClinicalWorks: 'eClinicalWorks',
      elation: 'Elation',
      thoroughCare: 'network',
      userFriendly: 'Member Feedback',
    },
    interventionTasks: 'Intervention Tasks',
    majorEpisodes: 'Major Episodes',
    medsAllergiesDme: 'Meds | Allergies | DME',
    memberDetails: 'Member Details',
    memberEvents: 'Member Events',
    memberNetwork: 'Member Network',
    memberTasks: 'Member Tasks',
    observations: 'Observations',
    phipManager: 'PHIP Manager',
    phipProfile: 'PHIP Profile',
    sms: 'SMS Texts',
    tocList: 'TOC / Skilled List',
    vitals: 'Vitals',
  },
  observations: {
    goToPhip: 'Go To PHIP Manager',
    setTrackRiskLevel: 'Set {{careTrack}} Risk Level',
    removePainDetailTitle: 'Remove pain detail',
    removePainDetailMessage: "Remove '{{location}}' pain?",
  },
  messaging: {
    caregiver: 'Caregiver',
    contactList: 'Contact List',
    member: 'Member',
    optedOutRecipientsWarning:
      "The following recipient IDs have opted out from SMS reminders and won't receive any message",
    selectedItems: {
      zero: 'No recipients selected',
      one: 'Selected {{count}} recipient',
      other: 'Selected {{count}} recipients',
    },
    sendAsChatMessage: 'Send as chat message',
    sendAsSMSMessage: 'Send as SMS message',
    sendBulkSms: 'Send Bulk SMS',
    sendBulkChat: 'Send Bulk CHAT',
    sendToContacts: 'This message will be sent to {{numContacts}} contact(s):',
    searchMember: 'Search Member',
    searchCaregiver: 'Search Caregiver',
    searchCareTeamPerson: 'Search Care Team Person',
  },
  virtualVisit: {
    startVirtualVisit: 'Start Virtual Visit?',
    startVirtualVisitBody:
      "This will place a video call to the member's iPad. Make sure to have your headset on and the volume turned up.",
    virtualVisit: 'Virtual Visit',
    yesStartVirtualVisit: 'Yes, start virtual visit',
  },
  vesta: {
    home: {
      addNewMember: 'Add a new member',
      searchByNameOrId: 'Search Members by name or ID',
      welcome: 'Welcome, {{name}}!',
    },
    addMember: {
      addPhone: 'Add phone number',
      programConfiguration: 'Program Configuration',
      externalId: 'External ID',
      firstName: 'First Name',
      lastName: 'Last Name',
      birthDate: 'Birth Date',
      phoneNumber: 'Phone Number',
      language: 'Language',
      address: 'Address',
      address2: 'Address 2',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      submitBtn: 'Add Member',
      otherUsers:
        'Other than the member, will anyone else be using the phone app?',
      yesOthers:
        "Yes, the member's family, friends, or professional aide will use the phone app",
      noOthers: 'No, there are no additional app users',
      warningUnknown:
        'There was a validation error when trying to create this member',
      warningDuplicate: 'Member cannot be created because it already exists',
      warningDuplicateLink: 'view existing record',
    },
    addAppUser: {
      addAnother: 'Add another Vesta app user',
      editTitle: 'Edit Vesta App User',
      title: 'Add Vesta App User',
      type: 'Caregiver Type',
      relationship: 'Relationship to Member',
      otherRelationship: 'Other relationship',
    },
  },
  memberConsents: {
    accepted: 'Accepted',
    answer: 'Answer',
    clearConsentBody: 'Are you sure you want to delete this consent?',
    clearConsentTitle: 'Remove Consent',
    dateDenied: 'Date Denied',
    dateReceived: 'Date Received',
    dateSent: 'Date Sent',
    denied: 'Denied',
    disabled: 'Disabled',
    empty: 'No enabled consents for this member.',
    exception: 'Exception',
    exceptions: 'Exceptions',
    lastUpdated: 'Last Updated',
    modalTitle: 'Edit {{name}}',
    name: 'Type',
    notes: 'Notes',
    received: 'Received',
    sectionTitle: 'Member Consents',
    sent: 'Sent',
    selectPHIAccess: 'Select which contacts have PHI Access',
    verbal: 'Verbal',
    version: 'Version',
    written: 'Written',
  },
  memberInsurances: {
    add: 'Add insurance',
    confirmInactivateInsuranceBody: 'Please confirm Inactivation for {{name}}',
    created: 'Created',
    discontinuedAt: 'Inactivated',
    editTitle: 'Edit insurance',
    eligibilityStatus: 'Eligibility Status',
    eligibility: {
      eligible: 'Eligible',
      errors: 'Errors Occurred',
      ineligible: 'Ineligible',
      notRun: 'Not Run',
      review: 'Review Info',
    },
    empty: 'No insurances set for the member',
    inactivateConfirmButton: 'Yes, Inactivate Insurance',
    inactivateTitle: 'Inactivate Insurance',
    insuranceQualifications: 'Insurance Qualification',
    eligibilityPushModal: {
      success: 'Eligibility check sent.',
      title: 'Check Member Eligibility',
      unsuccess: 'Cannot run Eligibility due to the following:',
    },
    enrollmentEnd: 'Enrollment End',
    enrollmentStart: 'Enrollment Start',
    history: 'History',
    insurancePlan: 'Insurance Plan',
    insuranceType: 'Type',
    mltc: 'MLTC/LTSS',
    mltcName: 'MLTC/LTSS Name',
    mltcPlanCode: 'MLTC/LTSS Plan Code',
    mltcPlanId: 'MLTC/LTSS Plan ID',
    mltcPlanType: 'MLTC/LTSS Plan Type',
    originalLeadQualification: 'Original Lead Qualification',
    payer: 'Payer',
    planType: 'Insurance Plan Type',
    primary: 'Primary',
    priority: 'Rank',
    runEligibility: 'Run Eligibility',
    subscriberId: 'Subscriber ID',
    title: 'Insurance Details',
    updated: 'Updated',
    viewQualifications: 'View Qualifications',
  },
  memberReferrals: {
    add: 'Add referral source',
    affiliation: 'Affiliation',
    confirmDeleteTitle: 'Delete referral source',
    confirmInactivateBody: 'Please confirm Inactivation for {{name}}',
    created: 'Created',
    discontinuedAt: 'Inactivated',
    editTitle: 'Edit referral source',
    empty: 'No Referral Sources set for the member',
    emptyHistory: 'No historical Referral Sources set for the member',
    enrollmentEnd: 'Enrollment End',
    enrollmentStart: 'Enrollment Start',
    externalId: 'External ID',
    history: 'History',
    inactivateConfirmButton: 'Yes, inactivate referral source',
    inactivateTitle: 'Inactivate Referral Source',
    showTitle: 'Show referral source',
    source: 'Source',
    title: 'Referral Sources',
    type: 'Type',
    updated: 'Updated',
  },
  memberDocuments: {
    name: 'Name',
    view: 'View',
    alert: 'Alert',
    uploaded: 'Uploaded By',
    empty: 'No documents for this member.',
  },
  memberSettings: {
    appReminders: 'In App Reminders',
    frequency: 'Frequency',
    rpmReminders: 'RPM Reminders',
    smsReminders: 'SMS Reminders',
    warning: "* Any changes made will override the member's preferences",
  },
  memberWarnings: {
    memberHeader: {
      title: 'Warnings',
    },
    modal: {
      admitDate: 'Admit Date',
      currentCycle: 'Current Cycle',
      dischargeDate: 'Discharge Date',
      dateOnboarded: 'DATE ONBOARDED',
      goToConsent: 'Go to Consent',
      goToEvents: 'Go to Events',
      goToNetwork: 'Go to Network',
      goToTasks: 'Go to Tasks',
      goToTOCDetails: 'Go to TOC List',
      goToVitals: 'Go to Vitals',
      historicalAdherence: 'HISTORICAL ADHERENCE',
      lastAppointment: 'LAST APPOINTMENT',
      lastCarePlan: 'LAST CARE PLAN',
      lastReading: 'Last Reading',
      lastUpdated: 'DATA AS OF',
      nextAppointment: 'NEXT APPOINTMENT',
      nextCarePlan: 'NEXT CARE PLAN',
      status: 'Status',
      timeCCM: 'Monthly CCM MINUTES',
      timeRPM: 'Monthly RPM MINUTES',
      timeNoteCCM:
        'Please note that it is not possible to bill more than 360 CCM minutes with a member in a month. However, please continue to log CCM minutes for internal reporting.',
      timeNoteRPM:
        'Please log additional RPM time this month as CCM time. Contact Triage Leadership with questions.',
      title: 'Warnings for {{member}}',
      totalReadings: 'TOTAL READINGS',
    },
    table: {
      status: 'Status',
    },
  },
  tasks: {
    attemptingToCloseWithOpenSubtasks:
      'You are attempting to close a task with open subtasks, this will close all open subtasks and make the subtasks status “Void”.',
    backToPreviousPage: 'Back to previous page',
    backToParent: 'Go to parent task',
    clearAllFilters: 'Clear all filters',
    createdFromAlert: 'Created from an alert',
    createdFromEvent: 'Created from an event',
    tableHeaders: {
      status: 'Status',
      dueDate: 'Due Date',
      completedAt: 'Completed',
      description: 'Description',
      group: 'Group',
      assignee: 'Assignee',
      member: 'Member',
      createdAt: 'Created at',
    },
    dateRangePicker: {
      custom: 'Custom Date Range',
      last7Days: 'Last 7 days',
      last3Weeks: 'Last 3 Weeks',
      last30Days: 'Last 30 days',
      last365Days: 'Last 365 days',
    },
    addSuccess: 'Task sucessfully created.',
    addTask: 'Add Task',
    address: 'Address',
    assignedTo: 'Assigned To',
    assignedToMe: 'Assigned to me',
    closedTasks: 'Closed Tasks',
    completedDate: 'Completed Date',
    completedDateVal: 'Completed: {{date}}',
    confirmOrderDevice:
      'Are you sure you wish to send this order to SmartMeter?',
    createdAt: 'Created At',
    createdBy: 'Created By',
    dashboardHeader: 'Tasks',
    description: 'Description',
    devices: 'Devices',
    disabled: 'Disabled',
    dueDate: 'Due Date',
    dueDateFrom: 'From',
    dueDateTo: 'To',
    dueDateVal: 'Due: {{date}}',
    dueTime: 'Due Time',
    empty: 'No tasks found.',
    encounter: '{{count}} encounter',
    encounters: '{{count}} encounters',
    fullName: 'Full Name',
    goToTaskPage: 'Task Details',
    group: 'Group',
    languages: 'Languages',
    minutes: 'Minutes',
    noteFieldChanged: '{{field}} change to {{value}}',
    placeOrder: 'Place Order',
    orderDevice: 'Order Device(s)',
    orderDeviceTitle: 'Order SmartMeter Device(s)',
    outcomeIsRequired: 'Outcome is required',
    referralSources: 'Referral Sources',
    searchMember: 'Search member name or Dash ID',
    searchTasks: 'Search Description, Member Name, Member ID or Note',
    seeParentTask: 'See parent task',
    showAll: 'Show all',
    showAllParents: 'Show all parents',
    showAllSubTasks: 'Show all subtasks',
    showClosed: 'Show closed tasks',
    showingFirst: 'Showing first 25 active and assigned to you',
    showVoided: 'Show voided tasks',
    size: 'Size',
    status: 'Status',
    subtaskDetails: 'Subtask Details',
    taskDetails: 'Task Details',
    taskOutcome: 'Task Outcome',
    thisIsASubtask: 'This is a subtask',
    type: 'Type',
    unassigned: 'Unassigned',
    updateTask: 'Update task',
    voidedTasks: 'Voided Tasks',
  },
  notification: {
    title: 'Notifications',
    markAllRead: 'Mark all as read',
    member: 'Member: ',
    noNotifications: "You don't have notifications",
    showAll: 'Show all',
    showUnread: 'Show unread only',
  },
  referrals: {
    title: 'Referrals',
    tableHeaders: [
      'Name',
      'Abbr',
      'Type',
      'Status',
      'Start Date',
      'End Date',
      'Medicare Discovery',
      'Last Modified',
      ' ',
    ],
    abbr: 'Abbreviation',
    addMail: 'Add another email address',
    addReferrals: 'Add Referral',
    affiliation: 'Affiliation',
    automatedIncidentReporting: 'Enable Automated Incident Reporting',
    brand: 'Brand',
    careManagersMail: 'Enable Emails for Care Managers',
    careManagersMailTooltip:
      'If this checkbox is enabled, automated incident emails will also be sent to a member’s care manager, if they have a care manager with an email address on file in their Care Team.',
    editReferrals: 'Edit Referral',
    endDate: 'End Date',
    mail:
      'Enter the email address of each recipient for automated incident reports for this referral source’s members',
    medicare: 'Medicare Discovery',
    name: 'Name',
    rpmOutsourcing: 'RPM Outsourcing',
    rpmOutsourcingTooltip:
      'Identifies if the referral source will be managing RPM fulfillment and onboarding members. When enabled, program statuses may be different and task creation is disabled.',
    startDate: 'Start Date',
    state: 'State',
    status: 'Status',
    type: 'Type',
    modal: {
      add: 'Add Referral',
      edit: 'Edit Referral',
      save: 'Save',
    },
    active: 'Active',
  },
  toc: {
    actions: {
      lastUpdatedTooltip:
        'The TOC/Skilled Dashboard is refreshed with up-to-date data from eCW at regular intervals during the day',
    },
    common: {
      acuityLevel: 'Acuity Level',
      assignee: 'Assignee',
      brand: 'Brand',
      closed: 'Closed',
      handoffDisposition: 'Handoff Disposition',
      homeHealth: 'Home Health',
      initialCP: 'Needs Initial Care Plan',
      language: 'Language',
      memberId: 'Dash ID',
      memberName: 'Member Name',
      noAppointment: 'No Appointment',
      npOwner: 'NP Owner',
      npVisitStatus: 'NP VIsit Status',
      programExtensions: 'Program Extensions',
      programExtensionStatus: 'Program Extension Status',
      referralSources: 'Referral Sources',
      rnVisitStatus: 'RN VIsit Status',
      state: 'State',
      status: 'Status',
      tcmEligibility: 'True TCM Eligibility',
    },
    detail: {
      acuityLevel: 'Acuity Level',
      acuityRationale: 'Acuity Rationale',
      admitDate: 'Admit Date',
      chiefComplaint: 'Chief Complaint',
      diagnosis: 'Primary Diagnosis',
      disabledEditAssignee:
        'You must be assigned to the TOC to perform this action',
      disabledEditStatus: 'The TOC must be opened to perform this action',
      disabledTooltipDischarged:
        'It\'s not possible to set "Discharged" status until "Discharge Date" is filled in',
      dischargeDate: 'Discharge Date',
      encountersTitle: {
        SKILLED: 'TOC / Skilled Encounters',
        TOC: 'TOC Encounters',
      },
      eventsTitle: {
        SKILLED: 'Skilled Events history',
        TOC: 'Hospitalizations / ER Visits history',
      },
      episodeStartDate: 'Episode Start Date',
      episodeEndDate: 'Episode End Date',
      episodeTitle: 'Episode Details',
      facility: 'Facility Name',
      goToEvents: 'Go to Events',
      handoffDisposition: 'Handoff Disposition',
      handoffRecommendations: {
        SKILLED: 'Handoff Notes',
        TOC: 'Handoff Recommendations',
      },
      hospitalizationTitle: 'Hospitalization / Rehab Details',
      infoTooltipHandoff:
        'When "Handoff" status is selected manually, "Handoff Disposition" is defaulted to "Withdrew"',
      logEncounter: 'Log Encounter',
      medicalRecords: 'Medical Records Requested',
      messageDefaultHandoffDisposition:
        '"Handoff disposition" field defaulted to "Withdrew"',
      noTOCEncounters: {
        SKILLED: 'No TOC / Skilled Encounters',
        TOC: 'No TOC Encounters',
      },
      noTOCEvents: {
        SKILLED: 'No Skilled Events',
        TOC: 'No TOC Events',
      },
      npVisit: 'NP Virtual Visit Completed',
      openEvent: 'Open Event',
      rnCarePlan: 'RN Care Plan Completed',
      seeSkilledDetails: 'See Skilled Details',
      seeTOCDetails: 'See TOC Details',
      skilledAgency: 'Skilled Agency',
      tcmEligible: 'TCM Eligible',
      tcmNotEligible: 'TCM Not Eligible',
      tcmPending: 'TCM Pending',
      tcmNull: 'TCM Not Evaluated',
      tocTitle: {
        SKILLED: 'Record Details',
        TOC: 'TOC Details',
      },
      wargningCloseSkilled:
        'Are you sure you want to close the Skilled record?',
    },
    sort: {
      admit: 'Admit',
      discharge: 'Discharge',
      episodeEnd: 'Episode End',
      episodeStart: 'Episode Start',
      episodeEndNewest: 'Episode End: Newest',
      episodeEndOldest: 'Episode End: Oldest',
      episodeStartNewest: 'Episode Start: Newest',
      episodeStartOldest: 'Episode Start: Oldest',
      facilityName: 'Facility Name',
      handoff: 'Handoff',
      newestDate: 'Newest: {{date}} Date',
      newestTOCEncounter: 'Newest: TOC Encounter',
      npVisitDateNewest: 'Newest: NP Visit',
      npVisitDateOldest: 'Oldest: NP Visit',
      rnCarePlanDateNewest: 'Newest: RN Care Plan',
      rnCarePlanDateOldest: 'Oldest: RN Care Plan',
      oldestDate: 'Oldest: {{date}} Date',
      oldestTOCEncounter: 'Oldest: TOC Encounter',
      status: 'Status',
    },
    table: {
      admitDate: 'Admit Date',
      assignee: 'Assignee',
      diagnosis: 'Diagnosis',
      dischargeDate: 'Discharge Date',
      episodeEndDate: 'Episode End Date',
      episodeStartDate: 'Episode Start Date',
      facility: 'Facility',
      handoffDate: 'Handoff Date',
      handoffStatus: 'Handoff Disposition',
      lastTOCEncounter: 'Last TOC Encounter',
      lastSkilledEncounter: 'Last Encounter',
      npVisit: 'NP Visit',
      referralSource: 'Ref. Sources',
      rnCarePlan: 'RN Care Plan',
      status: 'Status',
      type: 'Type',
    },
    tabs: {
      all: 'All',
      closed: 'Closed',
      discharged: 'Discharged',
      dischargedEligible: '< 2 Business Days',
      dischargedPostInitial: '> 2 Business Days',
      handoff: 'Handoff',
      inpatient: 'Inpatient',
      open: 'Open',
      rehab: 'Rehab',
      skilled: 'Skilled',
      toc: 'TOC',
    },
    title: 'TOC List',
  },
  careTracks: {
    interventionsLabels: {
      proactive: 'Proactive',
      responsive: 'Responsive',
    },
  },
};
