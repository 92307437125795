import React, { useEffect, useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { translate } from '@vestahealthcare/common/i18n';
import { EmployeeGroup } from '@vestahealthcare/common/models';
import { TaskDefinition } from '@vestahealthcare/common/models/TaskDefinition';

import { EmptyState, IconButton, Table, UpdatedBy } from 'styleguide-v2';

interface TaskDefinitionExtended extends TaskDefinition {
  employeeGroup?: EmployeeGroup;
}

interface Props {
  definitions?: TaskDefinitionExtended[];
  onEdit: (definition: TaskDefinitionExtended) => void;
}

const COLUMNS = ({ onEdit }: Props) => [
  {
    dataCy: 'task-definition-name',
    headerName: translate('taskDefinitions.name'),
    sortable: true,
    component: ({ name, reference }: TaskDefinitionExtended) => (
      <div>
        <p className="no-margin">{name}</p>
        <p className="no-margin hint">{reference}</p>
      </div>
    ),
  },
  {
    dataCy: 'task-definition-group',
    field: 'employeeGroup.name',
    headerName: translate('taskDefinitions.group'),
    width: 200,
  },
  {
    dataCy: 'task-definition-created',
    headerName: translate('taskDefinitions.created'),
    width: 200,
    component: ({ createdBy, createdAt }: TaskDefinitionExtended) => (
      <UpdatedBy date={createdAt} user={createdBy?.fullName} />
    ),
  },
  {
    dataCy: 'task-definition-status',
    headerName: translate('taskDefinitions.status'),
    width: 100,
    component: ({ status }: TaskDefinitionExtended) =>
      translate(`taskDefinitions.taskStatus.${status.valueOf()}`),
  },
  {
    dataCy: 'task-definition-edit',
    headerName: '',
    width: 50,
    component: (definition: TaskDefinitionExtended) => (
      <IconButton onClick={() => onEdit(definition)} size="small">
        <EditIcon />
      </IconButton>
    ),
  },
];

export const TaskDefinitionsTable = ({ definitions, onEdit }: Props) => {
  const [sortedData, setSortedData] = useState<TaskDefinitionExtended[]>([]);
  const columns = useMemo(() => COLUMNS({ onEdit }), [onEdit]);

  useEffect(() => {
    setSortedData(definitions ? [...definitions] : []);
  }, [definitions]);

  const sortDataByName = (direction?: string) => {
    setSortedData(
      [...sortedData].sort(
        (a: TaskDefinitionExtended, b: TaskDefinitionExtended) =>
          a.name.localeCompare(b.name) * (direction === 'desc' ? -1 : 1),
      ),
    );
  };

  return definitions?.length ? (
    <Table
      config={{
        columns,
        compact: true,
        data: sortedData,
        size: 'l',
      }}
      data-cy="task-template-table"
      onSort={(column) => sortDataByName(column.direction)}
    />
  ) : (
    <EmptyState>{translate('taskDefinitions.empty')}</EmptyState>
  );
};
export default TaskDefinitionsTable;
